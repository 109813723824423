(function(jy, Ctg, $) {

    if (!jy) {
        throw 'Missing jitify';
    }
    if (!Ctg) {
        throw 'Missing Ctg.Core';
    }
    if (!$) {
        throw 'Missing jQuery';
    }
    if (!$.fn.sortable) {
        throw 'Missing jQuery sortable';
    }

    Ctg.ColumnEditor = jy.define({
        extend: 'jy.PBase',

        constructor: function(options) {
            this._columnsConfig = options.columnsConfig;
            this._$activeList = $(options.active);
            this._$inactiveList = $(options.inactive);

            var self = this;
            this._$activeList.add(this._$inactiveList)
                .sortable({
                    connectWith: '.sortable',
                    opacity: 0.7,
                    handle: '.fa-bars'
                })
                .on('click', 'li', function() {
                    var $targetList = $(this).parent().is(self._$activeList) ? self._$inactiveList : self._$activeList;
                    $targetList.append(this);
                }).disableSelection();

            this.setActiveColumns(options.columns || []);
        },

        _renderRow: function(column) {
            return $('<li class="ui-state-default"><i class="fa fa-bars"></i> ' + column.name + '</li>')
                .data('ctg-column-editor.field', column.field);
        },

        _renderToList: function($list, columnKeys) {
            $list.empty();
            for (var i = 0, c = columnKeys.length; i < c; i++) {
                var column =  this._columnsConfig[columnKeys[i]];
                if (!column) {
                    // TODO: error loging
                    continue;
                }
                $list.append(this._renderRow(column));
            }
        },

        _render: function(active, inactive) {
            this._renderToList(this._$activeList, active);
            this._renderToList(this._$inactiveList, inactive);
        },

        activeAll: function() {
            var columns = [];
            for (var field in this._columnsConfig) {
                columns.push(field);
            }
            this._render(columns, []);
        },

        setActiveColumns: function(columns) {
            var map = {};
            for (var i = 0, c = columns.length; i < c; i++) {
                map[columns[i]] = true;
            }
            var inactive = [];
            for (var field in this._columnsConfig) {
                if (!map[field]) {
                    inactive.push(field);
                }
            }
            this._render(columns, inactive);
        },


        getActiveColumns: function() {
            return this._$activeList.find('li').map(function() {
                return $(this).data('ctg-column-editor.field');
            }).toArray();
        },
    });
})(jy, Ctg, jQuery);

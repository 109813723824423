(function() {
    'use strict';

    angular
        .module('packaging')
        .controller('PackagingController', Controller);

    Controller.$inject = ['GridDataSource', 'ServerGateway', '$q', 'config', '$scope'];

    function Controller(GridDataSource, ServerGateway, $q, config, $scope) {
        var pendingSort, serverGateway, lastDetail = null;
        var vm = this;

        vm.getBatchLocal = getBatchLocal;
        vm.isLoading  = false;
        vm.rowsLocal  = {};
        vm.rows       = [];
        vm.columns    = config.columns;
        vm.sorts      = [];
        vm.sortChange = sortChange;
        vm.done       = done;
        vm.skip       = skip;
        vm.scan       = scan;
        vm.scanned    = scanned;
        vm.showSpecial = showSpecial;
        vm.input      = {};
        vm.printLabelButtonLoading = false;
        vm.hide = hide;
        vm.queue = null;
        vm.autoLoadQueue = autoLoadQueue;
        vm.loadQueue = loadQueue;
        vm.loadNonPrintItem = loadNonPrintItem;
        vm.loadReprocesOrder = loadReprocesOrder;
        vm.unloadQueue = unloadQueue;
        vm.progress = 0;
        vm.progressAll = 0;
        vm.printLabelCache = [];
        vm.reloadAuto = reloadAuto;
        vm.autoMode = false;
        vm.enforceComplexQueueScanned = false;
        vm.hasPending = true;
        vm.unprintLabel = unprintLabel;
        vm.showQAHistory = showQAHistory;
        vm.reject = reject;
        vm.saveReject = saveReject;
        vm.stationMeta = null;
        vm.scrap = scrap;
        vm.showMaterialList = showMaterialList;
        vm.unloadNonPrintMaterial = unloadNonPrintMaterial;
        vm.specialMode = null;
        vm.addSerialNumber = addSerialNumber;
        vm.invalidSerialNumber = invalidSerialNumber;
        vm.serialNumber = [];
        vm.serialNumberKeypress = serialNumberKeypress;
        vm.onChangeSerialNumber = onChangeSerialNumber;
        vm.downloadJpYamatoFiles = downloadJpYamatoFiles;
        vm.downloadYamatoSkuCsv = downloadYamatoSkuCsv;

        // detail related
        vm.detailId         = null;
        vm.detail           = null;
        vm.openDetailDialog = openDetailDialog;
        vm.printLabel = printLabel;
        vm.saveSpecialHandling = saveSpecialHandling;
        vm.savePresetSpecialHandling = savePresetSpecialHandling;
        vm.enforceComplexQueueScanned = config.enforceComplexQueueScanned;


        // detai footer
        vm.indexOfBatch   = indexOfBatch;
        vm.detailNext     = detailNext;
        vm.detailPrevious = detailPrevious;
        vm.itemProblemList = [
            {'key':'Printing Quality (Special Handling)', 'value':'Printing Quality and Special Handling (印刷問題及需特別處理)'},
            {'key':'Printing Quality (Reprint)', 'value':'Printing Quality and Reprint (印刷問題及重印)'},
            {'key':'Other', 'value':'Other (其他)'},
        ];
        vm.orderProblemList = [
            {'key':'Cannot Print Label', 'value':'Cannot Print Label (沒有郵遞標籤)'},
            {'key':'Printing Not Found', 'value':'Printing Not Found (找不到手機殻)'},
            {'key':'Out of Stock', 'value':'Out of Stock (欠缺物料)'},
            {'key':'Other', 'value':'Other (其他)'},
        ];
        vm.trashList = [
            {'key':'0', 'value':'No - 不需棄置印刷品'},
            {'key':'1', 'value':'Yes - 需棄置印刷品'},
        ];
        vm.rejectRemarkList = [];
        /*vm.rejectRemarkList = [
            {'key':'1 - 塵', 'value':'1 - 塵'},
            {'key':'2 - 有間', 'value':'2 - 有間'},
            {'key':'3 - 揩到墨頭', 'value':'3 - 揩到墨頭'},
            {'key':'4 - 移位／出界', 'value':'4 - 移位／出界'},
            {'key':'5 - 化開', 'value':'5 - 化開'},
            {'key':'6 - 不對色', 'value':'6 - 不對色'},
            {'key':'7 - 擺錯殼／擺錯殻顏色', 'value':'7 - 擺錯殼／擺錯殻顏色'},
            {'key':'8 - 隻殻有問題(有氣／有雜質／有花／有怪紋路／滲漏）', 'value':'8 - 隻殻有問題(有氣／有雜質／有花／有怪紋路／滲漏）'},
            {'key':'9 - 甩色', 'value':'9 - 甩色'},
            {'key':'10 - Artwork 有問題', 'value':'10 - Artwork 有問題'},
            {'key':'11 - 有黑色烘', 'value':'11 - 有黑色烘'},
            {'key':'12 - 印多咗', 'value':'12 - 印多咗'},
            {'key':'13 - Order Cancelled', 'value':'13 - Order Cancelled'},
            {'key':'14 - White Design (but with white product i.e folio)', 'value':'14 - White Design (but with white product i.e folio)'},
            {'key':'15 - Rrip錯file', 'value':'15 - Rrip錯file'},
            {'key':'16 - Printing Not Found', 'value':'16 - Printing Not Found'},
            {'key':'17 - 印後有bubble', 'value':'17 - 印後有bubble'},
            {'key':'18 - COPYRIGHT Problem', 'value':'18 - COPYRIGHT Problem'},
            {'key':'19. 相框白底漏罅', 'value':'19. 相框白底漏罅'},
            {'key':'other', 'value':'Other (其他)'},
        ];*/
        vm.problem = 'Other';
        vm.remark = null;
        vm.queue = config.queues;

        // TODO: remove it later debug use
        vm.update = update;

        //Cloud Printer
        vm.openCloudPrinterSettingModal = openCloudPrinterSettingModal;
        vm.deleteCloudPrinter = deleteCloudPrinter;
        vm.cloudprinters = [];

        initialize();

        function setDetail(batchItem) {
            vm.detailId = (batchItem === null ? null : batchItem.id);
            vm.detail = batchItem;
            if (batchItem) {
                vm.detail.shouldShowSticker = false;
                if (vm.detail.order.createdAt) {
                    if (Date.parse(vm.detail.order.createdAt) >= Date.parse('2016-10-21 15:00:00') && Date.parse(vm.detail.order.createdAt) <= Date.parse('2016-10-25 15:00:00')) {
                        vm.detail.shouldShowSticker = true;
                    }
                }
            }
            
            

            // vm.input = {};
            // if(batchItem && batchItem.orderItems.data) {
            //     for(var idx in batchItem.orderItems.data)
            //         if(typeof batchItem.orderItems.data[idx].inventoryItem !== 'undefined') {
            //             var obj        = {};
            //             obj.serialCode = batchItem.orderItems.data[idx].inventoryItem.serial_number;
            //             obj.scanning   = false;
            //             obj.scanned    = false;
            //             if(typeof obj.serialCode == 'string' && obj.serialCode.length > 0)
            //                 obj.scanned = true;

            //             vm.input[batchItem.orderItems.data[idx].id] = obj;
            //         }
            // }
        }

        function initialize() {
            // sever side gateway setup
            serverGateway = new ServerGateway({
                endPoints: {
                    'findDelivery': { path: 'deliveries/{id}', method: 'GET' },
                    'finishShipping': { path: 'deliveries/{id}/done',  method: 'POST' },
                    'linkSerialCode': { path: 'order-items/{id}/linkSerialCode',  method: 'PUT' },
                    'printLabel': { path: 'print-label',  method: 'POST' },
                    'unprintLabel': { path: 'unprint-label',  method: 'POST' },
                    'specialHandling': { path: 'deliveries/{id}/special',  method: 'POST' },
                    'printJob': { path: 'print-jobs/{id}',  method: 'GET' },
                    'hideDelivery': { path: 'deliveries/{id}/deletePackingTask',  method: 'POST' },
                    'loadStation': { path: 'pack-stations/{id}/load',  method: 'POST' },
                    'loadStationWithNonPrint': { path: 'pack-stations/{id}/load-non-print',  method: 'POST' },
                    'loadStationWithReprocessOrder': { path: 'pack-stations/{id}/load-reprocess',  method: 'POST' },
                    'unloadStation': { path: 'pack-stations/{id}/unload',  method: 'POST' },
                    'getPackStation': { path: 'pack-stations/{id}',  method: 'GET' },
                    'scrapDelivery': { path: 'deliveries/{id}/scrapDelivery', method: 'POST' },
                    'moveToPrintSpecial': { path: 'production-items/{id}/moveToPrintSpecial', method: 'POST' },
                    'unloadStationByRawMaterial': { path: 'pack-stations/{id}/unloadByRawMaterial',  method: 'POST' },
                    'getCloudPrinter': { path: 'packing/getCloudPrinter/{id}', method: 'GET'},
                    'deleteCloudPrinter': {path: 'packing/deleteCloudPrinter/{id}', method: 'POST'},
                    'qaRejectList': {path: 'qaRejectList', method: 'GET'},
                    'syncSerialNumber': { path: 'order-items/{id}/serial-number',  method: 'POST' },
                    'checkAndGetPackStationTask': { path: 'deliveries/{id}/checkAndGetPackStationTask',  method: 'GET' },
                    'associateSerialNumber': { path: 'function/associate-serial-number', method: 'POST'},
                }
            });
            vm.gridDataSource = new GridDataSource({
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway,
                perPage: 10000
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.lenght; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // load the first batch of data
            load();

            // refresh data periodically
            setInterval(update, 10000);

            serverGateway.ajax('qaRejectList').then(function(response){
                vm.rejectRemarkList = response.data.data;
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });

            // detail modal setup
            $('#printingBatchModal')
                .modal({
                    show: false
                })
                .on('hide.bs.modal', function(event) {
                    console.log('#printingBatchModal is hidden');
                    setDetail(null);
                    // vm.autoMode = false;
                });

            $("#queue").focus();
            // $("#progress-block").show();
            $(".ng-preload").show();
            vm.queueDescs = config.queueDescs;
        }

        function sortChange(sorts) {
            console.log('sortChange', arguments);

            vm.gridDataSource.prop('sorts', sorts);
            load();
        }

        function done(delivery, callback) {
            const callbackFunction = () => {
                if (!vm.detail.shippingLabelPrinted && !vm.printLabelCache[delivery.id]) {
                    if (!confirm("Continue without printing label?")) {
                        return;
                    }
                }
                let preSealBoxName = config.canSkipBoxName ? 'hs-sz-bypass' : prompt('Please enter the packing box name');
                if(preSealBoxName == '' || preSealBoxName == null){
                    $.bootstrapGrowl('Box name is empty', {ele: 'body', type: 'error'});
                    return
                }
                
                vm.detail.detailButtonLoading = 'done';
                serverGateway.ajax('finishShipping', {id: delivery.id},{boxName: preSealBoxName, stationId: config.stationId}).then(function(response) {
                    if(response.data) {
                        $('#printingBatchModal').modal('hide');
                        update(function (response) {
                            if (vm.rows.length && vm.autoMode) {
                                openNextDetailDialog();
                                // openDetailDialog(response.data[0])
                            } else {
                                $('#printingBatchModal').modal('hide');
                                console.log('no next item');
                            }
                        });
                        callback && callback();
                    }
                }, function (response) {
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            };
            callCheckPackStationTask(callbackFunction);
        }

        function scanned() {
            for(var idx in vm.input) {
                if(vm.input[idx].scanned == false) return false;
            }
            return true;
        }

        function scan(e, id) {
            e.preventDefault();

            var serialCode = vm.input[id].serialCode;
            if(serialCode == '' || vm.input[id].scanned || vm.input[id].scanning) return;
            vm.input[id].scanning = true;

            serverGateway.ajax('linkSerialCode', {id: id}, {code: serialCode}).then(function(response) {
                vm.input[id].scanning = false;
                if(response.data) {
                    vm.input[id].scanned = true;
                    if(vm.scanned()) {
                        // auto done if all scanned
                        var refId = vm.detail.refId;
                        vm.done(vm.detail, function() {
                            $("#doneShippingModal").modal("show");
                            $("#doneShippingModal .box-body").html("The order " + refId + " shipped.");
                        });
                    }
                    update();
                }
            });
        }

        function indexOfBatch(batchItemId) {
            var index = -1;
            vm.rows.forEach(function(row, i) {
                if (batchItemId === row.id) {
                    index = i;
                    return false;
                }
            });

            return index;
        }

        function update(callback) {
            if (!vm.updateCallback) {
                vm.updateCallback = [];
            }
            if (callback) {
                vm.updateCallback.push(callback);
            }
            return vm.gridDataSource
                .update()
                .then(function(response) {
                    // console.log(response);
                    vm.stationMeta = response.meta.data;
                    var detailBatch = null;
                    vm.rows.splice(0, vm.rows.length);
                    response.data.forEach(function(datum) {
                        if (vm.detailId === datum.id) {
                            detailBatch = datum;
                        }
                        vm.rows.push(datum);
                    });

                    // if (response.data.length > 0 && vm.autoMode) {
                    //     // vm.waiting = false;
                    //     openNextDetailDialog();
                    //     // openDetailDialog(response.data[0]);
                    // }
                    vm.hasPending = false;
                    if (response.data.length == 0) {
                        vm.autoMode = false;
                    }

                    // handle detail dialog update
                    if (vm.detailId !== null) {
                        if (detailBatch === null) {
                            // handle may be removed case
                            // TODO: change it with deliveries endpoint
                            serverGateway.ajax('findDelivery', {id: vm.detailId}).then(function(response) {
                                if (response.data === '') {
                                    vm.detail = null;
                                } else {
                                    vm.detail = response.data.data;
                                }
                            });
                        } else {
                            setDetail(detailBatch);
                            // vm.detail = detailBatch;
                        }
                    }

                    vm.progressAll = vm.rows.length;
                    vm.progress = 0;
                    vm.rows.forEach(function (delivery, index) {
                        if (delivery.packTaskStatus != 'pending') {
                            vm.progress++;
                        } else {
                            vm.hasPending = true;
                        }
                    });
                    if (vm.updateCallback) {
                        var tempCallback = vm.updateCallback;
                        vm.updateCallback = [];
                        for (var index in tempCallback) {
                            tempCallback[index]();
                        }
                    }

                    if (vm.autoMode && vm.detail && vm.detail.packTaskStatus != 'pending') {
                        openNextDetailDialog();
                    }
                });
        }

        function reloadDetail(batchId) {
            if (batchId !== vm.detailId) {
                return $q(function(resolve) {
                    resolve();
                });
            }

            return serverGateway.ajax('findDelivery', {id: batchId}).then(function(response) {
                if (response.data === '') {
                    vm.detail = null;
                    return;
                }

                var index = indexOfBatch(batchId);
                if (index === -1) return;

                vm.rows[index] = vm.detail;
            });
        }

        function load() {
            console.log('load');

            vm.isLoading = true;

            return vm.gridDataSource
                .load()
                .then(function(response) {
                    console.log('load callback');
                    vm.rows = response.data;
                    vm.isLoading = false;

                    updateLoadMoreBlock();
                    // if (response.data.length) {
                    //     openNextDetailDialog();
                    //     // openDetailDialog(response.data[0]);
                    // }
                    // } else {
                    //     vm.waiting = true;
                    // }
                });
        }

        function loadNext() {
            console.log('loadNext');

            return vm.gridDataSource
                .loadNext()
                .then(function(response) {
                    console.log('loadNext callback');
                    vm.rows = vm.rows.concat(response.data);
                    updateLoadMoreBlock();
                });
        }

        function updateLoadMoreBlock() {
            if (vm.gridDataSource.hasNext()) {
                setTimeout(enableLoadMore, 10);
            } else {
                disableLoadMore();
            }
        }

        function enableLoadMore() {
            var waypoint = $('#rowLoading')
                .removeClass('hidden')
                .waypoint(function(event, direction) {
                    console.log('enableLoadMore: loadNextRows');
                    waypoint[0].destroy('remove');
                    loadNext();
                }, {
                    offset: '100%'
                });
        }

        function getBatchLocal(batchId) {
            return vm.rowsLocal[batchId] = vm.rowsLocal[batchId] ? vm.rowsLocal[batchId] : {};
        }

        function disableLoadMore() {
            $('#rowLoading').addClass('hidden');
        }

        function openNextDetailDialog() {
            var found = false;
            vm.rows.forEach(function (delivery, index) {
                if (!found && delivery.packTaskStatus == 'pending') {
                    openDetailDialog(delivery);
                    found = true;
                }
            });
            if (!found) {
                $('#confirmDialog').modal('show');
            }
        }

        function openDetailDialog(row, columns) {
            vm.detailId = row.id;
            // const callbackFunction = () => {
                setDetail(row);
                $('#printingBatchModal').modal('show');
                if (vm.autoMode && (!vm.enforceComplexQueueScanned || vm.detail.readyForPackComplexScanned) && !vm.detail.shippingLabelPrinted && vm.detail.readyForPack) {
                    vm.printLabel(vm.detail);
                }
            // };
            // callCheckPackStationTask(callbackFunction);
        }

        function detailNext() {
            var index = indexOfBatch(vm.detailId);
            if (index === -1) {
                // TODO: show error message
                return;
            }

            if (index === vm.rows.length - 1) {
                loadNext().then(function() {
                    setDetail(vm.rows[index + 1]);
                });
            } else {
                setDetail(vm.rows[index + 1]);
            }
        }

        function detailPrevious() {
            var index = indexOfBatch(vm.detailId);
            if (index === -1) {
                // TODO: show error message
                return;
            }
            setDetail(vm.rows[index - 1]);

        }

        function trackPrintStatus(printJobId, previousStatus, retryCount) {
            if (retryCount <= 0) {
                $.bootstrapGrowl("Print shipping label timeout: " + printJobId, {ele: 'body', type: 'error'});
                return;
            }
            serverGateway.ajax('printJob', {id: printJobId})
            .then(function(response) {
                if (response.data.data.status != previousStatus) {
                    $.bootstrapGrowl(response.data.data.status, {ele: 'body', type: 'info'});
                }
                if (response.data.data.status == 'printing' || response.data.data.status == 'created') {
                    setTimeout(function () {
                        trackPrintStatus(printJobId, response.data.data.status, retryCount -1);
                    }, 1000);
                }
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function printLabel(delivery) {
            const callbackFunction = () => {
                if (vm.detail.shippingLabelPrinted || vm.printLabelCache[delivery.id]) {
                    if (!confirm("Printing label again?")) {
                        return;
                    }
                }
                vm.printLabelCache[delivery.id] = true;
                vm.printLabelButtonLoading = true;
                serverGateway.ajax('printLabel', null, {stationId: config.stationId, deliveryId: delivery.id})
                .then(function(response) {
                    vm.printLabelButtonLoading = false;
                    // if (response.data === '') {
                    //     console.log(response);
                    // } else {
                    //     $.each(response.data.data, function (index, printJob) {
                    //         trackPrintStatus(printJob.id, printJob.status, 10);
                    //         // console.log(element);
                    //     });
                    // }
                    if (!delivery.readyForPack) {
                        reloadDetail(delivery.id)
                    }
                }, function (response) {
                    vm.printLabelButtonLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            };
            callCheckPackStationTask(callbackFunction);
        }

        function showSpecial(delivery) {
            $('#specialHandlingDialog').modal('show')
            vm.specialHandlingItem = delivery;
        }

        function savePresetSpecialHandling(problem) {
            serverGateway.ajax('specialHandling', {id: vm.detail.id}, {problem: problem, remark:null, skipFromPackStation: 'Y'})
            .then(function(response) {
                vm.remark = null;
                vm.problem = 'Other';
                $('#specialHandlingDialog').modal('hide')
                $('#printingBatchModal').modal('hide');
                update(function (response) {
                    if (vm.rows.length && vm.autoMode) {
                        openNextDetailDialog();
                        // openDetailDialog(response.data[0])
                    } else {
                        $('#printingBatchModal').modal('hide');
                    }
                });
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function saveSpecialHandling() {
            serverGateway.ajax('specialHandling', {id: vm.detail.id}, {problem: vm.problem, remark:vm.remark, skipFromPackStation: 'Y'})
            .then(function(response) {
                vm.remark = null;
                vm.problem = 'Other';
                $('#specialHandlingDialog').modal('hide')
                $('#printingBatchModal').modal('hide');
                update(function (response) {
                    if (vm.rows.length && vm.autoMode) {
                        openNextDetailDialog();
                        // openDetailDialog(response.data[0])
                    } else {
                        $('#printingBatchModal').modal('hide');
                    }
                });
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function skip(delivery) {
            const callbackFunction = () => {
                if (delivery.notReadyForPackReason) {
                    savePresetSpecialHandling(delivery.notReadyForPackReason);
                } else {
                    showSpecial(delivery);
                }
            };
            callCheckPackStationTask(callbackFunction);
        }

        function hide(delivery) {
            vm.hideButtonLoading = true;
            serverGateway.ajax('hideDelivery', {id: delivery.id}).then(function(response) {
                vm.hideButtonLoading = false;
                update();
                $('#printingBatchModal').modal('hide');
            }, function (response) {
                vm.hideButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function autoLoadQueue(queue) {
            vm.autoMode = true;
            vm.loadQueue(queue);
        }

        function loadQueue(queue) {
            if (!queue) {
                $.bootstrapGrowl("Empty queue", {ele: 'body', type: 'error'});
                return;
            }
            serverGateway.ajax('loadStation', {id: config.stationId}, {queue: vm.queue})
            .then(function(response) {
                vm.queueDescs = response.data.data;
                // console.log(response);
                update(function () {
                    if (vm.autoMode) {
                        openNextDetailDialog();
                    }
                });
                // $.bootstrapGrowl("Loaded", {ele: 'body', type: 'success'});
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function loadNonPrintItem(limit) {
            let sku = null;
            // if (!limit) {
                sku = prompt('Filter by SKU?');
            // }

            serverGateway.ajax('loadStationWithNonPrint', {id: config.stationId}, {limit: limit, sku: sku, specialMode: vm.specialMode})
            .then(function(response) {
                // console.log(response);
                // $.bootstrapGrowl("Loaded", {ele: 'body', type: 'success'});
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function loadReprocesOrder() {
            serverGateway.ajax('loadStationWithReprocessOrder', {id: config.stationId})
            .then(function(response) {
                // console.log(response);
                // $.bootstrapGrowl("Loaded", {ele: 'body', type: 'success'});
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function unloadQueue(queue) {
            vm.autoMode = false;
            vm.queueDescs = null;
            // if (!queue) {
            //     $.bootstrapGrowl("Empty queue", {ele: 'body', type: 'error'});
            //     return;
            // }
            $('#confirmDialog').modal('hide');
            serverGateway.ajax('unloadStation', {id: config.stationId})
            .then(function(response) {
                vm.queue = null;
                update();
                $.bootstrapGrowl("Unloaded", {ele: 'body', type: 'success'});
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function reloadAuto() {
            vm.autoMode = true;
            // vm.autoMode = !vm.autoMode;
            // if (vm.autoMode) {
            openNextDetailDialog();
            update();
            // }
        }

        function unprintLabel(delivery) {
            serverGateway.ajax('unprintLabel', null, {deliveryId: delivery.id})
            .then(function(response) {
                vm.printLabelButtonLoading = false;
                if (response.data === '') {
                    console.log(response);
                }
                update();
            }, function (response) {
                vm.printLabelButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function reject(orderItem, productionItem) {
            $('#rejectReasonDialog').modal('show')
            vm.rejectItem = productionItem;
            vm.rejectOrderItem = orderItem;
            $('#problem').val($('#problem>option')[0].value);
            vm.remark = '';
            vm.remarkOther = '';
            vm.trashItem = '1';
        }

        function saveReject() {
            var remark = vm.remark;
            // if (vm.remark == 'other') {
            //     remark = vm.remarkOther;
            // }
            if (vm.trashItem == '') {
                alert("Please select trash item.");
                return;
            }
            if (vm.trashItem == '1' && (!remark || remark == '')) {
                alert("Please input remark.");
                return;
            }
            invalidSerialNumber(vm.rejectOrderItem);
            // console.log("save reject");
            $('#rejectReasonDialog').modal('hide');
            serverGateway.ajax('moveToPrintSpecial', { id: vm.rejectItem.id }, { problem: vm.problem, remark: remark, remarkOther: vm.remarkOther, 'trashItem': vm.trashItem }).then(function (response) {
                update();
            });
            return false;
        }

        function showQAHistory(logs) {
            vm.qaHistory = logs;
            $('#qaHistoryDialog').modal('show');
        }

        function scrap(delivery) {
            vm.scrapButtonLoading = true;
            for (var i in delivery.orderItems) {
                invalidSerialNumber(delivery.orderItems[i]);
            }
            serverGateway.ajax('scrapDelivery', {id: delivery.id}).then(function(response) {
                vm.scrapButtonLoading = false;
                update();
                $('#printingBatchModal').modal('hide');
            }, function (response) {
                vm.scrapButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function showMaterialList() {
            vm.materialListMap = {};
            vm.materialList = [];
            for (var index in vm.rows) {
                var delivery = vm.rows[index];
                for (var index2 in delivery.orderItems) {
                    var orderItem = delivery.orderItems[index2];
                    for (var index3 in orderItem.productionItems) {
                        var productionItem = orderItem.productionItems[index3];
                        if (!productionItem.rawMaterial.allow_printing || productionItem.rawMaterial.allow_printing == '0') {
                            if (vm.materialListMap[productionItem.rawMaterial.name]) {
                                vm.materialListMap[productionItem.rawMaterial.name].count++;
                            } else {
                                vm.materialList.push(productionItem.rawMaterial.name);
                                vm.materialListMap[productionItem.rawMaterial.name] = {};
                                vm.materialListMap[productionItem.rawMaterial.name].count = 1;
                                vm.materialListMap[productionItem.rawMaterial.name].stock_quantity = productionItem.rawMaterial.stock_quantity ? parseInt(productionItem.rawMaterial.stock_quantity) : 0;
                                vm.materialListMap[productionItem.rawMaterial.name].name = productionItem.rawMaterial.name;
                                vm.materialListMap[productionItem.rawMaterial.name].description = productionItem.rawMaterial.description;
                                vm.materialListMap[productionItem.rawMaterial.name].shortDescription = productionItem.rawMaterial.shortDescription;
                            }
                        }
                    }
                }
            }
            $('#materialListDialog').modal('show');
        }

        function unloadNonPrintMaterial(sku) {
            serverGateway.ajax('unloadStationByRawMaterial', {id: config.stationId}, {sku: sku})
            .then(function(response) {
                update();
                if (response.data.success) {
                    $('#materialListDialog').modal('hide');
                    $.bootstrapGrowl("Unloaded", {ele: 'body', type: 'success'});
                } else {
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                }
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function openCloudPrinterSettingModal(){
            console.log('openCloudPrinterSettingModal');
            serverGateway.ajax('getCloudPrinter', {id: config.stationId}).then(function(response){
                vm.cloudprinters = response.data;
                console.log(vm.cloudprinters);
            });
            //Get the cloud printers
            //Open Modal
            $('#cloudPrinterSettingModal').modal('show');
        }

        function deleteCloudPrinter(id){
            console.log('deleteCloudPrinter',{id: id});
            if(!confirm('Confirm?')) return;
            serverGateway.ajax('deleteCloudPrinter',{id: id}).then(function(response){
                $('#cloudPrinterSettingModal').modal('hide');
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                $('#cloudPrinterSettingModal').modal('hide');
            });
        }

        function addSerialNumber(orderItem, productionItem = null) {
            const callbackFunction = () => {
                if(productionItem){
                    submitSerialNumber(orderItem, productionItem);
                    return;
                }
                let serialNumberSelector = document.querySelector('[name="serialNumber-' + orderItem.id + '"]');
                let serialNumber = serialNumberSelector.value;
                const regex = /casetify\.com\/auth\/([\w\-]*)/igm;
                let regexresult = regex.exec(serialNumber)
                if (regexresult && regexresult[1]) {
                    serialNumber = regexresult[1]
                }
    
                if (orderItem.serialNumber == serialNumber) return;
                if (orderItem.serialNumber && !confirm('Confirm Update?')) return;
                
                vm.serialNumberLoading = true;
                serverGateway.ajax('syncSerialNumber', { id: orderItem.id }, { serialNumber: serialNumber, packStationId: config.stationId })
                    .then(function (response) {
                        vm.serialNumberLoading = false;
                        if (response.data.success) {
                            orderItem.serialNumber = serialNumber;
                            vm.detail.orderItems.map((detailOrderItem)=>{
                                if(detailOrderItem.id == orderItem.id){
                                    detailOrderItem.serialNumber = serialNumber;
                                    return detailOrderItem;
                                }
                            });
                            $.bootstrapGrowl("Serial Number updated successfully", { ele: 'body', type: 'success' });
                        } else {
                            $.bootstrapGrowl(response.data.message, { ele: 'body', type: 'error' });
                        }
                        serialNumberSelector.value = response.data.data && response.data.data.serial_number ? response.data.data.serial_number : orderItem.serialNumber;
                        orderItem.serialNumber = serialNumberSelector.value;
                    }, function (response) {
                        vm.serialNumberLoading = false;
                        serialNumberSelector.value = response.data.data &&response.data.data.serial_number ? response.data.data.serial_number : orderItem.serialNumber;
                        orderItem.serialNumber = serialNumberSelector.value;
                        $.bootstrapGrowl(response.data.message, { ele: 'body', type: 'error' });
                    });
            };
            callCheckPackStationTask(callbackFunction);
        }

        function submitSerialNumber(orderItem, productionItem, locationId = config.stationLocationId){
            const sn = vm.serialNumber[productionItem.id];
            if(!sn){
                $.bootstrapGrowl('Please fill in Serial Number', {ele: 'body', type: 'error'});
                return;
            }
            if(vm.serialNumberLoading){
                return;
            }
            if(!confirm('Confirm update?')){
                return;
            }
            // const ctf = `CTF-${productionItem.printable.artworkId}-${orderItem.product.name}`;
            const ctf = `CTF-${productionItem.printable.artworkId}-${productionItem.printable.product}`;
            vm.serialNumberLoading = true;
            serverGateway.ajax('associateSerialNumber', null, {sn: sn, ctf: ctf, upc: null, locationId: locationId}).then(function(response){
                if(response.data.status == 'OK'){
                    $.bootstrapGrowl('Associate Success!', {ele: 'body', type: 'success'});
                } else {
                    $.bootstrapGrowl(response.data.msg, {ele: 'body', type: 'error'});
                }
                vm.serialNumberLoading = false;
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.serialNumberLoading = false;
            });
        }

        function invalidSerialNumber(orderItem) {
            vm.serialNumberLoading = true;
            serverGateway.ajax('syncSerialNumber', { id: orderItem.id }, { status: false } )
                .then(function (response) {
                    vm.serialNumberLoading = false;
                    if (response.data.success) {
                        let serialNumberSelector = document.querySelector('[name="serialNumber-' + orderItem.id + '"]');
                        serialNumberSelector.value = "";
                        $.bootstrapGrowl("Serial Number invalid successfully", { ele: 'body', type: 'success' });
                    } else {
                        $.bootstrapGrowl(response.data.message, { ele: 'body', type: 'error' });
                    }
                }, function (response) {
                    vm.serialNumberLoading = false;
                    $.bootstrapGrowl(response.data.message, { ele: 'body', type: 'error' });
                });
        }

        function serialNumberKeypress(event, orderItem, productionItem = null){
            if(event.which == 13){//is pressed enter
                addSerialNumber(orderItem, productionItem);
            }
        }

        function callCheckPackStationTask(callbackFunction){
            serverGateway.ajax('checkAndGetPackStationTask', {id: vm.detailId}, {packStationId: config.stationId}).then(function(response) {
                if(response.data.status == 'failed'){
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                    return;
                }
                callbackFunction();
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function downloadJpYamatoFiles(deliveryId){
            $("<a download/>").attr("href", `/deliveries/${deliveryId}/downloadJpYamatoFiles?fileType=csv`).get(0).click();
            setTimeout(function() {
                $("<a download/>").attr("href", `/deliveries/${deliveryId}/downloadJpYamatoFiles?fileType=trg`).get(0).click();
            }, 2000);//smartcat need to trigger csv first then trg, so need to complete download csv first
        }

        function downloadYamatoSkuCsv(deliveryId){
            $("<a download/>").attr("href", `/deliveries/${deliveryId}/downloadSkuCsv?nonPrint=Yes`).get(0).click();
        }

        function onChangeSerialNumber(productionItem){
            const regex = /casetify\.com\/auth\/([\w\-]*)/igm;
            let regexresult = regex.exec(vm.serialNumber[productionItem.id]);
            if (regexresult && regexresult[1]) {
                vm.serialNumber[productionItem.id] = regexresult[1];
            }
        }
    }
})();

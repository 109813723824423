(function(jy, Ctg, $) {
    'use strict';

    Ctg.Select2 = jy.define({
        extend: 'jy.PBase',
        statics: {
            default: {
                placeholder: 'Select an option',
                clearOpen: false,
                select: $.noop,
                clear: $.noop,
                open: $.noop,
                noResults: $.noop,
                change: $.noop,
                allowClear: false,
                templateRow: function(field) {
                    return $('<span></span>').text(field.text);
                },

                templateSelection: function(field) {
                    return $('<span></span>').text(field.text);
                }
            }
        },

        constructor: function(selector, options) {
            options = $.extend({}, Ctg.Select2.default, options);

            // public property
            this.noResults = options.noResults;
            this.clearOpen = options.clearOpen;
            this.select = options.select;
            this.clear = options.clear;
            this.open = options.open;
            this.change = options.change;

            // constructor set once only
            this._placeholder = options.placeholder;
            this._allowClear = options.allowClear;
            this.templateRow = options.templateRow;
            this.templateSelection = options.templateSelection;

            // internal property
            this._val = null;
            this._isLoading = false;
            this._loadingTempVal = null;
            this._loadingMessage = null;
            this._unselected = false; // flag indicate pressed clear button
            this._$el = $(selector);
            if (this._$el.length === 0) {
                throw 'No element is selected';
            }

            this.data(options.data || []);
            this._attachEventHandlers();
            this._select2 = this._$el.data('select2');
            this._$el
                .val(null).trigger('change');
        },

        _attachEventHandlers: function() {
            var self = this;
            this._$el
                .on("select2:selecting", function(e) {
                    self.select.apply(self, arguments);
                })
                .on("select2:unselecting", function(e) {
                    self._unselected = true;
                    self.clear.apply(self, arguments);
                })
                .on("select2:open", function(e) {
                    if (self._unselected && !this.clearOpen) {
                        self._unselected = false;

                        var $dropdown = $('body').children('.select2-container').hide();
                        setTimeout(function() {
                            $dropdown.show();
                            self.close();
                            self.open.apply(self, arguments);
                        }, 0);
                    } else {
                        self.open.apply(self, arguments);
                    }
                })
                .on('select2:noResults', function() {
                    self.noResults.apply(self, arguments);
                })
                .on('change', function() {
                    // showLoading and endLoading both will trigger change event to update the selection layout
                    // checking _loadingTempVal is important, since endLoading will _isLoading to false and
                    // it will trigger change event incorrectly
                    // to avoid this issue, need to check _isLoading and _loadingTempVal
                    if (!self._isLoading && self._loadingTempVal === null) {
                        if (self._$el.val() !== self._val) {
                            self._val = self._$el.val();
                            self.change.apply(self, arguments);
                        }
                    }
                });
        },

        _templateSelection: function(field) {
            if (this._isLoading) {
                return $('<span>' + this._loadingMessage + '<i style="margin:0.5em" class="fa fa-refresh fa-spin pull-right"></i></span>');
            } else {
                return this.templateSelection(field);
            }
        },

        showLoading: function (msg) {
            if (this._isLoading) return;

            this._loadingTempVal = {
                val: this._$el.val(),
                disabled: this._$el.prop('disabled'),
                isChanged: false
            };

            this._isLoading = true;
            this._loadingMessage = msg || '';   
            this._$el
                .prop('disabled', true)
                .val(null).trigger('change');
            return this;
        },

        endLoading: function() {
            if (!this._isLoading) return;

            this._isLoading = false;
            this._$el
                .val(this._loadingTempVal.val).trigger('change')
                .prop('disabled', this._loadingTempVal.disabled);

            // clear temp value
            this._loadingTempVal = null;
            this._loadingMessage = null;
            return this;
        },

        getIsLoading: function() {
            return this._isLoading;
        },

        setDisabled: function(value) {
            this._$el.prop('disabled', value);
            return this;
        },

        getDisabled: function() {
            return this._$el.prop('disabled');
        },

        data: function(data) {
            var self = this;
            if (this._select2) {
                this._$el.select2('destroy');
            }

            this._$el.empty().select2({
                placeholder: this._placeholder,
                allowClear: this._allowClear,
                data: data,
                templateResult: this.templateRow,
                templateSelection: function() {
                    return self._templateSelection.apply(self, arguments);
                }
            }).next().css('width', '100%');

            return this;
        },

        close: function() {
            this._$el.select2('close');
            return this;
        },

        val: function(value) {
            if (typeof value !== 'undefined') {
                this._val = value;
                if (this._loadingTempVal) {
                    this._loadingTempVal.val = value;
                    this._loadingTempVal.isChanged = true;
                } else {
                    this._$el.val(value).trigger('change');
                }
                return this;
            } else {
                return this._val;
            }
        }
    });
})(jy, Ctg, jQuery);

(function() {
    'use strict';

    angular
        .module('shipping')
        .controller('TrackingCodeController', TrackingCodeController);

    TrackingCodeController.$inject = ['$scope','GridDataSource','ServerGateway','$q','config','PageViewModel','$timeout','$interval','FileUploader'];

    function TrackingCodeController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, $interval, FileUploader){
        var serverGateway = null;
        var vm = this;

        initialize();

        function initialize(){
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                }
            });

            vm.isSubmiting = false;
            vm.uploadTrackingCode = uploadTrackingCode;

            vm.trackingCodeUpdateFileUploader = new FileUploader({
                autoUpload: true,
                removeAfterUpload: true,
                headers: {
                    Authorization: 'Basic ' + localStorage.getItem('api-auth-token'),
                },
                url: '/api/function/bulk-tracking-code-update',
                onBeforeUploadItem: function(){
                    vm.isSubmiting = true;
                },
                onCompleteItem: function(){
                    vm.isSubmiting = false;
                    $('#tracking_code_update_file').val('');
                },
                onSuccessItem: function (item,response) {
                    if(response.status == 'OK'){
                        if(response.failed == 0){
                            $.bootstrapGrowl('All Success!', {ele: 'body', type: 'success'});
                        }
                        else{
                            $.bootstrapGrowl(`Updated with ${response.failed} fails`, {ele: 'body', type: 'warning'});
                            window.location.assign(`/function/download-tracking-code-update-report?csv=${response.csv}`);
                        }
                    }
                    else{
                        $.bootstrapGrowl(response.msg, {ele: 'body', type: 'error'});
                    }
                },
                onErrorItem: function (item,response) {
                    $.bootstrapGrowl(response.message, {ele: 'body', type: 'error'});
                }
            });
        }

        function uploadTrackingCode(){
            $('#tracking_code_update_file').click();
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('grid')
        .factory('Popup', Popup);

    Popup.$inject = ['$timeout', '$interval', '$window', '$q'];

    function Popup ($timeout, $interval, $window, $q) {
        var vm = this;
        vm.open              = open;
        // vm.setReturn      = setReturn;
        vm.setReturnAndClose = setReturnAndClose;

        function open (url, modifier) {

            return $q(function (resolve, reject) {

                var popup = $window.open(url, '_blank', 'resizable=1,scrollbars=1,width=1024,height=550');
                modifier && modifier(popup);

                var stopTime = $interval(function () {
                    if (popup.closed) {
                        $interval.cancel(stopTime);
                        if (typeof popup.callbackReturn !== 'undefined') resolve(popup.callbackReturn);
                        else reject('');
                    }
                }, 100);

            });

        }

        function setReturnAndClose (response) {

            window.callbackReturn = response;
            window.close();

        }

        return vm;
    }

})();

// module
(function() {
    'use strict';

    angular
        .module('component')
        .factory('AlignmentTool', AlignmentTool);

        AlignmentTool.$inject = ['$timeout', '$q'];

        function AlignmentTool ($timeout, $q) {
            var service = this, vm = null;

            service.initTool         = initTool;
            service.performAlignment = performAlignment;

            return service;

            function initTool (_vm) {
                vm                 = _vm;
                vm.px2mm           = px2mm;
                vm.mm2px           = mm2px;
                vm.alignMode       = 'colorMask';
                vm.alignModeActive = true;

                // initialize keydown event
                $(document).on('keydown', function(e) {
                    if (!vm.alignModeActive) return;
                    if (!$('#modal').is(':visible') && !$('#repositionModal').is(':visible')) return;

                    switch(e.which) {
                        case 38:
                        case 40:
                            e.preventDefault();
                        break;
                    }

                    $timeout(function() { _keypressed(e); });
                });
                $('input[type=text]').focus(function () {
                    vm.alignModeActive = false;
                }).blur(function () {
                    vm.alignModeActive = true;
                });
            }

            function performAlignment () {
            }

            function px2mm(pixel, dpi, round) {
                dpi = dpi || 300;
                round = round || 0;

                var inch = pixel / dpi;
                return round === false ? inch * 2.54 * 10 : parseFloat(inch * 2.54 * 10).toFixed(round);
            }

            function mm2px(mm, dpi, round) {
                dpi = dpi || 300;
                round = round || 0;

                var inch = mm / 10 / 2.54;
                return round === false ? inch * dpi : parseFloat(inch * dpi).toFixed(round);
            }


            function _getAlignProperty(action) {

                var prefix = '';
                var suffix = '';
                if (vm.alignMode == 'colorMask')
                    prefix = 'mask';
                else if (vm.alignMode == 'preview')
                    prefix = 'preview';

                if (action == 'left' || action == 'right')
                    suffix = 'OffsetLeft';
                else if (action == 'up' || action == 'down')
                    suffix = 'OffsetBottom';
                else if (action == 'scaleup' || action == 'scaledown')
                    suffix = 'OffsetScale';

                return prefix + suffix;
            }

            function _getAlignAction(action) {

                if (action == 'left') {
                    return function (p) { return parseFloat(p - .1).toFixed(2); };
                }

                if (action == 'up') {
                    return function (p) { return parseFloat(p * 1 + .1).toFixed(2); };
                }

                if (action == 'down') {
                    return function (p) { return parseFloat(p - .1).toFixed(2); };
                }

                if (action == 'right') {
                    return function (p) { return parseFloat(p * 1 + .1).toFixed(2); };
                }

                if (action == 'scaleup') {
                    return function (p) { return parseFloat(p * 1 + .01).toFixed(2); };
                }

                if (action == 'scaledown') {
                    return function (p) { return parseFloat(p - .01).toFixed(2); };
                }

                return function (p) { return p; };

            }

            function _performAction(action) {

                // ignore scaleup, scaledown action for color mask
                if (vm.alignMode == 'colorMask' && (action == 'scaleup' || action == 'scaledown')) return;

                var transformAction = _getAlignAction(action);
                var property = _getAlignProperty(action);

                service.performAlignment && service.performAlignment(transformAction, property);
            }

            function _swapLayer(mode) {

                mode = mode || '';
                if (mode == 'colorMask' || mode == 'preview') {
                    vm.alignMode = mode;
                }
                else if (vm.alignMode == 'colorMask') {
                    vm.alignMode = 'preview';
                }
                else if (vm.alignMode == 'preview') {
                    vm.alignMode = 'colorMask';
                }

            }


            function _keypressed(e) {

                switch (e.which) {

                    // up key
                    case 38:
                        _performAction('up');
                    break;

                    // left key
                    case 37:
                        _performAction('left');
                    break;

                    // down key
                    case 40:
                        _performAction('down');
                    break;

                    // right key
                    case 39:
                        _performAction('right');
                    break;

                    // plus key
                    case 107:
                    case 187:
                        if (e.which == 187 && !e.shiftKey) break;
                        _performAction('scaleup');
                    break;

                    // minus key
                    case 109:
                    case 189:
                        _performAction('scaledown');
                    break;

                    // 1 key
                    case 49:
                    case 97:
                        _swapLayer('colorMask');
                    break;

                    // 2 key
                    case 50:
                    case 98:
                        _swapLayer('preview');
                    break;

                }

            }

        }

})();

// module
(function() {
    'use strict';

    angular
        .module('grid', [])
        .filter("sanitize", ['$sce', function($sce) {
            return function(htmlCode){
                return $sce.trustAsHtml(htmlCode);
            };
        }]);
})();

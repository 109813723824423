(function(){
    'use strict';

    angular
        .module('setting')
        .config(Config);
    
    Config.$inject = ['$interpolateProvider'];

    function Config($interpolateProvider) {
        $interpolateProvider.startSymbol('<%');
        $interpolateProvider.endSymbol('%>');
    }
})();
// module
(function() {
    'use strict';

    angular
        .module('inventory')
        .config(Config)
        .factory('XeroService', XeroService);

        Config.$inject = [];
        XeroService.$inject = ['ServerGateway', '$timeout', '$q'];

    function Config() {
        // $authProvider.httpInterceptor = false;
        // $authProvider.oauth1({
        //     name: 'xero',
        //     url: 'http://admin-dev.casetify.com/api/auth/xero',
        //     authorizationEndpoint: 'https://api.xero.com/api.xro/2.0/',
        //     redirectUri: 'http://admin-dev.casetify.com/api/auth/xero',
        //     type: '2.0',
        //     popupOptions: null
        // });
    }

    function XeroService(ServerGateway, $timeout, $q, $auth) {
        var vm = this, serverGateway, _authenticating = false;

        // vm.auth                  = auth;
        vm.isAuthenticated       = isAuthenticated;
        vm.getContacts           = getContacts;
        vm.isAuthenticating      = isAuthenticating;
        vm.savePurchaseOrder     = savePurchaseOrder;
        vm.deletePurchaseOrder   = deletePurchaseOrder;
        vm.downloadPurchaseOrder = downloadPurchaseOrder;

        initialize();
        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'xero/contacts'    : { path: 'xero/contacts', method: 'GET' },
                    'xero/po-update'   : { path: 'xero/po-update', method: 'POST' },
                    'xero/po-delete'   : { path: 'xero/po-delete', method: 'POST' },
                    // 'xero/po-download' : { path: 'xero/po-download', method: 'GET' },
                }
            });
        }

        // function auth() {

        //     _authenticating = true;

        //     return $q(function(resolve, reject) {
        //         $auth.authenticate('xero')
        //         .then(function(e) {
        //             resolve(e);
        //         }, function(e) {
        //             reject(e);
        //         })
        //         .finally(function() {
        //             _authenticating = false;
        //         });
        //     });

        // }

        function isAuthenticating() {
            return _authenticating;
        }

        function isAuthenticated() {
            // return $auth.isAuthenticated();
            return true;
        }

        function getContacts() {

            return $q(function(resolve, reject) {

                serverGateway.ajax('xero/contacts')
                .then(function(e) {
                    resolve(e);
                });

            });
        }

        function downloadPurchaseOrder(payload) {

            return '/xero/po-download/' + payload.po_number + '.pdf?' + $.param(payload);

            // return $q(function(resolve, reject) {

            //     serverGateway.ajax('xero/po-download', '', $.extend({}, $auth.getPayload(), payload))
            //     .then(function(e) {
            //         resolve(e);
            //     });

            // });

        }

        function savePurchaseOrder(payload) {

            return $q(function(resolve, reject) {

                serverGateway.ajax('xero/po-update', '', payload)
                .then(function(e) {
                    e.data.status == 'success' ? resolve(e) : reject(e);
                });

            });

        }

        function deletePurchaseOrder(payload) {

            return $q(function(resolve, reject) {

                serverGateway.ajax('xero/po-delete', '', payload)
                .then(function(e) {
                    e.data.status == 'success' ? resolve(e) : reject(e);
                });

            });

        }

        return vm;
    }

})();

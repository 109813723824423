function createRecords() {
    var result = [];
    for (var i = 0; i < 4; i++) {
        result.push(createRecord(i));
    }
    return result;
}


function createRecord(id) {
    var result = {
        id: id,
        original: createAddress(id),
        suggestion: []
    };

    for (var i = 0, c = (Math.random() * 4) + 1; i < c; i++) {
        result.suggestion.push(createAddress('sug ' + i + id ));
    }

    return result;
}

function createAddress(name) {
    return {
        street1: 'street 1 ' + name,
        street2: 'street 2 ' + name,
        city: 'city ' + name,
        state: 'state ' + name,
        zip: 'zip ' + name,
        country: 'country ' + name
    };
}

(function() {
    'use strict';

    angular
        .module('purchaseRequest')
        // .controller('PrintingTemplateController', Controller)
        .controller('PurchaseRequestController', PurchaseRequestController);
        // .directive('datePicker', datePicker);

    // Controller.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout'];
    PurchaseRequestController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout', '$window', '$interval', 'Popup'];
    // datePicker.$inject = ['$timeout'];

    function PurchaseRequestController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, $window, $interval, Popup) {
        var serverGateway, vm = this;
        // vm.materials         = config.materials;
        // vm.locations         = config.locations;
        // vm.selectedMaterial  = vm.materials.length ? vm.materials[0] : null;
        // vm.selectedLocation  = vm.locations.length ? vm.locations[0] : null;
        vm.rows                      = [];
        vm.sorts                     = [];
        vm.mode                      = 'create';
        vm.columns                   = config.columns;
        vm.locations                 = config.locations;
        vm.setFilter                 = PageViewModel.setFilter;
        vm.clearFilter               = PageViewModel.clearFilter;
        vm.sortChange                = PageViewModel.sortChange;
        vm.isLoading                 = PageViewModel._isLoading;
        vm.selectSKU                 = selectSKU;
        vm.submit                    = submit;
        // vm.openDetailDialog          = openDetailDialog;
        vm.detail                    = {};
        vm.openCreatePurchaseRequest = openCreatePurchaseRequest;
        vm.purchaseRequest           = {};
        vm.createPurchaseRequest     = createPurchaseRequest;
        vm.openUpdatePurchaseRequest = openUpdatePurchaseRequest;
        vm.removePR                  = removePR;
        vm.removePRConfirm           = removePRConfirm;
        vm.deleteCancel              = deleteCancel;
        vm.popupSelectPR             = popupSelectPR;
        vm.getSumQTY                 = getSumQTY;
        vm.superuser                 = false;

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'create-purchase-request': { path: 'purchase-requests', method: 'POST' },
                    'update-purchase-request': { path: 'purchase-requests', method: 'PUT' },
                    'delete-purchase-request': { path: 'purchase-requests', method: 'DELETE' },
                }
            });
            vm.gridDataSource = new GridDataSource({
                resoucesUrl : config.dataUrl,
                gateway     : serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            $timeout(function() { PageViewModel.update(); }, 500);

            PageViewModel.initializeQueryBuilder(config.filtersConfig);
            // setInterval(PageViewModel.update, 5000);
            vm.superuser = config.superuser || false;

            if (config.popup) {
                $timeout(function() {
                     PageViewModel.setFilterRules([
                        {"id":'purchase_requests.status',"field":'purchase_requests.status',"type":"string","input":"text","operator":"in","value":'Pending'}
                    ]);
                }, 500);
            }

            // for direct access of specific raw material
            var data = config.initConfig;
            var params = '';
            if (data && data.rawMaterialSKU) {
                PageViewModel.update(function () {
                    PageViewModel.setFilterId('raw_materials.name', data.rawMaterialSKU);
                });
            }
            else if ((params = PageViewModel.getSearchParameters()) && !$.isEmptyObject(params)) {
                vm.openCreatePurchaseRequest();
                params.quantity = parseInt(params.quantity);
                vm.purchaseRequest = params;
            }
        }

        function selectSKU() {

            // vm.purchaseRequest.selectError = false;
            Popup.open('/product/raw-material/popup').then(function (rawMaterial) {

                vm.purchaseRequest.sku = rawMaterial.name;

            }, function () {

                // vm.purchaseRequest.selectError = true;

            });

        }

        function submit(e) {
            e.preventDefault();

            vm.purchaseRequest.processing = true;

            serverGateway.ajax(vm.mode == 'create' ? 'create-purchase-request' : 'update-purchase-request', '', vm.purchaseRequest).then(function (response) {
                if (response.data.status == 'success') {
                    if (vm.mode == 'create') {
                        window.location = window.location.pathname;
                    }
                    else if (vm.mode == 'update') {
                        var row = _.find(vm.rows, function (row) {
                            return row.id == vm.purchaseRequest.id;
                        });
                        for (var key in response.data.purchaseRequest) {
                            if (typeof row[key] !== 'undefined')
                                row[key] = response.data.purchaseRequest[key];
                        }
                        PageViewModel.hideModal('#modal-create-purchase-request');
                    }
                }


            }, function (response) {

                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});

            })
            .finally(function () {
                vm.purchaseRequest.processing = false;
            });
        }

        function removePR() {

            PageViewModel.switchModal('#modal-create-purchase-request', '#modal-create-purchase-request-confirm');

        }

        function removePRConfirm() {

            vm.purchaseRequest.processing = true;

            serverGateway.ajax('delete-purchase-request', '', vm.purchaseRequest).then(function () {

                window.location.reload();

            }, function (response) {

                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});

            })
            .finally(function () {
                vm.purchaseRequest.processing = false;
            });

        }

        function deleteCancel() {

            PageViewModel.switchModal('#modal-create-purchase-request-confirm', '#modal-create-purchase-request');

        }

        // function openDetailDialog(row, mode) {
        //     mode = mode || 'create';
        //     PageViewModel.openModal();
        // }

        function openCreatePurchaseRequest() {
            vm.purchaseRequest = {};
            vm.mode = 'create';
            PageViewModel.openModal('#modal-create-purchase-request');
        }

        function openUpdatePurchaseRequest(row) {
            vm.purchaseRequest  = $.extend(true, {}, row);
            // vm.purchaseRequest.delivery_before = moment(vm.purchaseRequest.delivery_before, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm');
            vm.mode = 'update';
            PageViewModel.openModal('#modal-create-purchase-request');
        }

        function createPurchaseRequest() {
            serverGateway.ajax('create-purchase-request', '', vm.purchaseRequest).then(function(response) {
                vm.purchaseRequest = response.data.data;
                PageViewModel.hideModal("#modal-create-purchase-request");
            }, function () {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function popupSelectPR(row) {

            Popup.setReturnAndClose(row);

        }

        function getSumQTY(records) {

            var qty = 0;
            for (var idx in records)
                qty += records[idx].quantity;
            return qty;

        }

    }

})();

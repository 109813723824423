(function() {
    'use strict';

    angular
        .module('genericInput')
        .controller('GenericInputController', GenericInputController)
        .directive('datePicker', datePicker);

    GenericInputController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout'];
    datePicker.$inject = ['$timeout'];

    function GenericInputController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout) {

        var serverGateway, vm = this;

        vm.rows             = [];
        vm.sorts            = [];
        vm.columns          = config.columns;
        vm.openDetailDialog = openDetailDialog;
        vm.showImportDialog = showImportDialog;
        vm.hdieImportDialog = hdieImportDialog;
        vm.detail           = {};

        // vm.done             = done;
        vm.isLoading        = PageViewModel._isLoading;

        vm.openDetailDialog = openDetailDialog;
        vm.isDisabled = false;
        vm.allowShow = config.allowShow;
        vm.presetUrl = config.presetUrl;

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    
                }
            });

            // if (config.id > 0) {
            //     if (config.showEditPanel) {
            //         vm.showStockReservationEdit()
            //     }
            //     return;
            // }

            if (!config.dataUrl) return

            let query = {
                id: config.id,
            }
            vm.gridDataSource = new GridDataSource({
                perPage: 50,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            vm.detail.redirectUrl = '';

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                $('table tr td:nth-child(2)').each(function() {
                    if ($(this).text().trim() == '') {
                        $(this).closest('tr').addClass('clickable');
                    }
                });

                // PageViewModel.rows = PageViewModel.rows.filter(function (item) { return item.warnedByTimeBased; });
                // console.log(PageViewModel.rows);

                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            PageViewModel.update();

            setInterval(PageViewModel.update, 5000);

            $scope.$watch(function() {
                return vm.detail && vm.detail.items ? JSON.stringify(vm.detail.items) : null;
            }, function(items) {

            });
        }

        function openDetailDialog(row) {
            if (vm.allowShow) {
                window.location.href = '/generic-input/'+config.model+'/' + row.id + '/show' + vm.presetUrl;
            } else {
                return false;
            }
        }

        function showImportDialog() {
            PageViewModel.openModal('#modal-import');
            return false;
        }

        function hdieImportDialog() {
            PageViewModel.hideModal('#modal-import');
            return false;
        }
    }

    function datePicker($timeout) {
        return {
            restrict: 'A',
            scope: {
                ngModel : '=',
            },
            link: function(scope, element, attrs) {
                $(element).datetimepicker({
                    timepicker: false,
                    format: 'Y-m-d',
                    todayButton: false,
                    closeOnDateSelect: true,
                    scrollInput: false,
                    value: moment().format('YYYY-MM-DD'),
                    onChangeDateTime: function(dp, $input) {
                        $timeout(function() {
                            scope.ngModel = $input.val();
                        });
                    },
                    onShow: function() {
                        var self = this;
                        $timeout(function() {
                            self.setOptions({
                                value: $(element).val()
                            });
                        });
                    },
                });
            }
        };
    }

    function isNormalInteger(str) {
        var n = ~~Number(str);
        return String(n) === str && n >= 0;
    }

})();

(function() {
    'use strict';

    angular
        .module('grid')
        .directive('ctgCellInventoryInoutSummary', ctgCellInventoryInoutSummary);

    function ctgCellInventoryInoutSummary() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: '/template/inventory/cellInventoryInoutSummary.html',
            controller: ['$scope', function($scope) {
                var vm = this;
                vm.requireWarning = $scope.$parent.$parent.$parent.vm.requireWarning;
                return vm;
            }],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;
    }
})();
(function() {
    'use strict';

    angular
        .module('grid')
        .directive('ctgCellPrintingTemplateSlotAction', ctgCellPrintingTemplateSlotAction);

    function ctgCellPrintingTemplateSlotAction() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: '/template/printing/cellPrintingTemplateSlotAction.html?v=1',
            controller: ['$scope', function($scope) {
                var vm = this;

                vm.openDetailDialog = function(row) {
                    $scope.$parent.$parent.$parent.vm.openDetailDialog(row);
                };

                vm.downloadTestLine = function(row) {
                    $scope.$parent.$parent.$parent.vm.downloadTestLine(row);
                };

                vm.distribute = function(row) {
                    $scope.$parent.$parent.$parent.vm.distribute(row);
                };

                vm.copyFromId = function(row) {
                    $scope.$parent.$parent.$parent.vm.copyFromId(row);
                };

                vm.cloneForProduct = function(row) {
                    $scope.$parent.$parent.$parent.vm.cloneForProduct(row);
                };

                if ($scope.$parent.row.supportedTemplates && $scope.$parent.row.supportedTemplates.length) {
                    $scope.$parent.row.printingTemplate = $scope.$parent.row.supportedTemplates[0].id;
                }

                return vm;
            }],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;
    }
})();
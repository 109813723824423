(function() {
    'use strict';

    angular
        .module('packaging')
        .controller('NonPrintPackagingController', Controller);

    Controller.$inject = ['GridDataSource', 'ServerGateway', '$q', 'config', '$scope'];

    function Controller(GridDataSource, ServerGateway, $q, config, $scope) {
        var serverGateway = null;
        var vm = this;

        vm.showPendingDialog = false;
        vm.showProcessingDialog = false;
        vm.showActionDialog = false;
        vm.loadNext = loadNext;
        vm.reset = reset;
        vm.skip = skip;
        vm.ship = ship;
        vm.showSummary = showSummary;
        vm.showSetting = showSetting;
        vm.stationId = config.stationId;
        vm.selectAll = selectAll;
        vm.deselectAll = deselectAll;
        vm.loadMaterialList = loadMaterialList;
        vm.showWaitDialog = showWaitDialog;
        vm.saveMergeSlot = saveMergeSlot;
        vm.skipDeliveryIds = [];
        vm.mergeGroup = config.mergeGroup;
        vm.addSerialNumber = addSerialNumber;
        vm.downloadYamatoSkuCsv = downloadYamatoSkuCsv;
        vm.assignToQueueWithCode = assignToQueueWithCode;
        vm.downloadJpYamatoFiles = downloadJpYamatoFiles;

        initialize();

        function initialize() {
            // sever side gateway setup
            vm.showPendingDialog = true;
            serverGateway = new ServerGateway({
                endPoints: {
                    'nonPrintLoadStation': { path: 'pack-stations/{id}/non-print-simple-load',  method: 'POST' },
                    'unloadPrintStation': { path: 'pack-stations/{id}/unload',  method: 'POST' },
                    'simpleShip': { path: 'pack-stations/{id}/simple-ship-delivery', method: 'POST' },
                    'loadMaterialList': { path: 'pack-stations/{id}/load-non-print-material-list', method: 'POST' },
                    'nonPrintSimpleMergeSlot': { path: 'pack-stations/{id}/non-print-simple-merge-slot', method: 'POST' },
                    'doneNonPrintTask': { path: 'pack-stations/{id}/done-non-print-task', method: 'POST' },
                    'syncSerialNumber': { path: 'order-items/{id}/serial-number',  method: 'POST' },
                    'assignToQueueWithCode': { path: 'transition-queue/assignWithCode', method: 'POST' },
                }
            });

            vm.loadMaterialList();
        }

        function loadNext() {
            vm.showProcessingDialog = true;
            vm.showPendingDialog = false;
            vm.showActionDialog = false;
            vm.item = null;
            vm.items = null;
            vm.orderId = null;
            vm.prepackBucket = null;
            vm.message = null;
            vm.action = null;
            vm.packActionLogId = null;
            vm.queueCode = '';
            let skus = null;
            if (vm.settings) {
                skus = [];
                Object.keys(vm.settings).forEach((sku) => {
                    if (vm.settings[sku]) {
                        skus.push(sku)
                    }
                })
                skus = skus.join(',');
            }
            serverGateway.ajax('nonPrintLoadStation', {id: vm.stationId}, {skipIds: JSON.stringify(vm.skipDeliveryIds), sku: skus, mergeGroup: vm.mergeGroup}).then(function(response) {
                vm.showProcessingDialog = false;
                // vm.showPendingDialog = true;
                vm.showActionDialog = true;
                vm.item = response.data.item;
                if (response.data.items) {
                    vm.items = response.data.items;
                } else {
                    if (response.data.item) {
                        vm.items = [response.data.item];
                    } else {
                        vm.items = [];
                    }
                }
                vm.items.sort(function (a, b) {
                    if (a.id == vm.item.id) {
                        return -1;
                    } else {
                        return 0;
                    }
                })
                vm.orderId = null;
                vm.orderRemark = null;
                vm.prepackBucket = null;
                if (vm.items) {
                    vm.items.forEach(function (item, index) {
                        vm.orderId = item.order.refId;
                        if(item.order.remarks.includes('Gift Order')){
                            vm.orderRemark = `<b class='text-success'>${item.order.remarks}</b>`;
                        }
                        else{
                            vm.orderRemark = item.order.remarks;
                        }
                        vm.prepackBucket = item.prepackBucket;
                    })
                }

                vm.alreadyOnMergeSlot = false;
                if (vm.item) {
                    vm.item.productionItems.forEach((productionItem) => {
                        if (productionItem.mergeSlots && productionItem.mergeSlots.length) {
                            vm.alreadyOnMergeSlot = true;
                        }
                    })
                }

                vm.mergeSlotName = response.data.mergeSlotName;
                vm.mergeSlotId = response.data.mergeSlotId;
                vm.message = response.data.message;
                vm.remark = response.data.remark;
                vm.action = response.data.action;
                vm.packActionLogId = response.data.packActionLogId;
                if (response.data.autoSkipIds && response.data.autoSkipIds.length > 0) {
                    vm.skipDeliveryIds = vm.skipDeliveryIds.concat(response.data.autoSkipIds)
                }
            }, function (response) {
                vm.showProcessingDialog = false;
                vm.showPendingDialog = true;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function reset() {
            vm.showProcessingDialog = true;
            vm.showPendingDialog = false;
            vm.showActionDialog = false;
            serverGateway.ajax('unloadPrintStation', {id: vm.stationId}).then(function(response) {
                vm.showProcessingDialog = false;
                vm.showPendingDialog = true;
                vm.showActionDialog = false;
                vm.item = response.data.item;
                if (response.data.items) {
                    vm.items = response.data.items;
                } else {
                    if (response.data.item) {
                        vm.items = [response.data.item];
                    } else {
                        vm.items = [];
                    }
                }
                vm.items.sort(function (a, b) {
                    if (a.id == vm.item.id) {
                        return -1;
                    } else {
                        return 0;
                    }
                })
                vm.orderId = null;
                vm.orderRemark = null;
                vm.prepackBucket = null;
                if (vm.items) {
                    vm.items.forEach(function (item, index) {
                        vm.orderId = item.order.refId;
                        vm.orderRemark = item.order.remarks;
                        vm.prepackBucket = item.prepackBucket;
                    })
                }
                
                vm.message = response.data.message;
                vm.action = response.data.action;
                vm.packActionLogId = response.data.packActionLogId;
            }, function (response) {
                vm.showProcessingDialog = false;
                vm.showActionDialog = true;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function skip() {
            vm.skipDeliveryIds.push(vm.item.delivery.id);
            vm.loadNext();
        }

        function ship() {
            vm.showProcessingDialog = true;
            vm.showActionDialog = false;

            serverGateway.ajax('simpleShip', {id: vm.stationId}, {deliveryId: vm.item.delivery.id, 'packActionLogId' : vm.packActionLogId}).then(function(response) {
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                vm.showPendingDialog = true;
                vm.showProcessingDialog = false;
                vm.loadNext();
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.showProcessingDialog = false;
                vm.showActionDialog = true;
            });
            return false;
        }

        function showSummary() {
            $('#summaryDialog').modal('hide');
            serverGateway.ajax('loadMaterialList', {id: vm.stationId}).then(function(response) {
                vm.summary = response.data.data;
                $('#summaryDialog').modal('show');
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            return false;
        }

        function showSetting() {
            $('#settingDialog').modal('hide');
            serverGateway.ajax('loadMaterialList', {id: vm.stationId}).then(function(response) {
                vm.summary = response.data.data;
                if (!vm.settings) {
                    vm.settings = {};
                }
                vm.summary.forEach((row) => {
                    if (!vm.settings.hasOwnProperty(row.name)) {
                        vm.settings[row.name] = true;
                    }
                })
                $('#settingDialog').modal('show');
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            return false;
        }

        function loadMaterialList() {
            vm.showPendingDialog = false;
            serverGateway.ajax('loadMaterialList', {id: vm.stationId}).then(function(response) {
                vm.summary = response.data.data;
                if (!vm.settings) {
                    vm.settings = {};
                }
                vm.summary.forEach((row) => {
                    if (!vm.settings.hasOwnProperty(row.name)) {
                        vm.settings[row.name] = true;
                    }
                })
                vm.showPendingDialog = true;
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function selectAll() {
            if (vm.settings) {
                Object.keys(vm.settings).forEach((sku) => {
                    vm.settings[sku] = true;
                })
            }
        }

        function deselectAll() {
            if (vm.settings) {
                Object.keys(vm.settings).forEach((sku) => {
                    vm.settings[sku] = false;
                })
            }
        }

        function showWaitDialog() {
            vm.mergeSlotCode = null;
            vm.mergeTransitionQueueCode = null;
            $('#waitDialog').modal('show');
            $("#mergeTransitionQueueCode").focus();
        }

        function saveMergeSlot() {
            if (vm.savingMergeSlot) return;
            if (vm.alreadyOnMergeSlot && !confirm('Already on merge slot, confirm reassign?')) {
                return;
            }
            vm.showProcessingDialog = true;
            vm.showActionDialog = false;
            vm.savingMergeSlot = true;

            serverGateway.ajax('nonPrintSimpleMergeSlot', {id: vm.stationId}, {orderItemId: vm.item.id, 'packActionLogId' : vm.packActionLogId, 'mergeTransitionQueueCode' : vm.mergeTransitionQueueCode, 'mergeSlotId': vm.mergeSlotId, 'mergeSlotCode': vm.mergeSlotCode}).then(function(response) {
                vm.savingMergeSlot = false;
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                $('#waitDialog').modal('hide');
                vm.showBarcodeReadDialog = true;
                vm.showProcessingDialog = false;
                vm.loadNext();
            }, function (response) {
                vm.savingMergeSlot = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.showProcessingDialog = false;
                vm.showActionDialog = true;
            });
            return false;
        }

        function addSerialNumber(orderItem) {
            let serialNumberSelector = document.querySelector('[name="serialNumber-' + orderItem.id + '"]');
            let serialNumber = serialNumberSelector.value;
            const regex = /casetify\.com\/auth\/([\w\-]*)/igm;
            let regexresult = regex.exec(serialNumber)
            if (regexresult && regexresult[1]) {
                serialNumber = regexresult[1]
            }

            if (orderItem.serialNumber == serialNumber) return;
            if (orderItem.serialNumber && !confirm('Confirm Update?')) return;
            
            vm.serialNumberLoading = true;
            serverGateway.ajax('syncSerialNumber', { id: orderItem.id }, { serialNumber: serialNumber, packStationId: vm.stationId })
                .then(function (response) {
                    vm.serialNumberLoading = false;
                    if (response.data.success) {
                        orderItem.serialNumber = serialNumber
                        $.bootstrapGrowl("Serial Number updated successfully", { ele: 'body', type: 'success' });
                    } else {
                        $.bootstrapGrowl(response.data.message, { ele: 'body', type: 'error' });
                    }
                    serialNumberSelector.value = response.data.data && response.data.data.serial_number ? response.data.data.serial_number : orderItem.serialNumber;
                    orderItem.serialNumber = serialNumberSelector.value
                }, function (response) {
                    vm.serialNumberLoading = false;
                    serialNumberSelector.value = response.data.data && response.data.data.serial_number ? response.data.data.serial_number : orderItem.serialNumber;
                    orderItem.serialNumber = serialNumberSelector.value
                    $.bootstrapGrowl(response.data.message, { ele: 'body', type: 'error' });
                });
        }

        function downloadYamatoSkuCsv(deliveryId){
            $("<a download/>").attr("href", `/deliveries/${deliveryId}/downloadSkuCsv?nonPrint=Yes`).get(0).click();
        }

        function assignToQueueWithCode(){//for non print only current
            vm.assignQueueLoading = true;
            const productionItemId = vm.item.productionItems[0].id;//get the first production item
            serverGateway.ajax('assignToQueueWithCode', null, {code: vm.queueCode, productionItemId: productionItemId, scanLocation: `Station ${config.stationName}`}).then(function(response) {
                
                serverGateway.ajax('doneNonPrintTask', {id: vm.stationId}, {deliveryId: vm.item.delivery.id, orderItemId: vm.item.id}).then(function(response) {
                    vm.assignQueueLoading = false;
                    vm.loadNext();
                }, function (response) {
                    vm.assignQueueLoading = false;
                    $.bootstrapGrowl(response.data.message, { ele: 'body', type: 'error' });
                });

            }, function (response) {
                vm.assignQueueLoading = false;
                $.bootstrapGrowl(response.data.message, { ele: 'body', type: 'error' });
            });
        }

        function downloadJpYamatoFiles(deliveryId){
            $("<a download/>").attr("href", `/deliveries/${deliveryId}/downloadJpYamatoFiles?fileType=csv`).get(0).click();
            setTimeout(function() {
                $("<a download/>").attr("href", `/deliveries/${deliveryId}/downloadJpYamatoFiles?fileType=trg`).get(0).click();
            }, 2000);//smartcat need to trigger csv first then trg, so need to complete download csv first
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('materialPlanning')
        // .controller('PrintingTemplateController', Controller)
        .controller('AllocationPlanController', AllocationPlanController)
        .directive('datePicker', datePicker);

    // Controller.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout'];
    AllocationPlanController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout'];
    datePicker.$inject = ['$timeout'];

    function AllocationPlanController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout) {

        var serverGateway, vm = this;

        vm.rows             = [];
        vm.sorts            = [];
        vm.columns          = config.columns;
        vm.openDetailDialog = openDetailDialog;
        vm.detail           = {};

        // vm.done             = done;
        vm.isLoading        = PageViewModel._isLoading;
        vm.isAutofillLoading = false;

        vm.openDetailDialog = openDetailDialog;
        vm.isDisabled = false;
        vm.autofill = autofill;

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'autofill': { path: 'allocation-plans/{id}/autofill', method: 'POST' },
                }
            });

            // if (config.id > 0) {
            //     if (config.showEditPanel) {
            //         vm.showStockReservationEdit()
            //     }
            //     return;
            // }

            if (!config.dataUrl) return

            let query = {
                id: config.id,
            }
            vm.gridDataSource = new GridDataSource({
                perPage: 50,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            vm.detail.redirectUrl = '';

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                $('table tr td:nth-child(2)').each(function() {
                    if ($(this).text().trim() == '') {
                        $(this).closest('tr').addClass('clickable');
                    }
                });

                // PageViewModel.rows = PageViewModel.rows.filter(function (item) { return item.warnedByTimeBased; });
                // console.log(PageViewModel.rows);

                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            PageViewModel.update();

            setInterval(PageViewModel.update, 5000);

            $scope.$watch(function() {
                return vm.detail && vm.detail.items ? JSON.stringify(vm.detail.items) : null;
            }, function(items) {

            });
        }

        function openDetailDialog(row) {
            window.location.href = '/allocation-plans/' + row.id + '/show';
        }

        function autofill(id, type) {
            if (vm.isAutofillLoading) return false;
            vm.isAutofillLoading = true;
            serverGateway.ajax('autofill', {id: id}, {type: type}).then(function(response) {
                vm.isAutofillLoading = false;
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                window.location.reload();
            }, function (response) {
                vm.isAutofillLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }
    }

    function datePicker($timeout) {
        return {
            restrict: 'A',
            scope: {
                ngModel : '=',
            },
            link: function(scope, element, attrs) {
                $(element).datetimepicker({
                    timepicker: false,
                    format: 'Y-m-d',
                    todayButton: false,
                    closeOnDateSelect: true,
                    scrollInput: false,
                    value: moment().format('YYYY-MM-DD'),
                    onChangeDateTime: function(dp, $input) {
                        $timeout(function() {
                            scope.ngModel = $input.val();
                        });
                    },
                    onShow: function() {
                        var self = this;
                        $timeout(function() {
                            self.setOptions({
                                value: $(element).val()
                            });
                        });
                    },
                });
            }
        };
    }

    function isNormalInteger(str) {
        var n = ~~Number(str);
        return String(n) === str && n >= 0;
    }

})();

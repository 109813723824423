(function(){
    'use strict';

    angular
        .module('setting')
        .controller('locationPackagingCapabilityController', locationPackagingCapabilityController);

        locationPackagingCapabilityController.inject = ['$scope','GridDataSource','ServerGateway','$q','config','PageViewModel','$timeout','$interval'];

        function locationPackagingCapabilityController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, $interval){
            var serverGateway, vm = this;

            vm.rows = [];
            vm.sorts = [];
            vm.columns = config.columns;
            vm.setFilter = PageViewModel.setFilter;
            vm.clearFilter = PageViewModel.clearFilter;
            vm.sortChange = PageViewModel.sortChange;
            vm.isLoading = PageViewModel._isLoading;
            vm.detail = {};
            vm.newList = "";
            vm.packagingRequirementFilter = '';
            // vm.productNameFilter = '';
            vm.submitFilter = submitFilter;
            vm.updateDetail = updateDetail;
            vm.deleteDetail = deleteDetail;
            vm.locations = {};
            vm.buttonText = '';

            vm.openDetailModal = openDetailModal;


            initialize();

            function initialize(){
                serverGateway = new ServerGateway({
                    baseUrl: '/api',
                    endPoints: {
                        'getLocations' : {path: 'getLocations', method: 'GET'},
                        'createOrUpdateLocationPackagingCapability' : {path: 'createOrUpdateLocationPackagingCapability', method: 'POST'},
                        'deleteLocationPackagingCapability' : {path: 'deleteLocationPackagingCapability/{id}', method: 'POST'},
                    }
                });

                vm.GridDataSource = new GridDataSource({
                    resoucesUrl : config.dataUrl,
                    gateway     : serverGateway,
                    perPage: 999
                });

                var fields = [];
                for(var i, c = config.columns.length; i < c; i++){
                    fields.push(config.columns[i].field);
                }
                vm.GridDataSource.setColumns(fields);

                PageViewModel.setGridDataSource(vm.GridDataSource);
                $scope.$watch(function(){
                    return PageViewModel.rows;
                }, function(rows){
                    vm.rows = rows;
                }, true);

                $timeout(function(){PageViewModel.update();}, 500);

                $interval(function(){
                    PageViewModel.update();
                }, 5000);

                serverGateway.ajax('getLocations').then(function(response){
                    vm.locations = response.data.locations;
                },function(response){
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            }

            function submitFilter(){
                let query = new URLSearchParams(
                    {
                        packagingRequirementFilter: vm.packagingRequirementFilter,
                    }
                );
                vm.GridDataSource.setResourceUrl(config.dataUrl+"?"+query);
                PageViewModel.setGridDataSource(vm.GridDataSource);
                PageViewModel.load();
            }

            function openDetailModal(row){
                vm.detail = {};
                vm.buttonText = 'Create';
                if(row){
                    vm.detail = row;
                    vm.detail.location_id = vm.detail.location_id.toString();
                    vm.buttonText = 'Update';
                }
                $('#locationPackagingCapabilityModal').modal('show');
            }

            function updateDetail(){
                if(!confirm('Are you sure?')) return;
                serverGateway.ajax('createOrUpdateLocationPackagingCapability',null,{details: vm.detail}).then(function(response){
                    $.bootstrapGrowl('DONE', {ele: 'body', type: 'success'});
                    $('#locationPackagingCapabilityModal').modal('hide');
                    PageViewModel.update();
                },function(response){
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            }

            function deleteDetail(){
                if(!confirm('Are you sure?')) return;
                serverGateway.ajax('deleteLocationPackagingCapability',{id: vm.detail.id}).then(function(response){
                    $.bootstrapGrowl('DONE',{ele: 'body', type: 'success'});
                    $('#locationPackagingCapabilityModal').modal('hide');
                    PageViewModel.update();
                },function(response){
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            }
        }
})();
(function() {
    'use strict';

    angular
        .module('grid')
        .directive('ctgCellPrintBotItems', ctgCellPrintBotItems);

    function ctgCellPrintBotItems() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: '/template/printing/ctgCellPrintBotItems.html'
        };

        return directive;
    }
})();

(function() {
    'use strict';

    angular
        .module('grid')
        .directive('ctgCellDeliverySpecialHandling', ctgCellDeliverySpecialHandling);

    function ctgCellDeliverySpecialHandling() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: '/template/packaging/ctgCellDeliverySpecialHandling.html'
        };

        return directive;
    }
})();
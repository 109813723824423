(function() {

function capitaliseFirstLetter( string ) {
    return string.charAt( 0 ).toUpperCase() + string.slice( 1 );
}

function isObject( value ) {
    return !!( value && typeof value === 'object' || typeof value === 'function' );
}

var PBase = jy.define('jy.PBase', {
    get: function( key ) {
        var getter = this[ 'get' + capitaliseFirstLetter( key ) ];
        if ( typeof getter === 'function' ) {
            return getter.call( this );
        } else {
            return this[ key ];
        }
    },

    set: function( key, value ) {
        var setter = this[ 'set' + capitaliseFirstLetter( key ) ];
        if ( typeof setter === 'function' ) {
            setter.call( this, value );
        } else {
            this[ key ] = value;
        }

        // Trigger change event
        this.trigger(key, {
            key: key,
            newValue: value
        });

        return this;
    },

    chainGet: function( key ) {
        var prop, keys = key.split( '.' ),
            i = 0, c = keys.length;

        for ( ; i < c; i++ ) {
            prop = this.get( keys[ i ] );
            if ( i !== c - 1 && !isObject( prop ) ) {
                throw 'Invalid prop name ' + key;
            }
        }

        return prop;
    },

    chainSet: function( key, value ) {
        var prop, keys = key.split( '.' ),
            i = 0, c = keys.length - 1;

        for ( ; i < c; i++ ) {
            prop = this.get( keys[ i ] );
            if ( !isObject( prop ) ) {
                throw 'Invalid prop name ' + key;
            }
        }
        this.set( keys[ c ], value );
    },

    propChanged: function( key ) {
        // Convert key to string
        key += '';
        this.trigger(key, {
            key: key.toString(),
            newValue: this.chainGet( key )
        });

        return this;
    },

    trigger: function( key, params ) {
        // Convert key to string
        key += '';
        var self, callbacks, c, i = 0;
        if ( this._callbacks && ( callbacks = this._callbacks[ key ] ) ) {
            self = this;
            for ( c = callbacks.length; i < c; i++ ) {
                setTimeout((function( callback ) {
                    return function() {
                        callback.call( self, params );
                    };
                })( callbacks[ i ] ), 0);
            }
        }

        return this;
    },

    prop: function( key, value ) {
        if ( arguments.length === 0 || arguments.length === 1 && typeof key !== 'string' && !isObject( key ) ) {
            throw 'Invalid argument';
        }

        if ( isObject( key ) ) {
            for ( var theKey in key ) {
                if ( key.hasOwnProperty( theKey ) ) {
                    this.chainSet( theKey, key[ theKey ] );
                }
            }
        } else if ( arguments.length === 1 ) {
            if ( typeof key === 'undefined' ) {
                return this;
            }
            return this.chainGet( key );
        } else {
            this.chainSet( key, value );
        }

        return this;
    },

    on: function( key, callback ) {
        if ( typeof callback !== 'function' ) {
            return;
        }

        this._callbacks = this._callbacks || {};
        this._callbacks[ key ] = this._callbacks[ key ] || [];
        this._callbacks[ key ].push( callback );

        return this;
    },

    off: function( key, target ) {
        var callbacks = this._callbacks;
        if ( !callbacks ) {
            return;
        }

        if ( typeof key !== 'undefined' ) {
            if ( typeof target === 'function' ) {
                remove( callbacks[ key ], target );
            } else {
                delete callbacks[ key ];
            }
        } else {
            delete this._callbacks;
        }

        return this;
    }
});

})();
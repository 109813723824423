var volumnUpPressed = null;
var volumnDownPressed = null;

function volumnUpPressed () {
    if (volumnUpCallback) {
        volumnUpCallback();
    }
}

function volumnDownPressed () {
    if (volumnDownCallback) {
        volumnDownCallback();
    }
}

function showMessage(success, message, forceVibrate) {
    var prompt = "";
    if (!success || forceVibrate) {
        if (typeof nativeInterface !== 'undefined') {
            nativeInterface.vibrate(success ? 0 : 1);
            nativeInterface.playSound(success ? "success" : "error");
        }
    }
    if (success) {
        prompt += "Success. ";
    } else {
        prompt += "Error! ";
    }
    if (message) {
        prompt += message;
    }
    if (typeof nativeInterface !== 'undefined') {
        nativeInterface.toast(prompt);
    } else if (!success) {
        alert(prompt);
    }
}

function registerNativeInterface() {
    if (typeof nativeInterface !== 'undefined') {
        nativeInterface.listenForVolumeUp('volumnUpPressed');
        nativeInterface.listenForVolumeDown('volumnDownPressed');
        var currentNativeInterfaceVersion = 1;
        if ((typeof nativeInterface.platform !== 'undefined') && (nativeInterface.platform.version < currentNativeInterfaceVersion)) {
            alert("There is a new version for Casetify Admin App. Please update your app.");
        }
    }
    if (typeof nativeInterface === 'undefined') {
        $(".native-only").hide();
        $(".non-native-only").show();
    } else {
        $(".native-only").show();
        $(".non-native-only").hide();
    }
}
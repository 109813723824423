(function() {
    'use strict';

    angular
        .module('order')
        .controller('Controller', Controller);

    Controller.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout'];

    function Controller($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout) {
        var serverGateway, vm = this;
        vm.isCurrentPageLoading = false;
        vm.detail                            = {};
        vm.delivery                          = {};
        vm.mergeDeliveries                   = [];
        vm.orderItem                         = {};
        vm.rows                              = [];
        vm.setFilter                         = PageViewModel.setFilter;
        vm.clearFilter                       = PageViewModel.clearFilter;
        vm.sortChange                        = PageViewModel.sortChange;
        vm.isLoading                         = () => { PageViewModel._isLoading() || vm.isCurrentPageLoading };
        vm.switchModal                       = PageViewModel.switchModal;
        vm.openDetailDialog                  = openDetailDialog;
        vm.closeModal = closeModal;
        vm.columns                           = config.columns;
        vm.sorts                             = [];
        vm.canMergeDelivery                  = canMergeDelivery;
        vm.openMergeDeliveryModal            = openMergeDeliveryModal;
        vm.mergeDelivery                     = mergeDelivery;
        vm.mergeDeliveryData                 = {};
        vm.mergeDeliveryAddressOption        = mergeDeliveryAddressOption;
        vm.mergeDeliveryShippingMethodOption = mergeDeliveryShippingMethodOption;
        vm.mode                           = config.mode;
        vm.stationList = config.stationList;
        vm.station = (typeof config.stationList != 'undefined') ? config.stationList[0].key : null;
        vm.printStationList = config.printStationList;
        vm.printStation = -1;
        vm.printLabel = printLabel;
        vm.finishShipping = finishShipping;
        vm.addPreSealPacking = addPreSealPacking;
        vm.regenerateShipment = regenerateShipment;
        vm.schedulePrinting = schedulePrinting;
        vm.bulkSchedulePrinting = bulkSchedulePrinting;
        vm.schedulePrintingToNewStation = schedulePrintingToNewStation;
        vm.schedulePrepack = schedulePrepack;
        vm.returnShipping = returnShipping;
        vm.cancelLabel = cancelLabel;
        vm.removePrinting = removePrinting;
        vm.bulkRemovePrinting = bulkRemovePrinting;
        vm.resyncOrder = resyncOrder;
        vm.prepareOrder = prepareOrder;
        vm.withholdOrder = withholdOrder;
        vm.releaseOrder = releaseOrder;
        vm.sealActionWithhold = sealActionWithhold;
        vm.sealActionDiscard = sealActionDiscard;
        vm.sealActionUndo = sealActionUndo;
        vm.showSplitOrder = showSplitOrder;
        vm.splitOrder = splitOrder;
        vm.requestPreview = requestPreview;
        vm.locationList = config.locationList;
        vm.updateProcessingLocation = updateProcessingLocation;
        vm.updateDeliveryProcessingLocation = updateDeliveryProcessingLocation;
        vm.togglePrintOnPrint = togglePrintOnPrint;
        vm.loadOrderItem = loadOrderItem;
        vm.switchToOrderItem = switchToOrderItem;
        vm.switchToOrderItemSummary = switchToOrderItemSummary;
        vm.unshipOrder = unshipOrder;
        vm.addSerialNumber = addSerialNumber;
        vm.serialNumberKeypress = serialNumberKeypress;
        vm.onChangeSerialNumber = onChangeSerialNumber;
        vm.forceRequestPrintable = forceRequestPrintable;
        vm.forceRegeneratePrintable = forceRegeneratePrintable;
        vm.regenerateShippingLabel = regenerateShippingLabel;
        vm.downloadJpYamatoFiles = downloadJpYamatoFiles;
        vm.downloadYamatoSkuCsv = downloadYamatoSkuCsv;
        vm.requestBulkOrderMaterial = requestBulkOrderMaterial;
        vm.requestBulkOrderTransitBatch = requestBulkOrderTransitBatch;
        vm.requestPrintableLoading = false;
        vm.renewShipment = renewShipment;
        vm.unprepareOrder = unprepareOrder;
        vm.displayMode = 'detail';

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'get-order': { path: 'orders/{id}', method: 'GET' },
                    'get-order-item': { path: 'order-items/{id}', method: 'GET' },
                    'merge-delivery': { path: 'merge-delivery', method: 'POST' },
                    'printLabel': { path: 'print-label',  method: 'POST' },
                    'beforePrintLabel': { path: 'deliveries/{id}/beforePrintLabel', method: 'GET' },
                    'unprintLabel': { path: 'unprint-label',  method: 'POST' },
                    'finishShipping': { path: 'deliveries/{id}/done',  method: 'POST' },
                    'regenerateShipment': { path: 'deliveries/{id}/regenerateShipment',  method: 'POST' },
                    'schedulePrinting': { path: 'order-items/{id}/schedulePrinting',  method: 'POST' },
                    'bulkSchedulePrinting': { path: 'order-items/bulkSchedulePrinting',  method: 'POST' },
                    'schedulePrepack': { path: 'order-items/{id}/schedulePrepack',  method: 'POST' },
                    'removePrinting': { path: 'order-items/{id}/removePrinting',  method: 'POST' },
                    'bulkRemovePrinting': { path: 'order-items/bulkRemovePrinting',  method: 'POST' },
                    'returnShipping': { path: 'return-shipping-label', method: 'POST' },
                    'cancelLabel': { path: 'deliveries/{id}/cancelShippedLabel', method: 'POST' },
                    'resyncOrder': { path: 'orders/{id}/resync', method: 'POST' },
                    'prepareOrder': { path: 'orders/{id}/prepare', method: 'POST' },
                    'unprepareOrder': { path: 'orders/{id}/unprepare', method: 'POST' },
                    'withholdOrder': { path: 'orders/{id}/withhold', method: 'POST' },
                    'releaseOrder': { path: 'orders/{id}/release', method: 'POST' },
                    'sealActionWithhold': { path: 'seal/withholdShipment', method: 'POST' },
                    'sealActionDiscard': { path: 'seal/discardShipment', method: 'POST' },
                    'sealActionUndo': { path: 'seal/undoShipment', method: 'POST' },
                    'splitInfo': { path: 'orders/{id}/split-info', method: 'GET' },
                    'splitOrder': { path: 'orders/{id}/split', method: 'POST' },
                    'requestPreview': { path: 'order-items/{id}/requestPreview', method: 'POST' },
                    'updateProcessingLocation': { path: 'orders/{id}/update-location', method: 'POST' },
                    'updateDeliveryProcessingLocation': { path: 'deliveries/{id}/update-location', method: 'POST' },
                    'togglePrintOnPrint': { path: 'order-items/{id}/print-on-print/{enable}', method: 'POST' },
                    'unshipOrder': { path: 'orders/{id}/unship', method: 'POST' },
                    'addPreSealPacking': { path: 'deliveries/{id}/addPreSealPacking', method: 'POST' },
                    'syncSerialNumber': { path: 'order-items/{id}/serial-number',  method: 'POST' },
                    'forceRequestPrintable': { path: 'printables/{id}/refetch',  method: 'POST' },
                    'forceRegeneratePrintable': { path: 'printables/{id}/refetch',  method: 'POST' },
                    'regenerateShippingLabel': { path: 'function/regenerate-shipping-label', method: 'POST' },
                    'renewShipment': { path: 'deliveries/{id}/renewShipment', method: 'POST' },
                    'associateSerialNumber': { path: 'function/associate-serial-number', method: 'POST'},
                    'requestBulkOrderMaterial': { path: 'orders/{id}/requestBulkOrderMaterial', method: 'POST'},
                    'requestBulkOrderTransitBatch': { path: 'orders/{id}/requestBulkOrderTransitBatch', method: 'POST'},
                }
            });
            vm.gridDataSource = new GridDataSource({
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            $timeout(function() { 
                if (config.defaultId) {
                    $timeout(function () {
                        $('#builder-basic').queryBuilder('setRules',{"condition":"AND","rules":[{"id":"orders.ref_id","field":"orders.ref_id","type":"string","input":"text","operator":"in","value":config.defaultId}]});
                        PageViewModel.setFilter();
                        PageViewModel.update(function () {
                            vm.openDetailDialog(vm.rows[0], (row) => {
                                if (config.defaultOrderItemId) {
                                    let orderItem = null;
                                    let orderItemDelivery = null;
                                    if(typeof row.deliveries != 'undefined' && row.deliveries && row.deliveries.length > 0){
                                        if (vm.displayMode == 'summary') {
                                            row.deliveries.forEach((delivery) => {
                                                let out = delivery.orderItemSummary.find((orderItem) => orderItem.refId == config.defaultOrderItemId);
                                                if (out) {
                                                    orderItem = out;
                                                    orderItemDelivery = delivery;
                                                }
                                            });
                                        } else {
                                            row.deliveries.forEach((delivery) => {
                                                let out = delivery.orderItems.find((orderItem) => orderItem.refId == config.defaultOrderItemId);
                                                if (out) {
                                                    orderItem = out;
                                                    orderItemDelivery = delivery;
                                                }
                                            });
                                        }
                                        
                                    }
                                    if (orderItem) {
                                        if (vm.displayMode == 'summary') {
                                            vm.switchToOrderItemSummary(orderItem, orderItemDelivery);
                                        } else {
                                            vm.switchToOrderItem(orderItem, orderItemDelivery);
                                        }
                                        
                                    }
                                }
                            });
                        });
                    });
                }                
            }, 500);

            $timeout(function() { 
                PageViewModel.update();
            });

            $('#modal')
                .on('hide.bs.modal', function() {
                    window.history.pushState(null, null, "/"+vm.mode+"/order");
                });

            PageViewModel.initializeQueryBuilder(config.filtersConfig);

            initPrintStationSelect2();
        }

        function closeModal(id) {
            $(id).modal('hide');
        }

        function openDetailDialog(row, callback) {
            vm.isCurrentPageLoading = true;
            vm.displayMode = row.isBulkOrder ? 'summary' : 'track-order'
            serverGateway.ajax('get-order', {id: row.id}, {mode: vm.displayMode}).then(function (response) {
                vm.isCurrentPageLoading = false;
                vm.detail = response.data.data;

                // vm.detail = row;
                vm.detail.locationId = vm.detail.location ? vm.detail.location.id : vm.locationList[0].key;
                vm.detail.extraDataArr = null;
                if (vm.detail.extraData) {
                    let extraData = vm.detail.displayExtraData;
                    if (extraData) {
                        vm.detail.extraDataArr = [];
                        for (var key in extraData) {
                            vm.detail.extraDataArr.push({"key": key, "value": extraData[key]})
                        }
                    }
                }
                vm.detail.deliveryLocation = {};
                vm.detail.deliveries.forEach((delivery, i) => {
                    vm.detail.deliveryLocation[delivery.id] = delivery.location ? delivery.location.id : vm.locationList[0].key;
                    let printingOrderItems = []
                    delivery.orderItemSummary?.filter((itemLine) => itemLine.is_printing && !itemLine.isClearCase).forEach((itemLine) => {
                        itemLine.relatedOrderItems.forEach((relatedOrderItem) => {
                            if (relatedOrderItem.internalStatus.includes('Not Ready')) {
                                printingOrderItems.push(relatedOrderItem)
                            }
                        })
                    })
                    vm.detail.deliveries[i].printingOrderItems = printingOrderItems
                    let removePrintingOrderItems = []
                    delivery.orderItemSummary?.filter((itemLine) => itemLine.is_printing && !itemLine.isClearCase).forEach((itemLine) => {
                        itemLine.relatedOrderItems.forEach((relatedOrderItem) => {
                            if (relatedOrderItem.internalStatus.includes('In Print Queue')) {
                                removePrintingOrderItems.push(relatedOrderItem)
                            }
                        })
                    })
                    vm.detail.deliveries[i].removePrintingOrderItems = removePrintingOrderItems
                })
                if (row) {
                    vm.detail.shouldShowSticker = false;
                    if (vm.detail.createdAt) {
                        if (Date.parse(vm.detail.createdAt) >= Date.parse('2016-10-21 15:00:00') && Date.parse(vm.detail.createdAt) <= Date.parse('2016-10-25 15:00:00')) {
                            vm.detail.shouldShowSticker = true;
                        }
                    }

                    var shipmentLabels = [];
                    var shipmentLabelObj = $('#order-shipping-label-container');
                    if(typeof row.shippingLabels != 'undefined' && row.shippingLabels){
                        $.each(row.shippingLabels, function(k, shippingLabel){
                            shipmentLabels.push(shippingLabel);
                        });
                    }
                    // shipmentLabelObj.html('');//
                    var temp = [];
                    if(shipmentLabels.length > 0){
                        $.each(shipmentLabels, function(k, shipmentLabel){
                            if(shipmentLabel['shipping_label_url'] && shipmentLabel['shipping_label_url'][0]){
                                temp.push(`<div><a target="_blank" href="${shipmentLabel['shipping_label_url'][0]}">${shipmentLabel['tracking_code']}</a></div>`);
                            } else {
                                temp.push(`<div>${shipmentLabel['tracking_code']} (no label)</div>`);
                            }
                        });
                    }
                    //moved to blade
                    // shipmentLabelObj.html(temp.join(''));

                }

                PageViewModel.openModal();

                $timeout(function () {
                    $('[data-toggle="popover"]').on('click',function(e){
                        e.preventDefault();
                    }).popover(); 
                });

                window.history.pushState(null, null, "/"+vm.mode+"/order/" + row.refId);
                if (callback) {
                    callback(vm.detail)
                }
            }, (response) => {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.isCurrentPageLoading = false;
            });
        }

        function canMergeDelivery() {

            if (!vm.detail.deliveries) return false;

            var cnt = 0;
            for (var idx in vm.detail.deliveries) {
                if (vm.detail.deliveries[idx].check) {
                    if (vm.detail.deliveries[idx].status !== 'created') return false;
                    if (vm.detail.deliveries[idx].shippedTime !== null) return false;
                    cnt++;
                }
            }

            if (cnt < 2) return false;

            return true;
        }

        function openMergeDeliveryModal() {

            // check whether the delivery has ship time and status = created
            // also check if the deliveries check >= 2

            vm.mergeDeliveries = [];

            for (var idx in vm.detail.deliveries) {
                if (vm.detail.deliveries[idx].check) {
                    vm.mergeDeliveries.push(vm.detail.deliveries[idx]);
                }
            }

            vm.mergeDeliveryData = {
                address        : mergeDeliveryAddressOption()[0].id,
                shippingMethod : mergeDeliveryShippingMethodOption()[0]
            };

            PageViewModel.switchModal('#modal', '#modal-merge-delivery');

        }

        function mergeDelivery() {

            vm.mergeDeliveryData.processing = true;

            serverGateway.ajax('merge-delivery', '', {
                orderId    : vm.detail.id,
                deliveries : _.map(vm.mergeDeliveries, function (delivery) { return delivery.id; }),
                data       : vm.mergeDeliveryData
            }).then(function (response) {

                vm.mergeDeliveryData.processing = false;
                var success = (_.size(response.data.data[0].deliveries) != _.size(vm.detail.deliveries));

                if (success) {
                    PageViewModel.switchModal('#modal-merge-delivery', '#modal');

                    var idx = _.findIndex(vm.rows, function (row) {
                        return row.id == vm.detail.id;
                    });
                    vm.detail = response.data.data[0];
                    if (idx != -1) vm.rows[idx] = response.data.data[0];

                }
                else {
                    vm.mergeDeliveryData.error = true;
                }

            });

        }

        function mergeDeliveryAddressOption() {

            var address = [], addressIds = [];

            for (var idx in vm.mergeDeliveries) {
                if (addressIds.indexOf(vm.mergeDeliveries[idx].address.id) == -1) {
                    address.push(vm.mergeDeliveries[idx].address);
                    addressIds.push(vm.mergeDeliveries[idx].address.id);
                }
            }

            return address;

        }

        function mergeDeliveryShippingMethodOption() {

            var shippingMethod = [];

            for (var idx in vm.mergeDeliveries) {
                if (shippingMethod.indexOf(vm.mergeDeliveries[idx].shippingMethod) == -1)
                    shippingMethod.push(vm.mergeDeliveries[idx].shippingMethod);
            }

            return shippingMethod;

        }

        function printLabel(delivery) {
            if (delivery.status == 'completed') {
                if (!confirm("Printing label again for shipped order?")) {
                    return;
                }
            }

            if (delivery.hasExpiringShipment) {
                if (!confirm("Confirm printing expired label?")) {
                    return;
                }
            }

            vm.printLabelButtonLoading = true;
            const callbackFunction  = () => {
                serverGateway.ajax('printLabel', null, {stationId: vm.station, deliveryId: delivery.id, manualPrint: 'order'})
                .then(function(response) {
                    vm.printLabelButtonLoading = false;
                    if (response.data === '') {
                        
                    } else {
                        // $.each(response.data.data, function (index, printJob) {
                        //     trackPrintStatus(printJob.id, printJob.status, 10);
                        //     // console.log(element);
                        // });
                        if(vm.detail.refId.startsWith('AWS')){
                            window.location.reload();
                        }
    
                    }
                }, function (response) {
                    vm.printLabelButtonLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            };
            beforePrintLabel(callbackFunction, delivery);
        }

        function beforePrintLabel(callbackFunction, delivery){
            serverGateway.ajax('beforePrintLabel', {id: delivery.id}, {stationId: vm.station}).then(function(response) {
                if(response.data.status == 'failed'){
                    vm.printLabelButtonLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                    return;
                }
                else if(response.data.status == 'warning'){
                    if(!confirm(`${response.data.message}, continue?`)){
                        vm.printLabelButtonLoading = false;
                        return;
                    }
                }
                callbackFunction();
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function regenerateShipment(delivery) {
            if (vm.detail.shippingLabelPrinted) {
                if (!confirm("Cancel printed label?")) {
                    return; 
                }
            }
            vm.detail.regenerateButtonLoading = true;
            serverGateway.ajax('regenerateShipment', {id: delivery.id})
            .then(function(response) {
                vm.detail.regenerateButtonLoading = false;
                window.location.reload();
            }, function (response) {
                vm.detail.regenerateButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function regenerateShippingLabel(orderId){
            vm.detail.regenerateButtonLoading = true;
            serverGateway.ajax('regenerateShippingLabel', null, {id: orderId})
            .then(function(response) {
                vm.detail.regenerateButtonLoading = false;
                window.location.reload();
            }, function (response) {
                vm.detail.regenerateButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function schedulePrintingToNewStation(orderItem, orderId) {
            if (!vm.detail.remarks.match(/Skip Printing Process/i) || confirm('Order is marked as Skip Printing Process, sure to continue?')) {
                vm.detail.schedulePrintingButtonLoading = true;
                let newStation = vm.printStationList.find((station) => station.value == 'Station ST2_C1 (All Phone Case)');
                if (!newStation) {
                    vm.detail.schedulePrintingButtonLoading = false;
                    alert('Not found');
                    return;
                }
                serverGateway.ajax('schedulePrinting', {id: orderItem.id}, {printStationId:newStation.key})
                .then(function(response) {
                    window.location.href="/"+vm.mode+"/order/" + orderId;
                    vm.detail.schedulePrintingButtonLoading = false;
                }, function (response) {
                    vm.detail.schedulePrintingButtonLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            }
        }

        function schedulePrepack(orderItem, orderId) {
            vm.detail.schedulePrintingButtonLoading = true;
            serverGateway.ajax('schedulePrepack', {id: orderItem.id})
            .then(function(response) {
                if(!response.data.success){
                    $.bootstrapGrowl("Cannot schedule prepack", {ele: 'body', type: 'error'});
                    vm.detail.schedulePrintingButtonLoading = false;
                    return;
                }
                window.location.href="/"+vm.mode+"/order/" + orderId;
                vm.detail.schedulePrintingButtonLoading = false;
            }, function (response) {
                vm.detail.schedulePrintingButtonLoading = false;
                $.bootstrapGrowl("Cannot schedule prepack", {ele: 'body', type: 'error'});
            });
        }

        function schedulePrinting(orderItem, orderId) {
            vm.printStation = $('.print-station-select2').select2('val');
            if (!vm.detail.remarks.match(/Skip Printing Process/i) || confirm('Order is marked as Skip Printing Process, sure to continue?')) {
                vm.detail.schedulePrintingButtonLoading = true;
                serverGateway.ajax('schedulePrinting', {id: orderItem.id}, {printStationId:vm.printStation})
                .then(function(response) {
                    if(!response.data.success){
                        $.bootstrapGrowl(response.data.error, {ele: 'body', type: 'error'});
                        vm.detail.schedulePrintingButtonLoading = false;
                        return;
                    }
                    window.location.href="/"+vm.mode+"/order/" + orderId;
                    vm.detail.schedulePrintingButtonLoading = false;
                }, function (response) {
                    vm.detail.schedulePrintingButtonLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            }
        }

        function bulkSchedulePrinting(orderItems, orderId, orderItemId) {
            vm.printStation = $('.print-station-select2').select2('val');
            if (!vm.printStation) {
                vm.printStation = -1;
            }
            if (vm.detail.schedulePrintingButtonLoading) {
                return;
            }
            if (!vm.detail.remarks.match(/Skip Printing Process/i) || confirm('Order is marked as Skip Printing Process, sure to continue?')) {
                vm.detail.schedulePrintingButtonLoading = true;
                let orderItemIds = orderItems.map((orderItem) => orderItem.id).join(',')
                serverGateway.ajax('bulkSchedulePrinting', null, {printStationId:vm.printStation, orderItemIds: orderItemIds}).then((response) => {
                    vm.detail.schedulePrintingButtonLoading = false;
                    $.bootstrapGrowl("Done", {ele: 'body', type: 'success'});
                    window.location.reload();
                }, (response) => {
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                })
                vm.detail.schedulePrintingButtonLoading = false;
            }
        }

        function removePrinting(orderItem, orderId) {
            vm.detail.removePrintingButtonLoading = true;
            serverGateway.ajax('removePrinting', {id: orderItem.id})
            .then(function(response) {
                window.location.href="/"+vm.mode+"/order/" + orderId;
                vm.detail.removePrintingButtonLoading = false;
            }, function (response) {
                vm.detail.removePrintingButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function bulkRemovePrinting(orderItems, orderId, orderItemId) {
            console.log(orderItems)
            vm.detail.removePrintingButtonLoading = true;
            serverGateway.ajax('bulkRemovePrinting', null, {ids: orderItems.map((item) => item.id).join(',')})
            .then(function(response) {
                window.location.href="/"+vm.mode+"/order/" + orderId + "?orderItemId=" + orderItemId;
                vm.detail.removePrintingButtonLoading = false;
            }, function (response) {
                vm.detail.removePrintingButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }
        
        function returnShipping(shipment_id, delivery = null) {//if pass delivery = no shipment
            if (!confirm("Confirm?")) {
                return;
            }
            vm.detail.returnShippingButtonLoading = true;
            serverGateway.ajax('returnShipping', '', {shipment_id: shipment_id, delivery_id: delivery ? delivery.id : null}).then(function(response) {
                vm.detail.returnShippingButtonLoading = false;
                if(response.data && response.data.returnShippingLabel) {
                    let labelfilepath = response.data.returnShippingLabel;
                    if (vm.detail.shippingMethod == 'fedex-usps' && !labelfilepath.includes('http')) {              
                        let labelfile = labelfilepath.replace("/var/www/ctg_admin/storage/app/shipping_material/","");
                        window.location.href =  "https://cdn-admin.casetify.com/shipments/"+ shipment_id +"/shippingMaterials/"+labelfile;
                    } else {
                        window.location.href = response.data.returnShippingLabel;
                    }
                }
            }, function (response) {
                vm.detail.returnShippingButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function finishShipping(delivery, skipSerialNumber = false) {
            // if (!vm.detail.shippingLabelPrinted && !vm.printLabelCache[delivery.id]) {
            //     if (!confirm("Continue without printing label?")) {
            //         return;
            //     }
            // }
            if (!confirm("Confirm?")) {
                return;
            }
            let preSealBoxName = null;
            if (!vm.detail.isBulkOrder) {
                preSealBoxName = prompt('Please enter the packing box name');
                if(preSealBoxName == '' || preSealBoxName == null){
                    $.bootstrapGrowl('Box name is empty', {ele: 'body', type: 'error'});
                    return
                }
            } else {
                preSealBoxName = 'hs-sz-bypass';
            }
            

            vm.detail.shippedButtonLoading = true;
            serverGateway.ajax('finishShipping', {id: delivery.id},{boxName: preSealBoxName, skipSerialNumber: skipSerialNumber}).then(function(response) {
                vm.detail.shippedButtonLoading = false;
                if(response.data) {
                    window.location.reload();
                }
            }, function (response) {
                vm.detail.shippedButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function addPreSealPacking(delivery){
            console.log('addPreSealPacking START');

            let preSealBoxName = prompt('Please enter the packing box name');
            if(preSealBoxName == '' || preSealBoxName == null){
                $.bootstrapGrowl('Box name is empty', {ele: 'body', type: 'error'});
                return
            }

            serverGateway.ajax('addPreSealPacking', {id: delivery.id},{boxName: preSealBoxName}).then(function(response) {
                if(response.data) {
                    window.location.reload();
                }
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function cancelLabel(detail, delivery) {
            var orderTime = new Date(detail.createdAt);
            var currentTime = new Date();
            if ((currentTime - orderTime) / 3600 / 1000 > 24) {
                if (!confirm("Order is created 1 day before, are you sure you want to cancel label?")) {
                    return;
                }
            }
            vm.detail.cancelLabelButtonLoading = true;
            serverGateway.ajax('cancelLabel', {id: delivery.id}).then(function(response) {
                vm.detail.cancelLabelButtonLoading = false;
                $.bootstrapGrowl('Label cancelled', {ele: 'body', type: 'success'});
                if(response.data) {
                    window.location.reload();
                }
            }, function (response) {
                vm.detail.cancelLabelButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function resyncOrder(orderId, refId) {
            vm.detail.resyncOrderLoading = true;
            serverGateway.ajax('resyncOrder', {id: orderId}).then(function(response) {
                vm.detail.resyncOrderLoading = false;
                $.bootstrapGrowl('Order Synced', {ele: 'body', type: 'success'});
                if(response.data) {
                    window.location.href="/"+vm.mode+"/order/" + refId;
                }
            }, function (response) {
                vm.detail.resyncOrderLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function prepareOrder(orderId, refId) {
            vm.detail.prepareOrderLoading = true;
            serverGateway.ajax('prepareOrder', {id: orderId}).then(function(response) {
                vm.detail.prepareOrderLoading = false;
                $.bootstrapGrowl('Order Preparing', {ele: 'body', type: 'success'});
                if(response.data) {
                    window.location.href="/"+vm.mode+"/order/" + refId;
                }
            }, function (response) {
                vm.detail.prepareOrderLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function unprepareOrder(orderId, refId) {
            vm.detail.prepareOrderLoading = true;
            serverGateway.ajax('unprepareOrder', {id: orderId}).then(function(response) {
                vm.detail.prepareOrderLoading = false;
                $.bootstrapGrowl('Order Un-preparing', {ele: 'body', type: 'success'});
                if(response.data) {
                    window.location.href="/"+vm.mode+"/order/" + refId;
                }
            }, function (response) {
                vm.detail.prepareOrderLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function withholdOrder(orderId, refId) {
            vm.detail.withholdOrderLoading = true;
            serverGateway.ajax('withholdOrder', {id: orderId}).then(function(response) {
                vm.detail.withholdOrderLoading = false;
                $.bootstrapGrowl('Order withheld', {ele: 'body', type: 'success'});
                if(response.data) {
                    window.location.href="/"+vm.mode+"/order/" + refId;
                }
            }, function (response) {
                vm.detail.withholdOrderLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function releaseOrder(orderId, refId) {
            vm.detail.releaseOrderLoading = true;
            serverGateway.ajax('releaseOrder', {id: orderId}).then(function(response) {
                vm.detail.releaseOrderLoading = false;
                $.bootstrapGrowl('Order Released', {ele: 'body', type: 'success'});
                if(response.data) {
                    window.location.href="/"+vm.mode+"/order/" + refId;
                }
            }, function (response) {
                vm.detail.releaseOrderLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function sealActionWithhold(sealShipmentId) {
            vm.detail.sealActionWithholdButtonLoading = true;
            serverGateway.ajax('sealActionWithhold', null, {shipmentId: sealShipmentId}).then(function(response) {
                vm.detail.sealActionWithholdButtonLoading = false;
                $.bootstrapGrowl('Shipment Withhold', {ele: 'body', type: 'success'});
                if(response.data) {
                    window.location.href="/"+vm.mode+"/order/" + vm.detail.refId;
                }
            }, function (response) {
                vm.detail.sealActionWithholdButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function sealActionDiscard(sealShipmentId) {
            vm.detail.sealActionDiscardButtonLoading = true;
            serverGateway.ajax('sealActionDiscard', null, {shipmentId: sealShipmentId}).then(function(response) {
                vm.detail.sealActionDiscardButtonLoading = false;
                $.bootstrapGrowl('Shipment Discard', {ele: 'body', type: 'success'});
                if(response.data) {
                    window.location.href="/"+vm.mode+"/order/" + vm.detail.refId;
                }
            }, function (response) {
                vm.detail.sealActionDiscardButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function sealActionUndo(sealShipmentId) {
            vm.detail.sealActionWithholdButtonLoading = true;
            serverGateway.ajax('sealActionUndo', null, {shipmentId: sealShipmentId}).then(function(response) {
                vm.detail.sealActionUndoButtonLoading = false;
                $.bootstrapGrowl('Order Undo', {ele: 'body', type: 'success'});
                if(response.data) {
                    window.location.href="/"+vm.mode+"/order/" + vm.detail.refId;
                }
            }, function (response) {
                vm.detail.sealActionUndoButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function showSplitOrder(id) {
            vm.detail.splitOrderButtonLoading = true;
            serverGateway.ajax('splitInfo', {id}).then(function(response) {
                vm.detail.splitOrderButtonLoading = false;
                // $.bootstrapGrowl('Order Undo', {ele: 'body', type: 'success'});
                if(response.data) {
                    vm.splitInfo = [];
                    response.data.data.deliveries.forEach((delivery) => {
                        if (delivery.status == 'completed') return;
                        delivery.orderItems.forEach((orderItem) => {
                            orderItem.partialShip = !!orderItem.partialShipRequestId;
                            orderItem.autoSplitInfo = Object.assign(orderItem.autoSplitInfo, response.data.data.autoSplitInfo)
                            orderItem.autoSplitInfo = Object.assign(orderItem.autoSplitInfo, delivery.autoSplitInfo)
                            vm.splitInfo.push(orderItem);
                        })
                    });
                    PageViewModel.openModal('#modal-split-delivery');
                }
            }, function (response) {
                vm.detail.splitOrderButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function splitOrder(id, splitInfo) {
            vm.detail.splitOrderButtonLoading = true;
            serverGateway.ajax('splitOrder', {id}, {splitInfo}).then(function(response) {
                vm.detail.splitOrderButtonLoading = false;
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                PageViewModel.hideModal("#modal-split-delivery");
                vm.resyncOrder(vm.detail.id, vm.detail.refId);
            }, function (response) {
                vm.detail.splitOrderButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function requestPreview(orderItem) {
            vm.detail.requestPreviewLoading = true;
            serverGateway.ajax('requestPreview', {id: orderItem.id}).then(function(response) {
                vm.detail.requestPreviewLoading = false;
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                window.location.href="/"+vm.mode+"/order/" + vm.detail.refId;
            }, function (response) {
                vm.detail.requestPreviewLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function updateProcessingLocation() {
            vm.detail.updateProcessingLocationLoading = true;
            console.log($('#location-select').val().split()[1])
            console.log(vm.detail.locationId);
            serverGateway.ajax('updateProcessingLocation', {id: vm.detail.id}, {'locationId': vm.detail.locationId}).then(function(response) {
                vm.detail.updateProcessingLocationLoading = false;
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                window.location.href="/"+vm.mode+"/order/" + vm.detail.refId;
            }, function (response) {
                vm.detail.updateProcessingLocationLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function updateDeliveryProcessingLocation(delivery) {
            vm.detail.updateProcessingLocationLoading = true;
            serverGateway.ajax('updateDeliveryProcessingLocation', {id: delivery.id}, {'locationId': vm.detail.deliveryLocation[delivery.id]}).then(function(response) {
                vm.detail.updateProcessingLocationLoading = false;
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                window.location.href="/"+vm.mode+"/order/" + vm.detail.refId;
            }, function (response) {
                vm.detail.updateProcessingLocationLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function togglePrintOnPrint(orderItemId, enable) {
            vm.detail.printOnPrintLoading = true;
            serverGateway.ajax('togglePrintOnPrint', {id: orderItemId, enable: enable ? 'enable' : 'disable'}).then(function(response) {
                vm.detail.printOnPrintLoading = false;
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                window.location.href="/"+vm.mode+"/order/" + vm.detail.refId;
            }, function (response) {
                vm.detail.printOnPrintLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function loadOrderItem(orderItem, delivery) {
            console.log(delivery)
            vm.isCurrentPageLoading = true;
            serverGateway.ajax('get-order-item', {id: orderItem.id}, {mode: 'track-order'}).then(function(response) {
                vm.isCurrentPageLoading = false;
                vm.switchToOrderItem(response.data.data, delivery)
            }, function (response) {
                vm.isCurrentPageLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function switchToOrderItem(orderItem, delivery) {
            vm.delivery={}; 
            vm.deliveryLocationId = delivery.location.id;
            vm.orderItem=orderItem;
            let serialNumberSelector = document.querySelector('[name="serialNumber-' + orderItem.id + '"]');
            if(serialNumberSelector){
                serialNumberSelector.value = vm.orderItem.serialNumber;
            }
            vm.printStationList = config.printStationList.filter((printStation) => !printStation.location_id || printStation.location_id == ((delivery && delivery.location) ? delivery.location.id : vm.detail.locationId));
            updatePrintStationSelect2();
            vm.switchModal('#modal', '#modal-order-item');
            window.history.pushState(null, null, "/"+vm.mode+"/order/" + vm.detail.refId + "?orderItemId=" + orderItem.refId);
        }

        function switchToOrderItemSummary(orderItem, delivery) {
            vm.delivery=delivery; 
            vm.deliveryLocationId = delivery.location.id;
            orderItem.allowRemovePrintItems = orderItem.relatedOrderItems.filter((item) => item.internalStatus.includes('In Print Queue'))
            orderItem.allowSchedulePrintItems = orderItem.relatedOrderItems.filter((item) => item.internalStatus.includes('Not Ready'))
            vm.summaryOrderItem=orderItem;
            let serialNumberSelector = document.querySelector('[name="serialNumber-' + orderItem.id + '"]');
            if(serialNumberSelector){
                serialNumberSelector.value = vm.summaryOrderItem.serialNumber;
            }
            vm.printStationList = config.printStationList.filter((printStation) => !printStation.location_id || printStation.location_id == ((delivery && delivery.location) ? delivery.location.id : vm.detail.locationId));
            updatePrintStationSelect2();
            vm.switchModal('#modal', '#modal-order-item-summary');
            window.history.pushState(null, null, "/"+vm.mode+"/order/" + vm.detail.refId + "?orderItemId=" + orderItem.refId);
        }

        function unshipOrder(orderId) {
            vm.detail.unshipLoading = true;
            serverGateway.ajax('unshipOrder', {id: orderId}).then(function(response) {
                vm.detail.unshipLoading = false;
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                window.location.href="/"+vm.mode+"/order/" + vm.detail.refId;
            }, function (response) {
                vm.detail.unshipLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function addSerialNumber(orderItem, productionItem = null) {
            if(productionItem){
                submitSerialNumber(orderItem, productionItem);
                return;
            }
            let serialNumberSelector = document.querySelector('[name="serialNumber-' + orderItem.id + '"]');
            let serialNumber = serialNumberSelector.value;
            const regex = /casetify\.com\/auth\/([\w\-]*)/igm;
            let regexresult = regex.exec(serialNumber)
            if (regexresult && regexresult[1]) {
                serialNumber = regexresult[1]
            }

            // if (orderItem.serialNumber == serialNumber) return;
            if (orderItem.serialNumber && !confirm('Confirm Update?')) return;

            vm.serialNumberLoading = true;
            serverGateway.ajax('syncSerialNumber', { id: orderItem.id }, { serialNumber: serialNumber })
                .then(function (response) {
                    vm.serialNumberLoading = false;
                    if (response.data.success) {
                        orderItem.serialNumber = serialNumber
                        $.bootstrapGrowl("Serial Number updated successfully", { ele: 'body', type: 'success' });
                    } else {
                        $.bootstrapGrowl(response.data.message, { ele: 'body', type: 'error' });
                    }
                    serialNumberSelector.value = response.data.data && response.data.data.serial_number ? response.data.data.serial_number : orderItem.serialNumber;
                    orderItem.serialNumber = serialNumberSelector.value
                    vm.orderItem.serialNumber = serialNumberSelector.value
                }, function (response) {
                    vm.serialNumberLoading = false;
                    serialNumberSelector.value = response.data.data && response.data.data.serial_number ? response.data.data.serial_number : orderItem.serialNumber;
                    orderItem.serialNumber = serialNumberSelector.value
                    vm.orderItem.serialNumber = serialNumberSelector.value
                    $.bootstrapGrowl(response.data.message, { ele: 'body', type: 'error' });
                });
        }

        function submitSerialNumber(orderItem, productionItem, locationId = vm.deliveryLocationId){
            const sn = vm.serialNumber[productionItem.id];
            if(!sn){
                $.bootstrapGrowl('Please fill in Serial Number', {ele: 'body', type: 'error'});
                return;
            }
            if(vm.serialNumberLoading){
                return;
            }
            if(!confirm('Confirm update?')){
                return;
            }
            // const ctf = `CTF-${productionItem.printable.artworkId}-${orderItem.product.name}`;
            const ctf = `CTF-${productionItem.printable.artworkId}-${productionItem.printable.product}`;
            vm.serialNumberLoading = true;
            serverGateway.ajax('associateSerialNumber', null, {sn: sn, ctf: ctf, upc: null, locationId: locationId}).then(function(response){
                if(response.data.status == 'OK'){
                    $.bootstrapGrowl('Associate Success!', {ele: 'body', type: 'success'});
                } else {
                    $.bootstrapGrowl(response.data.msg, {ele: 'body', type: 'error'});
                }
                vm.serialNumberLoading = false;
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.serialNumberLoading = false;
            });
        }

        function serialNumberKeypress(event, orderItem, productionItem = null){
            if(event.which == 13){//is pressed enter
                addSerialNumber(orderItem, productionItem);
            }
        }

        function onChangeSerialNumber(productionItem){
            const regex = /casetify\.com\/auth\/([\w\-]*)/igm;
            let regexresult = regex.exec(vm.serialNumber[productionItem.id]);
            if (regexresult && regexresult[1]) {
                vm.serialNumber[productionItem.id] = regexresult[1];
            }
        }

        function forceRequestPrintable(orderItem, regeneratePrintable, printable, forceColorspace = null){
            if (!printable) {
                printable = orderItem.printable
            }
            if(typeof printable == 'undefined' || typeof printable.id == 'undefined' || !printable.id){
                $.bootstrapGrowl("Printable not exist", { ele: 'body', type: 'error' });

                return false;
            }

            var regenerate = 'N';
            if(typeof regeneratePrintable != 'undefined' && regeneratePrintable){
                regenerate = 'Y';
            }

            vm.requestPrintableLoading = true;

            serverGateway.ajax(
                'forceRequestPrintable', 
                { id: printable.id }, 
                { orderItemId: orderItem.id, force: 'Y', regenerate: regenerate, forceColorspace: forceColorspace }
            ).then(function (response) {
                vm.requestPrintableLoading = false;

                if (response.data.data.status == 'completed') {
                    $.bootstrapGrowl("Regenerate printable successfully", { ele: 'body', type: 'success' });

                    window.location.reload();

                } else {
                    $.bootstrapGrowl(response.data.data.status, { ele: 'body', type: 'error' });
                }

            }, function (response) {
                vm.requestPrintableLoading = false;
                
                $.bootstrapGrowl(response.data.data.status, { ele: 'body', type: 'error' });
            });


        }

        function forceRegeneratePrintable(orderItem, printable, forceColorspace = null){
            return forceRequestPrintable(orderItem, true, printable, forceColorspace);
        }

        function initPrintStationSelect2(){
            $(document).ready(function() {
                $('.print-station-select2').select2({
                    width: '100%',
                    dropdownCssClass: 'print-station-select2-style text-left',
                    dropdownParent: $('.print-station-select2-parent')
                });

                $('.print-station2-select2').select2({
                    width: '100%',
                    dropdownCssClass: 'print-station-select2-style text-left',
                    dropdownParent: $('.print-station2-select2-parent')
                });
            });
        }

        function updatePrintStationSelect2(){
            $('.print-station-select2').select2('destroy').empty().select2({
                data: vm.printStationList.map(printStation => { return {id: printStation.key, text: printStation.value};}),
                width: '100%',
                dropdownCssClass: 'print-station-select2-style text-left',
                dropdownParent: $('.print-station-select2-parent')
            });
            $('.print-station2-select2').select2('destroy').empty().select2({
                data: vm.printStationList.map(printStation => { return {id: printStation.key, text: printStation.value};}),
                width: '100%',
                dropdownCssClass: 'print-station-select2-style text-left',
                dropdownParent: $('.print-station2-select2-parent')
            });
        }

        function downloadJpYamatoFiles(deliveryId){
            $("<a download/>").attr("href", `/deliveries/${deliveryId}/downloadJpYamatoFiles?fileType=csv`).get(0).click();
            setTimeout(function() {
                $("<a download/>").attr("href", `/deliveries/${deliveryId}/downloadJpYamatoFiles?fileType=trg`).get(0).click();
            }, 2000);//smartcat need to trigger csv first then trg, so need to complete download csv first
        }

        function downloadYamatoSkuCsv(deliveryId){
            $("<a download/>").attr("href", `/deliveries/${deliveryId}/downloadSkuCsv?nonPrint=Yes`).get(0).click();
        }

        function requestBulkOrderMaterial(orderId) {
            if (vm.requestBulkOrderMaterialButtonLoading) return false;
            vm.requestBulkOrderMaterialButtonLoading =  true;
            serverGateway.ajax('requestBulkOrderMaterial', { id: orderId })
                .then(function (response) {
                    vm.requestBulkOrderMaterialButtonLoading = false;
                    if (response.data.data) {
                        $.bootstrapGrowl("Material request successful", { ele: 'body', type: 'success' });
                        window.location.reload();
                    } else {
                        $.bootstrapGrowl(response.data.message, { ele: 'body', type: 'error' });
                    }
                }, function (response) {
                    vm.requestBulkOrderMaterialButtonLoading = false;
                    $.bootstrapGrowl(response.data.message, { ele: 'body', type: 'error' });
                });
        }

        function requestBulkOrderTransitBatch(orderId) {
            if (vm.requestBulkOrderMaterialButtonLoading) return false;
            vm.requestBulkOrderMaterialButtonLoading =  true;
            serverGateway.ajax('requestBulkOrderTransitBatch', { id: orderId })
                .then(function (response) {
                    vm.requestBulkOrderMaterialButtonLoading = false;
                    if (response.data.data) {
                        $.bootstrapGrowl("Transit batch request successful", { ele: 'body', type: 'success' });
                        window.location.reload();
                    } else {
                        $.bootstrapGrowl(response.data.message, { ele: 'body', type: 'error' });
                    }
                }, function (response) {
                    vm.requestBulkOrderMaterialButtonLoading = false;
                    $.bootstrapGrowl(response.data.message, { ele: 'body', type: 'error' });
                });
        }

        function renewShipment(delivery){
            console.log(delivery);
            vm.detail.renewShipmentLoading = true;
            serverGateway.ajax('renewShipment', {id: delivery.id})
            .then(function(response) {
                vm.detail.renewShipmentLoading = false;
                window.location.reload();
            }, function (response) {
                vm.detail.renewShipmentLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }
    }

})();

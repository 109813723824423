(function() {
    'use strict';

    angular
        .module('grid')
        .directive('ctgCellPrintingTemplateAction', ctgCellPrintingTemplateAction);

    function ctgCellPrintingTemplateAction() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: '/template/printing/cellPrintingTemplateAction.html?v=1',
            controller: ['$scope', function($scope) {
                var vm = this;

                vm.openDetailDialog = function(row) {
                    $scope.$parent.$parent.$parent.vm.openDetailDialog(row);
                };

                vm.syncFromParent = function(row) {
                    $scope.$parent.$parent.$parent.vm.syncFromParent(row);
                };

                vm.distributeToChild = function(row) {
                    $scope.$parent.$parent.$parent.vm.distributeToChild(row);
                };

                return vm;
            }],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;
    }
})();
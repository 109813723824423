(function() {
    'use strict';

    angular
        .module('genericInput')
        .config(Config);

    Config.$inject = ['$interpolateProvider'];

    function Config($interpolateProvider) {
        $interpolateProvider.startSymbol('<%');
        $interpolateProvider.endSymbol('%>');
    }
})();
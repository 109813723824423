// (function() {
//     'use strict';

//     angular
//         .module('grid')
//         .controller('GridController', Controller);

//     Controller.$inject = ['$scope', '$timeout', 'gridDataSource', 'serverGateway', 'gridColumns', 'gridFields'];

//     function Controller($scope, $timeout, gridDataSource, serverGateway, gridColumns, gridFields) {
//         var pendingSort;
//         var vm = this;
//         vm.rows = [];
//         vm.columns = gridColumns;
//         vm.sorts = [];
//         vm.sortChange = sortChange;
//         vm.isLoading = true;

//         // initialize
//         gridDataSource.setColumns(gridFields);
//         loadRows();

//         function sortChange(sorts) {

//             $timeout.cancel(pendingSort);
//             pendingSort = $timeout(function() {
//                 pendingSort = null;
//                 loadSortResult();
//             }, 700);

//             function loadSortResult() {
//                 gridDataSource.prop('sorts', sorts);
//                 loadRows();
//             }
//         }

//         function loadRows() {
//             vm.isLoading = true;
//             gridDataSource.next().done(function(data) {
//                 $scope.$apply(function() {
//                     vm.rows = data;
//                     vm.isLoading = false;
//                 });
//             });
//         }
//     }
// })();
(function() {
    'use strict';

    angular
        .module('server')
        .factory('ServerGateway', ServerGateway);

    ServerGateway.$inject = ['$http', '$q'];

    function ServerGateway($http, $q) {

        var ServerGateway = jy.define({
            constructor: function(options) {
                if (typeof(Storage) === "undefined") {
                    throw 'Sorry, your browser is not support local stoarge';
                }
                options = options || {};
                this.endPoints = options.endPoints || {};
                this.baseUrl = options.baseUrl || '/api';
                this.version = options.version || 'v0';
                this.authFail = options.authFail || $.noop;
            },

            createUrl: function(path, data) {
                var match;
                var placehoderRegex = /\{([A-Za-z0-9\.\-]+)\}/g;
                while(match = placehoderRegex.exec(path)) {
                    path = path.substring(0, match.index) + jy.resolveVariable(match[1], data) + path.substring(match.index + match[0].length);
                }
                var url = this.baseUrl + '/' + path;
                return url;
            },

            saveApiToken: function(token) {
                localStorage.setItem('api-token', token);
            },

            getApiToken: function() {
                // return localStorage.getItem('api-token');
                return localStorage.getItem('api-auth-token');
            },

            login: function(email, password) {
                var self = this;
                return this._ajax('login', 'POST', {
                        email: email,
                        password: password
                    }).then(function(data) {
                        return data.data;
                    });
            },

            logout: function() {
                var self = this;
                return this._ajax('logout', 'POST');
            },

            _ajax: function(url, method, data) {
                var token = this.getApiToken();
                var dataSerialized = $.param(data);
                // console.trace(url);
                if (typeof url == 'undefined') return $q.defer().promise;
                var options = {
                        method: method,
                        url: url + (method === 'GET' && dataSerialized != '' ? (url.indexOf('?') != -1 ? '&' : '?') + dataSerialized : ''),
                        data: method === 'GET' ? {} : data,
                        responseType: method === 'GET' ? '' : 'json',
                        headers: {
                            Authorization: 'Basic ' + token,
                            'Content-Type': 'application/json; charset=UTF-8'
                        }
                    };

                // TODO: handle token expiry problem
                var self = this;
                return $http(options).error(function(e) {
                        if (e.status_code === 401) {
                            $.bootstrapGrowl("Unauthorize Access, please login your account again", {ele: 'body', type: 'error'});
                        }
                        if (e.status === 419) { // please verify if it should use status_code instead
                            self.logout().done(function() {
                                location.reload();
                            });
                        }
                    });
            },

            ajax: function(name, urlData, data) {
                var endPoint = this.endPoints[name];
                if (!endPoint) {
                    throw name + ' end point not found';
                }

                return this._ajax(this.createUrl(endPoint.path, urlData || {}), endPoint.method || 'POST', $.extend({}, endPoint.data, data));
            }
        });

        return ServerGateway;
    }
})();

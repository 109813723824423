(function(Ctg, $) {
    Ctg.DataGrid = jy.define({
        extend: 'jy.PBase',

        statics: {
            default: {
                mode: 'edit'
            },

            columns: [
                'checkbox'
            ],

            columnsConfig: {
                checkbox: Ctg.CheckboxColumn
            }
        },

        constructor: function(options) {
            options = $.extend({}, Ctg.Select2.default, options);

            this._views = options.views;
            this._originalColumns = null;
            this.viewModePageSize = options.viewModePageSize || 50;
            this.workingModePageSize = options.workingModePageSize || 10;

            this.serverGateway = Ctg.serverGateway();

            this._initGridDataSource();
            this.setMode(options.mode || 'working');

            this._initViewDropdown();
            this._initQueryBuilder(options);
            this._initGrid(options);
            this._renderButtonBar(options);
            this._initColumnsEditor(options);
            this._initSearchBlockAffix();
            this._attachEventHandlers();

            this._initView(options);
        },

        _findView: function(id) {
            var views = this._views;
            for (var i = views.length - 1; i >= 0; i--) {
                if (views[i].id == id) {
                    return i;
                }
            }
            return -1;
        },

        _loadDefaultView: function(options) {
            var columns = [];
            for (var field in options.columnsConfig) {
                columns.push(field);
            }
            this.applyView({columns: columns, rules: {}});
        },

        _initView: function(options) {
            this._loadDefaultView(options);
        },

        _initViewDropdown: function() {
            var self = this;
            this.viewDropdwon = new Ctg.Select2Editable('.badge-saved-view', {
                data: this._views,
                shouldCreate: function() {
                    return true;
                },
                create: function(name) {
                    return self._createView(name, this);
                },
                get: function(id) {
                    return self._getView(id);
                },
                remove: function(id) {
                    return self._removeView(id, this);
                },

            });
        },

        _initQueryBuilder: function(options) {
            jQuery.fn.queryBuilder.constructor.templates.group = jQuery.fn.queryBuilder.constructor.templates.group.replace('btn-group pull-right', 'pull-right');
            this.$queryBuilder = $('#builderBasic').queryBuilder({
                plugins: ['bt-tooltip-errors'],
                allow_groups: 2,
                allow_empty: true,
                filters: Ctg.makeFiltersConfig(options.filtersConfig)
            });
            this.clearFilter();
        },

        _initColumnsEditor: function(options) {
            this.columnEditor = new Ctg.ColumnEditor({
                active: '.active-list',
                inactive: '.inactive-list',
                columnsConfig: options.columnsConfig
            });

            var self = this;
            var restore = null;
            this.$columnEditorModal =  $('.badge-column-editor')
                .find('.badge-save')
                    .click(function() {
                        restore = false;
                        self._applyColumns(self.columnEditor.prop('activeColumns'));
                        self.$columnEditorModal.modal('hide');
                    })
                    .end()
                .find('.badge-all')
                    .click(function() {
                        self.columnEditor.activeAll();
                    })
                    .end()
                .on('show.bs.modal', function() {
                    self._originalColumns = self.columnEditor.prop('activeColumns');
                    restore = true;
                })
                .on('hidden.bs.modal', function() {
                    if (restore) {
                        self.columnEditor.prop('activeColumns', self._originalColumns);
                    }
                    self._originalColumns = null;
                    restore = null;
                });

        },

        _renderButton: function(button) {
            var self = this;
            var $button;

            if (button.render) {
                $button = $(button.render(this));
            } else {
                $button = $('<button class="btn btn-default">' + button.html + '</button>')
            }
            return $button.click(function() {
                button.click(self)
            });
        },

        _renderButtonBar: function(options) {
            for (var i = 0, c = options.buttons.length; i < c; i++) {
                var config = Ctg.ButtonBarConfig[options.buttons[i]];
                $('.badge-toolbar').append(this._renderButton(config));
            }
        },

        _initGridDataSource: function() {
            gridDataSource = this.gridDataSource = new Ctg.GridDataSource({
                getData: 'searchClientProducts',
                gateway: this.serverGateway,
            });
        },

        _initGrid: function(options) {
            var columnsConfig = $.extend({}, Ctg.DataGrid.columnsConfig, options.columnsConfig);

            // all column change line feed to br tag
            $.map(columnsConfig, function (val, i) {
                val.formatter = function (value, row) {
                    return (value && (typeof value == 'string')) ? value.replace(/(?:\r\n|\r|\n)/g, '<br />') : value;
                }
                return val;
            });

            var self = this;
            this.grid = new Ctg.Grid('#grid', {
                columnsConfig: columnsConfig,
                columnTypes: {
                    'Copyable': {
                        cell: function(row, column) {
                            var $button = $('<a href="javascript:void(0)">' + row[column.field] + '</a>')
                                .click(function() {
                                    var range = document.createRange();
                                    range.selectNode(this);
                                    window.getSelection().addRange(range);

                                    try {
                                        // Now that we've selected the anchor text, execute the copy command
                                        var successful = document.execCommand('copy');
                                    } catch(err) {
                                        alert('Oops, your browser does not support copy');
                                    }

                                    // Remove the selections - NOTE: Should use
                                    // removeRange(range) when it is supported
                                    window.getSelection().removeAllRanges();
                                });



                            return $button;
                        }
                    },
                    'DateTime': {
                        cell: function(row, column) {
                            return row[column.field] ? row[column.field].date : '';
                        }
                    },

                    'ContainsOutSourceItemTag': {
                        cell: function(row, column) {
                            return row[column.field] === null ? '' : 'hi';
                        }
                    },

                    'EditableAddress': {
                        cell: function(row, column) {
                            var $addressButton = $('<a href="javascript:void(0)">' + row[column.field] + '</a>').click(function() {
                                console.log('edit');
                            });
                            return $addressButton;
                        }
                    }
                },
                renderReady: function() {
                    this._$mask.remove();
                    this._$mask = $('<div class="overlay"><i class="fa fa-refresh fa-spin"></i></div>').appendTo(this._$el.parents('.box'));
                },
                sort: 'server',
                dataSource: this.gridDataSource,
                height: '200px'
            });
        },

        _initSearchBlockAffix: function() {
            // search box affix
            $('#searchBox').affix({
              offset: {
                top: 110,
              }
            }).on('affixed.bs.affix', function() {
                $(this).width($(this).parent().width());
            }).on('affixed-top.bs.affix', function() {
                $(this).css('width', '');
            });

            $(window).resize(function() {
                Ctg.slow('window.resize', 200, function() {
                    var $searchBox = $('#searchBox');
                    if ($searchBox.is('.affix-top')) return;
                    $searchBox.width($searchBox.parent().width())
                });
            });
        },

        _attachEventHandlers: function() {
            var self = this;
            $('#search').click(function() {
                self.search();
            });

            $('#reset').click(function() {
                self.clearFilter();
            });

            $('#viewMode').click(function() {
                self.setMode('view');
                self.grid.load();
            });

            $('#workingMode').click(function() {
                self.setMode('working');
                self.grid.load();
            });
        },

        _createView: function(name, select2) {
            var view = this.getView();
            var self = this;
            return this.serverGateway.ajax('createDataGridView', {}, {
                    name: name,
                    view: view,
                    gridName: 'order'
                })
                .done(function(result) {
                    self._views.push({
                        id: result.entity.id,
                        text: name,
                        view: view
                    });
                    select2.data(self._views).val(result.entity.id);
                });
        },

        _getView: function(id) {
            var view = this._views[this._findView(id)];
            var deferred;

            // check cache
            if (view.view) {
                deferred = $.Deferred().resolve();
                this.applyView(view.view);
            } else {
                var self = this;
                deferred = this.serverGateway.ajax('showDataGridView', {id: id})
                    .done(function(result) {
                        // store the cache
                        view.view = result.view;
                        self.applyView(view.view);
                    });
            }

            return deferred;
        },

        _removeView: function(id, select2) {
            var self = this;
            return this.serverGateway.ajax('deleteDataGridView', {id: id})
                .done(function(result) {
                    self._views.splice(self._findView(id), 1);
                    select2.data(self._views).val(null);
                });
        },

        setMode: function(mode) {
            if (this.mode === mode) return;
            this.mode = mode;

            if (mode === 'working') {
                this.gridDataSource.prop('pageSize', this.workingModePageSize);
                this.gridDataSource.prop('forceStopLoadNext', true);
                $('#workingMode').addClass('active');
                $('#viewMode').removeClass('active');
            } else if (mode === 'view') {
                this.gridDataSource.prop('pageSize', this.viewModePageSize);
                this.gridDataSource.prop('forceStopLoadNext', false);
                $('#workingMode').removeClass('active');
                $('#viewMode').addClass('active');
            } else {
                throw mode + ' is not support';
            }

            this.gridDataSource.reset();
        },

        getMode: function() {
            return _mode;
        },

        getView: function() {
            return {
                rules: this.$queryBuilder.queryBuilder('getRules'),
                columns: this.columnEditor.prop('activeColumns')
            }
        },

        getSelectedRow: function() {
            return this.grid._$table
                .find('input.badge-check[type="checkbox"]:checked')
                .parents('tr')
                .map(function() {
                    return $(this).data('ctg-grid.row');
                })
                .toArray();
        },

        search: function() {
            this.gridDataSource.prop('rules', this.$queryBuilder.queryBuilder('getRules'));
            this.grid.load();
        },

        clearFilter: function() {
            this.$queryBuilder.queryBuilder('setRules', []);
        },

        _applyColumns: function(columns) {
            columns = Ctg.DataGrid.columns.concat(columns);
            // console.log(columns);
            this.grid.prop('columns', columns);
            this.columnEditor.prop('activeColumns', columns);
        },

        _applyFilters: function(rules) {
            if ($.isEmptyObject(rules)) {
                this.clearFilter();
                this.gridDataSource.prop('rules', {});
            } else {
                this.$queryBuilder.queryBuilder('setRules', rules);
                this.gridDataSource.prop('rules', rules);
            }

        },

        applyView: function(view) {
            this._applyFilters(view.rules);
            this._applyColumns(view.columns);
        }
    });
})(Ctg, jQuery);

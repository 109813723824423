(function() {
    'use strict';

    angular
        .module('grid')
        .directive('ctgCellSkuFgDesc', ctgCellSkuFgDesc);

    function ctgCellSkuFgDesc() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: '/template/inventory/cellSkuFgDesc.html'
        };

        return directive;
    }
})();
Ctg = (function($) {
    Ctg = {};

    Ctg.guid = function() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
            return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
                s4() + '-' + s4() + s4() + s4();
    };

    var timers = {};
    Ctg.slow = function(id, delay, callback) {
        if (timers[id]) {
            return;
        }
        timers[id] = setTimeout(function() {
            timers[id] = null;
            callback();
        }, delay);
    };

    $(".btn-signout").click(function () {
        Ctg.serverGateway().logout();
    });

    return Ctg;

}(jQuery));
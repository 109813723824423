(function() {
    'use strict';

    angular
        .module('grid')
        .directive('screenshotPreview', screenshotPreview);

    screenshotPreview.$inject = ['$timeout'];

    function screenshotPreview($timeout) {
        return {
            restrict: 'A',
            scope: {
                ngModel : '=',
            },
            link: function(scope, element, attrs) {

                var yOffset = 300; //10;
                var xOffset = -100; //30;
                var $p = $('<p style="z-index:90"/>');


                $(element).hover(function (e) {
                    $p
                      .append("<span style='display: block; color: red; font-size: 20px; text-align: center; background-color: white;'>" + ( $(this).attr("data-artwork-name") || '')  + "</span><img src='"+ (this.rel || this.src) +"' />")
                      .css("top","10px")
                      .css("left", "auto")
                      .css("right","-" + $('img', $p).width() + "px")
                      .css("position", "absolute")
                      .fadeIn("fast");

                    // if the container outside the boundary, put it on the left side
                    var $modal = $p.closest('.modal-body');
                    if ($modal.length) {
                        if ($p.offset().left + $('img', $p).width() > $modal.offset().left + $modal.width()) {
                            $p.css("left", "-" + $('img', $p).width() + "px").css('right', 'auto');
                        }
                    }

                }, function () {
                    $p.empty();
                })/*.mousemove(function (e) {
                    $p
                      .css("top",(e.pageY - yOffset) + "px")
                      .css("left",(e.pageX + xOffset) + "px");
                })*/.after($p).parent().addClass('relative');

            }
        };
    }

})();

(function(jy, Ctg) {

    var filiterTypes = {
        select2: {
            multiple: true,
            plugin: 'select2',
            plugin_config: {
                createSearchChoice: function() { return null; },
                width: '100%',
                tags: true,
                createTag: function(params) {
                    return undefined;
                }
            }
        }
    };

    Ctg.makeFiltersConfig = function(filterConfig) {
        var operators = [];
        for (var i = 0, c = filterConfig.length; i < c; i++) {
            var config = filterConfig[i];
            if ($.isArray(config.values)) {
                config.input = 'checkbox';
            } else if (typeof config.type === 'number') {
                config.type = 'double';
            } else if (typeof config.type === 'list') {
                config.type = 'string'
            }

            if (config.columnType == 'Boolean') {
                config.type = 'boolean';
                config.input = 'radio';
            }
            if (config.columnType == 'DateTime') {
                config.type = 'datetime';
                config.plugin = 'datetimepicker';
                config.plugin_config = {
                    timepicker: false,
                    format: 'Y-m-d 00:00:00'
                };
            }

            // config.type = 'string';
            // console.log(config);
            // for (index in config.operators) {
            //     var found = false;
            //     var lastIndex = null;
            //     for (index2 in operators) {
            //         if (operators[index2].type == config.operators[index]) {
            //             found = true;
            //             lastIndex = index2;
            //             break;
            //         }
            //     }
            //     if (!found) {
            //         operators.push({ type: config.operators[index], apply_to: [] })
            //         lastIndex = operators.length - 1;
            //     }
            //     found = false;
            //     for (index3 in operators[lastIndex].apply_to) {
            //         if (operators[lastIndex].apply_to[index3] == config.filterType) {
            //             found = true;
            //             break;
            //         }
            //     }
            //     if (!found) {
            //         operators[lastIndex].apply_to.push(config.filterType)
            //     }
            // }
            // console.log(operators);
        }
//         filterConfig.operators = [
// { type: 'some_in', optgroup: 'basic' },
// { type: 'not_equal', optgroup: 'basic' }];
        return filterConfig;
    };

})(jy, Ctg);

(function() {
    'use strict';

    angular
        .module('grid')
        .directive('ctgCellMarketingInputProducts', ctgCellMarketingInputProducts);

    function ctgCellMarketingInputProducts() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: '../template/inventory/ctgCellMarketingInputProducts.html',
            controller: ['$scope', function($scope) {
                var vm = this;
                return vm;
            }],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;
    }
})();
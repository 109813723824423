(function() {
    'use strict';

    angular
        .module('grid')
        .factory('PageViewModel', PageViewModel);

    PageViewModel.$inject = ['$timeout', '$interval'];

    function PageViewModel($timeout, $interval) {
        var vm                 = this;
        vm.gridDataSource      = null;
        vm.rows                = [];
        vm.isLoading           = true;
        vm.setGridDataSource   = setGridDataSource;
        vm.update              = update;
        vm.load                = load;
        vm.loadNext            = loadNext;
        vm.updateLoadMoreBlock = updateLoadMoreBlock;
        vm.enableLoadMore      = enableLoadMore;
        vm.disableLoadMore     = disableLoadMore;
        vm.openModal           = openModal;
        vm.hideModal           = hideModal;
        vm.switchModal         = switchModal;
        vm.refreshWaypoint     = refreshWaypoint;
        vm.getSearchParameters = getSearchParameters;

        vm.initializeQueryBuilder = initializeQueryBuilder;
        vm.setFilter              = setFilter;
        vm.setFilterId            = setFilterId;
        vm.setFilterIdAndFirst    = setFilterIdAndFirst;
        vm.setFilterRules         = setFilterRules;
        vm.clearFilter            = clearFilter;
        vm.sortChange             = sortChange;
        vm._isLoading             = _isLoading;
        vm.debounceUpdate = debounceUpdate;

        $interval(function () {
            refreshWaypoint();
        }, 5000);

        function setGridDataSource(dataSource) {
            vm.gridDataSource = dataSource;
        }

        function debounce(fn, time) {
          let timeout;

          return function() {
            const functionCall = () => fn.apply(this, arguments);
            
            clearTimeout(timeout);
            timeout = setTimeout(functionCall, time);
          }
        }

        function debounceUpdate(callback, time) {
            return debounce(update, time)(callback);
        }

        function update(callback) {
            return vm.gridDataSource
                .update()
                .then(function(response) {
                    vm.rows.splice(0, vm.rows.length);
                    response.data.forEach(function(datum) {
                        vm.rows.push(datum);
                    });
                    vm.isLoading = false;
                    updateLoadMoreBlock();
                    updateFixedTableColumnWidth();
                    callback && callback();
                });
        }

        function load(callback) {
            console.log('load');

            vm.isLoading = true;

            return vm.gridDataSource
                .load()
                .then(function(response) {
                    console.log('load callback');
                    vm.rows = response.data;
                    vm.isLoading = false;

                    updateLoadMoreBlock();
                    updateFixedTableColumnWidth();
                    callback && callback();
                });
        }

        function loadNext() {
            if (vm.isLoading) return;
            vm.isLoading = true;

            console.log('loadNext');

            // set timeout for loading
            $timeout(function() { vm.isLoading = false; }, 5000);

            return vm.gridDataSource
                .loadNext()
                .then(function(response) {
                    console.log('loadNext callback');
                    $timeout(function() {
                        vm.rows = vm.rows.concat(response.data);
                        vm.isLoading = false;
                        updateLoadMoreBlock();
                        updateFixedTableColumnWidth();
                    }, 500);
                });
        }

        function updateLoadMoreBlock() {
            if (vm.gridDataSource.hasNext()) {
                $timeout(function() { enableLoadMore(); }, 10);
            } else {
                disableLoadMore();
            }
        }

        function enableLoadMore() {
            var waypoint = $('#rowLoading')
                .removeClass('hidden')
                .waypoint(function(event, direction) {
                    console.log('enableLoadMore: loadNextRows');
                    waypoint[0].destroy('remove');
                    loadNext();
                }, {
                    offset: '100%'
                });
        }

        function disableLoadMore() {
            $('#rowLoading').addClass('hidden');
        }

        function initializeQueryBuilder(filtersConfig) {
            $('#builder-basic').queryBuilder({

              plugins: ['bt-tooltip-errors'],

              filters: Ctg.makeFiltersConfig(filtersConfig),

              // rules: filtersConfig.length ? { 'condition': 'AND', 'rules': {
              //   id    : filtersConfig[0].id,
              //   field : filtersConfig[0].id,
              //   type  : filtersConfig[0].filterType,
              // } } : {}

            });
            $('#builder-basic').on('keydown', 'input', function(e) {
                if (e.which == 13) {
                    setFilter();
                }
            });
            setDefaultRule();
        }

        function setFilter(callback) {
            var result = $('#builder-basic').queryBuilder('getRules');
            if (!$.isEmptyObject(result)) {
                vm.isLoading = true;
                vm.gridDataSource.setRules(result);
                $timeout(function() { load(callback); }, 500);
            }
        }

        function setFilterIdAndFirst(id, value, callback) {
            $timeout(function() {
                $('#builder-basic').queryBuilder('setRules',
                    {"condition":"AND","rules":[{"id":id,"field":id,"type":"string","input":"text","operator":"equal","value":value}]}
                );

                $timeout(function() {
                    vm.setFilter(function () {
                        callback && callback(vm.rows[0]);
                        clearFilter();
                        $(".rule-value-container").last().find("input").focus();
                    });
                }, 500);
            });
        }

        function setFilterId(id, value, callback) {
            $timeout(function() {
                $('#builder-basic').queryBuilder('setRules',
                    {"condition":"AND","rules":[{"id":id,"field":id,"type":"string","input":"text","operator":"equal","value":value}]}
                );

                vm.setFilter(function () {
                    callback && callback(vm.rows[0]);
                });
            });
        }

        function setFilterRules(rules, callback) {
            $timeout(function() {
                $('#builder-basic').queryBuilder('setRules',
                    {"condition":"AND","rules": rules}
                );

                vm.setFilter(function () {
                    callback && callback(vm.rows[0]);
                });
            });
        }

        function clearFilter() {
            $('#builder-basic').queryBuilder('reset');
            vm.gridDataSource.setRules({});
            setDefaultRule();
            vm.isLoading = true;
            $timeout(function() { load(); }, 500);
        }

        function setDefaultRule() {
            $('#builder-basic [name=builder-basic_rule_0_filter] option').eq(1).prop('selected', true).change();
        }

        function sortChange(sorts) {
            vm.gridDataSource.setSorts(sorts);
            vm.isLoading = true;
            $timeout(function() { load(); }, 500);
        }

        function _isLoading() {
            return vm.isLoading;
        }

        function openModal(id) {
            id = id || '#modal';
            $(id).modal('show');
            $timeout(function() {
                $(id + ' input').first().focus();
            }, 700);
        }

        function hideModal(id) {
            id = id || '#modal';
            $(id).modal('hide');
        }

        function switchModal(from, to) {
            hideModal(from);
            openModal(to);
            $timeout(function() {
                $('body').addClass('modal-open');
                $('[data-toggle="popover"]').on('click',function(e){
                    e.preventDefault();
                }).popover(); 
            }, 700);
        }

        function updateFixedTableColumnWidth() {
            $timeout(function() {
                var $ctgGrid = $('.ctg-grid');
                var $fixedTableTh = $('.table-fixed-top th', $ctgGrid);
                var $mainTableTh = $('.dataTable th', $ctgGrid);

                for (var idx in $fixedTableTh) {
                    $($fixedTableTh.eq(idx)).css('width', $($mainTableTh.eq(idx)).css('width'));
                }
            }, 280);
        }

        function refreshWaypoint() {
            $timeout(function() {
                Waypoint.refreshAll();
            });
        }

        function getSearchParameters() {
              var prmstr = window.location.search.substr(1);
              return prmstr != null && prmstr != "" ? transformToAssocArray(prmstr) : {};
        }

        function transformToAssocArray( prmstr ) {
            var params = {};
            var prmarr = prmstr.split("&");
            for ( var i = 0; i < prmarr.length; i++) {
                var tmparr = prmarr[i].split("=");
                params[tmparr[0]] = decodeURIComponent(tmparr[1].replace(/\+/g, '%20'));
            }
            return params;
        }

        return vm;
    }

})();

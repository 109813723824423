(function() {
    'use strict';

    angular
        .module('deliveryNote')
        // .controller('PrintingTemplateController', Controller)
        .controller('DeliveryNoteController', DeliveryNoteController);
        // .directive('datePicker', datePicker);

    // Controller.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout'];
    DeliveryNoteController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout', '$window', '$interval', 'Popup'];
    // datePicker.$inject = ['$timeout'];

    function DeliveryNoteController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, $window, $interval, Popup) {
        var serverGateway, vm = this;
        // vm.materials           = config.materials;
        // vm.locations           = config.locations;
        // vm.selectedMaterial    = vm.materials.length ? vm.materials[0] : null;
        // vm.selectedLocation    = vm.locations.length ? vm.locations[0] : null;
        vm.rows                   = [];
        vm.sorts                  = [];
        vm.mode                   = 'create';
        vm.columns                = config.columns;
        vm.locations              = config.locations;
        vm.setFilter              = PageViewModel.setFilter;
        vm.clearFilter            = PageViewModel.clearFilter;
        vm.sortChange             = PageViewModel.sortChange;
        vm.isLoading              = PageViewModel._isLoading;
        vm.selectSKU              = selectSKU;
        vm.selectPR               = selectPR;
        vm.selectPO               = selectPO;
        vm.submit                 = submit;
        // vm.openDetailDialog    = openDetailDialog;
        vm.detail                 = {};
        vm.openCreateDeliveryNote = openCreateDeliveryNote;
        vm.deliveryNote           = {};
        vm.createDeliveryNote     = createDeliveryNote;
        vm.openUpdateDeliveryNote = openUpdateDeliveryNote;
        vm.removeDN               = removeDN;
        vm.removeDNConfirm        = removeDNConfirm;
        vm.deleteCancel           = deleteCancel;
        vm.popupSelectDN          = popupSelectDN;
        vm.confirmDraft           = confirmDraft;
        vm.isDNPending            = isDNPending;
        vm.getSumEffectiveQTY     = getSumEffectiveQTY;
        vm.convertPRtoDN          = convertPRtoDN;
        vm.superuser              = false;

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'create-delivery-note'  : { path: 'delivery-notes', method: 'POST' },
                    'update-delivery-note'  : { path: 'delivery-notes', method: 'PUT' },
                    'delete-delivery-note'  : { path: 'delivery-notes', method: 'DELETE' },
                    'confirm-delivery-note' : { path: 'delivery-notes/confirm', method: 'PUT' },
                    'purchase-requests'     : { path: 'purchase-requests', method: 'GET' },
                }
            });
            vm.gridDataSource = new GridDataSource({
                resoucesUrl : config.dataUrl,
                gateway     : serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });

            PageViewModel.initializeQueryBuilder(config.filtersConfig);
            vm.superuser = config.superuser || false;

            var data = config.initConfig;
            if (data && data.rawMaterialSKU) {
                $timeout(function() {

                    if (config.popup) {
                        PageViewModel.setFilterRules([
                            {"id":'raw_materials.name',"field":'raw_materials.name',"type":"string","input":"text","operator":"equal","value":data.rawMaterialSKU},
                            {"id":'delivery_notes.status',"field":'delivery_notes.status',"type":"string","input":"text","operator":"in","value":['Created']},
                        ]);
                    }
                    else {
                        PageViewModel.setFilterId('raw_materials.name', data.rawMaterialSKU);
                    }
                }, 500);
            }
            else {
                $timeout(function() { PageViewModel.update(); }, 500);
            }

        }

        function selectSKU(deliveryNoteItem) {

            if (!(vm.mode == 'create' || vm.isDNPending())) return;

            Popup.open('/product/raw-material/popup').then(function (rawMaterial) {

                deliveryNoteItem.sku         = rawMaterial.name;
                deliveryNoteItem.rawMaterial = rawMaterial;

                deliveryNoteItem.purchaseOrderItem = null;
                deliveryNoteItem.purchaseRequest = null;

            }, function () {

                // deliveryNoteItem.selectError = true;

            });

        }

        function selectPO(deliveryNoteItem) {

            if (!(vm.mode == 'create' || vm.isDNPending())) return;

            var modifier = function (popup) {
                popup.config = {
                    initConfig : {
                        rawMaterialSKU: deliveryNoteItem.rawMaterial.name
                    }
                };
            };

            Popup.open('/product/po/popup', modifier).then(function (purchaseOrder) {

                var valid = false;
                for (var idx in purchaseOrder.items)
                    if (purchaseOrder.items[idx].rawMaterial.name == deliveryNoteItem.sku) {
                        valid = true;
                        deliveryNoteItem.purchaseOrderItem = purchaseOrder.items[idx];

                        // prevent encode recursion
                        var po = $.extend(true, {}, purchaseOrder);
                        delete po.items;
                        deliveryNoteItem.purchaseOrderItem.purchaseOrder = po;
                    }

                if (!valid) {
                    $.bootstrapGrowl('Can only pick the purchase order, the purchase order should contain the selected SKU', {ele: 'body', type: 'error'});
                }

            }, function () {

                // vm.deliveryNote.selectError = true;

            });

        }

        function selectPR(deliveryNoteItem) {

            if (!(vm.mode == 'create' || vm.isDNPending())) return;

            Popup.open('/product/purchase_requests/popup').then(function (purchaseRequest) {

                // if same pr is added, ignore the selection and give warning
                for (var idx in vm.deliveryNote.deliveryNoteItems) {
                    if (vm.deliveryNote.deliveryNoteItems[idx].purchaseRequest && vm.deliveryNote.deliveryNoteItems[idx].purchaseRequest.id == purchaseRequest.id) {
                        $.bootstrapGrowl('The Purchase Request already selected!', {ele: 'body', type: 'error'});
                        return;
                    }
                }

                if (purchaseRequest.status == 'Pending' || purchaseRequest.status == 'In Progress' || (
                    deliveryNoteItem.purchaseRequest && typeof deliveryNoteItem.purchaseRequest.id !== 'undefined' && deliveryNoteItem.purchaseRequest.id == purchaseRequest.id)) {

                        deliveryNoteItem.purchaseRequest   = purchaseRequest;
                        deliveryNoteItem.sku               = purchaseRequest.rawMaterial.name;
                        deliveryNoteItem.rawMaterial       = purchaseRequest.rawMaterial;
                        deliveryNoteItem.quantity          = purchaseRequest.quantity;
                        deliveryNoteItem.purchaseOrderItem = null;

                }
                else {
                    $.bootstrapGrowl('Can only pick Pending purchase request', {ele: 'body', type: 'error'});
                }

            }, function () {

                // vm.deliveryNote.selectError = true;

            });

        }

        function submit(e, confirmDraft) {
            confirmDraft = confirmDraft || false;
            e && e.preventDefault();

            vm.deliveryNote.processing = true;

            serverGateway.ajax(vm.mode == 'create' ? 'create-delivery-note' : (confirmDraft ? 'confirm-delivery-note' : 'update-delivery-note'), '', vm.deliveryNote).then(function (response) {
                if (response.data.status == 'success') {
                    if (vm.mode == 'create') {
                        window.location.reload();
                    }
                    else if (vm.mode == 'update') {
                        var row = _.find(vm.rows, function (row) {
                            return row.id == vm.deliveryNote.id;
                        });
                        for (var key in response.data.deliveryNote) {
                            if (typeof row[key] !== 'undefined')
                                row[key] = response.data.deliveryNote[key];
                        }
                        PageViewModel.hideModal('#modal-create-delivery-note');
                    }
                }


            }, function (response) {

                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});

            })
            .finally(function () {
                vm.deliveryNote.processing = false;
            });
        }

        function removeDN() {

            PageViewModel.switchModal('#modal-create-delivery-note', '#modal-create-delivery-note-confirm');

        }

        function removeDNConfirm() {

            vm.deliveryNote.processing = true;

            serverGateway.ajax('delete-delivery-note', '', vm.deliveryNote).then(function () {

                window.location.reload();

            }, function (response) {

                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});

            })
            .finally(function () {
                vm.deliveryNote.processing = false;
            });

        }

        function deleteCancel() {

            PageViewModel.switchModal('#modal-create-delivery-note-confirm', '#modal-create-delivery-note');

        }

        // function openDetailDialog(row, mode) {
        //     mode = mode || 'create';
        //     PageViewModel.openModal();
        // }

        function openCreateDeliveryNote() {
            vm.deliveryNote = {
                deliveryNoteItems: [
                    {}
                ],
            };
            vm.mode = 'create';
            PageViewModel.openModal('#modal-create-delivery-note');
        }

        function openUpdateDeliveryNote(row) {
            vm.deliveryNote  = $.extend(true, {}, row);
            // vm.deliveryNote.delivery_before = moment(vm.deliveryNote.delivery_before, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm');
            vm.mode = 'update';
            PageViewModel.openModal('#modal-create-delivery-note');
        }

        function createDeliveryNote() {
            serverGateway.ajax('create-delivery-note', '', vm.deliveryNote).then(function(response) {
                vm.deliveryNote = response.data.data;
                PageViewModel.hideModal("#modal-create-delivery-note");
            }, function () {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function popupSelectDN(row) {

            Popup.setReturnAndClose(row);

        }

        function confirmDraft() {

            submit(null, true);

        }

        // isDNPending() -> isDNPending AND isDNCreated
        // this function determine whether the dn can be updated
        function isDNPending() {

            return vm.superuser || (vm.mode == 'update' && vm.deliveryNote && vm.deliveryNote.status!='Received');

        }

        function getSumEffectiveQTY(records) {

            var qty = 0;
            for (var idx in records)
                qty += records[idx].pivot.quantity;
            return qty;

        }

        function convertPRtoDN() {

            vm.deliveryNote.processing = true;

            serverGateway.ajax('purchase-requests', '', {
                perPage : 50,
                rules   : {
                    condition : 'AND',
                    rules     : [
                        {
                            id       : 'purchase_requests.status',
                            field    : 'purchase_requests.status',
                            type     : 'string',
                            input    : 'checkbox',
                            operator : 'in',
                            value    : ['Pending']
                        }
                    ]
                },
            }).then(function (response) {

                vm.deliveryNote.processing = false;
                vm.deliveryNote.deliveryNoteItems = [];
                for (var idx in response.data.data) {
                    var purchaseRequest = response.data.data[idx];

                    vm.deliveryNote.deliveryNoteItems.push({
                        purchaseRequest   : purchaseRequest,
                        sku               : purchaseRequest.rawMaterial.name,
                        rawMaterial       : purchaseRequest.rawMaterial,
                        quantity          : purchaseRequest.quantity,
                        purchaseOrderItem : null,
                    });
                }

                if (!vm.deliveryNote.deliveryNoteItems.length) {
                    vm.deliveryNote.deliveryNoteItems = [{}];
                }

            });

        }

    }

})();

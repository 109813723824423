(function() {
    'use strict';
    var app = angular
        .module('dashboard')
        .controller('DashboardController', Controller)
        .controller('DashboardForMiddlewareController', DashboardForMiddlewareController)
        ;

    app.directive('dateFormat', function() {
        return {
            require: 'ngModel',
            link: function(scope, element, attr, ngModelCtrl) {
                ngModelCtrl.$formatters.length = 0;
                ngModelCtrl.$parsers.length = 0;
            }
        };
    });

    Controller.$inject = ['GridDataSource', 'ServerGateway', '$q', 'config', '$timeout'];
    DashboardForMiddlewareController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout'];

    function Controller(GridDataSource, ServerGateway, $q, config, $timeout) {
        var serverGateway = null;
        var vm = this;
        vm.backlogChart = null;
        vm.pendingOrderChart = null;
        vm.pendingPrintingChart = null;
        vm.isBacklogFirstLoad = true;
        vm.isPendingOrderFirstLoad = true;
        vm.isPendingPrintingFirstLoad = true;
        var colors1 = shuffle([
                    '#4D4D4D',
                    '#5DA5DA',
                    '#FAA43A',
                    '#60BD68',
                    '#F17CB0',
                    '#B2912F',
                    '#B276B2',
                    '#DECF3F',
                    '#F15854',
                    '#800000',
                    '#f1b9a8'
                ]);
        var colors2 = shuffle([
                    '#4D4D4D',
                    '#5DA5DA',
                    '#FAA43A',
                    '#60BD68',
                    '#F17CB0',
                    '#B2912F',
                    '#B276B2',
                    '#DECF3F',
                    '#F15854',
                    '#800000',
                    '#f1b9a8'
                ]);
        
        initialize();

        function initialize() {
            // sever side gateway setup
            serverGateway = new ServerGateway({
                endPoints: {
                    'backlog': { path: 'report/backlog', method: 'GET' },
                    'pendingOrderDistribution': { path: 'report/pendingOrderDistribution', method: 'GET' },
                    'pendingPrintingDistribution': { path: 'report/pendingPrintingDistribution', method: 'GET' },
                    'leadtime': { path: 'report/leadTime', method: 'GET' },
                }
            });

            var ctx = document.getElementById("backlogChart");
            if (ctx) {
                loadBacklog();
                setInterval(loadBacklog, 5 * 60 * 1000);
            }
            
            
            var ctx = document.getElementById("pendingItemDistributionChart");
            if (ctx) {
                loadPendingOrderDistribution();
                setInterval(loadPendingOrderDistribution, 5 * 60 * 1000);
            }

            var ctx = document.getElementById("pendingPrintingDistributionChart");
            if (ctx) {
                loadPendingPrintingDistribution();
                setInterval(loadPendingPrintingDistribution, 5 * 60 * 1000);
            }

            loadLeadTime();
            setInterval(loadLeadTime, 5 * 60 * 1000);
            vm.isFirstLoad = false;
        }

        function loadPendingOrderDistribution() {
            serverGateway.ajax('pendingOrderDistribution', null).then(function(response) {
                var labels = [];
                var values = [];
                
                if (response.data.list.length > colors1.length) {
                    var count = response.data.list.length - colors1.length;
                    for (var i = 0; i < count; i++) {
                        colors1.push(randomColor());
                        colors1 = shuffle(colors1);
                    }
                }
                for (var index in response.data.list) {
                    labels.push(response.data.list[index].label);
                    values.push(response.data.list[index].value);
                }
                var ctx = document.getElementById("pendingItemDistributionChart");
                if (vm.pendingOrderChart) {
                    vm.pendingOrderChart.destroy();
                }
                vm.pendingOrderChart = new Chart(ctx, {
                    type: 'pie',
                    animation:{
                        animateRotate:vm.isPendingOrderFirstLoad,
                        animateScale:vm.isPendingOrderFirstLoad
                    },
                    data: {
                        labels: labels,
                        datasets: [
                            {
                                data: values,
                                backgroundColor: colors1,
                                hoverBackgroundColor: colors1
                            }]
                    },
                });
                vm.isPendingOrderFirstLoad = false;
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function loadPendingPrintingDistribution() {
            serverGateway.ajax('pendingPrintingDistribution', null).then(function(response) {
                var labels = [];
                var values = [];
                if (response.data.list.length > colors2.length) {
                    var count = response.data.list.length - colors2.length;
                    for (var i = 0; i < count; i++) {
                        colors2.push(randomColor());
                        colors2 = shuffle(colors2);
                    }
                }

                for (var index in response.data.list) {
                    labels.push(response.data.list[index].label);
                    values.push(response.data.list[index].value);
                }
                var ctx = document.getElementById("pendingPrintingDistributionChart");
                if (vm.pendingPrintingChart) {
                    vm.pendingPrintingChart.destroy();
                }
                vm.pendingPrintingChart = new Chart(ctx, {
                    type: 'pie',
                    animation:{
                        animateRotate:vm.isPendingPrintingFirstLoad,
                        animateScale:vm.isPendingPrintingFirstLoad
                    },
                    data: {
                        labels: labels,
                        datasets: [
                            {
                                data: values,
                                backgroundColor: colors2,
                                hoverBackgroundColor: colors2
                            }]
                    },
                });
                vm.isPendingPrintingFirstLoad = false;
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function loadBacklog() {
            serverGateway.ajax('backlog', null).then(function(response) {
                var ctx = document.getElementById("backlogChart");
                var all = [];
                for (var index in response.data.all) {
                    var obj = {};
                    obj.x = new Date(response.data.all[index].year, response.data.all[index].month - 1, response.data.all[index].day, response.data.all[index].hour, 0, 0);
                    obj.y = response.data.all[index].value;
                    all.push(obj);
                }
                var migrated = [];
                for (var index in response.data.migrated) {
                    var obj = {};
                    obj.x = new Date(response.data.migrated[index].year, response.data.migrated[index].month - 1, response.data.migrated[index].day, response.data.migrated[index].hour, 0, 0);
                    obj.y = response.data.migrated[index].value;
                    migrated.push(obj);
                    vm.shippingBacklog = response.data.migrated[index].value;
                }
                var printing = [];
                for (var index in response.data.printing) {
                    var obj = {};
                    obj.x = new Date(response.data.printing[index].year, response.data.printing[index].month - 1, response.data.printing[index].day, response.data.printing[index].hour, 0, 0);
                    obj.y = response.data.printing[index].value;
                    vm.printingBacklog = response.data.printing[index].value;
                    printing.push(obj);
                }
                var migrated_hk = [];
                for (var index in response.data.migrated_hk) {
                    var obj = {};
                    obj.x = new Date(response.data.migrated_hk[index].year, response.data.migrated_hk[index].month - 1, response.data.migrated_hk[index].day, response.data.migrated_hk[index].hour, 0, 0);
                    obj.y = response.data.migrated_hk[index].value;
                    migrated_hk.push(obj);
                }
                var printing_hk = [];
                for (var index in response.data.printing_hk) {
                    var obj = {};
                    obj.x = new Date(response.data.printing_hk[index].year, response.data.printing_hk[index].month - 1, response.data.printing_hk[index].day, response.data.printing_hk[index].hour, 0, 0);
                    obj.y = response.data.printing_hk[index].value;
                    printing_hk.push(obj);
                }
                var migrated_sz = [];
                for (var index in response.data.migrated_sz) {
                    var obj = {};
                    obj.x = new Date(response.data.migrated_sz[index].year, response.data.migrated_sz[index].month - 1, response.data.migrated_sz[index].day, response.data.migrated_sz[index].hour, 0, 0);
                    obj.y = response.data.migrated_sz[index].value;
                    migrated_sz.push(obj);
                }
                var printing_sz = [];
                for (var index in response.data.printing_sz) {
                    var obj = {};
                    obj.x = new Date(response.data.printing_sz[index].year, response.data.printing_sz[index].month - 1, response.data.printing_sz[index].day, response.data.printing_sz[index].hour, 0, 0);
                    obj.y = response.data.printing_sz[index].value;
                    printing_sz.push(obj);
                }
                var migrated_store = [];
                for (var index in response.data.migrated_store) {
                    var obj = {};
                    obj.x = new Date(response.data.migrated_store[index].year, response.data.migrated_store[index].month - 1, response.data.migrated_store[index].day, response.data.migrated_store[index].hour, 0, 0);
                    obj.y = response.data.migrated_store[index].value;
                    migrated_store.push(obj);
                }
                var printing_store = [];
                for (var index in response.data.printing_store) {
                    var obj = {};
                    obj.x = new Date(response.data.printing_store[index].year, response.data.printing_store[index].month - 1, response.data.printing_store[index].day, response.data.printing_store[index].hour, 0, 0);
                    obj.y = response.data.printing_store[index].value;
                    printing_store.push(obj);
                }
                if (vm.backlogChart) {
                    vm.backlogChart.destroy();
                }
                let datasets = [];
                if (config.shouldShowAll) {
                    datasets.push({
                        label: "Un-migrated",
                        // label: '# of Votes',
                        data: all,
                        backgroundColor: 'rgba(255, 206, 86, 0.2)',
                        borderColor: 'rgba(255, 206, 86, 1)',
                        borderWidth: 1,
                    });
                }
                if (config.shouldShowAllShipping) {
                    datasets.push({
                        label: "Pending Shipping",
                        // label: '# of Votes',
                        data: migrated,
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 1,
                    });
                }
                if (config.shouldShowAllPrinting) {
                    datasets.push({
                        label: "Pending Printing",
                        // label: '# of Votes',
                        data: printing,
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        borderColor: 'rgba(255,99,132,1)',
                        borderWidth: 1,
                    });
                }
                if (config.shouldShowHKShipping) {
                    datasets.push({
                        label: "HK Shipping",
                        // label: '# of Votes',
                        data: migrated_hk,
                        backgroundColor: 'rgba(46, 204, 204, 0.2)',
                        borderColor: 'rgba(46, 204, 204, 1)',
                        borderWidth: 1,
                        hidden: true,
                    });
                }
                if (config.shouldShowHKPrinting) {
                    datasets.push({
                        label: "HK Printing",
                        // label: '# of Votes',
                        data: printing_hk,
                        backgroundColor: 'rgba(229, 89, 182, 0.2)',
                        borderColor: 'rgba(229,89,182,1)',
                        borderWidth: 1,
                        hidden: true,

                    });
                }
                if (config.shouldShowSZShipping) {
                    datasets.push({
                        label: "SZ Shipping",
                        // label: '# of Votes',
                        data: migrated_sz,
                        backgroundColor: 'rgba(41, 178, 132, 0.2)',
                        borderColor: 'rgba(41, 178, 132, 1)',
                        borderWidth: 1,
                        hidden: true,
                    });
                }
                if (config.shouldShowSZPrinting) {
                    datasets.push({
                        label: "SZ Printing",
                        // label: '# of Votes',
                        data: printing_sz,
                        backgroundColor: 'rgba(204, 79, 204, 0.2)',
                        borderColor: 'rgba(204,79,204,1)',
                        borderWidth: 1,
                        hidden: true,
                    });
                }
                if (config.shouldShowStoreShipping) {
                    datasets.push({
                        label: "Store Shipping",
                        // label: '# of Votes',
                        data: migrated_store,
                        backgroundColor: 'rgba(53, 102, 234, 0.2)',
                        borderColor: 'rgba(53, 102, 234, 1)',
                        borderWidth: 1,
                        hidden: true,
                    });
                }
                if (config.shouldShowStorePrinting) {
                    datasets.push({
                        label: "Store Printing",
                        // label: '# of Votes',
                        data: printing_store,
                        backgroundColor: 'rgba(255, 112, 99, 0.2)',
                        borderColor: 'rgba(255,112,99,1)',
                        borderWidth: 1,
                        hidden: true,
                    });
                }
                vm.backlogChart = new Chart(ctx, {
                    type: 'line',
                    animation:{
                        animateRotate:vm.isBacklogFirstLoad,
                        animateScale:vm.isBacklogFirstLoad
                    },
                    data: {
                        
                        datasets: datasets
                    },
                    options: {
                        scales: {
                            xAxes: [{
                                type: 'time',
                                time: {
                                    displayFormats: {
                                        quarter: 'MMM YYYY'
                                    }
                                }
                            }],
                            // yAxes: [{
                            //     stacked: true
                            // }]
                        },
                        tooltips: {
                            mode: 'index',
                        },
                    }
                });
                vm.isBacklogFirstLoad = false;
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function loadLeadTime() {
            serverGateway.ajax('leadtime', null).then(function(response) {
                vm.leadTimes = response.data.list;
                $timeout(function () {
                    $('.inlinesparkline').sparkline();
                })
                
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }
    }

    function DashboardForMiddlewareController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout){
        var serverGateway = null;
        var vm = this;
        vm.isFirstLoad = true;
        vm.isLoading = PageViewModel._isLoading;
        vm.loading = true;
        vm.loadOrdersForMiddleware = loadOrdersForMiddleware;
        vm.exportMiddleReport = exportMiddleReport;
        vm.rows = {};
        vm.debugRows = {};
        vm.setFilter                         = PageViewModel.setFilter;
        vm.clearFilter                       = PageViewModel.clearFilter;
        vm.sortChange                        = PageViewModel.sortChange;
        vm.isLoading                         = PageViewModel._isLoading;
        vm.switchModal                       = PageViewModel.switchModal;
        vm.columns                           = config.columns;
        vm.sorts                             = [];
        vm.org = null;
        vm.from_date = null;
        vm.to_date = null;
        vm.dataFormat = 'Y-MM-DD';
        vm.debug = false;
        vm.exportButtonLoading = false;

        initialize();

        function initialize() {
            // sever side gateway setup
            serverGateway = new ServerGateway({
                endPoints: {
                    'ordersForMiddleware': { path: 'report/ordersForMiddleware', method: 'GET' },
                    'ordersForMiddlewareExport': { path: 'report/ordersForMiddleware/export', method: 'GET' },
                }
            });

            // $timeout(function() { 
            //     loadOrdersForMiddleware();
            // });

            vm.isFirstLoad = false;
            vm.org = config.org;
            vm.from_date = moment(config.from_date).format(vm.dataFormat);
            vm.to_date = moment(config.to_date).format(vm.dataFormat);
            vm.debug = config.debug;

        }

        function loadOrdersForMiddleware(){
            vm.loading = true;

            var from_date = moment(vm.from_date).format(vm.dataFormat);
            var to_date = moment(vm.to_date).format(vm.dataFormat);

            serverGateway.ajax('ordersForMiddleware', null, {
                org: config.org,
                from_date: from_date,
                to_date: to_date
            }).then(function(response) {
                vm.loading = false;
                try {
                    if (response && typeof response.data != 'undefined') {
                        vm.rows = response.data.data;
                        console.log(vm.rows);

                        if(vm.debug){
                            formatDebugData(response.data.data);
                        }
                    } else {
                        $.bootstrapGrowl("Cannot load Orders", {ele: 'body', type: 'error'});
                    }
                } catch (e) {
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                }
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });

        }

        function formatDebugData(data){
            var tempRows = [];
            if(typeof data != 'undefined' && data){
                $.each(data, function(k, v){
                    var tempRow = [];
                    tempRow['refId'] = v.refId;
                    tempRow['items'] = [];
                    $.each(v.deliveries, function(i, delivery){
                        $.each(delivery.orderItems, function(a, orderItem){
                            tempRow['items'].push(`CTF-${orderItem.artworkId}-${orderItem.product.name}`);
                        });
                    });
                    tempRow['status'] = v.status;
                    tempRow['createdAt'] = moment(v.createdAt).format(vm.dataFormat);;
                    tempRows.push(tempRow);
                });
            }
            vm.debugRows = tempRows;
        }

        function exportMiddleReport(){
            var from_date = moment(vm.from_date).format(vm.dataFormat);
            var to_date = moment(vm.to_date).format(vm.dataFormat);

            vm.exportButtonLoading = true;

            serverGateway.ajax('ordersForMiddlewareExport', null, {
                org: config.org,
                from_date: from_date,
                to_date: to_date
            }).then(function(response) {
                vm.loading = false;
                vm.exportButtonLoading = false;
                try {
                    if (response && typeof response.data != 'undefined') {
                        console.log(response);

                        var blob = new Blob([response.data]);
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = config.org+"-dashboard-report-"+Date.now()+".csv";
                        link.click();
                    } else {
                        $.bootstrapGrowl("Cannot export", {ele: 'body', type: 'error'});
                    }
                } catch (e) {
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                }
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.exportButtonLoading = false;
            });
        }


    }

})();

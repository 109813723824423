(function() {
    'use strict';

    angular
        .module('grid')
        .directive('ctgCellCheckBox', ctgCellCheckBox);

    function ctgCellCheckBox() {
        var directive = {
            restrict: 'E',
            replace: true,
            template: '<input type="checkbox" class="ctg-checkbox" id="ctg-checkbox-{{ row[column.field] }}" value="{{ row[column.field] }}">'
        };

        return directive;
    }
})();
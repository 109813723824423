(function() {
    'use strict';

    angular
        .module('grid')
        .directive('ctgCellDeliveries', ctgCellDeliveries);

    function ctgCellDeliveries() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: '/template/packaging/cellDeliveries.html?v=1'
        };

        return directive;
    }
})();
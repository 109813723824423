(function(Ctg) {

    Ctg.ButtonBarConfig = {
        columns: {
            html: '<i class="fa fa-columns fa-fw"></i> Columns',
            click: function(dataGrid) {
                dataGrid.$columnEditorModal.modal()
            }
        },

        validate: {
            render: function(dataGrid) {
                var html = '<button class="btn btn-default btn-animate">' +
                    '<i class="fa fa-map-marker"></i> Validate <span style="display:none;" class="badge-laoding">&nbsp;&nbsp;<i class="fa fa-refresh fa-spin"><span></span></i>' +
                '</button>';

                var $button = this._$button = $(html);

                // Only display when any checkbox is checked
                dataGrid.grid.on('hasCheck', function() {
                    $button.show();
                }).on('noCheck', function() {
                    $button.hide();
                });
                
                return this._$button;
            },
            click: function(dataGrid) {
                var self = this;
                this.showLoading();
                var data = dataGrid.getSelectedRow();

                dataGrid.serverGateway.ajax('validateAddress').done(function(data) {
                    $('div.badge-address-validator').modal();
                    self.hideLoading();
                });
            },

            showLoading: function() {
                this._$button.prop('disabled', true).find('.badge-laoding').show();
            },

            hideLoading: function() {
                this._$button.prop('disabled', false).find('.badge-laoding').hide();
            }
        }
    };

})(Ctg);

(function() {
    'use strict';

    angular
        .module('grid')
        .directive('ctgCellTransitionQueueItem', ctgCellTransitionQueueItem);

    function ctgCellTransitionQueueItem() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: '/template/transitionQueue/ctgCellTransitionQueueItem.html'
        };

        return directive;
    }
})();

(function() {
    'use strict';

    angular
        .module('component')
        .directive('ctgIcheck', ctgIcheck);

    ctgIcheck.$inject = ['$timeout'];

    function ctgIcheck($timeout) {
        var directive = {
            restrict: 'E',
            replace: true,
            require: '?ngModel',
            scope: {
                ngModel: '=',
                ngChange: '&',
                required: '@',
            },
            template: '<div class="icheck"><input type="checkbox" class="badge-check"></div>',
            link: link
        };

        return directive;

        function link(scope, element, attrs, ngModel) {
            var $element = $(element);
            var required = attrs.required;
            if (typeof required !== typeof undefined && required !== false) {
                $('input', $element).prop('required', true);
            }

            $('input', $element)
                .iCheck({
                    checkboxClass: 'icheckbox_square-blue',
                    increaseArea: '20%'
                })
                .on('ifChanged', ifChanged);

            if (ngModel) {
                scope.$watch('ngModel', modelWatch);
            }

            function modelWatch(newValue, oldValue) {
                $element.iCheck(newValue ? 'check' : 'uncheck');
            }

            function ifChanged(event) {
                if (ngModel) {
                    ngModel.$setViewValue(element.find('input').prop('checked'));
                }
            }
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('seal')
        .controller('SealController', Controller);

    Controller.$inject = ['GridDataSource', 'ServerGateway', '$q', 'config', '$timeout', '$scope'];

    function Controller(GridDataSource, ServerGateway, $q, config, $timeout, $scope) {
        var serverGateway = null;
        var vm = this;

        vm.searchLocation = config.searchLocation;
        vm.searchKeyword = config.searchKeyword;
        vm.undo = undo;
        vm.undoAndRefresh = undoAndRefresh;
        vm.createYamatoPickup = createYamatoPickup;
        vm.scanType = config.scanType;
        vm.changeScanType = changeScanType;
        vm.changeScanBatchType = changeScanBatchType;
        vm.submitBarcode = submitBarcode;
        vm.submitTracking = submitTracking;
        vm.confirmArrival = confirmArrival;
        vm.confirmShipped = confirmShipped;
        vm.updateRemark = updateRemark;
        vm.openBatchScan = openBatchScan;
        vm.sealBatchScan = "";
        vm.submitSealBatchScan = submitSealBatchScan;
        vm.autoScanCNShipment = autoScanCNShipment;
        vm.validateSealBatchScanning = validateSealBatchScanning;
        vm.sealBatchScanningPreview = "";
        vm.isSealBatchScanningValid = false;
        vm.openBoxTrackingModal = openBoxTrackingModal;
        vm.batchBoxAction = batchBoxAction;
        vm.boxTrackingCode = "";
        vm.printBoxBarcode = printBoxBarcode;
        vm.records = [];
        vm.openSendManifestModal = openSendManifestModal;
        vm.target_boxes = [];
        vm.email_boxes = "";
        vm.email_to = "";
        vm.email_from = "";
        vm.email_title = "";
        vm.email_content = "";
        vm.email_attachment = "";
        vm.createBatchUrl = '';
        vm.location = config.location;
        vm.sendManifest = sendManifest;
        vm.redirectCreateBatchUrl = redirectCreateBatchUrl;
        vm.deleteBatch = deleteBatch;
        vm.exportManifest = exportManifest;
        vm.statistics = '';
        vm.calculateBoxStatistics = calculateBoxStatistics;
        vm.sealBatchScanKeyPress = sealBatchScanKeyPress;
        vm.pendingToChecks = {};
        vm.checklist = [];
        vm.resolveCheck = resolveCheck;
        vm.warningSound = new Audio('/media/warning.mp3');
        vm.limitSeal = [];
        vm.bulkExportCSVCn = bulkExportCSVCn;

        var text = [], startTime, endTime, fnAddBarcode, shouldSkipCode
        const maxKeyDeltaTime = 500
            
        initialize();

        if (config.listenBarcode) {
            window.addEventListener('keydown', onKeydown, false)
        }

        window.addEventListener('keydown',checkValidInput,false)

        // $("#batch_scan_btn").hide();
        // $("#add_box_tracking_btn").hide();
        // if(vm.scanType == 'hk_to_us_parcel_transit'){
        //     $("#batch_scan_btn").show();
        //     $("#add_box_tracking_btn").show();
        // }
        if(vm.scanType != 'apg'){
            $(".exportsac-btn").hide();
            //$("#send_manifest_btn").hide();
        }
        if(!['hk_to_nz_parcel_transit','hkpost','pickupp','yamato','hk_to_us_parcel_transit','dhl_ecommerce','ecms','ampm','kerry','us_local_parcel','yunexpress','yamato-tw'].includes(vm.scanType)){
            $(".exportmanifest-btn").hide();
        }

        function initialize() {
            // sever side gateway setup
            serverGateway = new ServerGateway({
                endPoints: {
                    'sealScan': { path: 'function/seal-scan', method: 'POST' },
                    'sealScanDelete': { path: 'function/seal-scan-delete', method: 'POST' },
                    'createYamatoPickup': { path: 'function/create-yamato-pickup', method: 'POST' },
                    'submitTracking': { path: 'seal-batches/{id}/submitTracking', method: 'POST' },
                    'confirmArrival': { path: 'seal-batches/{id}/confirmArrival', method: 'POST' },
                    'confirmShipped': { path: 'seal-batches/{id}/confirmShipped', method: 'POST' },
                    'updateRemark': {path: 'seal-batches/{id}/updateRemark', method: 'POST'},
                    'autoScanCNShipment': { path: 'seal-scan/autoScanCNShipment', method: 'POST' },
                    'scanSealBatch' : { path: 'seal-batches/scanSealBatch', method: 'POST'},
                    'addBoxTracking' : { path: 'seal-batches/addBoxTracking', method: 'POST'},
                    'validateSealBatchScanning' : {path: 'seal-batches/validateSealBatchScanning', method: 'POST'},
                    'exportBoxBarcodePdf' : {path: 'seal-batches/exportBoxPdfBarcode', method: 'GET'},
                    'getMailList' : {path: 'seal-batches/getMailList/{type}', method: 'GET'},
                    'sendManifestEmail' : {path: 'seal-batches/sendManifestEmail', method: 'POST'},
                    'deleteBatch' : {path: 'seal-batches/deleteBatch/{id}', method: 'DELETE'},
                    'calculateBoxStatistics' : {path: 'seal-batches/getBoxStatistics', method: 'GET'},
                    'bulkExportCSVCn' : {path: 'seal-batches/bulkExportCSVCn', method: 'GET'}
                }
            });

            vm.createBatchUrl = '/seal-batches/create/'+vm.scanType;
        }

        function redirectCreateBatchUrl(){
            vm.createBatchUrl = '/seal-batches/create/'+vm.scanType+'/'+vm.location;
            window.location.href = vm.createBatchUrl
        }

        function saveSealScan(record) {
            let trackingCode = record.trackingCode
            serverGateway.ajax('sealScan', null, {trackingCode: trackingCode, scanType: vm.scanType}).then(function(response) {
                if (!response.data.data.sealScanId) {
                    record.status = 'Error'
                    record.error = true
                } else {
                    record.status = 'Success'
                }
                record.sealScanId = response.data.data.sealScanId
                record.orderId = response.data.data.orderId
                record.duplicated = response.data.data.duplicated
                record.action = response.data.data.action
                record.time = response.data.data.time
            }, function (response) {
                record.error = true;
                record.status = response.data.message;
                // $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function checkValidInput(event){
            if($('#code_input_area').is(':focus')){
                let allowChar = /^[\x08\x20-\x7f\r\n\f\v]$/i;    //Allow Backspace, most printable characters, space, newline, enter
                let allowSpecial = /shift/i;                             //Allow Shift
                let insertChar = String.fromCharCode(event.keyCode);
                if(!allowChar.test(insertChar) && !allowChar.test(event.key) && !allowSpecial.test(event.key)){
                    console.log({key: event.key},'Unacceptable character: '+ insertChar);
                    event.preventDefault();
                }
            }
        }

        function onKeydown(event) {
            startTime = startTime || new Date()
            endTime = new Date()
            let delta = endTime - startTime

            if (delta < maxKeyDeltaTime) {
                console.log(event)
                console.log(event.keyCode)
                let char = String.fromCharCode(event.keyCode)
                if (!event.shiftKey && !event.metaKey && !event.ctrlKey && !event.altKey && event.keyCode != 16) {
                    text.push(char)
                }
                console.log(text)
                fnAddBarcode && clearTimeout(fnAddBarcode)
                fnAddBarcode = setTimeout(() => {
                    if (isValidBarcode(text)) {
                        var trackingCode = text.join('')
                        var record = {
                            trackingCode: trackingCode,
                            status: 'Initialized',
                            mykey: Math.random()
                        }
                        vm.records.unshift(record)
                        $scope.$apply();
                        saveSealScan(record)
                        startTime = undefined
                        text = []
                        // barcodes.push(b)
                        // tbody.innerHTML = b.html + tbody.innerHTML
                        // b.animate()
                        // b.queryStatus()
                        //     .then(r => b.update(r))
                    }
                }, maxKeyDeltaTime)
            } else {
                console.log('2')
                console.log(event)
                console.log(event.keyCode)
                startTime = undefined
                text = []
            }
        }

        function isValidBarcode(text) {
            return text.length >= 5
        }

        function undo(id) {
            serverGateway.ajax('sealScanDelete', null, {id: id}).then(function(response) {
                vm.records = vm.records.filter(function (element) {
                    return element.sealScanId != id
                })
                $.bootstrapGrowl('Done', {ele: 'body', type: 'success'});
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function undoAndRefresh(id) {
            serverGateway.ajax('sealScanDelete', null, {id: id}).then(function(response) {
                $.bootstrapGrowl('Done', {ele: 'body', type: 'success'});
                window.location.reload();
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function createYamatoPickup() {
            serverGateway.ajax('createYamatoPickup').then(function(response) {
                $.bootstrapGrowl('Done', {ele: 'body', type: 'success'});
                window.location.reload();
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function changeScanType() {
            window.location.href = "/seal/scan/" + vm.scanType;
        }

        function changeScanBatchType(keyword = null,location = null) {
            var query = '';
            if(keyword || location){
                query = '?'
                let param = [];
                if(keyword){
                    param.push('keyword='+keyword);
                }
                if(location != 'all'){
                    param.push('location='+location)
                }
                query += param.join('&');
            }
            console.log(query);
            window.location.href = "/seal-batches/" + vm.scanType + query;
        }

        function submitBarcode() {
            var record = {
                trackingCode: vm.barcode,
                status: 'Initialized',
                mykey: Math.random()
            }
            vm.records.unshift(record)
            saveSealScan(record)
            vm.barcode = '';
        }

        function submitTracking(batchId) {
            let trackingCode = prompt("Please input tracking code");
            if (!trackingCode) {
                return false;
            }
            let trackingCodes = trackingCode.split(',');
            trackingCodes = trackingCodes.map((trackingCode) => {
                return trackingCode.trim();
            });
            let invalidTracking = false;
            trackingCodes.forEach((trackingCode) => {
                if (!trackingCode.match(/[A-Za-z0-9,]/)) {
                    invalidTracking = true;
                    $.bootstrapGrowl("Invalid tracking: " + trackingCode, {ele: 'body', type: 'error'});
                }
            })
            if (invalidTracking) {
                return false;
            }
            serverGateway.ajax('submitTracking', {id: batchId}, {trackingCode: trackingCodes.join(',')}).then(function(response) {
                $.bootstrapGrowl('Done', {ele: 'body', type: 'success'});
                window.location.reload();
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function confirmArrival(batchId) {
            serverGateway.ajax('confirmArrival', {id: batchId}).then(function(response) {
                $.bootstrapGrowl('Done', {ele: 'body', type: 'success'});
                window.location.reload();
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function confirmShipped(batchId){
            serverGateway.ajax('confirmShipped', {id: batchId}).then(function(response) {
                $.bootstrapGrowl('Done', {ele: 'body', type: 'success'});
                window.location.reload();
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function updateRemark(batchId) {
            let remark = prompt("Please input remark");
            if(remark === null){
                return false;
            }
            serverGateway.ajax('updateRemark', {id: batchId}, {remark: remark}).then(function(response){
                $.bootstrapGrowl('Done', {ele: 'body', type: 'success'});
                window.location.reload();
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            console.log(remark);
        }

        function openBatchScan(){
            vm.pendingToChecks = {};
            vm.checklist = [];
            vm.limitSeal = [];
            $("#seal-batch-scanning").modal('show');
        }

        function validateSealBatchScanning(){
            if(vm.sealBatchScan == ""){
                $.bootstrapGrowl("Code is empty!", {ele: 'body', type: 'error'});
                return;
            }
            let user = prompt("Pack by user:", config.currentUser)
            if (!user) return;
            vm.sealScanBatchValidateLoading = true;
            serverGateway.ajax('validateSealBatchScanning',null,{code: JSON.stringify(vm.sealBatchScan.split('\n')), user: user, scanType: vm.scanType}).then(function(response){
                vm.sealScanBatchValidateLoading = false;
                console.log(response);
                const res = response.data;
                vm.sealBatchScanningPreview = res.result;
                console.log({data:vm.sealBatchScanningPreview,type: typeof(vm.sealBatchScanningPreview)});
                if(res.status == 'OK'){
                    vm.isSealBatchScanningValid = true;
                } else {
                    console.log({error_orders:res.error_orders,res});
                    for(const e in res.error_orders){
                        setTimeout(()=>$("#scanning-table #" + e + ' .errmsg ').text(res.error_orders[e].reason),100);
                    }
                    vm.isSealBatchScanningValid = false;

                }
            },function(response){
                vm.sealScanBatchValidateLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.isSealBatchScanningValid = false;
            });
        }

        function submitSealBatchScan(){
            if(!vm.isSealBatchScanningValid){
                $.bootstrapGrowl('Please validate the code before submitting', {ele: 'body', type: 'error'});
            }
            console.log(JSON.stringify(vm.sealBatchScanningPreview));
            vm.sealScanBatchSubmitLoading = true;
            serverGateway.ajax('scanSealBatch',null,{code: JSON.stringify(vm.sealBatchScanningPreview), scanType: vm.scanType, location: vm.location}).then(function(response){
                vm.sealScanBatchSubmitLoading = false;
                console.log('It works');
                console.log({data:response.data});
                const rd = response.data;
                $("#submit_message").text("Success: " + rd.success.length + ", Failed: " + rd.failed.length);
                for(const i in rd.success){
                    //Suppose the table still exist
                    console.log(rd.success[i])
                    $("<strong style='color:#00AA00;'>Success, Seal Batch ID: "+ rd.success[i].seal_batch_id +"</strong>").insertAfter("#scanning-table #" + response.data.success[i].box + ' .errmsg ');
                }
                for(const j in rd.failed){
                    $("<strong style='color:#AA0000;'>"+rd.success[j].reason+"</strong>").insertAfter("#scanning-table #" + response.data.success[j].box + ' .errmsg ');
                }
                window.location.reload()
                // setTimeout(() => window.location.reload(),3000);
            }, function(response){
                vm.sealScanBatchSubmitLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                console.log("It doesn't work...");
            });
        }

        function autoScanCNShipment() {
            serverGateway.ajax('autoScanCNShipment').then(function(response) {
                $.bootstrapGrowl('Done', {ele: 'body', type: 'success'});
                window.location.href = "/seal/list/" + config.scanType;
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function openBoxTrackingModal(){
            vm.statistics = '';
            $("#add-box-tracking-modal").modal('show');
        }

        function batchBoxAction(type){
            if(!confirm('Confirm?')) return;
            if(type == 'manifest'){
                if(!confirm('Please make sure the boxes are in the same type')) return;
            }
            if(type == 'sac'){
                if(!confirm('Please make sure the boxes are all apg packages')) return;
            }
            vm.statistics = '';
            serverGateway.ajax('addBoxTracking',null,{code: JSON.stringify(vm.boxTrackingCode.split('\n')),mode: type}).then(function(response){
                if((['manifest','sac','csv','csv.details']).includes(type)){
                    console.log(response.data.stat);
                    vm.statistics = response.data.stat;
                    let url = '/seal-batches/file/'+response.data.name;
                    window.location.href = url;
                } else {
                    window.location.reload();
                }
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function printBoxBarcode(){
            vm.target_boxes = getCheckedBox();
            if(vm.target_boxes.length > 0){
                window.open(`${window.location.protocol}//${window.location.hostname}/seal-batches/exportBoxPdfBarcode?boxes=${encodeURIComponent(JSON.stringify(vm.target_boxes))}`);
            }
        }

        function openSendManifestModal(){
            vm.target_boxes = getCheckedBox();
            if(vm.target_boxes.length > 0){
                vm.email_boxes = vm.target_boxes.join(',');
            }
            //Get the mail list for that sealType
            serverGateway.ajax('getMailList',{type: vm.scanType}).then(function(response){
                const res = response.data;
                if(res.list){
                    vm.email_to = res.list.emailTo;
                    vm.email_from = res.list.emailFrom;
                    vm.email_title = res.list.title;
                    vm.email_content = res.list.content;
                    if(res.list.files){
                        vm.email_attachment = res.list.files;
                    }
                }
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            $('#send-manifest-modal').modal('show');
        }

        function sendManifest(){
            if(!vm.email_to){
                $.bootstrapGrowl('Recipient cannot be empty', {ele: 'body', type: 'warning'});
                return;
            }
            if(!vm.email_from){
                $.bootstrapGrowl('Sender cannot be empty', {ele: 'body', type: 'warning'});
                return;
            }
            if(!vm.email_boxes){
                $.bootstrapGrowl('Box is empty', {ele: 'body', type: 'warning'});
                return;
            }
            if(!confirm('Confirm send?')) return;

            serverGateway.ajax('sendManifestEmail',null,{scanType: vm.scanType, boxes: vm.email_boxes ,email: {to: vm.email_to,from: vm.email_from,title: vm.email_title,content: vm.email_content,files: vm.email_attachment}}).then(function(response){
                const res = response.data;
                if(res.status == 'OK'){
                    $.bootstrapGrowl('Email Sent!', {ele: 'body', type: 'success'});
                    $('#send-manifest-modal').modal('hide');
                }
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function bulkExportCSVCn() {
            let download_batches = [];

            if ($(".barcode_check:checked").length == 0) {
                $.bootstrapGrowl('No Selected Seal Batch(es)', {ele: 'body', type: 'warning'});
                return;
            }

            $(".barcode_check:checked").each(function(i, obj){
                if($(obj).data('batch-id') != ""){
                    download_batches.push($(obj).data('batch-id'));
                }
            });

            let url = '/seal-batches/bulkExportCsvCn/' + download_batches.join();
            window.location.href = url;

        }

        function getCheckedBox(){
            let target_boxes = [];
            $(".barcode_check:checked").each(function(i, obj){
                if($(obj).val() != ""){
                    target_boxes.push($(obj).val());
                }
            });

            return target_boxes;
        }

        function deleteBatch(batch_id){
            if(!confirm('Are you sure? You will need to scan everything again.')){
                return;
            }
            serverGateway.ajax('deleteBatch',{id: batch_id}).then(function(response){
                window.location.reload();
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function exportManifest(batch_id,shipped_at){
            //Requires the shipped time being clicked before export the manifest
            // -> Need to update the shipped date in each shipment
            if(!shipped_at){
                $.bootstrapGrowl('Please click "Confirm Shipped" before exporting Manifest', {ele: 'body', type: 'error'});
                return;
            }
            let url = '/seal-batches/'+batch_id+'/exportManifest';
            window.location.href = url;
        }

        function calculateBoxStatistics(boxes){
            vm.statistics = '';
            if(!boxes){
                $.bootstrapGrowl('Cannot calculate with 0 boxes', {ele: 'body', type: 'warning'});
            }
            if(boxes.indexOf('\n')!==-1){
                boxes = JSON.stringify(vm.boxTrackingCode.split('\n'))
            }

            serverGateway.ajax('calculateBoxStatistics',null,{boxes: boxes}).then(function(response){
                const res = response.data;
                if(res.status == 'OK'){
                    vm.statistics = res.stat;
                }
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function sealBatchScanKeyPress(event){
            if(event.which == 13){//is pressed enter
                const allScans = vm.sealBatchScan.split('\n');
                allScans.forEach(allScan => {
                    if(!(allScan in vm.pendingToChecks)){//new item
                        vm.pendingToChecks[allScan] = {
                            status: 'checking'
                        };
                        vm.checklist.push(allScan);
                    }
                });
                clearTimeout(vm.checkSealBatchScan);
                vm.checkSealBatchScan = setTimeout(() => {
                    const checklist = vm.checklist.slice();
                    serverGateway.ajax('validateSealBatchScanning',null,{code: JSON.stringify(checklist), scanType: vm.scanType, quickCheck: 1, limitSeal: vm.limitSeal}).then(function(response){
                        const res = response.data;
                        vm.limitSeal = res.limit_seal;
                        var clearChecked = [];
                        let haveError = false;
                        for(const box in res.result){
                            vm.pendingToChecks[box] = {
                                status: 'OK'
                            };
                            for(const code in res.result[box]){
                                if(code in res.error_orders){
                                    vm.pendingToChecks[code] = {
                                        status: res.error_orders[code].reason
                                    };
                                    haveError = true;
                                }
                                else{
                                    vm.pendingToChecks[code] = {
                                        status: 'OK'
                                    };
                                }
                                clearChecked.push(code);
                            }
                        }
                        if(haveError){
                            warningSound(); 
                        }
                        vm.checklist = vm.checklist.filter(checkCode => !clearChecked.includes(checkCode));
                    },function(response){

                    });
                }, 1000);
            }
        }

        function resolveCheck(code){
            vm.pendingToChecks[code].status = 'OK';
        }

        function warningSound(){
            vm.warningSound.pause();
            vm.warningSound.currentTime = 0;
            vm.warningSound.play();
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('transferPrinting')
        .controller('TransferPrintingController', Controller);

    Controller.$inject = ['GridDataSource', 'ServerGateway', '$q', 'config', '$timeout'];

    function Controller(GridDataSource, ServerGateway, $q, config, $timeout) {
        var serverGateway = null;
        var vm = this;
        
        vm.transferPrinting = transferPrinting;
        vm.undoTransferPrinting = undoTransferPrinting;
        vm.transferNonPrint = transferNonPrint;
        vm.undoTransferNonPrint = undoTransferNonPrint;
        vm.displayDetail = displayDetail;
        vm.serachSkuByDescription = serachSkuByDescription;
        vm.updateSelected = updateSelected;
        vm.updateSelectedAll = updateSelectedAll;
        vm.addSelectedSku = addSelectedSku;
        vm.detail = {};
        vm.transferAll = false;
        vm.changeTransferAll = changeTransferAll;
        initialize();

        function initialize() {
            // sever side gateway setup
            serverGateway = new ServerGateway({
                endPoints: {
                    'transferPrinting': { path: 'function/transfer-printing/transfer', method: 'POST' },
                    'undoTransferPrinting': { path: 'function/transfer-printing/undo', method: 'POST' },
                    'transferNonPrint': { path: 'function/transfer-non-print/transfer', method: 'POST' },
                    'undoTransferNonPrint': { path: 'function/transfer-non-print/undo', method: 'POST' },
                    'serachSkuByDescription': { path: 'raw-material/serach-by-description', method: 'GET'}
                }
            });
            
            $('.box-primary').css('min-height', ($(window).height() - 200) + 'px');

            initStationSelect2();
        }
        
        function transferPrinting() {
            if (vm.transferButtonLoading) {
                return;
            }
            if (!confirm("Confirm?")) {
                return;
            }
            vm.transferButtonLoading = true;
            vm.fromStation = $('.from-station-select2').select2('val');
            vm.toStation = $('.to-station-select2').select2('val');
            serverGateway.ajax('transferPrinting', '', {fromStation: vm.fromStation, toStation: vm.toStation, count: vm.numOfItemToTransfer, rawMaterials: vm.rawMaterialToTransfer, artworkIds: vm.artworkToTransfer, products: vm.productToTransfer, orders: vm.orderToTransfer, orderItemIds: vm.orderItemToTransfer, transferAll: vm.transferAll, skipOptimize: vm.skipOptimize}).then(function(response) {
                vm.transferButtonLoading = false;
                if(response.data && response.data.hasOwnProperty('status') && response.data.status == 1) {
                    window.location.reload();
                }
            }, function (response) {
                vm.transferButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }
        
        function undoTransferPrinting(logId) {
            if (vm.undoTransferButtonLoading) {
                return;
            }
            if (!confirm("Confirm?")) {
                return;
            }
            vm.undoTransferButtonLoading = true;
            serverGateway.ajax('undoTransferPrinting', '', {logId: logId}).then(function(response) {
                vm.undoTransferButtonLoading = false;
                if(response.data && response.data.hasOwnProperty('status') && response.data.status == 1) {
                    window.location.reload();
                }
            }, function (response) {
                vm.undoTransferButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function transferNonPrint() {
            if (vm.transferButtonLoading) {
                return;
            }
            if (!confirm("Confirm?")) {
                return;
            }
            vm.transferButtonLoading = true;
            vm.fromStation = $('.from-station-select2').select2('val');
            vm.toStation = $('.to-station-select2').select2('val');
            serverGateway.ajax('transferNonPrint', '', {fromStation: vm.fromStation, toStation: vm.toStation, count: vm.numOfItemToTransfer, rawMaterials: vm.rawMaterialToTransfer}).then(function(response) {
                vm.transferButtonLoading = false;
                if(response.data && response.data.hasOwnProperty('status') && response.data.status == 1) {
                    window.location.reload();
                }
            }, function (response) {
                vm.transferButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }
        
        function undoTransferNonPrint(logId) {
            if (vm.undoTransferButtonLoading) {
                return;
            }
            if (!confirm("Confirm?")) {
                return;
            }
            vm.undoTransferButtonLoading = true;
            serverGateway.ajax('undoTransferNonPrint', '', {logId: logId}).then(function(response) {
                vm.undoTransferButtonLoading = false;
                if(response.data && response.data.hasOwnProperty('status') && response.data.status == 1) {
                    window.location.reload();
                }
            }, function (response) {
                vm.undoTransferButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }
        
        function displayDetail(data) {
            vm.detail = data;
            $('#transferPrintingLogModal').modal('show');
        }

        function initStationSelect2(){
            $(document).ready(function() {
                $('.from-station-select2').select2();
                $('.to-station-select2').select2();
                $('.to-station-select2').on('change', () => updateSelectedLocation());
            });
        }

        function serachSkuByDescription(){
            if(!vm.serachDescription){
                $.bootstrapGrowl('Please input description', {ele: 'body', type: 'error'});
                return;
            }
            serverGateway.ajax('serachSkuByDescription', {}, {description: vm.serachDescription}).then(function(response) {
                if(response.data.status == 'OK'){
                    vm.selectedAll = true;
                    vm.rawMaterials = response.data.rawMaterials.map(rawMaterial => {
                        rawMaterial.selected = true;
                        return rawMaterial;
                    });
                    $('#modal-search-description').modal('show');
                }
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function updateSelected(rawMaterial){
            rawMaterial.selected = !rawMaterial.selected;
            if(!rawMaterial.selected){
                vm.selectedAll = false; 
            }
            else{
                for(let i = 0; i < vm.rawMaterials.length; i++){
                    if(!vm.rawMaterials[i].selected){
                        return;
                    }
                }
                vm.selectedAll = true;
            }
        }

        function updateSelectedAll(){
            vm.rawMaterials.map(rawMaterial => {
                rawMaterial.selected = vm.selectedAll;
                return rawMaterial;
            });
        }

        function addSelectedSku(){
            vm.serachDescription = '';
            var rawMaterialMap = [];
            vm.rawMaterials.forEach(rawMaterial => {
                if(rawMaterial.selected){
                    rawMaterialMap.push(rawMaterial.name);
                }
            });
            vm.rawMaterialToTransfer = rawMaterialMap.join(',');
            $('#modal-search-description').modal('hide');
        }

        function changeTransferAll(){
            if(vm.transferAll){
                updateSelectedLocation();
            }
        }

        function updateSelectedLocation(){
            $('.selected-location-description').text(``);
            const selectedPrintStation = config.printStations.find(printStation => printStation.id == $('.to-station-select2').select2('val'));
            $('.selected-location-description').text(`(${selectedPrintStation.location.description})`);
        }
    }
})();

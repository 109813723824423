(function() {
    'use strict';

    angular
        .module('grid')
        .directive('ctgCellDatetime', ctgCellDatetime);

    function ctgCellDatetime() {
        var directive = {
            restrict: 'E',
            replace: true,
            template: '<span>{{ row[column.field].date }}</span>'
        };

        return directive;
    }
})();
(function() {
    Ctg.CheckboxColumn = {
        width: '70px', // TODO: width is not support
        getRowCheckboxesStatus: function(grid) {
            var isAllChecked = true;
            var isAllUnchecked = true;

            // loop through all row checkboxes to determine current status
            grid._$table.find('input.badge-check[type="checkbox"]')
                .each(function() {
                    if (!$(this).prop('checked')) {
                        isAllChecked = false;
                    } else {
                        isAllUnchecked = false;
                    }

                    if (!isAllChecked && !isAllUnchecked) {
                        return false;
                    }
                });

            var checkAllState;
            if (isAllChecked) {
                checkAllState = 'check';
            } else if (isAllUnchecked) {
                checkAllState = 'uncheck';
            } else {
                checkAllState = 'indeterminate';
            }

            return checkAllState;
        },

        updateStatus: function(status, grid) {
            status = status !== 'uncheck' ? 'hasCheck' : 'noCheck';
            if (self.status === status) return;
                
            grid.trigger(status);
            self.status = status;
        },

        cell: function(row) {
            var $checkbox = $('<div class="checkbox icheck"><input type="checkbox" class="badge-check"></div>');
            
            // apply iCheck plugin
            $checkbox
                .find('input')
                .iCheck({
                    checkboxClass: 'icheckbox_square-blue',
                    increaseArea: '20%' // optional
                });

            // if the header checkbox exist
            // base on the header checkbox status render row checkbox
            if (this._$checkAll && this._$checkAll.prop('checked')) {
                $checkbox.find('input').iCheck('check');
            }
            return $checkbox;
        },

        headerCell: function(e, grid) {
            var self = this;
            var $cell = $('<div class="checkbox icheck"><input type="checkbox" class="icheck badge-check-all"></div>');

            this._$checkAll = $cell
                .find('input')
                .iCheck({
                    checkboxClass: 'icheckbox_square-blue',
                    increaseArea: '20%',
                })
                .on('ifToggled', function(e) {
                    if (self._isCheckingOne) return;
                    self._isCheckingAll = true;

                    // update status
                    var latestStatus = $(this).prop('checked') ? 'check' : 'uncheck';
                    self.updateStatus(latestStatus, grid);

                    // update row checkboxes
                    grid._$table
                        .find('input[type="checkbox"]')
                        .iCheck(latestStatus);

                    self._isCheckingAll = false;
                })
                // base on the row checkboxes change the header checkbox status
                // it will be used when setColumns is invoked 
                // due to the headerCell is rerender, after all row checkboxes are rendered
                .iCheck(this.getRowCheckboxesStatus(grid));

            return $cell;
        },
        init: function(grid) {
            var self = this;
            // iCheck behavor is not same as native checkbox
            // If change the state through programming, it will trigger ifXXXX event
            // To prevent event trigger loop, there are special flags to handle to that
            this._isCheckingAll = false;
            this._isCheckingOne = false;
            // 3 status: unkown, hasCheck, noCheck
            // it is to determine when to trigger event
            this.status = 'unkown';

            grid._$table.on('ifToggled', '.badge-check', function(e) {
                if (self._isCheckingAll) return;
                
                self._isCheckingOne = true;

                // update status
                var latestStatus = self.getRowCheckboxesStatus(grid);
                self.updateStatus(latestStatus, grid);

                // update header checkbox
                self._$checkAll.iCheck(latestStatus);

                self._isCheckingOne = false;
            });
        }
    };

})();

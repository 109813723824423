(function() {
    'use strict';

    angular
        .module('printStation')
        .controller('PrintStationController', Controller)
        .controller('PrintStationForItemsController', PrintStationForItemsController);

    Controller.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout'];
    PrintStationForItemsController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout'];

    function Controller($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout) {
        var serverGateway, vm = this;


        vm.submit      = submit;
        vm.rows        = [];
        vm.sorts       = [];
        vm.columns     = config.columns;
        vm.setFilter   = PageViewModel.setFilter;
        vm.clearFilter = PageViewModel.clearFilter;
        vm.sortChange  = PageViewModel.sortChange;
        vm.isLoading   = PageViewModel._isLoading;
        vm.openDetailDialog = openDetailDialog;
        vm.savePrintStation = savePrintStation;
        vm.openDuplicateDialog = openDuplicateDialog;
        vm.duplicatePrintStation = duplicatePrintStation;
        vm.closeModal = closeModal;
        vm.detail = null;
        vm.duplicate = null;
        vm.printers = [];
        vm.searchPrintStation = null;
        vm.updateSearchPrintStation = updateSearchPrintStation;
        vm.timer = 0;
        vm.disabledHide = false;
        updateDisabledHideBtn();
        vm.toggleDisabledHideBtn = toggleDisabledHideBtn;

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'savePrintStation': { path: 'print-stations/{id}', method: 'POST' },
                    'getPrinters': { path: 'print-stations/printers', method: 'GET'},
                    'duplicatePrintStation': {path: 'print-stations/duplicate/{id}', method: 'POST'},
                }
            });
            vm.gridDataSource = new GridDataSource({
                stationId   : config.stationId,
                resoucesUrl : config.dataUrl,
                gateway     : serverGateway,
                perPage     : 999,
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                return PageViewModel.rows;
            }, function(rows) {
                vm.rows = rows;
                var found = false;
                rows.forEach(function(row) {
                    if (vm.detail && row.id == vm.detail.id) {
                        found = true;
                        // vm.detail = row;
                    }
                });
                if (!found) {
                    vm.detail = null;
                }
            }, true);

            $timeout(() => PageViewModel.update());

            // refresh data periodically
            setInterval(PageViewModel.update, 5000);
            vm.isLoading = false;

            serverGateway.ajax('getPrinters').then(function(response){
                vm.printers = response.data.printers
            })
        }

        function submit() {

        }

        function openDetailDialog(row) {
            vm.detail = row;
            $('#printStationModal').modal('show');
        }

        function savePrintStation() {
            vm.processing = true;
            serverGateway.ajax('savePrintStation', {id: vm.detail.id}, $.extend({}, vm.detail)).then(function(response) {
                vm.processing = false;
                PageViewModel.update();
                PageViewModel.hideModal('#printStationModal');
            }, function (response) {
                vm.processing = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function openDuplicateDialog(){
            vm.duplicate = Object.assign({}, vm.detail);
            const match = vm.detail.description.match(/\((.+)\)/);
            if(match !== null){
                vm.duplicate.description = match[1];
            }
            console.log({detail: vm.detail.description});
            console.log({duplicate: vm.duplicate.description});
            $('#duplicateModal').modal('show');
        }

        function duplicatePrintStation(){
            serverGateway.ajax('duplicatePrintStation', {id: vm.duplicate.id}, $.extend({}, vm.duplicate)).then(function(response){
                PageViewModel.update();
                PageViewModel.hideModal('#duplicateModal');
                PageViewModel.hideModal('#printStationModal');
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function closeModal(){
            $('.modal').each(function(){
                $(this).modal('hide');
            });
        }

        function updateSearchPrintStation(){
            updateRules();
        }

        function toggleDisabledHideBtn(){
            vm.disabledHide = !vm.disabledHide;
            updateDisabledHideBtn();
            updateRules();
        }

        function updateDisabledHideBtn(){
            vm.disabledHideBtn = vm.disabledHide ? 'Show Disabled: Off' : 'Show Disabled: On';
        }

        function updateRules(){
            clearTimeout(vm.timer);
            vm.timer = setTimeout(function () {
                vm.gridDataSource.setRules(
                    JSON.stringify({
                        search: vm.searchPrintStation,
                        disabledHide: vm.disabledHide
                    })
                );
                PageViewModel.update();
                vm.gridDataSource.load();
            }, 500);
        }
    }

    function PrintStationForItemsController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout) {
        var serverGateway, vm = this;


        vm.submit      = submit;
        vm.rows        = [];
        vm.sorts       = [];
        vm.columns     = config.columns;
        vm.setFilter   = PageViewModel.setFilter;
        vm.clearFilter = PageViewModel.clearFilter;
        vm.sortChange  = PageViewModel.sortChange;
        vm.isLoading   = PageViewModel._isLoading;
        vm.openDetailDialog = openDetailDialog;
        vm.closeDetailDialog = closeDetailDialog;
        vm.create = create;
        vm.savePrintStationForItem = savePrintStationForItem;
        vm.deletePrintStationForItem = deletePrintStationForItem
        vm.locationList = config.locationList;
        vm.printStations = config.printStations;
        vm.slotTypes = config.slotTypes;
        vm.stationToSlotRelationship = config.stationToSlotRelationship;
        vm.detail = null;

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'savePrintStationForItem': { path: 'print-stations-for-items/{id}', method: 'POST' },
                    'deletePrintStationForItem': {path: 'delete-print-stations-for-items/{id}',method: 'POST'}
                }
            });
            vm.gridDataSource = new GridDataSource({
                stationId   : config.stationId,
                resoucesUrl : config.dataUrl,
                gateway     : serverGateway,
                perPage     : 999,
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                return PageViewModel.rows;
            }, function(rows) {
                vm.rows = rows;
                // var found = false;
                // rows.forEach(function(row) {
                //     if (vm.detail && row.id == vm.detail.id) {
                //         found = true;
                //         // vm.detail = row;
                //     }
                // });
                // if (!found) {
                //     vm.detail = {
                //         id : 0,
                //         is_hot_stamp_detail : 'Any',
                //         is_gift_order: 'Any'
                //     };
                // }
            }, true);

            // $timeout(() => PageViewModel.update());

            // refresh data periodically
            // setInterval(PageViewModel.update, 5000);

            PageViewModel.initializeQueryBuilder(config.filtersConfig);
            
        }

        function submit() {

        }

        function addEventListener(){
            $("#location-search").off('keyup');
            $("#location-dropdown li").off('click');
            $('.location-dropdown-toggle').off('click');

            $("#print-station-search").off('keyup');
            $("#print-station-dropdown li").off('click');
            $('.print-station-dropdown-toggle').off('click');

            $("#slot-type-search").off('keyup');
            $("#slot-type-dropdown li").off('click');
            $('.slot-type-dropdown-toggle').off('click');

            $("#slot-type-search-second").off('keyup');
            $("#slot-type-dropdown-second li").off('click');
            $('.slot-type-dropdown-toggle-second').off('click');

            $("#location-search").on("keyup", function() {
                var value = $(this).val().toLowerCase();
                var tempObj = $("#location-dropdown li");
                if(!value){
                    tempObj.show();
                } else {
                    tempObj.filter(function() {
                        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
                    });
                }
            });
            $("#location-dropdown li").on({
                'click': function(e){
                    e.preventDefault();
                    e.stopPropagation();

                    var tempVal = $(this).data('location-id');
                    var tempDescription = $(this).html();

                    vm.detail.location_id = tempVal;
                    $('#location_display_name').val(tempDescription);
                    $('#location_id').val(tempVal);
                    
                }
            });

            $('.print-station-dropdown-toggle').on({
                'click': function(e){
                    var locationId = $('#location_id').val();
                    var tempObj = $("#print-station-dropdown li");
                    var value = $("#print-station-search").val().toLowerCase();

                    if(typeof locationId != 'undefined' && locationId > 0){
                        tempObj.filter(function() {
                            var tempLocationId = $(this).data('location-id');

                            var tempFlag = $(this).text().toLowerCase().indexOf(value) > -1;
                            if(typeof tempLocationId != 'undefined' && tempLocationId){
                                tempFlag = tempFlag && ($(this).data('location-id') == locationId);
                            }
                            
                            $(this).toggle(tempFlag);
                        });
                    }else{
                        if(!value){
                            tempObj.show();
                        } else {
                            tempObj.filter(function() {
                                $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
                            });
                        }
                    }
                }
            });
            $("#print-station-search").on("keyup", function() {
                var value = $(this).val().toLowerCase();
                var locationId = $('#location_id').val();
                var tempObj = $("#print-station-dropdown li");
                
                if (locationId > 0) {
                    if (!value) {
                        tempObj.filter(function () {
                            var tempFlag = ($(this).data('location-id') == locationId);
                            $(this).toggle(tempFlag);
                        });
                    } else {
                        tempObj.filter(function () {

                            var tempLocationId = $(this).data('location-id');

                            var tempFlag = $(this).text().toLowerCase().indexOf(value) > -1;
                            if (typeof tempLocationId != 'undefined' && tempLocationId && typeof locationId != 'undefined' && locationId) {
                                tempFlag = tempFlag && ($(this).data('location-id') == locationId);
                            }
                        
                            $(this).toggle(tempFlag);
                        });
                    }
                } else {
                    tempObj.filter(function () {
                        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
                    });
                }
            });
            $("#print-station-dropdown li").on({
                'click': function(e){
                    e.preventDefault();
                    e.stopPropagation();

                    var tempVal = $(this).data('print-station-id');
                    var tempDescription = $(this).html();
                    var tempName = $(this).data('print-station-name');

                    vm.detail.print_station_name = tempName;
                    vm.detail.print_station_id = tempVal;
                    $('#print_station_display_name').val(tempDescription);
                    $('#print_station_name').val(tempName);
                    $('#print_station_id').val(tempVal);
                    vm.detail.show_slot_type = true;
                    vm.detail.slot_type_id = null;
                    $('#slot_type_dislay_name').val(null);
                }
            });
            
            $('.slot-type-dropdown-toggle').on({
                'click': function(e){
                    var locationId = $('#location_id').val();
                    var printStationId = $('#print_station_id').val();
                    var tempObj = $("#slot-type-dropdown li");
                    var value = $("#slot-type-search").val().toLowerCase();

                    if(typeof printStationId != 'undefined' && printStationId){
                        tempObj.filter(function() {
                            var tempLocationId = $(this).data('location-id');
                            var tempSlotTypeId = $(this).data('slot-type-id');

                            var tempFlag = $(this).text().toLowerCase().indexOf(value) > -1;

                            if(typeof tempSlotTypeId != 'undefined' && tempSlotTypeId){
                                var tempRelationship = (typeof config.stationToSlotRelationship[printStationId] != 'undefined') ? config.stationToSlotRelationship[printStationId] : false;
                                if(tempRelationship){
                                    tempFlag = tempFlag && $.inArray(tempSlotTypeId, tempRelationship) >= 0;
                                }
                            }
                            
                            $(this).toggle(tempFlag);
                        });
                    }
                }
            });
            $("#slot-type-search").on("keyup", function() {
                var value = $(this).val().toLowerCase();
                var tempObj = $("#slot-type-dropdown li");
                if(!value){
                    tempObj.show();
                } else {
                    tempObj.filter(function() {
                        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
                    });
                }
            });
            $("#slot-type-dropdown li").on({
                'click': function(e){
                    e.preventDefault();
                    e.stopPropagation();

                    var tempVal = $(this).data('slot-type-id');
                    var tempDescription = $(this).html();
                    vm.detail.slot_type_id = tempVal;
                    $('#slot_type_dislay_name').val(tempDescription);
                    $('#slot_type_id').val(tempVal);
                }
            });

            $('.slot-type-dropdown-toggle-second').on({
                'click': function(e){
                    var locationId = $('#location_id').val();
                    var printStationId = $('#print_station_id').val();
                    var tempObj = $("#slot-type-dropdown-second li");
                    var value = $("#slot-type-search-second").val().toLowerCase();

                    if(typeof printStationId != 'undefined' && printStationId){
                        tempObj.filter(function() {
                            var tempLocationId = $(this).data('location-id');
                            var tempSlotTypeId = $(this).data('slot-type-id-second');

                            var tempFlag = $(this).text().toLowerCase().indexOf(value) > -1;

                            if(typeof tempSlotTypeId != 'undefined' && tempSlotTypeId){
                                var tempRelationship = (typeof config.stationToSlotRelationship[printStationId] != 'undefined') ? config.stationToSlotRelationship[printStationId] : false;
                                if(tempRelationship){
                                    tempFlag = tempFlag && $.inArray(tempSlotTypeId, tempRelationship) >= 0;
                                }
                            }
                            
                            $(this).toggle(tempFlag);
                        });
                    }
                }
            });
            $("#slot-type-search-second").on("keyup", function() {
                var value = $(this).val().toLowerCase();
                var tempObj = $("#slot-type-dropdown-second li");
                if(!value){
                    tempObj.show();
                } else {
                    tempObj.filter(function() {
                        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
                    });
                }
            });
            $("#slot-type-dropdown-second li").on({
                'click': function(e){
                    e.preventDefault();
                    e.stopPropagation();

                    var tempVal = $(this).data('slot-type-id-second');
                    var tempDescription = $(this).html();
                    vm.detail.slot_type_id_second = tempVal;
                    $('#slot_type_dislay_name_second').val(tempDescription);
                    $('#slot_type_id_second').val(tempVal);
                }
            });
        }

        function create() {
            $('#location_display_name').val('');
            $('#location_id').val(0);
            $('#print_station_display_name').val('');
            $('#print_station_name').val('');
            $('#print_station_id').val(0);
            $('#slot_type_dislay_name').val('');
            $('#slot_type_id').val(0);
            $('#slot_type_dislay_name_second').val('');
            $('#slot_type_id_second').val(0);

            openDetailDialog({
                id : 0,
                use_parent_artwork : 'Any',
                is_hot_stamp_detail : 'Any',
                is_laser_engraving: 'Any',
                is_charms_detail : 'Any',
                is_gift_order: 'Any',
                is_laser_engraving: 'Any',
                is_charms_detail: 'Any',
                is_retail_order: 'Any',
                is_nft_your_case_order: 'Any',
                is_cn_to_cn_order: 'Any',
                location_id: 0, 
                print_station_id: 0,
                slot_type_id: 0,
                slot_type_id_second: 0,
                show_slot_type: true
            });
        }

        function closeDetailDialog() {
            vm.detail = null;
            $('#printStationForItemModal').modal('hide');
        }

        function openDetailDialog(row) {
            // clone from row to prevent 2-way binding
            vm.detail = Object.assign({}, row);

            if(vm.detail.artwork_id == 'Any'){
                vm.detail.artwork_id = '';
            }

            if(vm.detail.raw_material_name == 'Any'){
                vm.detail.raw_material_name = '';
            }

            if(vm.detail.location_id){
                $('#location_display_name').val(vm.detail.location_name);
                $('#location_id').val(vm.detail.location_id);
            }
            if(vm.detail.print_station){
                $('#print_station_display_name').val(vm.detail.print_station_description);
                $('#print_station_name').val(vm.detail.print_station_name);
                $('#print_station_id').val(vm.detail.print_station.id);
            }

            addEventListener()

            $('#printStationForItemModal').modal('show');
        }

        function savePrintStationForItem() {
            vm.processing = true;
            serverGateway.ajax('savePrintStationForItem', {id: vm.detail.id}, $.extend({}, vm.detail)).then(function(response) {
                vm.processing = false;
                PageViewModel.update();
                PageViewModel.hideModal('#printStationForItemModal');
            }, function (response) {
                vm.processing = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function deletePrintStationForItem(){
            vm.processing = true;
            serverGateway.ajax('deletePrintStationForItem', {id: vm.detail.id}).then(function(response){
                vm.processing = false;
                PageViewModel.update();
                PageViewModel.hideModal('#printStationForItemModal');
            }, function(response){
                vm.processing = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }
    }
})();

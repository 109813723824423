(function() {
    'use strict';

    angular
        .module('printingTemplate')
        // .controller('PrintingTemplateController', Controller)
        .controller('SlotTypeInfoController', SlotTypeInfoController)
        .controller('PrintingTemplateController', PrintingTemplateController)
        .controller('SlotTypeController', SlotTypeController)
        .controller('TestPrintController', TestPrintController)
        .directive('datePicker', datePicker);

    // Controller.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout'];
    SlotTypeInfoController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout', 'FileUploader', 'AlignmentTool'];
    PrintingTemplateController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout'];
    SlotTypeController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout'];
    TestPrintController.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout'];
    datePicker.$inject = ['$timeout'];

    function SlotTypeInfoController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, FileUploader, AlignmentTool) {
        $scope.colorMaskUploader = new FileUploader({
            autoUpload: true,
            removeAfterUpload: true,
            headers: {
                Authorization: 'Basic ' + localStorage.getItem('api-auth-token'),
            },
            onCompleteItem: function () {
                _updateFileList();
                vm.detail.maskOffsetLeft = 0;
                vm.detail.maskOffsetBottom = 0;
                $("input[name=color_mask_file]").val('');
            }
        });
        $scope.whiteMaskUploader = new FileUploader({
            autoUpload: true,
            removeAfterUpload: true,
            headers: {
                Authorization: 'Basic ' + localStorage.getItem('api-auth-token'),
            },
            onCompleteItem: function () {
                _updateFileList();
                $("input[name=white_mask_file]").val('');
            }
        });
        $scope.previewUploader = new FileUploader({
            autoUpload: true,
            removeAfterUpload: true,
            headers: {
                Authorization: 'Basic ' + localStorage.getItem('api-auth-token'),
            },
            onCompleteItem: function () {
                _updateFileList();
                vm.detail.previewOffsetScale = 1;
                vm.detail.previewOffsetLeft = 0;
                vm.detail.previewOffsetBottom = 0;
                $("input[name=preview_file]").val('');
            }
        });
        $scope.printableUploader = new FileUploader({
            autoUpload: true,
            removeAfterUpload: true,
            headers: {
                Authorization: 'Basic ' + localStorage.getItem('api-auth-token'),
            },
            onCompleteItem: function () {
                _updateFileList();
                $("input[name=printable_file]").val('');
            }
        });

        var serverGateway, vm = this;
        // vm.materials         = config.materials;
        // vm.locations         = config.locations;
        // vm.selectedMaterial  = vm.materials.length ? vm.materials[0] : null;
        // vm.selectedLocation  = vm.locations.length ? vm.locations[0] : null;
        vm.rows              = [];
        vm.sorts             = [];
        vm.columns           = config.columns;
        vm.locations         = config.locations;
        vm.setFilter         = PageViewModel.setFilter;
        vm.clearFilter       = PageViewModel.clearFilter;
        vm.sortChange        = PageViewModel.sortChange;
        vm.isLoading         = PageViewModel._isLoading;
        vm.submit            = submit;
        vm.openDetailDialog  = openDetailDialog;
        vm.detail            = {};
        vm.stockin           = {};
        vm.histories = [];
        vm.currentTimestamp  = currentTimestamp;
        vm.alignScale        = alignScale;
        vm.downloadTestLine = downloadTestLine;
        vm.distribute = distribute;
        vm.copyFromId = copyFromId;
        vm.cloneForProduct = cloneForProduct;
        vm.hidePreview = true;
        vm.hidePreviewMask = false;
        vm.showHistory = showHistory;
        vm.restructureRawMaterialList = restructureRawMaterialList;
        vm.refreshMask = refreshMask;

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'slot-type-info'         : { path: 'slot-type-info', method: 'GET' },
                    'slot-type-info-update'  : { path: 'slot-type-info', method: 'PUT' },
                    'slot-type-info-files'   : { path: 'slot-type-info/{id}/files?{t}', method: 'GET' },
                    'slot-type-info-preview' : { path: 'slot-type-info/{id}/preview', method: 'GET' },
                    'slot-type-info-preview-mask' : { path: 'slot-type-info/{id}/previewMask', method: 'GET' },
                    'slot-type-info-distribute'   : { path: 'slot-type-info/{id}/distribute', method: 'POST' },
                    'slot-type-info-copy-from'   : { path: 'slot-type-info/{id}/copyFromId', method: 'POST' },
                    'slot-type-info-clone-for-product'   : { path: 'slot-type-info/{id}/cloneForProduct', method: 'POST' },
                    'slot-type-info-history' : { path: 'slot-type-info/{id}/history', method: 'GET' },
                    'slot-type-info-refresh' : { path: 'slot-type-info/{id}/refreshMask', method: 'POST' },
                }
            });
            vm.gridDataSource = new GridDataSource({
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            // $timeout(function() { PageViewModel.update(); }, 500);

            PageViewModel.initializeQueryBuilder(config.filtersConfig);

            AlignmentTool.initTool(vm);

            $timeout(function () {

                var tempRules = [];

                if(typeof config.preSearch != 'undefined' && config.preSearch && config.preSearch.type){
                    switch(config.preSearch.type){
                        case 'id':
                            tempRules = [
                                {
                                    "id": "printing_slot_type_raw_materials.id",
                                    "field": "printing_slot_type_raw_materials.id",
                                    "type": "string",
                                    "input": "checkbox",
                                    "operator": "equal",
                                    "value": (typeof config.preSearch.search != 'undefined') ? config.preSearch.search : null
                                }
                            ];
                            $('#builder-basic').queryBuilder('setRules', {
                                "condition": "AND",
                                "rules": tempRules
                                }
                            );
                        break;
                        default:
                            tempRules = [
                                {
                                    "id": "printing_slot_type_raw_materials.raw_material_id",
                                    "field": "printing_slot_type_raw_materials.raw_material_id",
                                    "type": "string",
                                    "input": "checkbox",
                                    "operator": "in",
                                    "value": []
                                }
                            ];
                            $('#builder-basic').queryBuilder('setRules', {
                                "condition": "AND",
                                "rules": tempRules
                                }
                            );

                            restructureRawMaterialList(config.preSearch.search);
                        break;
                    }

                    PageViewModel.setFilter();
                    PageViewModel.update();

                } else {

                    tempRules = [
                        {
                            "id": "printing_slot_type_raw_materials.raw_material_id",
                            "field": "printing_slot_type_raw_materials.raw_material_id",
                            "type": "string",
                            "input": "checkbox",
                            "operator": "in",
                            "value": []
                        }
                    ];
                    $('#builder-basic').queryBuilder('setRules', {
                        "condition": "AND",
                        "rules": tempRules
                        }
                    );
                    restructureRawMaterialList();
                }

            }, 500);
            
        }

        function submit(e) {
            e.preventDefault();

            serverGateway.ajax('slot-type-info-update', '', vm.detail).then(function (response) {
                if (response.data.status == 'success') {
                    var idx = _.findIndex(vm.rows, function (row) {
                        return row.id == vm.detail.id;
                    });
                    if (typeof idx !== -1) vm.rows[idx] = vm.detail;
                    PageViewModel.hideModal();
                    PageViewModel.update();
                }
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });

        }

        function openDetailDialog(row) {
            if (row.preview == null) {
                serverGateway.ajax('slot-type-info-preview', {id: row.id}).then(function (response) {
                    _updateFileList();
                });
            }
            if (row.previewMask == null) {
                serverGateway.ajax('slot-type-info-preview-mask', {id: row.id}).then(function (response) {
                    _updateFileList();
                });
            }

            AlignmentTool.performAlignment = function (action, property) {
                vm.detail[property] = action(vm.detail[property]);
            };

            vm.detail  = $.extend({}, true, row);
            $scope.colorMaskUploader.url = "/api/slot-type-info/" + row.id + "/colorMask.png";
            $scope.whiteMaskUploader.url = "/api/slot-type-info/" + row.id + "/whiteMask.png";
            $scope.previewUploader.url = "/api/slot-type-info/" + row.id + "/preview.png";
            $scope.printableUploader.url = "/api/slot-type-info/" + row.id + "/printable.png";

            _updateFileList();
            PageViewModel.openModal();
        }

        function downloadTestLine(row) {
            window.location.href="/slot-type-info/"+row.id+"/testLineFile?templateId=" + row.printingTemplate;
            // console.log(printingTemplate);
        }

        function distribute(row) {
            serverGateway.ajax('slot-type-info-distribute', {id: row.id}, '').then(function(response) {
                $.bootstrapGrowl("done", {ele: 'body', type: 'success'});
                PageViewModel.update();
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function refreshMask(row) {
            serverGateway.ajax('slot-type-info-refresh', {id: row.id}).then(function(response) {
                $.bootstrapGrowl("done", {ele: 'body', type: 'success'});
                PageViewModel.update();
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function copyFromId(row) {
            console.log(row.id);
            console.log(row.targetId);

            if(typeof row.canEdit != 'undefined' && !row.canEdit){
                $.bootstrapGrowl("You don't have permission to edit", {ele: 'body', type: 'error'});
                return;
            }

            if(typeof row.targetId == 'undefined' || !row.targetId){
                $.bootstrapGrowl("Please input id to copy", {ele: 'body', type: 'error'});
                return;
            }

            serverGateway.ajax('slot-type-info-copy-from', {id: row.id}, {targetId: row.targetId, 'manual-copy': true}).then(function(response) {
                $.bootstrapGrowl("done", {ele: 'body', type: 'success'});
                PageViewModel.update();
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function cloneForProduct(row) {
            console.log(row.id);
            console.log(row.targetProductId);

            if(typeof row.canEdit != 'undefined' && !row.canEdit){
                $.bootstrapGrowl("You don't have permission to edit", {ele: 'body', type: 'error'});
                return;
            }

            if(typeof row.targetProductId == 'undefined' || !row.targetProductId){
                $.bootstrapGrowl("Please input product id", {ele: 'body', type: 'error'});
                return;
            }

            serverGateway.ajax('slot-type-info-clone-for-product', {id: row.id}, {targetProductId: row.targetProductId}).then(function(response) {
                $.bootstrapGrowl("done", {ele: 'body', type: 'success'});
                PageViewModel.update();
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function currentTimestamp() {
            return +new Date;
        }

        function alignScale() {
            return Math.min(
                $('.alignment-canvas').height() / $('.align-item-color-mask').height(),
                $('.alignment-canvas').height() / $('.align-item-preview').height()
            );
        }

        function _updateFileList() {

            serverGateway.ajax('slot-type-info-files', {id: vm.detail.id, t: currentTimestamp()}, '').then(function(response) {
                if (response.data.data.colorMask !== null) response.data.data.colorMask += '?' + currentTimestamp();
                if (response.data.data.whiteMask !== null) response.data.data.whiteMask += '?' + currentTimestamp();
                if (response.data.data.preview !== null) response.data.data.preview += '?' + currentTimestamp();
                if (response.data.data.previewMask !== null) response.data.data.previewMask += '?' + currentTimestamp();
                if (response.data.data.printable !== null) response.data.data.printable += '?' + currentTimestamp();
                vm.detail.colorMask = response.data.data.colorMask;
                vm.detail.whiteMask = response.data.data.whiteMask;
                vm.detail.preview = response.data.data.preview;
                vm.detail.previewMask = response.data.data.previewMask;
                vm.detail.printable = response.data.data.printable;
            });
        }

        function showHistory() {
            $('#historyDialog').modal('show');
            serverGateway.ajax('slot-type-info-history', {id: vm.detail.id}).then(function(response) {
                vm.histories = response.data.data
                console.log(vm.histories)
            });
        }

        function restructureRawMaterialList(presearch){
            // var result = $('#builder-basic').queryBuilder('getRules');
            var tempSelect = $('select[name="builder-basic_rule_0_filter"]');

            var temp = false;
            if(typeof tempSelect != 'undefined'){
                // temp = (typeof result.rules != 'undefined' && typeof result.rules[0] != 'undefined' && result.rules[0].field == "printing_slot_type_raw_materials.raw_material_id");
                temp = (tempSelect.val() == "printing_slot_type_raw_materials.raw_material_id");
            }

            if(temp){
                var tempObj = $('.rule-value-container');
                var tempLabels = tempObj.find('label');
                var tempFilterObj = $('<input></input>').attr('type', 'text').attr('id', 'raw-materail-search').attr('placeholder', 'Type to search raw materials').addClass('form-control').on({
                    'keyup': function(e){
                        e.preventDefault();
                        var tempVal = $(this).val();
                        if(tempVal){
                            tempLabels.filter(function() {
                                var temp = $(this);

                                var tempFlag = true;
                                if(typeof temp != 'undefined' && temp){
                                    tempFlag = tempFlag && (temp.text().toLowerCase().indexOf(tempVal.toLowerCase()) > -1);
                                }
                                
                                $(this).toggle(tempFlag);
                            });
                        } else {
                            tempLabels.show();
                        }
                    }
                });
                tempObj.prepend($('<div class="row filter-search-row"></div>').append(tempFilterObj));

                if(typeof presearch != 'undefined'){
                    tempFilterObj.val(presearch).trigger('keyup');

                    tempLabels.filter(function(){
                        var temp = $(this);

                        if(typeof temp != 'undefined' && temp){
                            if(temp.html().indexOf("["+presearch+"]") >= 0){
                                temp.find('input[type="checkbox"]').attr('checked', true).trigger('change');
                            } else {
                                temp.find('input[type="checkbox"]').attr('checked', false);
                            }
                        }

                    });
                }

            }
        }

    }

    function PrintingTemplateController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout) {

        var serverGateway, vm = this;
        // vm.materials         = config.materials;
        // vm.locations         = config.locations;
        // vm.selectedMaterial  = vm.materials.length ? vm.materials[0] : null;
        // vm.selectedLocation  = vm.locations.length ? vm.locations[0] : null;
        vm.rows              = [];
        vm.sorts             = [];
        vm.columns           = config.columns;
        vm.locations         = config.locations;
        vm.rawMaterial       = config.rawMaterial;
        vm.printStations     = config.printStations;
        vm.printQueues = config.printQueues;
        vm.parentTemplates = config.parentTemplates;
        vm.setFilter         = PageViewModel.setFilter;
        vm.clearFilter       = PageViewModel.clearFilter;
        vm.sortChange        = PageViewModel.sortChange;
        vm.isLoading         = PageViewModel._isLoading;
        vm.openDetailDialog  = openDetailDialog;
        vm.detail            = {};
        vm.slotTypeChanged = slotTypeChanged;
        vm.savePrintingTemplate = savePrintingTemplate;
        vm.deleteSlot = deleteSlot;
        vm.openCreateTemplateModal = openCreateTemplateModal;
        vm.createTemplate = createTemplate;
        vm.openCreateSlotModal = openCreateSlotModal;
        vm.createSlot = createSlot;
        vm.applyTopDelta = applyTopDelta;
        vm.applyLeftDelta = applyLeftDelta;
        vm.openBatchCreateSlotsModal = openBatchCreateSlotsModal;
        vm.batchCreateSlots = batchCreateSlots;
        vm.syncFromParent = syncFromParent;
        vm.distributeToChild = distributeToChild;
        vm.leftDelta = 0;
        vm.topDelta = 0;
        vm.slotTypes = [];
        vm.newSlot = {
            top: 0,
            left: 0,
        };
        vm.printStationsList = [];
        vm.updateSearchTemplateName = updateSearchTemplateName;
        vm.timer = 0;

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'printing-template-update'  : { path: 'printing-templates/{id}', method: 'PUT' },
                    'deleteSlot' : {path: 'printing-templates/{id}', method: 'DELETE' },
                    'createSlot' : {path: 'printing-templates/slots', method: 'POST'},
                    'getSlotTypes' : {path: 'printing-templates/getSlots', method: 'GET'},
                    'createTemplate' : {path: 'printing-templates', method: 'POST'},
                    'batchCreateSlots' : {path: 'printing-templates/batchCreateSlots', method: 'POST'},
                    'syncFromParent' : {path: 'printing-templates/{id}/syncFromParent', method: 'POST' },
                    'distributeToChild' : {path: 'printing-templates/{id}/distributeToChild', method: 'POST' },
                }
            });
            vm.gridDataSource = new GridDataSource({
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            $timeout(function() { PageViewModel.update(); }, 500);

            // setInterval(PageViewModel.update, 5000);
            serverGateway.ajax('getSlotTypes').then(function(response){
                vm.slotTypes = response.data;
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            })
            vm.locations.forEach(function(location){
                var locationStation = vm.printStations.filter(item => item.locationId == location.key);
                var locationPrintStationsMap = new Map();
                locationStation.forEach(item => locationPrintStationsMap.set(item.id,item.description));
                vm.printStationsList[location.key] = [...locationPrintStationsMap].map(([id, text]) => ({ id, text }));
            })
        }

        function openDetailDialog(row) {
            vm.detail = row;
            vm.detail.is_backprint = vm.detail.is_backprint.toString();
            var rawMaterialSelector = $('#modal .default_raw_material_id');
            rawMaterialSelector.val(null).empty();
            if (rawMaterialSelector.hasClass("select2-hidden-accessible")) {
                rawMaterialSelector.select2('destroy')
            }
            
            rawMaterialSelector.select2({
                data:vm.rawMaterial.map(i=>({id:i.key,text:i.value})),
                minimumInputLength: 2,
                placeholder: "Select an option",
                allowClear: true,
                dropdownParent: $("#modal")
            }).on('select2:select', function (e) {
                vm.detail.default_raw_material_id = rawMaterialSelector.val();
            })
            if(vm.detail.default_raw_material_id){
                rawMaterialSelector.val(vm.detail.default_raw_material_id).trigger("change")
            }
            PageViewModel.openModal();
        }

        function slotTypeChanged(slot) {
            $.each(vm.detail.slots, function (index, element) {
                if (vm.detail.slots[index].slotType == slot.slotType) {
                    vm.detail.slots[index].width = slot.width;
                    vm.detail.slots[index].height = slot.height;
                }
            });
        }

        function savePrintingTemplate() {
            serverGateway.ajax('printing-template-update', {id: vm.detail.id}, {templateInfo: vm.detail}).then(function (response) {
                $.bootstrapGrowl('Saved', {ele: 'body', type: 'success'});
                vm.detail = response.data.data;
                openDetailDialog(vm.detail);
                if (vm.detail.clone_type == 'parent') {
                    if (confirm("Also distribute to children?")) {
                        distributeToChild(vm.detail);
                    }
                }
                // if (vm.detail.clone_type == 'child') {
                //     syncFromParent(vm.detail)
                // }
                // PageViewModel.hideModal();
                // window.location.reload();
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function deleteSlot(slot){
            if(!confirm('Confirm?')) return;
            serverGateway.ajax('deleteSlot',{id: slot.id}).then(function(response){
                $.bootstrapGrowl('Deleted', {ele: 'body', type: 'success'});
                PageViewModel.update();
                vm.detail = response.data.data;
                openDetailDialog(vm.detail);
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function openCreateTemplateModal(){
            vm.detail = {
                name: "",
                description: "",
                location_id: 0,
                print_station_ids: [],
                default_raw_material_id: null,
                width: 0,
                height: 0,
                is_backprint: "0",
            };
            var parentModal = $("#createTemplateModal");
            var printStationSelector = $('#createTemplateModal .print_station');
            printStationSelector.val(null).empty();
            if (printStationSelector.hasClass("select2-hidden-accessible")) {
                printStationSelector.select2('destroy');
            }
            printStationSelector.hide();
            var locationSelector = $('#createTemplateModal .location');
            locationSelector.select2({
                data:vm.locations.map(i=>({id:i.key,text:i.value})),
                placeholder: "Select an option",
                dropdownParent: parentModal
            }).on('select2:select', function (e) {
                vm.detail.location_id = $('.location').val();
                printStationSelector.val(null).empty();
                if (printStationSelector.hasClass("select2-hidden-accessible")) {
                    printStationSelector.select2('destroy')
                }
                vm.detail.print_station = [];
                var printStationData = vm.printStationsList[vm.detail.location_id];
                printStationSelector.select2({
                    data: printStationData,
                    minimumInputLength: 2,
                    placeholder: "Select an option",
                    allowClear: true,
                    dropdownParent: parentModal
                }).on('select2:select', function (e) {
                    vm.detail.print_station_ids = $('.print_station').val();
                });
                printStationSelector.show();
            });
            var rwaMaterialSelector = $('#createTemplateModal .default_raw_material_id')
            rwaMaterialSelector.val(null).empty();
            if (rwaMaterialSelector.hasClass("select2-hidden-accessible")) {
                rwaMaterialSelector.select2('destroy')
            }
            
            rwaMaterialSelector.select2({
                data:vm.rawMaterial.map(i=>({id:i.key,text:i.value})),
                minimumInputLength: 2,
                placeholder: "Select an option",
                allowClear: true,
                dropdownParent: parentModal
            }).on('select2:select', function (e) {
                vm.detail.default_raw_material_id = rwaMaterialSelector.val();
            });
            
            parentModal.modal('show');
        }

        function createTemplate(){
            if(!(vm.detail.name)){
                $.bootstrapGrowl('Please insert all fields', {ele: 'body', type: 'error'});
                return;
            }
            serverGateway.ajax('createTemplate',null,{templateInfo: vm.detail}).then(function(response){
                $.bootstrapGrowl('Created', {ele: 'body', type: 'success'});
                PageViewModel.update();
                $('#createTemplateModal').modal('hide');
                vm.detail = response.data.data;
                openDetailDialog(vm.detail);
            },function(){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function openCreateSlotModal(){
            vm.newSlot = {
                top: 0,
                left: 0,
            };
            $('#createSlotModal').modal('show');
        }

        function createSlot(){
            if(!(vm.newSlot.slotType && (vm.newSlot.rotation != undefined))){  //top and left is default 0 so always has a value
                $.bootstrapGrowl('Please insert all fields', {ele: 'body', type: 'error'});
                return;
            }
            serverGateway.ajax('createSlot',null,{slot: vm.newSlot,template_id: vm.detail.id}).then(function(response){
                $.bootstrapGrowl('Created', {ele: 'body', type: 'success'});
                PageViewModel.update();
                $('#createSlotModal').modal('hide');
                vm.detail = response.data.data;
                openDetailDialog(vm.detail);
            },function(){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function applyTopDelta() {
            if (vm.topDelta) {
                for (var i in vm.detail.slots) {
                    vm.detail.slots[i].top += vm.topDelta;
                }
            }
        }

        function applyLeftDelta() {
            if (vm.topDelta) {
                for (var i in vm.detail.slots) {
                    vm.detail.slots[i].left += vm.leftDelta;
                }
            }
        }
        
        function openBatchCreateSlotsModal(){
            vm.newBatchSlots = {
                top: 0,
                left: 0,
                topDelta: 0,
                leftDelta: 0,
                row: 1,
                column: 1,
                rotation: "0",
            };
            $('#batchCreateSlotsModal').modal('show');
        }

        function batchCreateSlots(){
            if(!(vm.newBatchSlots.slotType && (vm.newBatchSlots.rotation != undefined))){  //top and left is default 0 so always has a value
                $.bootstrapGrowl('Please insert all fields', {ele: 'body', type: 'error'});
                return;
            }
            serverGateway.ajax('batchCreateSlots',null,{slot: vm.newBatchSlots,template_id: vm.detail.id}).then(function(response){
                $.bootstrapGrowl('Created', {ele: 'body', type: 'success'});
                PageViewModel.update();
                $('#batchCreateSlotsModal').modal('hide');
                vm.detail = response.data.data;
                openDetailDialog(vm.detail);
            },function(){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function updateSearchTemplateName(){
            clearTimeout(vm.timer);
            vm.timer = setTimeout(function () {
                vm.gridDataSource.setRules(vm.searchTemplateName);
                PageViewModel.update();
                vm.gridDataSource.load();
            }, 500);
        }

        function syncFromParent(row){
            if(!confirm('Confirm?')) return;
            serverGateway.ajax('syncFromParent',{id: row.id}).then(function(response){
                $.bootstrapGrowl('Synced', {ele: 'body', type: 'success'});
                PageViewModel.update();
            },function(){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function distributeToChild(row){
            if(!confirm('Confirm?')) return;
            serverGateway.ajax('distributeToChild',{id: row.id}).then(function(response){
                $.bootstrapGrowl('Distributed', {ele: 'body', type: 'success'});
                PageViewModel.update();
            },function(){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }
    }

    function SlotTypeController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout) {

        var serverGateway, vm = this;

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'slot-type-info-update'  : { path: 'slot-type-info', method: 'PUT' },
                    'slot-type-info-clone'  : { path: 'slot-type-info/clone', method: 'POST' },
                    'slot-type-info-set-parent'  : { path: 'slot-type-info/set-parent', method: 'POST' },
                }
            });

            vm.updateSlotTypeInfo = updateSlotTypeInfo;
            vm.downloadPreview = downloadPreview;
            vm.cloneData = cloneData;
            vm.setParentSlotType = setParentSlotType;
            vm.openSetParentDialog = openSetParentDialog;
            vm.closeSetParentDialog = closeSetParentDialog;


            vm.slotTypes = config.slotTypes;
            vm.slotTypeInfos = config.slotTypeInfos;
            vm.supportedTemplates = config.supportedTemplates;
            vm.previewTemplate = config.supportedTemplates && config.supportedTemplates.length ? config.supportedTemplates[0] : null;
            vm.textFilter = '';
            vm.filterSlotType = filterSlotType;
            vm.batchLeft = '';
            vm.batchBottom = '';
            vm.batchUpdatePosition = batchUpdatePosition;
            vm.batchSlotTypeUpdateLoading = false;
            // setInterval(PageViewModel.update, 5000);

            filterSlotType();
        }

        function batchUpdatePosition(){
            if(!confirm('This will apply the changes to all visible records. Proceed?')) return;
            vm.batchSlotTypeUpdateLoading = true;
            var loopPromise = [];
            Object.entries(vm.slotTypeInfos).forEach(([key, value]) => {
                if(value.show == true){
                    var defered = $q.defer();
                    loopPromise.push(defered.promise);
                    serverGateway.ajax('slot-type-info-update', null, {
                        id: vm.slotTypeInfos[key].id, 
                        sharedTemplateOffsetLeft: vm.batchLeft, 
                        sharedTemplateOffsetBottom: vm.batchBottom
                    }).then(function (response){
                        defered.resolve();
                        vm.slotTypeInfos[key].updatedAt = response.data.updatedAt;
                        vm.slotTypeInfos[key].sharedTemplateOffsetLeft = vm.batchLeft;
                        vm.slotTypeInfos[key].sharedTemplateOffsetBottom = vm.batchBottom;
                        //$.bootstrapGrowl(vm.slotTypeInfos[key].id + ': Update completed', {ele: 'body', type: 'success'});
                    }, function (response){
                        defered.resolve();
                        $.bootstrapGrowl(vm.slotTypeInfos[key].id + ': ' + response.data.message, {ele: 'body', type: 'error'});
                    })
                }                   
            });
            $q.all(loopPromise).then(function(){
                $.bootstrapGrowl('Batch Update Completed!', {ele: 'body', type: 'success'});
                vm.batchSlotTypeUpdateLoading = false;
            });
        }


        function filterSlotType(){
            Object.entries(vm.slotTypeInfos).forEach(([key, value]) => {
                if(vm.batchGroupFilter == '' || value.rawMaterialPrintingBatchGroup.indexOf(vm.textFilter) !== -1 || value.rawMaterialDesc.indexOf(vm.textFilter) !== -1){
                    vm.slotTypeInfos[key].show = true;
                } else {
                    vm.slotTypeInfos[key].show = false;
                }
            });
        }

        function updateSlotTypeInfo($event, slotTypeInfo) {
            vm.slotTypeUpdateLoading = true;
            serverGateway.ajax('slot-type-info-update', null, {
                id: slotTypeInfo.id, 
                sharedTemplateOffsetLeft: slotTypeInfo.sharedTemplateOffsetLeft, 
                sharedTemplateOffsetBottom: slotTypeInfo.sharedTemplateOffsetBottom
            }).then(function (response) {
                vm.slotTypeUpdateLoading = false;
                slotTypeInfo.updatedAt = response.data.updatedAt;
                $.bootstrapGrowl('Update completed', {ele: 'body', type: 'success'});
            }, function (response) {
                vm.slotTypeUpdateLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            if($event){
                $event.preventDefault();
            }
            return false;
        }

        function downloadPreview($event, slotTypeInfo) {
            var date = new Date();
            var timestamp = date.getTime();
            let url = "/slot-type-info/"+slotTypeInfo.id+"/testLineFile?templateId="+vm.previewTemplate.id+"&hash="+timestamp;
            window.location.href = url;
            $event.preventDefault();
            return false;
        }

        function cloneData($event, slotTypeInfo, type, force){
            if(!slotTypeInfo.parent){
                return false;
            }
            console.log(slotTypeInfo.parent)
            if(typeof force != 'undefined' && force){
                if(!confirm('Replace by parent (id: '+slotTypeInfo.parent.id+') '+ type+ '?')){
                    return false;
                }
            }

            vm.slotTypeUpdateLoading = true;
            serverGateway.ajax('slot-type-info-clone', null, {
                id: slotTypeInfo.id, 
                parentSlotTypeId: slotTypeInfo.parent.id, 
                type: type,
                force: force,
                slotTypeInfo: slotTypeInfo
            }).then(function (response) {
                vm.slotTypeUpdateLoading = false;
                if(response && response.data.status == 'success'){
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'success'});
                } else {
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                }
            }, function (response) {
                vm.slotTypeUpdateLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            $event.preventDefault();
            return false;
        }

        function openSetParentDialog(row){
            vm.detail = row;
            $('#parentSlotTypeModal').modal('show');
        }

        function closeSetParentDialog(){
            $('.modal').each(function(){
                $(this).modal('hide');
            });
        }

        function setParentSlotType(){
            vm.slotTypeUpdateLoading = true;
            serverGateway.ajax('slot-type-info-set-parent', {id: vm.detail.id}, $.extend({}, vm.detail)).then(function(response) {
                vm.slotTypeUpdateLoading = false;
                // PageViewModel.update();
                PageViewModel.hideModal('#parentSlotTypeModal');
            }, function (response) {
                vm.slotTypeUpdateLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }
    }
    
    function TestPrintController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout) {
        var serverGateway = null;
        var vm = this;
        vm.data = config.data;

        initialize();

        function initialize() {
            $scope.$watch(function() {
                return JSON.stringify(vm.data);
            }, function(data) {
                data = JSON.parse(data);
                console.log(data)
                for (let i in data) {
                    let preset = data[i].preset;
                    let mode = data[i].mode;
                    let param = JSON.parse(data[i].param);
                    if (preset == 'preview') {
                        param.showSlot = true;
                        param.showPrintArea = true;
                    } else {
                        param.showSlot = false;
                        param.showPrintArea = false;
                    }
                    if (preset == 'alignPrintingArea') {
                        param.showPrintArea = true;
                        param.showAlignment = true;
                    } else {
                        param.showPrintArea = false;
                        param.showAlignment = false;
                    }
                    for (let j in param.slots) {
                        param.slots[j].showAlignment = true;
                        if (preset == 'alignPrintingArea') {
                            param.slots[j].showAlignment = false;
                        }
                        if (preset == 'alignSingle' && !data[i].selectedSlots[j]) {
                            param.slots[j].showAlignment = false;
                        }
                        if (preset == 'preview') {
                            param.slots[j].showSlot = true;
                            param.slots[j].showPrintArea = true;
                        } else {
                            param.slots[j].showSlot = false;
                            param.slots[j].showPrintArea = false;
                        }
                        if (preset == 'mirror') {
                            param.slots[j].showMirrorLine = true;
                            param.slots[j].showAlignment = false;
                        } else {
                            param.slots[j].showMirrorLine = false;
                        }
                    }
                    data[i].param = JSON.stringify(param, null, 4)
                }
                vm.data = data;
            });
            
        }
    }

    function datePicker($timeout) {
        return {
            restrict: 'A',
            scope: {
                ngModel : '=',
            },
            link: function(scope, element, attrs) {
                $(element).datetimepicker({
                    timepicker: false,
                    format: 'Y-m-d',
                    todayButton: false,
                    closeOnDateSelect: true,
                    scrollInput: false,
                    value: moment().format('YYYY-MM-DD'),
                    onChangeDateTime: function(dp, $input) {
                        $timeout(function() {
                            scope.ngModel = $input.val();
                        });
                    },
                    onShow: function() {
                        var self = this;
                        $timeout(function() {
                            self.setOptions({
                                value: $(element).val()
                            });
                        });
                    },
                });
            }
        };
    }

    function isNormalInteger(str) {
        var n = ~~Number(str);
        return String(n) === str && n >= 0;
    }

})();

(function() {
    'use strict';

    angular
        .module('grid')
        .directive('datetimePicker', datetimePicker);

    datetimePicker.$inject = ['$timeout'];

    function datetimePicker($timeout) {
        return {
            restrict: 'A',
            scope: {
                ngModel : '=',
            },
            link: function(scope, element, attrs) {
                $(element).datetimepicker({
                    timepicker: true,
                    format: 'Y-m-d H:i',
                    todayButton: false,
                    closeOnDateSelect: true,
                    scrollInput: false,
                    value: moment().format('YYYY-MM-DD HH:mm'),
                    onChangeDateTime: function(dp, $input) {
                        $timeout(function() {
                            scope.ngModel = $input.val();
                        });
                    },
                    onShow: function() {
                        var self = this;
                        $timeout(function() {
                            self.setOptions({
                                value: $(element).val()
                            });
                        });
                    },
                });
            }
        };
    }

})();

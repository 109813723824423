(function() {
    'use strict';

    angular
        .module('grid')
        .directive('ctgCellData', ctgCellData);

    ctgCellData.$inject = ['$compile', 'cellTypeMapper'];

    function ctgCellData($compile, cellTypeMapper) {
        var directive = {
            restrict: 'A',
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            var columnType = cellTypeMapper.map(scope.column);
            // console.log(columnType);
            var template = '<ctg-' + columnType + ' row="row" column="column">';
            var linkFn = $compile(template);
            var content = linkFn(scope);
            element.append(content);
        }
    }
})();
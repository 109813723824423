(function() {
    'use strict';

    angular
        .module('workOrder')
        .controller('WorkOrderController', Controller);

    Controller.$inject = ['GridDataSource', 'ServerGateway', '$q', 'config', '$timeout', '$scope', 'PageViewModel'];

    function Controller(GridDataSource, ServerGateway, $q, config, $timeout, $scope, PageViewModel) {
        var serverGateway = null;
        var vm = this;

        vm.detail = {}
        vm.showCreateModal = showCreateModal;
        vm.performCreate = performCreate;
        vm.performDelete = performDelete;
        vm.performShow = performShow;
        vm.performUpdate = performUpdate;
        vm.syncInventoryAction = syncInventoryAction;

        vm.types = [
            {key: 'pre_print', value: 'Pre-Print'},
            {key: 'retail_restock', value: 'Retail Restock'},
            {key: 'retail_vm', value: 'Retail VM'},
        ]

        var text = []
            
        initialize();

        function initialize() {
            // sever side gateway setup
            serverGateway = new ServerGateway({
                endPoints: {
                    'createWorkOrder': { path: 'work-orders/create', method: 'POST' },
                    'deleteWorkOrder': { path: 'work-orders/{id}', method: 'DELETE' },
                    'showWorkOrder': { path: 'work-orders/{id}', method: 'GET' },
                    'updateWorkOrder': { path: 'work-orders/{id}', method: 'POST' },
                    'syncInventoryAction': { path: 'inventory-actions/{id}/sync', method: 'POST' },
                }
            });

            vm.createBatchUrl = '/seal-batches/create/'+vm.scanType;
            if (config.defaultId) {
                vm.performShow(config.defaultId)
            }

            $('#modal-show,#modal-create')
                .on('hide.bs.modal', function() {
                    window.history.pushState(null, null, "/function/work-order");
                });
        }

        function showCreateModal() {
            PageViewModel.openModal('#modal-create');
        }   

        function performCreate() {
            if(!vm.detail.orderId){
                $.bootstrapGrowl('Please insert the orderId', {ele: 'body', type: 'error'});
                return;
            }
            if(!vm.detail.type){
                $.bootstrapGrowl('Please select type', {ele: 'body', type: 'error'});
                return;
            }
            if(!vm.detail.description){
                $.bootstrapGrowl('Please input description', {ele: 'body', type: 'error'});
                return;
            }
            serverGateway.ajax('createWorkOrder',{}, vm.detail).then(function(response){
                $.bootstrapGrowl('Work Order created', {ele: 'body', type: 'success'});
                window.location.reload();
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            })
        }

        function performDelete(id) {
            serverGateway.ajax('deleteWorkOrder',{id: id}).then(function(response){
                $.bootstrapGrowl('Work Order deleted', {ele: 'body', type: 'success'});
                window.location.reload();
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            })
        }

        function performShow(id) {
            serverGateway.ajax('showWorkOrder',{id: id}).then(function(response){
                vm.detail = response.data.data;
                PageViewModel.openModal('#modal-show');
                window.history.pushState(null, null, "/function/work-order/" + id);
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            })
        }

        function performUpdate() {
            if(!vm.detail.description){
                $.bootstrapGrowl('Please input description', {ele: 'body', type: 'error'});
                return;
            }
            serverGateway.ajax('updateWorkOrder',{id: vm.detail.id}, vm.detail).then(function(response){
                $.bootstrapGrowl('Work Order created', {ele: 'body', type: 'success'});
                window.location.reload();
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            })
        }

        function syncInventoryAction(id) {
            serverGateway.ajax('syncInventoryAction',{id: id}).then(function(response){
                $.bootstrapGrowl('Inventory action synced', {ele: 'body', type: 'success'});
                window.location.reload();
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            })
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('grid')
        .directive('ctgCellPOItems', ctgCellPOItems);

    function ctgCellPOItems() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: '/template/inventory/cellPOItems.html',
            controller: ['$scope', function($scope) {
                var vm = this;
                vm.moneyFormat = $scope.$parent.$parent.$parent.vm.moneyFormat;
                vm.totalAmount = totalAmount;

                function totalAmount(items) {
                    var amount = 0;
                    for (var idx in items) {
                        amount += items[idx].quantity * items[idx].unit_price;
                    }
                    return amount;
                }

                return vm;
            }],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;
    }
})();
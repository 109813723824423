(function() {
    'use strict';

    angular
        .module('grid')
        .directive('ctgCellDnSkuQuantity', ctgCellDnSkuQuantity);

    function ctgCellDnSkuQuantity() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: '/template/deliveryNote/cellDnSkuQuantity.html',
            controller: ['$scope', function($scope) {
                var vm = this;

                // console.log($('img', ));

                return vm;
            }],
            controllerAs: 'vm',
            bindToController: true,
            link: link
        };

        link.$inject = ['scope', 'element', 'attrs'];

        function link(scope, element, attrs) {

        }

        return directive;
    }
})();

(function() {
    'use strict';

    angular
        .module('stockTakeSession')
        .controller('StockTakeSessionController', Controller);

    Controller.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout', 'Popup'];
    

    function Controller($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, Popup) {
        var serverGateway, vm = this;

        vm.submit = submit;
        vm.deleteItem = deleteItem;
        vm.resetInventory = resetInventory;
        vm.queue = [];
        vm.counter = 1;

        var text = [], startTime, endTime, fnAddBarcode
        const maxKeyDeltaTime = 500;
        vm.quantity = 1;

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'submitScan'           : { path: 'inventory/stocktake-session/{id}/scan', method: 'POST' },
                    'deleteItem'           : { path: 'inventory/stocktake-session/items/{id}/delete', method: 'POST' },
                    'resetInventory'           : { path: 'inventory/{locationId}/reset', method: 'POST' },
                }
            });

            window.addEventListener('keydown', onKeydown, false)
        }

        function onKeydown(event) {
            if (event.target && event.target.nodeName == 'INPUT' && (event.target.type == 'text' || event.target.type == 'number')) {
                return;
            }

            startTime = startTime || new Date()
            endTime = new Date()
            let delta = endTime - startTime

            if (delta < maxKeyDeltaTime) {
                let char = String.fromCharCode(event.keyCode)
                text.push(char)
                fnAddBarcode && clearTimeout(fnAddBarcode)
                fnAddBarcode = setTimeout(() => {
                    vm.barcode = text.filter((char) => char.match(/^[a-z0-9]+$/i)).join('').trim();
                    startTime = null;
                    text = [];
                    $scope.$apply();
                    if (vm.barcode && vm.barcode != '') {
                        $('#form-submit-btn').click();
                    }
                    // if (isValidBarcode(text)) {
                    //     var trackingCode = text.join('')
                    //     var record = {
                    //         trackingCode: trackingCode,
                    //         status: 'Initialized',
                    //         mykey: Math.random()
                    //     }
                    //     vm.records.unshift(record)
                    //     $scope.$apply();
                    //     saveSealScan(record)
                    //     // barcodes.push(b)
                    //     // tbody.innerHTML = b.html + tbody.innerHTML
                    //     // b.animate()
                    //     // b.queryStatus()
                    //     //     .then(r => b.update(r))
                    // }
                }, maxKeyDeltaTime)
            } else {
                startTime = undefined
                text = []
            }
        }

        function submit(event) {
            var data = {};
            data.id = vm.counter;
            data.barcode = vm.barcode;
            data.quantity = vm.quantity;
            data.areaCode = vm.areaCode;
            data.status = 'Pending';
            vm.queue.unshift(data);
            vm.barcode = '';
            vm.counter++;
            event.preventDefault();
            event.stopPropagation();
            serverGateway.ajax('submitScan', {id: config.id}, data).then(function(response) {
                data.itemId = response.data.data.id;
                data.sku = response.data.data.finishedGood ? response.data.data.finishedGood.sku : response.data.data.rawMaterial.name;
                data.status = 'Done';
            }, function (response) {
                data.status = 'Error';
                data.message = response.data.message;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            return false;
        }

        function deleteItem(data, id) {
            if (!confirm('Confirm?')) {
                return;
            }
            serverGateway.ajax('deleteItem', {id: id}).then(function(response) {
                if (data) {
                    data.status = 'Deleted';
                } else {
                    $.bootstrapGrowl('Deleted', {ele: 'body', type: 'success'});
                }
                $('tr.delete-row[data-id='+id+']').each((element) => {
                    element.remove()
                })
            }, function (response) {
                if (data) {
                    data.status = 'Error';
                    data.message = response.data.message;
                }
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function resetInventory() {
            if (!confirm('Confirm to reset inventory to zero?')) {
                return;
            }
            serverGateway.ajax('resetInventory', {locationId: config.locationId}).then(function(response) {
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }
        
        initialize();
    }
})();

(function() {
    'use strict';

    angular
        .module('grid')
        .directive('ctgCellInventoryDataItems', ctgCellInventoryDataItems);

    function ctgCellInventoryDataItems() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: '/template/inventory/cellInventoryDataItems.html',
            controller: ['$scope', function($scope) {
                var vm = this;

                vm.showStockIn = function(row) {
                    $scope.$parent.$parent.$parent.vm.showStockIn(row);
                };

                vm.showStockOut = function(row) {
                    $scope.$parent.$parent.$parent.vm.showStockOut(row);
                };

                vm.showInoutRecord = function(row) {
                    $scope.$parent.$parent.$parent.vm.showInoutRecord(row);
                };

                vm.showUpdateQTY = function(row) {
                    $scope.$parent.$parent.$parent.vm.showUpdateQTY(row);
                };

                vm.showInventoryForecast = function(row) {
                    $scope.$parent.$parent.$parent.vm.showInventoryForecast(row);
                };

                vm.showPrintLabel = function(row) {
                    $scope.$parent.$parent.$parent.vm.showPrintLabel(row);
                };

                vm.showWarningLevel = function(row) {
                    $scope.$parent.$parent.$parent.vm.showWarningLevel(row);
                };

                vm.showStockTransfer = function(row) {
                    $scope.$parent.$parent.$parent.vm.showStockTransfer(row);
                };

                vm.canViewForecast = $scope.$parent.$parent.$parent.vm.canViewForecast;
                vm.canInout = $scope.$parent.$parent.$parent.vm.canInout;
                vm.canPrintLabel = $scope.$parent.$parent.$parent.vm.canPrintLabel;
                vm.canViewHistory = $scope.$parent.$parent.$parent.vm.canViewHistory;
                vm.canUpdateQTY = $scope.$parent.$parent.$parent.vm.canUpdateQTY;
                vm.canUpdateWarning = $scope.$parent.$parent.$parent.vm.canUpdateWarning;
                vm.canTransferStock = $scope.$parent.$parent.$parent.vm.canTransferStock;

                return vm;
            }],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;
    }
})();

(function() {
    'use strict';

    angular
        .module('transitionQueue')
        .controller('transitionQueueController', Controller);

    Controller.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout', '$interval'];

    function Controller($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, $interval) {
        var serverGateway, vm = this;

        // vm.submit      = submit;
        // vm.rows        = [];
        // vm.sorts       = [];
        // vm.columns     = config.columns;
        // vm.setFilter   = PageViewModel.setFilter;
        // vm.clearFilter = PageViewModel.clearFilter;
        // vm.sortChange  = PageViewModel.sortChange;
        // vm.isLoading   = PageViewModel._isLoading;
        vm.detail = {};
        vm.showDetail = showDetail;
        vm.queuePreviewMap = config.queuePreviewMap;

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                }
            });

            $(".transition-queue-row").click(function (event) {
                var id = $(event.currentTarget).attr('data-id');
                var queuePreviews = vm.queuePreviewMap[id];
                vm.detail.id = id;
                vm.detail.queuePreviews = queuePreviews;

                $("#queueDetailModal").modal("show");
            });
            // vm.gridDataSource = new GridDataSource({
            //     stationId: config.stationId,
            //     resoucesUrl: config.dataUrl,
            //     gateway: serverGateway
            // });

            // // config data source
            // var fields = [];
            // for (var i, c = config.columns.length; i < c; i++) {
            //     fields.push(config.columns[i].field);
            // }
            // vm.gridDataSource.setColumns(fields);

            // // refresh data periodically
            // PageViewModel.setGridDataSource(vm.gridDataSource);
            // $scope.$watch(function() {
            //     return PageViewModel.rows;
            // }, function(rows) {
            //     vm.rows = rows;
            //     // var found = false;
            //     // rows.forEach(function(row) {
            //     //     if (vm.detail && row.id == vm.detail.id) {
            //     //         found = true;
            //     //         vm.detail = row;
            //     //     }
            //     // });
            //     // if (!found) {
            //     //     vm.detail = null;
            //     // }
            // }, true);
            // $timeout(function() { PageViewModel.update(); }, 500);

            // PageViewModel.initializeQueryBuilder(config.filtersConfig);
            // PageViewModel.setFilterRules([{"id":"transition_queue_locations.description","field":"transition_queue_locations.description","type":"string","input":"text","operator":"in","value":config.initConfig.location.description}]);

            // $interval(function () {
            //     PageViewModel.update();
            // }, 5000);
        }

        function showDetail(queueId) {
            console.log('here');
        }

    }

    // function isNormalInteger(str) {
    //     var n = ~~Number(str);
    //     return String(n) === str && n >= 0;
    // }

})();

(function(jy, Ctg, $) {

    if (!jy) {
        throw 'Missing jitify';
    }
    if (!Ctg) {
        throw 'Missing Ctg.Core';
    }
    if (!Ctg.Select2) {
        throw 'Missing Ctg.Select2 library';
    }
    if (!$) {
        throw 'Missing jQuery';
    }

    Ctg.Select2Editable = jy.define({
        extend: 'Ctg.Select2',

        statics: {
            default: {
                clear: $.noop,
                allowClear: false,
                change: $.noop
            }
        },

        constructor: function(selector, options) {
            options = $.extend({}, Ctg.Select2Editable.default, options);
            var self = this;
            if (!options.get || !options.create || !options.remove) {
                throw 'must include callbacks clear, get, create and remove. ' + 
                    'Those callbacks should return $.Deffered object';
            }

            this.get = options.get;
            this.shouldCreate = options.shouldCreate;
            this.create = options.create;
            this.remove = options.remove;

            this.callParent(selector, {
                placeholder: 'Select an filter',
                allowClear: options.allowClear,
                clear: options.clear,
                templateRow: function(field) {
                    return self._templateRow(field);
                },
                noResults: this._noResults,
                data: options.data,
                change: function() {
                    options.change.call(self, self.val());
                    self._change();
                }
            });

            this._$el.on('select2:reselect', function(e) {
                self._change();
            });
        },

        _templateRow: function(field) {
            var self = this;
            var $field = $('<span></span>')
                .text(field.text)
                .data('ctg-select2-editable.id', field.id)
                .append('<button class="cross-btn pull-right">×</button>')
                .find('.cross-btn')
                .on('mouseup', function() {
                    self.showLoading('Removing...');
                    self.close();
                    self.remove($(this).parent().data('ctg-select2-editable.id')).done(function() {
                        self.endLoading();
                    });

                    return false;
                })
                .end();
            return $field;
        },

        _noResults: function() {
            var self = this;
            var $dropdown = $('body').children('.select2-container');
            var name = $dropdown.find('.select2-search__field').val();
            $dropdown.find('.select2-results__option')
                .text('"' + name + '" (create new)')
                .addClass('create-new')
                .click(function() {
                    if (self.shouldCreate) {
                        self.showLoading('Creating...');
                        self.create(name).done(function() {
                            self.endLoading();
                        });
                    } else {

                    }
                    
                });
        },

        _change: function() {
            var self = this;
            var value = this.val();
            if (value === null) return;

            this.showLoading('Loading...');
            this.get(value).done(function() {
                self.endLoading();
            });
        }
    });
})(jy, Ctg, jQuery);
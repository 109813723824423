(function() {
    'use strict';

    angular
        .module('grid')
        .factory('GridDataSource', GridDataSource);

    GridDataSource.$inject = ['$q'];

    function GridDataSource($q) {

        var GridDataSource = jy.define({
            extend: 'jy.PBase',

            constructor: function(options) {
                this.sort = options.sort || null;
                this.perPage = options.perPage || 10;
                this.forceStopLoadNext = false;
                this.columns = [];
                this.rules = {};
                this.sorts = [];
                this.loadedCount = 0;
                this.isLoading = false;

                this._stationId = options.stationId;
                this._orderId = options.orderId;
                this._locationId = options.locationId;
                this._resoucesUrl = options.resoucesUrl || '';
                this._gateway = options.gateway || '';
            },

            setSorts: function(value) {
                this.sorts = value;
            },

            setColumns: function(value) {
                this.columns = value;
            },

            setRules: function(value) {
                this.rules = value;
            },

            // this is a temp solution, pagination may not be preserved
            setResourceUrl: function(value) {
                this._resoucesUrl = value;
            },

            hasNext: function() {
                if (this.forceStopLoadNext) return false;
                return !!this.nextPageUrl;
            },

            update: function() {
                // this.nextPageUrl = null;
                var loadedCount = this.loadedCount;
                // this.loadedCount = 0;

                loadedCount = Math.ceil(loadedCount / this.perPage) * this.perPage

                return this._load(this._gateway.createUrl(this._resoucesUrl), Math.max(loadedCount, this.perPage), true);
            },

            load: function() {
                this.nextPageUrl = null;
                this.loadedCount = 0;

                return this._load(this._gateway.createUrl(this._resoucesUrl), this.perPage);
            },

            loadNext: function() {
                return this._load(this.nextPageUrl, this.perPage);
            },

            _load: function(url, perPage, isReload) {
                if (this.isLoading || typeof url === 'undefined' || !perPage) return $q.defer().promise;

                var self = this;

                this.isLoading = true;

                return this._gateway._ajax(url, 'GET', {
                    perPage: perPage,
                    stationId: self._stationId,
                    locationId: self._locationId,
                    orderId: self._orderId,
                    rules: self.rules,
                    sorts: self.sorts
                }).then(function(response) {
                    self.isLoading = false;
                    if ((!self.loadedCount || !isReload) && response.data.meta) {
                        self.nextPageUrl = response.data.meta.pagination.links.next;
                        self.loadedCount += response.data.meta.pagination.count;
                    }

                    return response.data;
                }, function () {
                    self.isLoading = false;
                });
            },
        });

        return GridDataSource;
    }
})();

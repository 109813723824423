(function() {
    'use strict';

    angular
        .module('grid')
        .factory('cellTypeMapper', cellTypeMapper);

    function cellTypeMapper() {
        var defaultCellType = 'cell-string';
        var map = {
            'string'                   : 'cell-string',
            'address'                  : 'cell-address',
            'batch-items'              : 'cell-batch-items',
            'print-bot-items'              : 'cell-print-bot-items',
            'order-items'              : 'cell-order-items',
            'po-data-items'            : 'cell-p-o-data-items',
            'po-items'                 : 'cell-p-o-items',
            'img'                      : 'cell-img',
            'marketing-input-products' : 'cell-marketing-input-products',
            'production-item'          : 'cell-production-item',
            'datetime'                 : 'cell-datetime',
            'string-sanitize'          : 'cell-string-sanitize',
            'deliveries'               : 'cell-deliveries',
            'problems'                 : 'cell-problems',
            'inventory-data-items'     : 'cell-inventory-data-items',
            'inventory-inout-total'  : 'cell-inventory-inout-total',
            'inventory-inout-summary'  : 'cell-inventory-inout-summary',
            'inventory-inout-allocated'  : 'cell-inventory-inout-allocated',
            'delivery-status'          : 'cell-delivery-status',
            'delivery-order-date'          : 'cell-delivery-order-date',
            'dn-sku-quantity'          : 'cell-dn-sku-quantity',
            'printing-template-action' : 'cell-printing-template-action',
            'printing-template-slot-action'    : 'cell-printing-template-slot-action',
            'production-item-special-handling' : 'cell-production-item-special-handling',
            'production-item-buckets'          : 'cell-production-item-buckets',
            'delivery-special-handling'        : 'cell-delivery-special-handling',
            'delivery-order-id'                : 'cell-delivery-order-id',
            'transition-queue-item'            : 'cell-transition-queue-item',
            'transition-queue-item-buckets'    : 'cell-transition-queue-item-buckets',
            'transition-queue-item-location'   : 'cell-transition-queue-item-location',
            'booking-request-item'   : 'cell-booking-request-item',
            'order-date'   : 'cell-order-date',
            'check-box'    : 'cell-check-box',
            'sku-fg': 'cell-sku-fg',
            'sku-fg-desc': 'cell-sku-fg-desc'
        };

        var service = {
            map: mapper
        };

        return service;

        function mapper(key) {
            return map[key.type] ? map[key.type] : defaultCellType;
        }
    }
})();

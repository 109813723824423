(function() {
    'use strict';

    angular
        .module('grid')
        .directive('ctgCellOrderDate', ctgCellOrderDate);

    function ctgCellOrderDate() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: '/template/order/cellOrderDate.html?v=1',
            controller: ['$scope', function($scope) {
                var vm = this;

                vm.openDetailDialog = function(row) {
                    $scope.$parent.$parent.$parent.vm.openDetailDialog(row);
                };

                return vm;
            }],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;
    }
})();
(function() {
    'use strict';

    angular
        .module('addressValidation')
        .controller('AddressValidationController', Controller)
        .controller('AddressValidationEmailController',EmailController);

    Controller.$inject = ['GridDataSource', 'ServerGateway', '$q', 'config', '$timeout'];
    EmailController.$inject = ['$scope','GridDataSource', 'ServerGateway','$q','config','PageViewModel','$timeout','$interval'];

    function Controller(GridDataSource, ServerGateway, $q, config, $timeout) {
        var pendingSort, serverGateway, lastDetail = null;
        var vm = this;

        initialize();

        vm.getBatchLocal = getBatchLocal;
        vm.isLoading  = false;
        vm.rowsLocal  = {};
        vm.rows       = [];
        vm.columns    = config.columns;
        vm.sorts      = [];
        vm.setFilter   = setFilter;
        vm.clearFilter = clearFilter;
        vm.isLoading   = _isLoading;
        vm.sortChange = sortChange;
        vm.generateLabel = generateLabel;
        vm.editAddress = editAddress;
        vm.editFilter = editFilter;
        vm.updateFilter = updateFilter;
        vm.uploadAction = uploadAction;
        vm.bulkUpdateAction = bulkUpdateAction;
        vm.selectAddress = selectAddress;
        vm.saveEditAddress = saveEditAddress;
        vm.confirmAddress = confirmAddress;
        vm.saveAddressUpdateNote = saveAddressUpdateNote;
        vm.saveAction = saveAction;
        vm.address = null;
        vm.input      = {};
        vm.countryList = config.countryList;
        vm.applyFilter = applyFilter;
        vm.exportData = exportData;

        // detail related
        vm.detailId         = null;
        vm.detail           = null;
        vm.openDetailDialog = openDetailDialog;


        // detai footer
        vm.indexOfBatch   = indexOfBatch;
        vm.detailNext     = detailNext;
        vm.detailPrevious = detailPrevious;

        // TODO: remove it later debug use
        vm.update = update;

        // Batch Send Email
        vm.openEmailModal = openEmailModal;
        vm.getEmailTemplate = getEmailTemplate;
        vm.sendEmail = sendEmail;
        vm.targetDeliveryIds = '';
        vm.targetOrderIds = '';
        vm.emailType = ['1','2','3'];
        vm.targetEmail = {
            from: 'hello@casetify.com',
            subStatus: 3
        };
        vm.isEmailLoading = false;
        vm.errorMsg = '';

        //Special ID
        vm.identificationType = {'mask_import_permit':'Mask Import Permit','vat':'VAT/TAX ID/ConsigneeEIN', 'tin':'TIN / NPWP'};
        vm.deleteSpecialId = deleteSpecialId;
        vm.addNewSpecialId = addNewSpecialId;


        function setDetail(batchItem) {
            vm.detailId = (batchItem === null ? null : batchItem.id);
            vm.detail = batchItem;
            if (vm.detail) {
                vm.currentAddressUpdateNote = vm.detail.addressUpdateNote;
            }

            // vm.input = {};
            // if(batchItem && batchItem.orderItems.data) {
            //     for(var idx in batchItem.orderItems.data)
            //         if(typeof batchItem.orderItems.data[idx].inventoryItem !== 'undefined') {
            //             var obj        = {};
            //             obj.serialCode = batchItem.orderItems.data[idx].inventoryItem.data.serial_number;
            //             obj.scanning   = false;
            //             obj.scanned    = false;
            //             if(typeof obj.serialCode == 'string' && obj.serialCode.length > 0)
            //                 obj.scanned = true;

            //             vm.input[batchItem.orderItems.data[idx].id] = obj;
            //         }
            // }
        }

        function initialize() {
            // sever side gateway setup
            serverGateway = new ServerGateway({
                endPoints: {
                    'findDelivery': { path: 'deliveries/{id}', method: 'GET' },
                    'generateLabel': { path: 'deliveries/{id}/generateLabel', method: 'POST' },
                    'saveAddress': { path: 'deliveries/{id}/saveAddress', method: 'POST' },
                    'confirmAddress': { path: 'deliveries/{id}/confirmAddress', method: 'POST' },
                    'saveAddressUpdateNote': { path: 'deliveries/{id}/saveAddressUpdateNote', method: 'POST' },
                    'saveAddressAction': { path: 'deliveries/{id}/saveAddressAction', method: 'POST' },
                    'bulkSaveAddressAction': { path: 'deliveries/bulkSaveAddressAction', method: 'POST' },
                    'updateAddressValidationRule': { path: 'shipping/updateAddressValidationRule', method: 'POST' },
                    'getEmailDetails': { path: 'shipping/getEmailDetails', method: 'GET'},
                    'addressValidationEmailType' : { path: 'shipping/address-validation-email-type', method: 'GET'},
                    'getEmailTemplate' : { path: 'shipping/get-email-template', method: 'GET'},
                    'sendAddressValidationEmail' : { path: 'shipping/send-address-validation-email', method: 'POST'}
                }
            });
            vm.gridDataSource = new GridDataSource({
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway,
                perPage: 30
            });
            vm.actionFilter = config.actionFilter;
            vm.typeFilter = config.typeFilter;

            // config data source
            var fields = [];
            for (var i, c = config.columns.lenght; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // load the first batch of data
            load();

            // refresh data periodically
            setInterval(update, 10000);
            // console.log(config.filtersConfig);

            // $('#builder-basic').queryBuilder({

            //   plugins: ['bt-tooltip-errors'],

            //   filters: Ctg.makeFiltersConfig(config.filtersConfig),

            // });

            // detail modal setup
            $('#printingBatchModal')
                .modal({
                    show: false
                })
                .on('hide.bs.modal', function() {
                    console.log('#printingBatchModal is hidden');
                    setDetail(null);
                });

            // $timeout(function () {
            //     $('#builder-basic').queryBuilder('setRules',{"condition":"AND","rules":[{"condition":"OR","rules":[{"id":"deliveries.address_valid","field":"deliveries.address_valid","type":"boolean","input":"radio","operator":"equal","value":"false"},{"id":"deliveries.shipment_valid","field":"deliveries.shipment_valid","type":"boolean","input":"radio","operator":"equal","value":"false"}]},{"id":"deliveries.label_generated","field":"deliveries.label_generated","type":"boolean","input":"radio","operator":"equal","value":"false"}]});
            //     setFilter();
            // }, 500);
        }

        function deleteSpecialId(id){
            vm.address.identifications.splice(id,1);
        }

        function addNewSpecialId(){
            if(vm.address.identifications == null){
                vm.address.identifications = [];
            }
            vm.address.identifications.push(
                {
                    'type': '',
                    'value': '',
                }
            );
        }

        function openEmailModal(){ 
            if($('.ctg-checkbox:checked').length <= 0){
                $.bootstrapGrowl('Please select a record first',{ele: 'body', type: 'error'});
                return;
            }
            //Get Data
            vm.targetDeliveryIds = [];
            $('.ctg-checkbox:checked').each(function(i,e){
                vm.targetDeliveryIds.push(e.value);  
            });
            serverGateway.ajax('getEmailDetails',null,{delivery_ids:vm.targetDeliveryIds}).then(function(response){
                vm.targetOrderIds = response.data.rtn.ref_id;
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            vm.targetDeliveryIds = vm.targetDeliveryIds.join(',');
            serverGateway.ajax('addressValidationEmailType').then(function(response){
                console.log(response.data.types);
                vm.emailType = response.data.types;
                vm.targetEmail.type = vm.emailType[0];
                vm.targetEmail.subStatus = '3';
                vm.targetEmail.forceLang = false;
                getEmailTemplate();
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            $('#sendEmailModal').modal('show');
        }

        function getEmailTemplate(){
            vm.isEmailLoading = true;
            vm.errorMsg = '';
            serverGateway.ajax('getEmailTemplate',null,{type: vm.targetEmail.type,lang: vm.targetEmail.lang}).then(function(response){
                var template = response.data.template;
                if(template){
                    if(!vm.targetEmail.lang && (vm.targetEmail.subject != template.subject)){
                        vm.targetEmail.lang = template.lang;
                    }
                    vm.targetEmail.subject = template.subject;
                    vm.targetEmail.content = template.content;
                    vm.errorMsg = '';
                } else {
                    vm.errorMsg = 'No template found in that language';
                }
                vm.isEmailLoading = false;
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.isEmailLoading = false;
            });
        }

        function sendEmail(){
            if(!confirm('Are you sure?')) return;
            vm.isEmailLoading = true;
            serverGateway.ajax('sendAddressValidationEmail',null,{template: vm.targetEmail,deliveries: vm.targetDeliveryIds}).then(function(response){
                $.bootstrapGrowl('Email Sent', {ele: 'body', type: 'success'});
                vm.isEmailLoading = false;
                $('.ctg-checkbox:checked').each(function(i,e){
                    $(e).prop('checked',false); 
                });
                $('#sendEmailModal').modal('hide');
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.isEmailLoading = false;
            });
        }

        function setFilter() {
            var result = $('#builder-basic').queryBuilder('getRules');
            if (!$.isEmptyObject(result)) {
                vm.isLoading = true;
                vm.gridDataSource.setRules(result);
                $timeout(function() { load(); }, 500);
            }
        }

        function clearFilter() {
            $('#builder-basic').queryBuilder('reset');
            vm.gridDataSource.setRules({});
            vm.isLoading = true;
            $timeout(function() { load(); }, 500);
        }

        function sortChange(sorts) {
            console.log('sortChange', arguments);

            vm.gridDataSource.prop('sorts', sorts);
            load();
        }

        function _isLoading() {
            return vm.isLoading;
        }

        function indexOfBatch(batchItemId) {
            var index = -1;
            vm.rows.forEach(function(row, i) {
                if (batchItemId === row.id) {
                    index = i;
                    return false;
                }
            });

            return index;
        }

        function update(callback) {
            return vm.gridDataSource
                .update()
                .then(function(response) {
                    var detailBatch = null;
                    vm.rows.splice(0, vm.rows.length);
                    response.data.forEach(function(datum) {
                        if (vm.detailId === datum.id) {
                            detailBatch = datum;
                        }
                        vm.rows.push(datum);
                    });

                    // handle detail dialog update
                    if (vm.detailId !== null) {
                        if (detailBatch === null) {
                            // handle may be removed case
                            // TODO: change it with deliveries endpoint
                            serverGateway.ajax('findDelivery', {id: vm.detailId}).then(function(response) {
                                if (response.data === '') {
                                    vm.detail = null;
                                } else {
                                    vm.detail = response.data.data;
                                }
                            });
                        } else {
                            vm.detail = detailBatch;
                        }
                    }

                    updateLoadMoreBlock();
                    if (callback) {
                        callback(response);
                    }
                });
        }

        function reloadDetail(batchId) {
            if (batchId !== vm.detailId) {
                return $q(function(resolve) {
                    resolve();
                });
            }

            return serverGateway.ajax('findDelivery', {id: batchId}).then(function(response) {
                if (response.data === '') {
                    vm.detail = null;
                    return;
                }

                var index = indexOfBatch(batchId);
                if (index === -1) return;

                vm.rows[index] = vm.detail;
            });
        }

        function load() {
            console.log('load');

            vm.isLoading = true;

            return vm.gridDataSource
                .load()
                .then(function(response) {
                    console.log('load callback');
                    vm.rows = response.data;
                    vm.isLoading = false;

                    updateLoadMoreBlock();
                });
        }

        function loadNext() {
            console.log('loadNext');

            return vm.gridDataSource
                .loadNext()
                .then(function(response) {
                    console.log('loadNext callback');
                    vm.rows = vm.rows.concat(response.data);
                    updateLoadMoreBlock();
                });
        }

        function updateLoadMoreBlock() {
            if (vm.gridDataSource.hasNext()) {
                setTimeout(enableLoadMore, 10);
            } else {
                disableLoadMore();
            }
        }

        function enableLoadMore() {
            var waypoint = $('#rowLoading')
                .removeClass('hidden')
                .waypoint(function(event, direction) {
                    console.log('enableLoadMore: loadNextRows');
                    waypoint[0].destroy('remove');
                    loadNext();
                }, {
                    offset: '100%'
                });
        }

        function getBatchLocal(batchId) {
            return vm.rowsLocal[batchId] = vm.rowsLocal[batchId] ? vm.rowsLocal[batchId] : {};
        }

        function disableLoadMore() {
            $('#rowLoading').addClass('hidden');
        }

        function openDetailDialog(row, columns) {
            setDetail(row);
            $('#printingBatchModal').modal('show');
        }

        function detailNext() {
            var index = indexOfBatch(vm.detailId);
            if (index === -1) {
                // TODO: show error message
                return;
            }

            if (index === vm.rows.length - 1) {
                loadNext().then(function() {
                    setDetail(vm.rows[index + 1]);
                });
            } else {
                setDetail(vm.rows[index + 1]);
            }
        }

        function detailPrevious() {
            var index = indexOfBatch(vm.detailId);
            if (index === -1) {
                // TODO: show error message
                return;
            }
            setDetail(vm.rows[index - 1]);

        }

        function generateLabel(delivery) {
            vm.validateButtonLoading = true;
            vm.generateLabelLoading = true;
            serverGateway.ajax('generateLabel', {id: delivery.id})
            .then(function(response) {
                vm.validateButtonLoading = false;
                vm.generateLabelLoading = false;
                if(response.data) {
                    update();
                }
            }, function (response) {
                vm.validateButtonLoading = false;
                vm.generateLabelLoading = false;
                $.bootstrapGrowl('Cannot generate label. ' + response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function editAddress(delivery) {
            let focusingOn = $(':focus');
            if(focusingOn.hasClass('ctg-checkbox')){
                return;
            }
            setDetail(delivery);
            $("#editAddressModal").modal('show');
            vm.address = JSON.parse(JSON.stringify(delivery.address));
        }

        function editFilter() {
            $("#editFilterModal").modal('show');
        }

        function updateFilter() {
            var ruleObj = {
                id: document.querySelector('#tfm-id').value,
                source: document.querySelector('#tfm-source').value,
                category: document.querySelector('#tfm-category').value,
                operator: document.querySelector('#tfm-operator').value,
                value: document.querySelector('#tfm-value').value,
                active: document.querySelector('#tfm-active').checked
            };
            if (ruleObj.category == "" || ruleObj.value == "") return alert('Empty values found.')
            if (ruleObj.id !== "New Rules" && !Number(ruleObj.id)) return alert('Invalid ID.')
            serverGateway.ajax('updateAddressValidationRule', {}, {ruleObj: ruleObj})
            .then(
                function(response) {
                    var updatedRule = response.data.data;
                    typeFilterTable.updateOrAddData([updatedRule]);
                    alert('Rule updated');
                    document.querySelector('#tfm-value').value = "";
                    document.querySelector('#tfm-id').value = "Double Click To Create";
                },
                function(response) { alert('Failed to update'); console.log(response.data.message); })
        }

        function uploadAction() {
            $("#uploadActionModal").modal('show');
        }

        function selectAddress(address) {
            vm.address = JSON.parse(JSON.stringify(address));
        }

        function saveEditAddress(delivery, address) {
            if (delivery.address.parent_address_id != address.parent_address_id) {
                $.bootstrapGrowl('Inconsistence state, please reload', {ele: 'body', type: 'error'});
                return
            }
            vm.validateButtonLoading = true;
            vm.addressValidationLoading = true;
            serverGateway.ajax('saveAddress', {id: delivery.id}, {name: address.name, street1: address.street1, street2: address.street2, city: address.city, state: address.state, zip: address.zip, country_code: address.country_code.code, phone: address.phone, email: address.email, pccc: address.pccc, identifications: address.identifications})
            .then(function(response) {
                vm.validateButtonLoading = false;
                vm.addressValidationLoading = false;
                if(response.data) {
                    selectAddress(response.data.data);
                    if (!response.data.data.warning) {
                        update();
                        generateLabel(delivery);
                        // $("#editAddressModal").modal('hide');
                    } else {
                        $("#confirm-address").modal('show');
                    }
                }
            }, function (response) {
                vm.validateButtonLoading = false;
                vm.addressValidationLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            
        }

        function confirmAddress(delivery, address) {
            if (delivery.address.parent_address_id != address.parent_address_id) {
                $.bootstrapGrowl('Inconsistence state, please reload', {ele: 'body', type: 'error'});
                return
            }
            vm.confirmAddressButtonLoading = true;
            serverGateway.ajax('confirmAddress', {id: delivery.id}, {addressId: address.id})
            .then(function(response) {
                vm.confirmAddressButtonLoading = false;
                if(response.data) {
                    selectAddress(response.data.data);
                    update();
                    $("#confirm-address").modal('hide');
                    generateLabel(delivery);
                    // $("#editAddressModal").modal('hide');
                }
            }, function (response) {
                vm.confirmAddressButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            
        }

        function saveAddressUpdateNote(delivery) {
            serverGateway.ajax('saveAddressUpdateNote', {id: delivery.id}, {note: vm.currentAddressUpdateNote})
            .then(function(response) {
                update();
                $.bootstrapGrowl('Saved', {ele: 'body', type: 'success'});
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            
        }

        function saveAction() {
            serverGateway.ajax('saveAddressAction', {id: vm.detail.id}, {action: vm.detail.addressAction})
            .then(function(response) {
                update();
                $.bootstrapGrowl('Saved', {ele: 'body', type: 'success'});
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function bulkUpdateAction() {
            var actionList = JSON.parse(document.querySelector('#upload-btn').dataset.actionList)
            serverGateway.ajax('bulkSaveAddressAction', {}, {updateItems: actionList})
            .then(function(response) {
	        var updatedIds = response.data.orderIds
                var errors = response.data.errors
                updatedIds.forEach(function (updatedId) { document.querySelector(`#action-node-${updatedId}`) && document.querySelector(`#action-node-${updatedId}`).classList.add('success') })
                errors.forEach(function (error) { document.querySelector(`#action-node-${error[0]}`) && document.querySelector(`#action-node-${error[0]}`).classList.add('error') })
                alert('Update complete')
	    }, function (response) { $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'}) })
        }

        function applyFilter() {
            window.location.href = "/function/address-validation/" + vm.actionFilter + "/" + vm.typeFilter;
            return false;
        }

        function exportToCsv(filename, rows) {
            var processRow = function (row) {
                var finalVal = '';
                for (var j = 0; j < row.length; j++) {
                    var innerValue = row[j] === null ? '' : row[j].toString();
                    if (row[j] instanceof Date) {
                        innerValue = row[j].toLocaleString();
                    };
                    var result = innerValue.replace(/"/g, '""');
                    if (result.search(/("|,|\n)/g) >= 0)
                        result = '"' + result + '"';
                    if (j > 0)
                        finalVal += ',';
                    finalVal += result;
                }
                return finalVal + '\n';
            };

            var csvFile = '';
            for (var i = 0; i < rows.length; i++) {
                csvFile += processRow(rows[i]);
            }

            var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, filename);
            } else {
                var link = document.createElement("a");
                if (link.download !== undefined) { // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", filename);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        }

        function exportData() {
            console.log(vm.rows)
            let out = vm.rows.map((row) => {
                let ret = [];
                ret.push('')
                ret.push(row.id)
                ret.push(row.order.refId)
                ret.push(row.address.name)
                ret.push(row.address.email)
                ret.push(row.address.street1)
                ret.push(row.address.street2)
                ret.push(row.address.city)
                ret.push(row.address.state)
                ret.push(row.address.zip)
                ret.push(row.address.country_code.name)
                ret.push(row.address.pccc)
                ret.push(row.address.text)
                ret.push(row.shipments.map((shipment) => shipment.errors.join(', ')).join(', '))
                ret.push((row.address.warning ? JSON.stringify(row.address.warning) : ''))
                ret.push(row.shippingMethod);
                return ret
            })
            out.unshift(['action(E|M)', 'delivery id', 'order id', 'name', 'email', 'street1', 'street2', 'city', 'state', 'zip', 'country_code', 'pccc', 'address', 'error', 'warning', 'courier'])
            exportToCsv('export.csv', out)
        }
    }

    function EmailController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, $interval) {
        var serverGateway = null;
        var vm = this;

        initialize();

        vm.rows = [];
        vm.sorts = [];
        vm.columns = config.columns;
        vm.setFilter   = PageViewModel.setFilter;
        vm.clearFilter = PageViewModel.clearFilter;
        vm.sortChange  = PageViewModel.sortChange;
        vm.isLoading   = false;
        vm.editAddress = editAddress;
        vm.updateAddress = updateAddress;
        vm.deleteAddress = deleteAddress;
        vm.targetEmail = {};
        vm.langList = '';
        vm.mailTypeList = [];
        
        function initialize(){
            serverGateway = new ServerGateway({
                endPoints: {
                    'addressValidationEmailType' : {path: 'shipping/address-validation-error-type', method: 'GET'},
                    'createOrUpdateEmailTemplate' : {path: 'shipping/createOrUpdateEmailTemplate', method: 'POST'},
                    'deleteEmailTemplate' : {path: 'shipping/deleteEmailTemplate', method: 'DELETE'},
                },
            });

            vm.gridDataSource = new GridDataSource({
                resoucesUrl : config.dataUrl,
                gateway     : serverGateway,
                perPage: 999
            });

            var fields = [];
                for(var i = 0, c = config.columns.length; i < c; i++){
                    fields.push(config.columns[i].field);
                }
                vm.gridDataSource.setColumns(fields);

                PageViewModel.setGridDataSource(vm.gridDataSource);
                $scope.$watch(function(){
                    return PageViewModel.rows;
                }, function(rows) {
                    vm.rows = rows;
                }, true);

                $timeout(function(){PageViewModel.update();}, 500);

                $interval(function () {
                    PageViewModel.update();
                }, 5000);
        }

        function editAddress(row = null){
            if(row){
                vm.targetEmail = {
                    id: row.id,
                    type: row.type,
                    subject: row.subject,
                    content: row.content,
                    lang: row.lang
                };
            } else {
                vm.targetEmail = {};
            }
            serverGateway.ajax('addressValidationEmailType').then(function(response){
                vm.mailTypeList = response.data.types;
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            $('#templateModal').modal('show');
        }

        function updateAddress(){
            if(!vm.targetEmail.type){
                $.bootstrapGrowl('Please select a error type', {ele: 'body', type: 'error'});
                return;
            }
            serverGateway.ajax('createOrUpdateEmailTemplate',null,{mail: vm.targetEmail}).then(function(response){
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                $('#templateModal').modal('hide');
                PageViewModel.update();
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function deleteAddress(){
            if(!vm.targetEmail){
                $.bootstrapGrowl('You need to select an existing template', {ele: 'body', type: 'error'});
                return;
            }
            if(!confirm('Are you sure?')){ return; }
            serverGateway.ajax('deleteEmailTemplate',null,{mail: vm.targetEmail}).then(function(response){
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                $('#templateModal').modal('hide');
                PageViewModel.update();
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }
    }
})();

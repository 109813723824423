(function() {
    'use strict';

    angular
        .module('dhlGlobalMail')
        .controller('DHLGlobalMailController', Controller);

    Controller.$inject = ['GridDataSource', 'ServerGateway', '$q', 'config', '$timeout'];

    function Controller(GridDataSource, ServerGateway, $q, config, $timeout) {
        var serverGateway = null;
        var vm = this;
        
        vm.toggleCheckAll = toggleCheckAll;
        vm.generateDatFile = generateDatFile;
        vm.importShippingLabels = importShippingLabels;

        initialize();

        function initialize() {
            // sever side gateway setup
            serverGateway = new ServerGateway({
                endPoints: {
                    //'findDelivery': { path: 'deliveries/{id}', method: 'GET' },
                }
            });
            
            $('.box-primary').css('min-height', ($(window).height() - 200) + 'px');
        }
        
        function toggleCheckAll() {
            $('.dataTable tbody input[type="checkbox"]').prop('checked', $('.dataTable thead input[type="checkbox"]').prop('checked'));
        }
        
        function generateDatFile() {
            $('#loadingOverlay').fadeIn();
            var formdata = new FormData($('#generateDatForm').get(0));
            $.ajax({
                url: '/function/dhl-global/generate',
                type: 'post',
                data: formdata,
                processData: false,
                contentType: false,
                dataType: 'json',
                complete: function () {
                    $('#loadingOverlay').fadeOut();
                },
                success: function (res) {
                    $.bootstrapGrowl('DAT file generated.', {ele: 'body', type: 'success'});
                    var $downloadForm = $('<form action="/function/dhl-global/download" method="post"></form>');
                    $downloadForm.append('<input type="hidden" name="_token" value="' + $('#generateDatForm input[name="_token"]').val() + '">');
                    $downloadForm.append('<input type="hidden" name="hash" value="' + res.hash + '">');
                    $downloadForm.appendTo('body');
                    $downloadForm.trigger('submit');
                    $downloadForm.remove();
                    console.log(res);
                },
                error: function (xhr) {
                    try {
                        var res = $.parseJSON(xhr.responseText);
                        if (res.hasOwnProperty('error')) {
                            $.bootstrapGrowl(res.error, {ele: 'body', type: 'error'});
                        }
                    } catch (e) { }
                    console.log(xhr);
                }
            });
        }
        
        function importShippingLabels() {
            var $file = $('<input type="file" accept="application/pdf, application/x-pdf" style="display: none;">');
            $file.appendTo('body').trigger('click');
            $file.change(function () {
                var file = this.files[0];
                
                var formdata = new FormData();
                formdata.append('pdf', file, file.name);
                
                $('#loadingOverlay').fadeIn();
                $.ajax({
                    url: '/function/dhl-global/import',
                    type: 'post',
                    data: formdata,
                    processData: false,
                    contentType: false,
                    dataType: 'json',
                    complete: function () {
                        $file.remove();
                        $('#loadingOverlay').fadeOut();
                    },
                    success: function (res) {
                        $.bootstrapGrowl('PDF file received. The import process will take about ' + res.estimatedTime + '. You will receive an email when it completes.', {ele: 'body', type: 'success'});
                        console.log(res);
                    },
                    error: function (xhr) {
                        try {
                            var res = $.parseJSON(xhr.responseText);
                            if (res.hasOwnProperty('error')) {
                                $.bootstrapGrowl(res.error, {ele: 'body', type: 'error'});
                            }
                        } catch (e) { }
                        console.log(xhr);
                    }
                });
            });
        }
    }
})();
(function() {

var root = this, // Establish the root object, window in the browser, or exports on the server.
    previousJY = root.jy, // Save the previous value of the jy variable.
    jy = function() {}; // Create a safe reference to the Jitify object for use below.

// Export the Jitify object for Node.js, with backwards-compatibility for the old require() API.
// If we’re in the browser, add jy as a global object via a string identifier, for Closure Compiler “advanced” mode.
if ( typeof exports !== 'undefined' ) {
    if ( typeof module !== 'undefined' && module.exports ) {
        exports = module.exports = jy;
    }
    exports.jy = jy;
} else {
    root.jy = jy;
}

// Current Version
jy.VERSION = '1.0.0';
jy.rootNamespace = window;

function isObject( value ) {
    return !!( value && typeof value === 'object' || typeof value === 'function' );
}

function isFunction( value ) {
 var getType = {};
 return value && getType.toString.call(value) === '[object Function]';
}

jy.Base = (function() {
    function Base() {
        // empty implementation
    }

    Base.__super__ = {};

    var baseProto = Base.prototype;

    baseProto.callParent = function() {
        var parentClass, owner, methodName,
            method = this.callParent.caller;

        if ( method.caller && method.caller.__mixin__ ) {
            method = method.caller;
        }

        owner = method.__owner__;
        methodName = method.__name__;

        if ( !owner ) {
            throw 'Parent class not found';
        }

        parentClass = owner.__super__;

        if ( !( methodName in parentClass ) ) {
            throw 'Method not found';
        }
        return parentClass[ methodName ].apply( this, arguments );
    };

    return Base;
})();

jy.resolveVariable = function( path, base ) {
    if ( typeof path !== 'string' ) {
        throw 'Invalid path';
    }

    var namespace = typeof base !== 'undefined' ? base : jy.rootNamespace,
        tokens = path.split( '.' ),
        i = 0,
        c = tokens.length;
    for ( ; i < c; i++ ) {
        namespace = namespace[ tokens[ i ] ];
        if ( !isObject( namespace ) && i !== c - 1 ) {
            throw 'Fail to resolve ' + tokens.slice( 0, i + 1 ).join('.' );
        }
    }

    return namespace;
};

jy.define = (function() {
    function addStatics( theClass, statics ) {
        if ( !statics ) {
            return;
        }
        for ( var key in statics ) {
            if ( statics.hasOwnProperty( key ) ) {
                theClass[ key ] = statics[ key ];
            }
        }
    }

    function addMixins( theClass, mixins ) {
        if ( !mixins ) {
            return;
        }

        var key, mixinCopy, mixinObj, methodName,
            classProto = theClass.prototype;
        for ( key in mixins ) {
            if ( mixins.hasOwnProperty( key ) ) {
                // Create object of mixin class and copy all methods to the target class
                mixinObj = new mixins[ key ]();
                for ( methodName in mixinObj ) {
                    // TODO: need to test inheritance method
                    if ( typeof mixinObj[ methodName ] !== 'function' || methodName === 'constructor' ) {
                        continue;
                    }
                    classProto[ methodName ] = mixinCopy = (function( method ) {
                        return function() {
                            return method.apply( this, arguments );
                        };
                    })( mixinObj[ methodName ] );
                    mixinCopy.__mixin__ = true;
                    mixinCopy.__owner__ = theClass;
                    mixinCopy.__name__ = methodName;
                }
            }
        }
    }

    function define( className, properties ) {
        if ( isObject( className ) ) {
            properties = className;
            className = null;
        } else if ( typeof className !== 'string' || className === '' ) {
            throw 'Invalid class name';
        }

        properties = isObject( properties ) ? properties : {};

        // Save and remove all config property from properties
        var superclass, theClass, key, property, classProto, mixinClass,
            tokens, token, i, c,
            isSingleton = properties.isSingleton,
            namespace = jy.rootNamespace,
            mixinsConfig = {},
            statics     = properties.statics,
            extend      = properties.extend,
            mixins      = properties.mixins,
            constructor = properties.hasOwnProperty( 'constructor' ) ? properties.constructor : undefined;

        delete properties.isSingleton;
        delete properties.extend;
        delete properties.constructor;
        delete properties.mixins;

        if ( typeof constructor !== 'function' && typeof constructor !== 'undefined' ) {
            throw 'constructor must be a function';
        }

        // Validate mixins
        if ( !isObject( mixins ) && typeof mixins !== 'undefined' ) {
        } else if ( isObject( mixins ) ) {
            for ( key in mixins ) {
                if ( mixins.hasOwnProperty( key ) ) {
                    // mixin with class name
                    if ( typeof mixins[ key ] !== 'string' ) {
                        throw 'Invalid mixins config';
                    }

                    // Get the mixin class
                    mixinClass = jy.resolveVariable( mixins[ key ] );
                    if ( !mixinClass || typeof mixinClass !== 'function' ) {
                        throw 'Mixin ' + mixins[ key ] + ' is invalid class';
                    }
                    mixinsConfig[ key ] = mixinClass;
                }
            }
        }

        // Validate statics
        if ( !isObject( statics ) && typeof statics !== 'undefined' ) {
            throw 'statics must be a object';
        }

        // Resolve and validate superclass
        if ( extend ) {
            superclass = isFunction( extend ) ? extend : jy.resolveVariable( extend );
            if ( superclass === null || typeof( superclass ) !== 'function' ) {
                throw 'Invalid super class';
            }
        } else {
            superclass = jy.Base;
        }

        if ( className ) {
            tokens = className.split( '.' );
            // Create path transition objects
            for ( i = 0, c = tokens.length; i < c; i++ ) {
                token = tokens[ i ];
                if ( i === c - 1 ) {
                    break;
                }
                if ( typeof namespace[ token ] === 'undefined' ) {
                    namespace[ token ] = {};
                } else if ( !isObject( namespace[ token ] ) ) {
                    throw tokens.slice( 0, i + 1 ).join('.' ) + ' already contain non-object value ' + namespace[ token ];
                }
                namespace = namespace[ token ];
            }
            if ( typeof namespace[ token ] !== 'undefined' ) {
                throw className + ' is already defined';
            }
        }

        // Build constructor
        if ( constructor ) {
            theClass = constructor;
        } else if ( superclass ) {
            theClass = function() {
                return superclass.apply( this, arguments );
            };
        } else {
            theClass = function() {};
        }

        theClass.__name__ = 'constructor';
        theClass.__owner__ = theClass;
        if ( token ) {
            namespace[ token ] = theClass;
        }

        // Extend superclass
        if ( superclass ) {
            _extend( theClass, superclass );
        }

        classProto = theClass.prototype;
        classProto.__jyclass__ = className;

        addMixins( theClass, mixinsConfig );

        // Add instance methods
        for ( key in properties ) {
            if ( properties.hasOwnProperty( key ) ) {
                property = properties[ key ];
                classProto[ key ] = property;

                // Add meta data for method
                if ( typeof property === 'function' ) {
                    property.__owner__ = theClass;
                    property.__name__ = key;
                }
            }
        }

        if ( isSingleton && token ) {
            theClass = namespace[ token ] = new theClass();
        }

        // Add static methods
        addStatics( theClass, statics );

        return theClass;
    }

    return define;
})();

function _extend( child, parent ) {
    function Surrogate () {
        this.constructor = child;
    }
    Surrogate.prototype = parent.prototype;
    child.prototype = new Surrogate();
    child.__super__ = parent.prototype;
    return child;
}

jy.noConflict = function() {
    window.jy = previousJY;
    return this;
};

})();
(function(Ctg, $) {
    Ctg.AddressValidator = jy.define({
        extend: 'jy.PBase',

        constructor: function(selector, options) {
            this._$el = $(selector);
            this._idCounter = 0;
            if (this._$el.length === 0) {
                throw 'No element is selected';
            }

            this._attachEventHandlers();
        },

        getFullAddress: function(addressData) {
            return addressData.street1 + ', ' + addressData.street2 + ', ' +
                addressData.city + ', ' + addressData.state + ', ' + addressData.zip + ', ' + addressData.country;
        },

        _renderHeader: function(id) {
            this._$el.find('.badge-id').text(id);
        },

        _renderOriginalAddress: function(addressData) {
            this._$el
                .find('.badge-original-address')
                .find('.badge-street1')
                    .text(addressData.street1)
                    .end()
                .find('.badge-street2')
                    .text(addressData.street2)
                    .end()
                .find('.badge-city')
                    .text(addressData.city)
                    .end()
                .find('.badge-state')
                    .text(addressData.state)
                    .end()
                .find('.badge-zip')
                    .text(addressData.zip)
                    .end()
                .find('.badge-country')
                    .text(addressData.country)
                    .end();;
        },

        _renderSuggestAddresses: function(addressesData) {
            var $addressList = this._$el.find('.badge-suggest-address-list');
            for (var i = 0, c = addressesData.length; i < c; i++) {
                var $row = this._renderSuggestAddress(addressesData[i]);
                $addressList.append($row);
            }
        },

        _renderSuggestAddress: function(addressData) {
            this._idCounter++;
            var elId = 'address-validator-' + this._idCounter;
            return this._$el
                .find('.badge-suggest-address-template')
                .clone()
                .removeClass('badge-suggest-address-template template')
                .data('address-validator.address', addressData)
                .find('.badge-full-address')
                    .text(this.getFullAddress(addressData))
                    .end()
                .find('.badge-expand')
                    .attr('href', '#' + elId)
                    .end()
                .find('.badge-expand-content')
                    .prop('id', elId)
                    .end()
                .find('.badge-street1')
                    .text(addressData.street1)
                    .end()
                .find('.badge-street2')
                    .text(addressData.street2)
                    .end()
                .find('.badge-city')
                    .text(addressData.city)
                    .end()
                .find('.badge-state')
                    .text(addressData.state)
                    .end()
                .find('.badge-zip')
                    .text(addressData.zip)
                    .end()
                .find('.badge-country')
                    .text(addressData.country)
                    .end();
            
        },

        copyAddressToEdit: function(addressData) {
            this._$el
                .find('.badge-correct-street1')
                    .val(addressData.street1)
                    .end()
                .find('.badge-correct-street2')
                    .val(addressData.street2)
                    .end()
                .find('.badge-correct-city')
                    .val(addressData.city)
                    .end()
                .find('.badge-correct-state')
                    .val(addressData.state)
                    .end()
                .find('.badge-correct-zip')
                    .val(addressData.zip)
                    .end()
                .find('.badge-correct-country')
                    .val(addressData.country)
                    .end();
        },

        setShipment: function(shipment) {
            this.shipment = shipment;
            this._renderHeader(shipment.id);
            this._renderOriginalAddress(shipment.original);
            this._renderSuggestAddresses(shipment.suggestion);
        },

        showLoadingMask: function() {
            this._$el.find('.badge-loading-mask').removeClass('hidden');
        },

        hideLoadingMask: function() {
            this._$el.find('.badge-loading-mask').addClass('hidden');
        },

        _attachEventHandlers: function() {
            var self = this;
            this._$el.on('click', '.badge-full-address', function() {
                self.copyAddressToEdit($(this).closest('.badge-suggest-address').data('address-validator.address'));
            });

            this._$el
                .find('.badge-next')
                    .on('click', function() {
                        setTimeout(function() {
                            self.showLoadingMask();
                        }, 100);
                    })
                    .end()
                .find('.badge-skip')
                    .on('click', function() {

                    })
                    .end()
        }

    })

})(Ctg, jQuery);
(function() {
    'use strict';

    angular
        .module('grid')
        .directive('ctgCellTransitionQueueItemBuckets', ctgCellTransitionQueueItemBuckets);

    function ctgCellTransitionQueueItemBuckets() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: '/template/transitionQueue/ctgCellTransitionQueueItemBuckets.html'
        };

        return directive;
    }
})();
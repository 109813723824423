(function() {
    'use strict';

    angular
        .module('print')
        .controller('PrintController', Controller);

    Controller.$inject = ['$scope','GridDataSource', 'ServerGateway', '$q', 'config', '$timeout', 'FileUploader', '$http'];

    function Controller($scope, GridDataSource, ServerGateway, $q, config, $timeout, FileUploader, $http) {
        var pendingSort, serverGateway;
        var vm = this;
      
        $scope.rejectImageFileUploader = function() {   
            var data = new FormData();
            data.append('uploadimg', $('#uploadimg')[0].files[0]);
            data.append('problem', $('#fproblem').val());
            data.append('remark', $('#fremark').val());
            data.append('trash', $('#ftrash').val());
            data.append('remarkOther', $('#fremarkOther').val());
            data.append('description', $('#fdescription').val());

            $('#rejectReasonDialog').modal('hide'); 

            jQuery.ajax({
                url: '/api/printing-batch-items/' + vm.rejectItem.id + '/rejectItemUpload',
                type:'post',
                data: data,
                contentType: false,
                processData: false,
                headers: {
                    Authorization: 'Basic ' + localStorage.getItem('api-auth-token'),
                },
                success: function(response) {
                    //console.log(response);
                    reloadDetail(vm.detail.id).then(function() {
                        // batch.detailButtonLoading = null;
                    });
                },
                error: function(jqXHR, textStatus, errorMessage) {
                    alert('Error uploading: ' + errorMessage);
                }
            });
            return false;   
        };

        initialize();

        vm.isLoading = false;
        vm.rows = [];
        vm.columns = config.columns;
        vm.sorts = [];
        vm.sortChange = sortChange;
        vm.shouldShowHide = false;
        vm.toggleShouldShowHide = toggleShouldShowHide;
        vm.stationName = config.stationName;
        vm.workingMode = config.workingMode;
        vm.packStationList = config.packStationList;
        vm.station = (typeof config.packStationList != 'undefined') ? config.packStationList[0].key : null;

        // detail related
        vm.detailId = null;
        vm.detail = null;
        vm.openDetailDialog = openDetailDialog;
        vm.detailRawMaterials = detailRawMaterials;
        vm.detailSubRawMaterials = detailSubRawMaterials;
        vm.hasSubRawMaterials = hasSubRawMaterials;
        vm.download = download;
        vm.downloadDescription = downloadDescription;
        vm.showQAHistory = showQAHistory;
        vm.assignToQueueWithCode = assignToQueueWithCode;
        vm.bulkAssignToQueueWithCode = bulkAssignToQueueWithCode;
        vm.associateTransitionBatchCode = associateTransitionBatchCode;
        vm.clearTransitionBatch = clearTransitionBatch;
        vm.removeFromQueue = removeFromQueue;
        vm.start = start;
        vm.done = done;
        vm.hide = hide;
        vm.dispatch = dispatch;
        vm.reject = reject;
        vm.saveReject = saveReject;
        vm.remark = null;
        vm.rejectHandling = null;
        vm.rejectItem = null;
        vm.queueCodeCache = [];
        vm.queueCodeCache2 = [];
        vm.serialNumberCache = [];
        vm.addSerialNumber = addSerialNumber;
        vm.serialNumberLoading = false;
        vm.optimizeStation = optimizeStation;
        vm.clearDownloadCache = clearDownloadCache;
        vm.isSimple = isSimple;
        vm.pregenerateStation = pregenerateStation;
        vm.requestMaterial = requestMaterial;
        vm.isRequestMaterialButtonLoading = isRequestMaterialButtonLoading;
        vm.requestMaterialButtonLoading = {};
        vm.printerSent = printerSent;
        vm.printerUnsent = printerUnsent;
        vm.resetRoboticWorkgroup = resetRoboticWorkgroup;
        vm.resetRoboticWorkgroupLoading = false;
        vm.VaildateInput = VaildateInput;

        // detai footer
        vm.indexOfBatch = indexOfBatch;
        vm.detailNext = detailNext;
        vm.detailPrevious = detailPrevious; // Deprecated, remove it later
        vm.saveAcceptanceTest = saveAcceptanceTest;
        vm.canSaveAcceptance = canSaveAcceptance;
        vm.acceptAll = acceptAll;
        vm.rejectAll = rejectAll;
        vm.regenerate = regenerate;
        vm.showPreprintSummary = showPreprintSummary;
        vm.preprintSave = preprintSave;

        //Fine Adjust
        vm.openFineAdjustModal = openFineAdjustModal;
        vm.getFinalPostion = getFinalPostion;
        vm.updateFineAdjust = updateFineAdjust;
        $scope.fineAdjust = [];
        $scope.finalTop = [];
        $scope.finalLeft = [];
        $scope.$watch('fineAdjust',function(newVal,oldVal){
            Object.entries(newVal).forEach(([key,val]) => {
                $scope.finalTop[key] = Math.round((val.originalTop + val.fineAdjustTop) * 100) / 100;
                $scope.finalLeft[key] = Math.round((val.originalLeft + val.fineAdjustLeft) * 100) / 100;
            });
        },true);

        // shipping label
        vm.printLabelButtonLoading = false;
        vm.openPrintLabelModal = openPrintLabelModal;
        vm.generateLabels = generateLabels;
        vm.printLabels = printLabels;
        vm.printLabelUrls = null;
        vm.selectedPrintStation = null;

        vm.setSlotTypeSetting = setSlotTypeSetting;

        vm.downloadYamatoSkuCsv = downloadYamatoSkuCsv;

        // TODO: remove it later debug use
        vm.update = update;

        vm.showPrintingDescription = localStorage.getItem('showPrintingDescription');
        vm.setShowPrintingDescription = setShowPrintingDescription;
        vm.showAssembly = localStorage.getItem('showAssembly') == 'true';
        vm.changeShowAssembly = changeShowAssembly;

        vm.receivedStockTransitRequest = receivedStockTransitRequest;

        function toggleShouldShowHide(shouldShowHide) {
            vm.shouldShowHide = shouldShowHide;
        }

        function setDetail(batchItem) {
            vm.detailId = (batchItem === null ? null : batchItem.id);
            vm.detail = batchItem;
        }

        function initialize() {
            // sever side gateway setup
            serverGateway = new ServerGateway({
                endPoints: {
                    'findPrintBatch': { path: config.dataUrl + '/{id}', method: 'GET', data:{stationId: config.stationId} },
                    // 'download': { path: 'printing-batches/{id}/printingFile', method: 'GET' },
                    'clearCache': { path: 'printing-batches/{id}/clearCache', method: 'POST' },
                    'start': { path: 'printing-batches/{id}/startPrinting', method: 'PUT' },
                    'done': { path: 'printing-batches/{id}/finishPrinting', method: 'PUT' },
                    'hide': { path: 'printing-batches/{id}/hidePrinting', method: 'PUT' },
                    'dispatch': { path: 'printing-batches/{id}/dispatchPrinting', method: 'PUT' },
                    
                    // 'acceptPrinting': { path: 'production-items/{id}/printingAccept', method: 'PUT' },
                    'rejectPrinting': { path: 'printing-batch-items/{id}/rejectItem', method: 'PUT' },
                    'regenerate': { path: 'printing-batches/{id}/regenerate', method: 'PUT' },
                    'assignToQueueWithCode': { path: 'transition-queue/assignWithCode', method: 'POST' },
                    'bulkAssignToQueueWithCode': { path: 'transition-queue/bulkAssignWithCode', method: 'POST' },
                    'associateTransitionBatchCode': { path: 'transition-queue/associateTransitionBatchCode', method: 'POST' },
                    'clearTransitionBatch': { path: 'transition-queue/clearTransitionBatch', method: 'POST' },
                    'removeFromQueue': { path: 'transition-queue/removeFromQueue', method: 'POST' },
                    'optimizeStation': { path: 'printingStation/{id}/optimize', method: 'POST' },
                    'pregenerateStation': { path: 'printingStation/{id}/pregenerate', method: 'POST' },
                    'savePreprintItem': { path: 'order-items/{id}/savePreprint', method: 'POST' },
                    'qaRejectList': {path: 'qaRejectList', method: 'GET'},
                    'getFineAdjust': {path: 'printing-batches/{id}/getFineAdjust', method: 'GET'},
                    'updateFineAdjust': {path: 'printing-batches/updateFineAdjust', method: 'POST'},
                    'requestMaterial': {path: 'printing-batches/{id}/requestMaterial', method: 'POST'},
                    'setSentToPrinter': {path: 'printing-batches/{id}/setSentToPrinter', method: 'POST'},
                    'getShippingLabels': {path: 'printing-batches/{id}/getShippingLabels', method: 'POST'},
                    'generateLabels': {path: 'printing-batches/{id}/generateLabels', method: 'POST'},
                    'printLabels': {path: 'printing-batches/{id}/printLabels', method: 'POST'},
                    'syncSerialNumber': { path: 'order-items/{id}/serial-number',  method: 'POST' },
                    'resetRoboticWorkgroup': { path: 'robotic/workgroup/{id}/reset-workgroup',  method: 'POST' },
                    'received-transit-request': { path: 'inventory/transit-requests/{id}/received', method: 'POST' },
                }
            });

            //$scope.rejectImageFileUploader.url = "";

            vm.gridDataSource = new GridDataSource({
                perPage: config.perPage ? config.perPage : 2,
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                orderId: config.orderId,
                gateway: serverGateway
            });

            vm.gridDataSource.setRules(
                JSON.stringify({
                    sku: config.sku
                })
            );
            
            // config data source
            var fields = [];
            for (var i, c = config.columns.lenght; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // load the first batch of data
            load();

            // refresh data periodically
            setInterval(update, 20000);

            // detail modal setup
            $('#printingBatchModal')
                .modal({
                    show: false
                })
                .on('hide.bs.modal', function() {
                    console.log('#printingBatchModal is hidden');
                    setDetail(null);
                });

            // $('#reject-save-btn').click(saveReject);

        }

        function sortChange(sorts) {
            console.log('sortChange', arguments);

            vm.gridDataSource.prop('sorts', sorts);
            load();
        }

        function indexOfBatch(batchItemId) {
            var index = -1;
            vm.rows.forEach(function(row, i) {
                if (batchItemId === row.id) {
                    index = i;
                    return false;
                }
            });

            return index;
        }

        function keepLocal(localBatch, batch) {
            if(typeof localBatch === 'undefined') return;
            batch.detailButtonLoading = localBatch.detailButtonLoading;
            var itemMap = {};
            localBatch.items.forEach(function(item) {
                itemMap[item.productionItem.id] = item.productionItem;
            });

            batch.items.forEach(function(item) {
                var localItem = itemMap[item.productionItem.id];
                if (localItem) {
                    item.productionItem.acceptance = localItem.acceptance;
                    item.productionItem.rejectReason = localItem.rejectReason;
                }
            });
        }

        function update() {
            var rowMap = {};
            vm.rows.forEach(function(row) {
                rowMap[row.id] = row;
            });

            return vm.gridDataSource
                .update()
                .then(function(response) {
                    if (response) {
                        var detailBatch = null;
                        vm.rows.splice(0, vm.rows.length);
                        response.data.forEach(function(datum) {
                            datum = parseBatchDetail(datum);
                            if (vm.detailId === datum.id) {
                                detailBatch = datum;
                            }
                            keepLocal(rowMap[datum.id], datum);
                            vm.rows.push(datum);
                        });


                        // handle detail dialog update
                        if (vm.detailId !== null) {
                            if (detailBatch === null) {
                                // handle may be removed case
                                serverGateway.ajax('findPrintBatch', {id: vm.detailId}).then(function(response) {
                                    if (response.data === '') {
                                        vm.detail = null;
                                    } else {
                                        response.data.data = parseBatchDetail(response.data.data);
                                        keepLocal(rowMap[response.data.data.id], response.data.data);
                                        if(!_.isEqual(vm.detail, response.data.data)) vm.detail = response.data.data;
                                    }
                                });
                            } else {
                                vm.detail = detailBatch;
                            }
                        }

                        updateLoadMoreBlock();
                    }

                });
        }

        function parseBatchDetail(datum) {
            var queueTypeDetails = [];
            var queueTypes = {};
            var shortQueueTypes = {};
            var bucketProductionItems = {};
            var queueGroupDetails = {};
            var queueTypeGroupsDetailProgress = {};
            datum.items.forEach(function (item, index) {
                if (!vm.queueCodeCache[item.productionItem.id]) {
                    vm.queueCodeCache[item.productionItem.id] = item.productionItem.transitionQueueCode;
                }
                // if (!item.productionItem.transitionQueueItem) {
                //     buckets[-1] = ['Deleted'];
                //     if (!bucketProductionItems[-1]) {
                //         bucketProductionItems[-1] = [];
                //     }
                //     bucketProductionItems[-1].push(item);
                //     return;
                // }
                if (item.productionItem.deletedAt || item.productionItem.hasActiveQALog || !item.isLatest) {
                    if (!bucketProductionItems[-1]) {
                        bucketProductionItems[-1] = [];
                        queueTypes[-1] = -1;
                        queueTypeDetails.push(-1);
                    }
                    bucketProductionItems[-1].push(item);
                    return;
                }
                // buckets[item.productionItem.transitionQueueItem.bucket.id] = item.productionItem.transitionQueueItem.bucket;
                if (!bucketProductionItems[item.productionItem.queueTypeDetail]) {
                    bucketProductionItems[item.productionItem.queueTypeDetail] = [];
                    queueTypeDetails.push(item.productionItem.queueTypeDetail);
                    queueTypes[item.productionItem.queueTypeDetail] = item.productionItem.queueType;
                    shortQueueTypes[item.productionItem.queueTypeDetail] = item.productionItem.shortQueueType;
                    queueGroupDetails[item.productionItem.queueTypeDetail] = item.productionItem.queueTypeGroupsDetail;
                    queueTypeGroupsDetailProgress[item.productionItem.queueTypeDetail] = item.productionItem.queueTypeGroupsDetailProgress;
                }
                bucketProductionItems[item.productionItem.queueTypeDetail].push(item);
            });
            queueTypeDetails.sort(function (a, b) {
                if (a == -1 || !a || a == 'null' || a == null) {
                    return 1;
                }
                if (b == -1 || !b || b == 'null' || b == null) {
                    return -1;
                }

                if (a.startsWith('Complex')) {
                    return 1;
                }
                if (b.startsWith('Complex')) {
                    return -1;
                }
                return a > b;
            });
            for (var queueTypeDetail in bucketProductionItems) {
                var batchItems = bucketProductionItems[queueTypeDetail];
                batchItems.sort(function (a, b) {
                    if (a.productionItem.transitionQueueItem && !b.productionItem.transitionQueueItem) {
                        return -1;
                    }
                    if (!a.productionItem.transitionQueueItem && b.productionItem.transitionQueueItem) {
                        return 1;
                    }
                    if (a.productionItem.transitionQueueItem && b.productionItem.transitionQueueItem && a.productionItem.transitionQueueItem.position < b.productionItem.transitionQueueItem.position) {
                        return -1
                    } else if (a.productionItem.transitionQueueItem && b.productionItem.transitionQueueItem && a.productionItem.transitionQueueItem.position > b.productionItem.transitionQueueItem.position) {
                        return 1;
                    } else if (a.id < b.id) {
                        return -1;
                    } else if (a.id > b.id) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            }
            datum.queueTypeDetails = queueTypeDetails;
            datum.queueTypes = queueTypes;
            datum.shortQueueTypes = shortQueueTypes;
            datum.bucketProductionItems = bucketProductionItems;
            datum.queueGroupDetails = queueGroupDetails;
            datum.queueTypeGroupsDetailProgress = queueTypeGroupsDetailProgress;
            return datum;
        }

        function reloadDetail(batchId) {
            if (batchId !== vm.detailId) {
                return $q(function(resolve) {
                    resolve();
                });
            }

            return serverGateway.ajax('findPrintBatch', {id: batchId}).then(function(response) {
                if (response.data === '') {
                    vm.detail = null;
                    return;
                }
                response.data.data = parseBatchDetail(response.data.data);
                vm.detail = response.data.data;
                var index = indexOfBatch(batchId);
                if (index === -1) return;

                vm.rows[index] = vm.detail;
            });
        }

        function load() {
            console.log('load');

            vm.isLoading = true;

            return vm.gridDataSource
                .load()
                .then(function(response) {
                    if (response) {
                        console.log('load callback');
                        response.data.forEach(function(datum) {
                            datum = parseBatchDetail(datum);
                        });
                        vm.rows = response.data;
                        vm.isLoading = false;
                        if (config.defaultId) {
                            let defaultRow = vm.rows.find((row) => row.id == config.defaultId);
                            if (defaultRow) {
                                vm.openDetailDialog(defaultRow);

                                if(config.fn && config.fn == 'print-labels'){
                                    vm.openPrintLabelModal(config.defaultId);
                                }
                            }
                        }
                        updateLoadMoreBlock();
                    }
                });
        }

        function loadNext() {
            console.log('loadNext');

            return vm.gridDataSource
                .loadNext()
                .then(function(response) {
                    if (response) {
                        console.log('loadNext callback');
                        vm.rows = vm.rows.concat(response.data);
                        updateLoadMoreBlock();
                    }
                });
        }

        function updateLoadMoreBlock() {
            if (vm.gridDataSource.hasNext()) {
                setTimeout(enableLoadMore, 10);
            } else {
                disableLoadMore();
            }
        }

        function enableLoadMore() {
            var waypoint = $('#rowLoading')
                .removeClass('hidden')
                .waypoint(function(event, direction) {
                    console.log('enableLoadMore: loadNextRows');
                    waypoint[0].destroy('remove');
                    loadNext();
                }, {
                    offset: '100%'
                });
        }

        function disableLoadMore() {
            $('#rowLoading').addClass('hidden');
        }

        function openDetailDialog(row, columns) {
            setDetail(row);
            $('#printingBatchModal').modal('show');
            if (row.status == 'printed' || row.status == 'completed') {
                $('a[href="#qa"]').tab('show');
                vm.shouldShowHide = true;
            } else {
                if (vm.detail.stationType == 'hotstamp' || vm.detail.stationType == 'charms' || vm.detail.stationType == 'string') {
                    $('a[href="#qa"]').tab('show');
                } else {
                    $('a[href="#prepare"]').tab('show');
                }
                
                vm.shouldShowHide = false;
            }

        }

        function detailRawMaterials() {
            if (vm.detail === null) return [];

            var materialMap = {};
            vm.detail.items.forEach(function(item) {
                var material = item.productionItem.rawMaterial;
                var theMaterial = materialMap[material.name];
                if (typeof theMaterial === 'undefined') {
                    theMaterial = materialMap[material.name] = material;
                    theMaterial.qty = 0;
                }

                theMaterial.qty++;
            });

            return materialMap;
        }

        function detailSubRawMaterials() {
            if (vm.detail === null) return [];

            var materialMap = {};
            vm.detail.items.forEach(function(item) {
                const materials = item.productionItem.subRawMaterials;
                materials.forEach(material => {
                    var theMaterial = materialMap[material.name];
                    if (typeof theMaterial === 'undefined') {
                        theMaterial = materialMap[material.name] = material;
                        theMaterial.qty = 0;
                    }
                    theMaterial.qty++;
                })
            });

            return materialMap;
        }

        function hasSubRawMaterials(){
            return Object.keys(vm.detailSubRawMaterials()).length > 0;
        }

        function clearDownloadCache() {
            vm.detail.clearCacheButtonLoading = false;
            serverGateway.ajax('clearCache', {id: vm.detail.id}).then(function(response) {
                vm.clearCacheButtonLoading = false;
                $.bootstrapGrowl('Cleared', {ele: 'body', type: 'success'});
            }, function (response) {
                vm.clearCacheButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });

        }

        function download(exportType) {
            var batch = vm.detail;
            batch.detailButtonLoading = 'download';
            if (exportType) {
                window.location = vm.detail.printingFile.replace("/printingFile", "/printingFile/" + exportType);
            } else {
                window.location = vm.detail.printingFile;
            }
            
            batch.detailButtonLoading = null;
        }

        function start() {
            var batch = vm.detail;
            batch.detailButtonLoading = 'start';

            serverGateway
                .ajax('start', {id: batch.id})
                .then(function(response) {
                    // if (true) {
                        reloadDetail(batch.id).then(function() {
                            batch.detailButtonLoading = null;
                        });
                    // }
                    $('a[href="#qa"]').tab('show');
                    // console.log(response);
                });
        }

        function done() {
            var batch = vm.detail;
            batch.detailButtonLoading = 'done';

            serverGateway
                .ajax('done', {id: batch.id})
                .then(function(response) {

                    reloadDetail(batch.id).then(function() {
                        batch.detailButtonLoading = null;
                    });
                    $('a[href="#qa"]').tab('show');
                    
                    // console.log(response);
                    // $('#printingBatchModal').modal('hide');
                    // vm.detail = null;
                }, function(response){
                    batch.detailButtonLoading = null;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
        }

        function hide() {
            var batch = vm.detail;
            batch.detailButtonLoading = 'hide';

            serverGateway
                .ajax('hide', {id: batch.id})
                .then(function(response) {

                    // if (true) {
                        reloadDetail(batch.id).then(function() {
                            batch.detailButtonLoading = null;
                        });
                    // }
                    // console.log(response);
                    $('#printingBatchModal').modal('hide');
                    vm.detail = null;
                });
        }

        function dispatch() {
            var batch = vm.detail;
            batch.detailButtonLoading = 'hide';

            serverGateway
                .ajax('dispatch', {id: batch.id})
                .then(function(response) {

                    // if (true) {
                        reloadDetail(batch.id).then(function() {
                            batch.detailButtonLoading = null;
                        });
                        vm.shouldShowHide = true;
                    // }
                    // console.log(response);
                    // $('#printingBatchModal').modal('hide');
                    vm.detail = null;
                });
        }

        function reject(batchItem) {
            if (batchItem.productionItem.hasActiveQALog) {
                return false;
            }
            vm.remarkList = [];
            /*vm.remarkList = [
                {'key':'1 - 塵', 'value':'1 - 塵'},
                {'key':'2 - 有間', 'value':'2 - 有間'},
                {'key':'3 - 揩到墨頭', 'value':'3 - 揩到墨頭'},
                {'key':'4 - 移位／出界', 'value':'4 - 移位／出界'},
                {'key':'5 - 化開', 'value':'5 - 化開'},
                {'key':'6 - 不對色', 'value':'6 - 不對色'},
                {'key':'7 - 擺錯殼／擺錯殻顏色', 'value':'7 - 擺錯殼／擺錯殻顏色'},
                {'key':'8 - 隻殻有問題(有氣／有雜質／有花／有怪紋路／滲漏）', 'value':'8 - 隻殻有問題(有氣／有雜質／有花／有怪紋路／滲漏）'},
                {'key':'9 - 甩色', 'value':'9 - 甩色'},
                {'key':'10 - Artwork 有問題', 'value':'10 - Artwork 有問題'},
                {'key':'11 - 有黑色烘', 'value':'11 - 有黑色烘'},
                {'key':'12 - 印多咗', 'value':'12 - 印多咗'},
                {'key':'13 - Order Cancelled', 'value':'13 - Order Cancelled'},
                {'key':'14 - White Design (but with white product i.e folio)', 'value':'14 - White Design (but with white product i.e folio)'},
                {'key':'15 - Rrip錯file', 'value':'15 - Rrip錯file'},
                {'key':'16 - Printing Not Found', 'value':'16 - Printing Not Found'},
                {'key':'17 - 印後有bubble', 'value':'17 - 印後有bubble'},
                {'key':'18 - COPYRIGHT Problem', 'value':'18 - COPYRIGHT Problem'},
                {'key':'19. 相框白底漏罅', 'value':'19. 相框白底漏罅'},
                {'key':'other', 'value':'其他'},
            ];*/

            vm.trashList = [
                {'key':'0', 'value':'No - 不需棄置印刷品'},
                {'key':'1', 'value':'Yes - 需棄置印刷品'},
            ];
            if ($.inArray(vm.detail.status, ['pending','generating','generated', 'downloaded']) > -1) {
                vm.problem = 'Other';
                vm.trashItem = '0';
                vm.remarkMsg = '';
                vm.handlingList = [
                    // {'key':'Printing Quality (Reprint)', 'value':'Printing Quality (Reprint)'},
                    {'key':'Printing File Problem', 'value':'Printing File Problem'},
                    {'key':'Out of Stock', 'value':'Out of Stock'},
                    {'key':'Other', 'value':'Other'},
                ];
            } else {
                vm.problem = 'Printing Quality (Reprint)';
                vm.trashItem = '1';
                vm.remarkMsg = '* Please input remark.';
                vm.handlingList = [
                    {'key':'Printing Quality (Reprint)', 'value':'Printing Quality (Reprint)'},
                    {'key':'Printing File Problem', 'value':'Printing File Problem'},
                    {'key':'Other', 'value':'Other'},
                ];
            }

            $('#rejectReasonDialog').modal('show')
            vm.rejectItem = batchItem;
            $('#problem').val($('#problem>option')[0].value);
            vm.remark = '';
            vm.remarkOther = '';
            vm.description = '';
            vm.trashMsg = '';

            //$scope.rejectImageFileUploader.url = "/api/printing-batch-items/" + vm.rejectItem.id + "/rejectItemUpload";

            serverGateway.ajax('qaRejectList').then(function(response){
                vm.remarkList = response.data.data;
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function saveReject() {
            //console.log("save reject");

            var remark = vm.remark;
            if (vm.remark == 'other') {
                remark = vm.remarkOther;
            }
            if (vm.trashItem == '') {
                alert("Please select trash item.");
                return;
            }
            if (vm.trashItem == '1' && (!remark || remark == '')) {
                alert("Please input remark.");
                return;
            }

            $('#rejectReasonDialog').modal('hide');
            serverGateway.ajax('rejectPrinting', {id: vm.rejectItem.id}, {problem: vm.problem, remark: remark, 'trashItem': vm.trashItem, description: vm.description, uploadimg: vm.uploadimg }).then(function(response) {
                reloadDetail(vm.detail.id).then(function() {
                    // batch.detailButtonLoading = null;
                });
                update();
            });
            return false;
        }

        function VaildateInput() {
            var remark = vm.remark;
            if (vm.remark == 'other') {
                remark = vm.remarkOther;
            }
            if (vm.trashItem == '') {
                vm.trashMsg = '* Please select trash item.';
                return;
            }
            if (vm.trashItem == '1' && (!remark || remark == '')) {
                vm.remarkMsg = '* Please input remark.';
                return;
            } else {
                vm.remarkMsg = '';
            }
            if (vm.remark !== '') {
                vm.remarkMsg = '';
                return;
            }
        }

        function showQAHistory(logs) {
            vm.qaHistory = logs;
            $('#qaHistoryDialog').modal('show');
        }

        function downloadDescription() {
            location.href = vm.detail.printingDescription.replace('printingDescriptionFile', 'printingDescriptionFileDownload');
        }

        function saveAcceptanceTest() {
            var batch = vm.detail;
            batch.detailButtonLoading = 'save';
            $('#printingBatchModal').modal('hide');

            var promises = batch.items.map(function(item) {
                var promise;
                var printable = item.productionItem;

                if (printable.acceptance === 'accept') {
                    promise = serverGateway.ajax('acceptPrinting', {id: item.productionItem.id});
                } else if (printable.acceptance === 'reject') {
                    promise = serverGateway.ajax('rejectPrinting', {id: item.productionItem.id}, {reason: printable.rejectReason});
                }
                return promise;
            });

            $q.all(promises).then(function() {
                reloadDetail(batch.id).then(function() {
                    batch.detailButtonLoading = null;
                });
                update();
            });
        }

        function canSaveAcceptance() {
            if (vm.detail === null) return false;

            var canSave = (_.filter(vm.detail.items, function(item) {
                if(typeof item.productionItem.acceptance === 'undefined') return true;
                if(item.productionItem.acceptance === 'reject') {
                    if(typeof item.productionItem.rejectReason === 'undefined' || item.productionItem.rejectReason == '') return true;
                }
                return false;
            }).length == 0);

            return canSave;
        }

        function detailNext() {
            var index = indexOfBatch(vm.detailId);
            if (index === -1) {
                // TODO: show error message
                return;
            }

            if (index === vm.rows.length - 1) {
                loadNext().then(function() {
                    setDetail(vm.rows[index + 1]);
                });
            } else {
                setDetail(vm.rows[index + 1]);
            }
        }

        function detailPrevious() {
            var index = indexOfBatch(vm.detailId);
            if (index === -1) {
                // TODO: show error message
                return;
            }
            setDetail(vm.rows[index - 1]);
        }

        function acceptAll() {
            vm.detail.items.forEach(function(item) {
                item.productionItem.acceptance = 'accept';
            });
        }

        function rejectAll() {
            vm.detail.items.forEach(function(item) {
                item.productionItem.acceptance = 'reject';
            });
        }

        function regenerate() {
            vm.detail.detailButtonLoading = true;
            serverGateway.ajax('regenerate', {id: vm.detail.id}).then(function(response) {
                reloadDetail(vm.detail.id).then(function() {
                    vm.detail.detailButtonLoading = false;
                });
                update();
                vm.detail.detailButtonLoading = false;
            });
        }

        function assignToQueueWithCode(productionItemId, queueCode) {
            vm.detail.saveCodeButtonLoading = true;
            serverGateway.ajax('assignToQueueWithCode', null, {code: queueCode, productionItemId: productionItemId, scanLocation: 'Station ' + vm.stationName}).then(function(response) {
                    reloadDetail(vm.detail.id).then(function() {
                        vm.detail.saveCodeButtonLoading = false;
                    });
                vm.detail.saveCodeButtonLoading = false;
            }, function (response) {
                vm.detail.saveCodeButtonLoading = false;
                vm.queueCodeCache[productionItemId] = null;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function bulkAssignToQueueWithCode(queueType, batchItems, queueCode) {
            vm.detail.saveCodeButtonLoading = true;
            var productionItemIds = [];
            for (var index in batchItems) {
                productionItemIds.push(batchItems[index].productionItem.id);
            }
            console.log(productionItemIds);
            serverGateway.ajax('bulkAssignToQueueWithCode', null, {scanLocation: 'Station ' + vm.stationName, code: queueCode, productionItemIds: productionItemIds.join(',')}).then(function(response) {
                    reloadDetail(vm.detail.id).then(function() {
                        vm.detail.saveCodeButtonLoading = false;
                        vm.queueCodeCache2[queueType] = null;
                    });
                vm.detail.saveCodeButtonLoading = false;
            }, function (response) {
                vm.detail.saveCodeButtonLoading = false;
                vm.queueCodeCache2[queueType] = null;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function clearTransitionBatch(transitionBatchCode) {
            vm.detail.saveCodeButtonLoading = true;
            
            serverGateway.ajax('clearTransitionBatch', null, {transitionBatchCode: transitionBatchCode}).then(function(response) {
                    reloadDetail(vm.detail.id).then(function() {
                        vm.detail.saveCodeButtonLoading = false;
                        vm.transitionBatchCdoe = null;
                    });
            }, function (response) {
                vm.detail.saveCodeButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function associateTransitionBatchCode() {
            vm.detail.saveCodeButtonLoading = true;
            var printingBatchId = vm.detail.id;
            var transitionBatchCode = vm.transitionBatchCdoe;
            
            serverGateway.ajax('associateTransitionBatchCode', null, {printingBatchId: printingBatchId, transitionBatchCode: transitionBatchCode}).then(function(response) {
                    reloadDetail(vm.detail.id).then(function() {
                        vm.detail.saveCodeButtonLoading = false;
                        vm.transitionBatchCdoe = null;
                    });
            }, function (response) {
                vm.detail.saveCodeButtonLoading = false;
                if (response.data.message == 'Batch already occupiced' &&  confirm('Clear Batch?')) {
                    vm.clearTransitionBatch(vm.transitionBatchCdoe);
                } else {
                    vm.transitionBatchCdoe = null;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                }
            });
        }

        function removeFromQueue(productionItemId) {
            vm.detail.saveCodeButtonLoading = true;
            vm.queueCodeCache[productionItemId] = null;
            serverGateway.ajax('removeFromQueue', null, {productionItemId: productionItemId}).then(function(response) {
                    reloadDetail(vm.detail.id).then(function() {
                        vm.detail.saveCodeButtonLoading = false;
                    });
                vm.detail.saveCodeButtonLoading = false;
            }, function (response) {
                vm.detail.saveCodeButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function optimizeStation(stationId) {
            if (vm.optimizeButtonLoading) {
                $.bootstrapGrowl('Be Patient...', {ele: 'body', type: 'info'});
                return false;
            }
            vm.optimizeButtonLoading = true;
            serverGateway.ajax('optimizeStation', {id: stationId}).then(function(response) {
                    window.location.reload();
                vm.optimizeButtonLoading = false;
            }, function (response) {
                vm.optimizeButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function pregenerateStation(stationId) {
            vm.pregenerateButtonLoading = true;
            serverGateway.ajax('pregenerateStation', {id: stationId}).then(function(response) {
                vm.pregenerateButtonLoading = false;
            }, function (response) {
                vm.pregenerateButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function showPreprintSummary() {
            $('#summaryDialog').modal('show');
        }

        function isSimple(str) {
            return str.startsWith('Simple');
        }

        function preprintSave(orderItemId) {
            var queueCode = $('.queue-code[data-id=' + orderItemId + ']').val();
            var areaCode = $('.area-code[data-id=' + orderItemId + ']').val();
            vm.preprintButtonLoading = true;
            serverGateway.ajax('savePreprintItem', {id: orderItemId}, {queueCode: queueCode, areaCode: areaCode}).then(function(response) {
                vm.preprintButtonLoading = false;
                $('.queue-code[data-id=' + orderItemId + ']').val('');
                $('.area-code[data-id=' + orderItemId + ']').val('');
                $('input[data-id=' + orderItemId + ']').addClass('disabled');
                $('tr[data-id=' + orderItemId + ']').css('opacity', .5);
            }, function (response) {
                vm.preprintButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            return false;
        }

        function openFineAdjustModal(batchId){
            serverGateway.ajax('getFineAdjust',{id: batchId}).then(function(response){
                if(response.data){
                    console.log(response.data.data);
                    Object.entries(response.data.data).forEach(([key,value]) => {
                        response.data.data[key].fineAdjustStatus = response.data.data[key].fineAdjustStatus == 'A' ? true : false;
                        //console.log(response.data.data[key].fineAdjustStatus);
                    });
                    $scope.fineAdjust = response.data.data;
                    $('#fineAdjustDialog').modal('show');
                }
            }, function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function openPrintLabelModal(batchId){
            vm.printLabelButtonLoading = true;
            
            serverGateway.ajax('getShippingLabels',{id: batchId}).then(function(response){
                if(response){
                    vm.printLabelButtonLoading = false;

                    vm.printLabelUrls = response.data;
                    $('#printLabelDialog').modal('show');

                    setTimeout(() => {
                        $('.print-label-item-error .fa').popover({
                            container: 'body'
                        });
                    }, 1000);

                }
            }, function(response){
                vm.printLabelButtonLoading = false;

                $.bootstrapGrowl('Cannot get shipping labels', {ele: 'body', type: 'error'});
            });
        }

        function generateLabels(batchId, station, force){
            vm.printLabelButtonLoading = true;

            force = (typeof force != 'undefined') ? force : false;
            
            serverGateway.ajax('generateLabels',{id: batchId}, {station: station, force: force}).then(function(response){
                if(response){
                    vm.printLabelButtonLoading = false;

                    if(typeof response.data.printJobs != 'undefined' && response.data.printJobs.length > 0){
                        $.bootstrapGrowl('Shipping label(s) generated', {ele: 'body', type: 'success'});
                    }

                    if(typeof response.data.errors != 'undefined' && response.data.errors.length === 0){
                        serverGateway.ajax('getShippingLabels',{id: batchId}).then(function(response){
                            if(response){
                                vm.printLabelButtonLoading = false;
                                vm.printLabelUrls = response.data;
                            }
                        }, function(response){
                            vm.printLabelButtonLoading = false;
                            $.bootstrapGrowl('Cannot get shipping labels', {ele: 'body', type: 'error'});
                        });
                    }

                }
            }, function(response){
                vm.printLabelButtonLoading = false;

                $.bootstrapGrowl('Cannot generate shipping labels', {ele: 'body', type: 'error'});
            });
        }
        function printLabels(batchId, station){
            vm.printLabelButtonLoading = true;
            
            serverGateway.ajax('printLabels',{id: batchId}, {station: station}).then(function(response){
                if(response){
                    vm.printLabelButtonLoading = false;

                    if(typeof response.data.printJobs != 'undefined' && response.data.printJobs.length > 0){
                        $.bootstrapGrowl('Shipping label(s) print jobs assigned', {ele: 'body', type: 'success'});
                    }

                    if(typeof response.data.errors != 'undefined' && response.data.errors.length === 0){
                        serverGateway.ajax('getShippingLabels',{id: batchId}).then(function(response){
                            if(response){
                                vm.printLabelButtonLoading = false;
                                vm.printLabelUrls = response.data;
                            }
                        }, function(response){
                            vm.printLabelButtonLoading = false;
                            $.bootstrapGrowl('Cannot get shipping labels', {ele: 'body', type: 'error'});
                        });
                    }
                }
            }, function(response){
                vm.printLabelButtonLoading = false;

                $.bootstrapGrowl('Cannot print shipping labels', {ele: 'body', type: 'error'});
            });
        }

        function getFinalPostion(original,adjust){
            return Math.round((original + adjust) * 100)/100;
        }

        function updateFineAdjust(){
            serverGateway.ajax('updateFineAdjust',null,{data: $scope.fineAdjust}).then(function(response){
                console.log(response.data);
                if(response.data.status == 'OK'){
                    $.bootstrapGrowl('Success. Please regenerate the file', {ele: 'body', type: 'success'});
                    $('#fineAdjustDialog').modal('hide');
                }
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function requestMaterial(batchId) {
            vm.requestMaterialButtonLoading[`batch_${batchId}`] = true;
            serverGateway.ajax('requestMaterial', {id: batchId}).then(function(response){
                $.bootstrapGrowl('Success.', {ele: 'body', type: 'success'});
                vm.requestMaterialButtonLoading[`batch_${batchId}`] = false;
                reloadDetail(batchId);
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.requestMaterialButtonLoading[`batch_${batchId}`] = false;
            });
        }

        function isRequestMaterialButtonLoading(batchId){
            if(vm.requestMaterialButtonLoading[`batch_${batchId}`]){
                return true;
            }
            return false;
        }

        function printerSent(batchId) {
            serverGateway.ajax('setSentToPrinter', {id: batchId}, {status: 1}).then(function(response){
                $.bootstrapGrowl('Success.', {ele: 'body', type: 'success'});
                reloadDetail(batchId);
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function printerUnsent(batchId) {
            serverGateway.ajax('setSentToPrinter', {id: batchId}, {status: 0}).then(function(response){
                $.bootstrapGrowl('Success.', {ele: 'body', type: 'success'});
                reloadDetail(batchId);
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function addSerialNumber(productionItem, serialNumber, remove) {
            const regex = /casetify\.com\/auth\/([\w\-]*)/igm;
            let regexresult = regex.exec(serialNumber)
            if (regexresult && regexresult[1]) {
                serialNumber = regexresult[1];
            }

            if (productionItem.orderItem.serialNumber == serialNumber) return;
            if (!remove && !serialNumber) return;
            if(remove && !confirm('Confirm Remove?')) return;
            if (productionItem.orderItem.serialNumber && !remove && !confirm('Confirm Update?')) return;
            
            vm.serialNumberLoading = true;
            const data = remove ? {} :  { serialNumber: serialNumber, printStationId: config.stationId };
            serverGateway.ajax('syncSerialNumber', { id: productionItem.orderItem.id }, data)
                .then(function (response) {
                    vm.serialNumberLoading = false;
                    if (response.data.success) {
                        productionItem.orderItem.serialNumber = serialNumber;
                        vm.serialNumberCache[productionItem.id] = serialNumber;
                        $.bootstrapGrowl("Serial Number updated successfully", { ele: 'body', type: 'success' });
                    } else {
                        $.bootstrapGrowl(response.data.message, { ele: 'body', type: 'error' });
                    }
                }, function (response) {
                    vm.serialNumberLoading = false;
                    $.bootstrapGrowl(response.data.message, { ele: 'body', type: 'error' });
                });
        }

        function resetRoboticWorkgroup(workgroupId) {
            if (vm.resetRoboticWorkgroupLoading) return;
            vm.resetRoboticWorkgroupLoading = true;
            serverGateway.ajax('resetRoboticWorkgroup', {id: workgroupId}).then(function(response){
                vm.resetRoboticWorkgroupLoading = false;
                $.bootstrapGrowl('Success.', {ele: 'body', type: 'success'});
            },function(response){
                vm.resetRoboticWorkgroupLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function setSlotTypeSetting(){
            const selectedSlotType = $('#select_slot_type_setting').val();
            if(selectedSlotType == 0 || !selectedSlotType){
                $.bootstrapGrowl('Please select type', {ele: 'body', type: 'error'});
                return;
            }
            window.open(`/slot-type/${selectedSlotType}/setting`, '_blank').focus();
        }

        function downloadYamatoSkuCsv(batchId){
            $("<a download/>").attr("href", `/printing-batches/${batchId}/downloadSkuCsv?shipment=yamato,amazon`).get(0).click();
        }

        function setShowPrintingDescription(value){
            localStorage.setItem('showPrintingDescription', value);
            vm.showPrintingDescription = localStorage.getItem('showPrintingDescription') ;
        }

        function receivedStockTransitRequest() {
            serverGateway.ajax('received-transit-request', {id: vm.detail.stockTransitRequest.id}).then(function(response) {
                reloadDetail(vm.detail.id);
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function changeShowAssembly(){
            localStorage.setItem('showAssembly', vm.showAssembly?'true':'false');
        }
    }
})();

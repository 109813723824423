(function() {
    'use strict';

    angular
        .module('grid')
        .directive('ctgCellAddress', ctgCellAddress);

    function ctgCellAddress() {
        var directive = {
            restrict: 'E',
            replace: true,
            template: '<span ng-bind-html="row[column.field].text | nl2br | sanitize "></span>'
        };

        return directive;
    }
})();
(function() {
    'use strict';

    angular
        .module('grid')
        .directive('ctgCellProductionItemSpecialHandling', ctgCellProductionItemSpecialHandling);

    function ctgCellProductionItemSpecialHandling() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: '/template/printing/ctgCellProductionItemSpecialHandling.html'
        };

        return directive;
    }
})();
(function() {
    'use strict';

    angular
        .module('component')
        .directive('ctgSelect2', ctgSelect2);

    ctgSelect2.$inject = ['$timeout',];

    function ctgSelect2($timeout) {
        var directive = {
            restrict: 'E',
            replace: true,
            require: '?ngModel',
            scope: {
                ngModel: '=', 
                ctgOptions: '=',
                ctgRemove: '&',
                ctgCreate: '&',
                ctgGet: '&'
            },
            template: '<select class="form-control"></select>',
            link: link
        };

        return directive;



        function link(scope, element, attrs, ngModel) {
            var $element = $(element);

            var ctgSelect2 = new Ctg.Select2Editable($element, {
                shouldCreate: select2ShouldCreate,
                create: select2Create,
                get: select2Get,
                remove: select2Remove,
                change: select2Change
            });

            element.on('destory', destory);

            ngModel.$render = render;

            scope.$watchCollection('ctgOptions', ctgOptionsWatch);    

            // function declaration
            function select2ShouldCreate() {
                return true
            }

            function select2Create(name) {
                return scope.ctgCreate({
                    name: name
                });
            }

            function select2Get(id) {
                return scope.ctgGet({
                    id: id
                });
            }

            function select2Remove(id) {
                return scope.ctgRemove({
                    id: id
                });
            }

            function select2Change(id) {
                console.log(id);
                if (ngModel) {
                    ngModel.$setViewValue(id);
                }
            }

            function render() {
                console.log('render');
                ctgSelect2.val(ngModel.$viewValue);
            }

            function ctgOptionsWatch(newValue, oldValue) {
                console.log('ctgOptionsWatch');
                ctgSelect2.data(newValue);
                select2Change(null);
            }

            function destory() {

            }
        }
    }
})();
(function() {
    'use strict';

    angular
        .module('grid')
        .directive('ctgGrid', ctgGrid);

    ctgGrid.$inject = ['$timeout'];

    function ctgGrid($timeout) {
        var directive = {
            restrict: 'E',
            replace: true,
            scope: {
                rows: '=',
                columns: '=',
                sorts: '=',
                isLoading: '=loading',
                sortChange: '&',
                rowClick: '&'
            },
            templateUrl: '/template/common/grid.html',
            link: link,
            controller: CtgGridController,
            controllerAs: 'vm',
            bindToController: true
        }

        link.$inject = ['scope', 'element', 'attrs', 'gridCtrl'];
        function link(scope, element, attrs, gridCtrl) {
            // console.log('CtgGridController: post link', element[0].innerHTML);

            $(element)
                .on('click', 'th:not(.badge-check)', sortToggle)
                .on('click', 'td', rowClick)
                .on('mousedown', 'td', rowMouseDown);

            // $('.dataTable', element);
            // $('.table-fixed-top', element);
            var $fixedTable = $('.table-fixed-top', element);
            var $mainTable = $('.dataTable', element);
            $(window).scroll(function() {
                if($(window).scrollTop() > $mainTable.offset().top) {
                    $fixedTable.css({ top: $(window).scrollTop() - $mainTable.offset().top });
                }
                else {
                    $fixedTable.css({ top: 0 });
                }
            });

            function sortToggle(e) {
                console.log('sortToggle');
                var column = angular.element(e.target).scope().column;
                if (!column.sortable && gridCtrl.sorts) return;

                var target, index;

                gridCtrl.sorts.forEach(function(sort, i) {
                    if (sort.field === column.field) {
                        target = sort;
                        index = i;
                        return false;;
                    }
                });

                if (target == null) {
                    gridCtrl.sorts.push({field: column.field, order: 'asc'});
                } else if (target.order === 'asc'){
                    target.order = 'desc';
                } else if (target.order === 'desc') {
                    gridCtrl.sorts.splice(index, 1);
                }

                gridCtrl.sortChangeDebounce(gridCtrl.sorts);
            }

            var clickTime = 0;
            function rowClick(e) {
                // prevent row click event and user may highlight some text
                if (+new Date - clickTime > 380) return;
                gridCtrl.rowClick({
                    row: angular.element($(e.target).closest('tr')).scope().row,
                    column: angular.element($(e.target)).scope().column
                });
                scope.$apply();
            }

            function rowMouseDown(e) {
                clickTime = +new Date;
            }
        }

        return directive;
    }

    CtgGridController.$inject = ['$scope', '$timeout', '$element'];

    function CtgGridController($scope, $timeout, $element) {
        // console.log('CtgGridController', $element[0].innerHTML);
        var vm = this;
        vm.checkAll = false;
        vm.toggleAllCheckBox = toggleAllCheckBox;
        vm.toggleRowCheckbox = toggleRowCheckbox;
        vm.sortChangeDebounce = sortChangeDebounce;
        vm.sortClass = sortClass;

        window.s = $scope;
        window.c = vm.columns;
        window.r = vm.rows;

        function toggleAllCheckBox() {
            vm.rows.forEach(function(row) {
                row.checked = vm.checkAll;
            });
        }

        function toggleRowCheckbox() {
            var isAllChecked = true;
            vm.rows.forEach(function(row) {
                if (!row.checked) {
                    isAllChecked = false;
                    return false;
                }
            });

            vm.checkAll = isAllChecked;
        }

        var pendingSort = null;
        function sortChangeDebounce(sorts) {
            console.log('sortChangeDebounce');
            vm.sorts = sorts;
            $scope.$apply();
            $timeout.cancel(pendingSort);
            pendingSort = $timeout(function() {
                pendingSort = null;
                vm.sortChange({
                    sorts: vm.sorts
                });
            }, 400);
        }

        function sortClass(column) {
            if (!column.sortable) return false;

            var result = false;
            vm.sorts.forEach(function(sort) {
                if (sort.field === column.field) {
                    result = sort.order;
                    return false;
                }
            });
            // console.log('sortClass:', result);
            return result ? 'sorting-' + result : false;
        }
    }
})();
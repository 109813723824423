(function() {
    'use strict';

    angular
        .module('grid')
        .directive('ctgCellProductionItem', ctgCellProductionItem);

    function ctgCellProductionItem() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: '/template/printing/ctgCellProductionItem.html'
        };

        return directive;
    }
})();
(function() {
    'use strict';

    angular
        .module('specialPrinting')
        .controller('SpecialPrintingController', Controller);

    Controller.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout', 'FileUploader', 'AlignmentTool', '$interval'];

    function Controller($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, FileUploader, AlignmentTool, $interval) {
        var serverGateway, vm = this;

        $scope.printableUploader = new FileUploader({
            autoUpload: true,
            removeAfterUpload: true,
            // method: 'PUT',
            alias: 'printable',
            formData: {
                'shouldSkipInsertPrintingBatch': 'Y',
                'saveOverride': 'Y'
            },
            headers: {
                Authorization: 'Basic ' + localStorage.getItem('api-auth-token'),
            },
            onCompleteItem: function () {
                $("input[name=printable]").val('');
                PageViewModel.update();
            }
        });

        $scope.printableColorUploader = new FileUploader({
            autoUpload: true,
            removeAfterUpload: true,
            // method: 'PUT',
            formData: {
                'shouldSkipInsertPrintingBatch': 'Y',
                'saveOverride': 'Y'
            },
            headers: {
                Authorization: 'Basic ' + localStorage.getItem('api-auth-token'),
            },
            alias: 'printableColor',
            onCompleteItem: function () {
                $("input[name=printable_color_file]").val('');
                PageViewModel.update();
            }
        });

        $scope.printableMaskUploader = new FileUploader({
            autoUpload: true,
            removeAfterUpload: true,
            // method: 'PUT',
            formData: {
                'shouldSkipInsertPrintingBatch': 'Y',
                'saveOverride': 'Y'
            },
            headers: {
                Authorization: 'Basic ' + localStorage.getItem('api-auth-token'),
            },
            alias: 'printableMask',
            onCompleteItem: function () {
                $("input[name=printable_mask_file]").val('');
                PageViewModel.update();
            }
        });

        vm.submit      = submit;
        vm.rows        = [];
        vm.sorts       = [];
        vm.columns     = config.columns;
        vm.locationFilter = [];
        vm.remarksFilter = [];
        vm.setFilter   = setFilter;
        vm.clearFilter = PageViewModel.clearFilter;
        vm.sortChange  = PageViewModel.sortChange;
        vm.isLoading   = PageViewModel._isLoading;
        vm.openDetailDialog = openDetailDialog;
        vm.stationList = config.stationList;
        vm.station = config.stationList[0].key;
        vm.detail = null;
        vm.alignDetail = {};
        vm.done = done;
        vm.moveToPrint = moveToPrint;
        vm.assignToStation = assignToStation;
        vm.refetchPrintable = refetchPrintable;
        vm.regenerateColor = regenerateColor;
        vm.regenerateMask = regenerateMask;
        vm.showRepositionTool = showRepositionTool;
        vm.alignScale = alignScale;
        vm.savePosition = savePosition;
        vm.toggleSaveOverride = toggleSaveOverride;
        vm.hide = hide;
        vm.resetScale = resetScale;
        vm.refetchPreview = refetchPreview;
        vm.fromDateFilter = config.dateFilter;
        vm.toDateFilter = config.dateFilter;
        vm.filterLocationList = config.filterLocationList;
        vm.locationFilter = [];
        vm.isToggleLocation = true;
        vm.toggleLocation = toggleLocation;
        vm.filterRemarksList = config.filterRemarksList;
        vm.remarkFilter = [];
        vm.isToggleRemark = true;
        vm.toggleRemark = toggleRemark;
        vm.initFilterValue = initFilterValue;
        vm.showQAEditModal = showQAEditModal;
        vm.saveImageFileUploader = saveImageFileUploader;

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'printDone': { path: 'production-items/{id}/printDone', method: 'POST' },
                    'moveToPrint': { path: 'production-items/{id}/moveToPrint', method: 'POST' },
                    'assignToStation': { path: 'production-items/{id}/assignToStation', method: 'POST' },
                    'refetchPrintable': { path: 'printables/{id}/refetch', method: 'POST' },
                    'refetchPreview': { path: 'order-items/{id}/refetchPreview', method: 'POST' },
                    'regenerateMask': { path: 'printables/{id}/regenerateMask', method: 'POST' },
                    'regenerateColor': { path: 'printables/{id}/regenerateColor', method: 'POST' },
                    'hideSpecialPrinting': { path: 'production-items/{id}/deleteSpecialHandling', method: 'POST' },
                    'saveAlignment': { path: 'printables/{id}/saveAlignment', method: 'POST' },
                    'toogleSaveOverride': { path: 'printables/{id}/toogleSaveOverride', method: 'POST' },
                    'assignToQueueWithCode': { path: 'transition-queue/assignWithCode', method: 'POST' },
                }
            });

            vm.gridDataSource = new GridDataSource({
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway,
                perPage: 10
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                return PageViewModel.rows;
            }, function(rows) {
                vm.rows = rows;
                var found = false;
                rows.forEach(function(row) {
                    if (vm.detail && row.id == vm.detail.id) {
                        found = true;
                        vm.detail = row;
                    }
                });
                if (!found) {
                    vm.detail = null;
                }
            }, true);
            $timeout(function() { PageViewModel.update(function () {
                    $timeout(function () {
                        vm.initFilterValue();
                    });
                }); }, 500);

            PageViewModel.initializeQueryBuilder(config.filtersConfig);
            AlignmentTool.initTool(vm);
            vm.alignMode = 'preview';

            $interval(function () {
                PageViewModel.update();
            }, 5000);
        }

        function submit() {

        }

        function toggleLocation(){
            vm.filterLocationList.forEach(function(index){
                vm.locationFilter[index.id] = vm.isToggleLocation;
            });
            vm.isToggleLocation = !vm.isToggleLocation;
        }

        function toggleRemark(){
            vm.filterRemarksList.forEach(function(index){
                vm.remarkFilter[index.id] = vm.isToggleRemark;
            });
            vm.isToggleRemark = !vm.isToggleRemark;
        }
        
        function initFilterValue(){
            $('#builder-basic').queryBuilder('setRules',{"condition":"AND","rules":[{"id":"production_items.problem","field":"production_items.problem","type":"string","input":"checkbox","operator":"not_in","value":["Out of Stock"]}]});
            PageViewModel.setFilter;
        }

        function setFilter() {
            var result = $('#builder-basic').queryBuilder('getRules');
            if (!$.isEmptyObject(result)) {
                PageViewModel.isLoading = true;
                vm.gridDataSource.setRules(result);
            }
            let query = new URLSearchParams(
                {
                    fromDate: vm.fromDateFilter,
                    toDate: vm.toDateFilter,
                    locations: returnFilterString(vm.locationFilter),
                    remarks: returnFilterString(vm.remarkFilter),
                }
            );
            vm.gridDataSource.setResourceUrl(config.dataUrl+"?"+query);
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $timeout(function() { PageViewModel.load(); }, 500);
        }

        function returnFilterString(filter){
            var selected = [];
            Object.keys(filter).forEach(function(item){
                if(filter[item] == true){
                    selected.push(item);
                }
            });
            return selected;
        }

        function openDetailDialog(row, columns) {
            vm.detail = row;
            AlignmentTool.performAlignment = function (action, property) {
                property = property.replace(/preview/, 'printable');
                vm.alignDetail[property] = action(vm.alignDetail[property]);
            };

            $('#productionItemModal').modal('show');
            $scope.printableUploader.url = "/api/printables/" + row.printable.id;
            $scope.printableColorUploader.url = "/api/printables/" + row.printable.id;
            $scope.printableMaskUploader.url = "/api/printables/" + row.printable.id;
        }

        function closeDetailDialog() {
            vm.detail = null;
            $('#productionItemModal').modal('hide');
        }

        function done(productionItem) {
            vm.detail.printButtonLoading = true;
            var queueCode = prompt("Please enter queue code");
            if (!queueCode || queueCode == '') {
                $.bootstrapGrowl('Invalid queue code', {ele: 'body', type: 'error'});
                return;
            }
            serverGateway.ajax('assignToQueueWithCode', null, {code: queueCode, productionItemId: productionItem.id, scanLocation: 'Special Printing'}).then(function(response) {
                    serverGateway.ajax('printDone', {id: productionItem.id}).then(function(response) {
                        PageViewModel.update();
                        closeDetailDialog();
                    }, function (response) {
                        $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                    });
                vm.detail.printButtonLoading = false;
            }, function (response) {
                vm.detail.printButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function moveToPrint(productionItem) {
            vm.moveToPrintButtonLoading = true;
            serverGateway.ajax('moveToPrint', {id: productionItem.id}).then(function(response) {
                vm.moveToPrintButtonLoading = false;
                PageViewModel.update();
                closeDetailDialog();
            }, function (response) {
                vm.moveToPrintButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function assignToStation(productionItem) {
            vm.moveToStationButtonLoading = true;
            serverGateway.ajax('assignToStation', {id: productionItem.id}, {stationId: vm.station}).then(function(response) {
                vm.moveToStationButtonLoading = false;
                PageViewModel.update();
                closeDetailDialog();
            }, function (response) {
                vm.moveToStationButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function refetchPrintable(printable) {
            vm.refetchPrintableButtonLoading = true;
            serverGateway.ajax('refetchPrintable', {id: printable.id}).then(function(response) {
                vm.refetchPrintableButtonLoading = false;
                PageViewModel.update();
            }, function (response) {
                vm.refetchPrintableButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }


        function refetchPreview(orderItem) {
            vm.refetchPreviewButtonLoading = true;
            serverGateway.ajax('refetchPreview', {id: orderItem.id}).then(function(response) {
                vm.refetchPreviewButtonLoading = false;
                PageViewModel.update();
            }, function (response) {
                vm.refetchPreviewButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }


        function regenerateMask(printable) {
            vm.regenerateMaskButtonLoading = true;
            serverGateway.ajax('regenerateMask', {id: printable.id}).then(function(response) {
                vm.regenerateMaskButtonLoading = false;
                PageViewModel.update();
            }, function (response) {
                vm.regenerateMaskButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function regenerateColor(printable) {
            vm.regenerateColorButtonLoading = true;
            serverGateway.ajax('regenerateColor', {id: printable.id}).then(function(response) {
                vm.regenerateColorButtonLoading = false;
                PageViewModel.update();
            }, function (response) {
                vm.regenerateColorButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function hide(productionItem) {
            vm.hideButtonLoading = true;
            serverGateway.ajax('hideSpecialPrinting', {id: productionItem.id}).then(function(response) {
                vm.hideButtonLoading = false;
                PageViewModel.update();
                closeDetailDialog();
            }, function (response) {
                vm.hideButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function showRepositionTool(printable) {
            $('#repositionModal').modal('show');

            //
            if (vm.detail.printable.alignmentOverwrite == 0) {
                vm.alignDetail.printableOffsetBottom = vm.detail.slotTypeInfo.printableOffsetBottom;
                vm.alignDetail.printableOffsetLeft   = vm.detail.slotTypeInfo.printableOffsetLeft;
                vm.alignDetail.printableOffsetScale  = vm.detail.slotTypeInfo.printableOffsetScale;
            }
            else {
                vm.alignDetail.printableOffsetBottom = vm.detail.printable.printableOffsetBottom;
                vm.alignDetail.printableOffsetLeft   = vm.detail.printable.printableOffsetLeft;
                vm.alignDetail.printableOffsetScale  = vm.detail.printable.printableOffsetScale;
            }
        }

        function alignScale() {
            var previewScale = 1;
            if (vm.detail) {
                previewScale = vm.alignDetail.printableOffsetScale;
            }

            return Math.min(
                $('.alignment-canvas').height() / $('.align-item-color-mask').height(),
                $('.alignment-canvas').height() / $('.align-item-preview').height() / previewScale
            );
        }

        function savePosition(slotTypeInfo) {
            vm.savePositionButtonLoading = true;
            serverGateway.ajax('saveAlignment', {id: vm.detail.printable.id}, $.extend({}, slotTypeInfo, vm.alignDetail)).then(function(response) {
                vm.savePositionButtonLoading = false;
                PageViewModel.update();
                PageViewModel.hideModal('#repositionModal');
            }, function (response) {
                vm.savePositionButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });

        }

        function toggleSaveOverride(printable) {
            vm.toggleSaveOverrideBtnLoading = true;
            serverGateway.ajax('toogleSaveOverride', {id: printable.id}).then(function(response) {
                vm.toggleSaveOverrideBtnLoading = false;
                PageViewModel.update();
            }, function (response) {
                vm.toggleSaveOverrideBtnLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function resetScale() {
            if (vm.detail) {
                vm.alignDetail.printableOffsetBottom = vm.detail.slotTypeInfo.printableOffsetBottom;
                vm.alignDetail.printableOffsetLeft   = vm.detail.slotTypeInfo.printableOffsetLeft;
                vm.alignDetail.printableOffsetScale  = vm.detail.slotTypeInfo.printableOffsetScale;
            }
        }

        function showQAEditModal(log) {
            vm.qaLogsId = log.id;
            vm.qaLogsHash_file = log.hash_file;
            vm.qaLogsProblem_desc = log.problem_desc;
            if (vm.qaLogsHash_file) {
                vm.showimg = '<img ng-src="/special/printing/' +  vm.qaLogsHash_file + '/preview">';
            } else {
                vm.showimg = '';
            }
            $('#qaEditDialog').modal('show');
        }

        function saveImageFileUploader() {   
            var data = new FormData();
            data.append('uploadimg', $('#uploadimg')[0].files[0]);
            data.append('description', $('#fdescription').val());

            $('#qaEditDialog').modal('hide'); 

            jQuery.ajax({
                url: '/api/special/printing/' + vm.qaLogsId + '/rejectItemUpdate',
                type:'post',
                data: data,
                contentType: false,
                processData: false,
                headers: {
                    Authorization: 'Basic ' + localStorage.getItem('api-auth-token'),
                },
                success: function(response) {
                    PageViewModel.update();
                    //console.log(response);
                },
                error: function(jqXHR, textStatus, errorMessage) {
                    alert('Error uploading: ' + errorMessage);
                }
            });
            return false;   
        };

    }

    function isNormalInteger(str) {
        var n = ~~Number(str);
        return String(n) === str && n >= 0;
    }

})();

// (function() {
//     'use strict';

//     angular
//         .module('dataGrid')
//         .factory('dataGrid', serverGateway);

//     function serverGateway() {

//         var endPoints = {
//             'searchClientProducts': { path: 'orders/search', method: 'POST' },
//             'createDataGridView': { path: 'data-grid-views', method: 'POST' },
//             'updateDataGridView': { path: 'data-grid-views/{id}', method: 'PUT' },
//             'deleteDataGridView': { path: 'data-grid-views/{id}', method: 'DELETE' },
//             'showDataGridView': { path: 'data-grid-views/{id}', method: 'GET' },
//             'validateAddress': { path: 'address/validate', method: 'POST' }
//         }

//         var serverGateway = new Ctg.ServerGateway({
//             endPoints: endPoints
//         });

//         return serverGateway;
//     }
// })();

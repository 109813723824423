(function() {
    'use strict';

    angular
        .module('grid')
        .directive('ctgCellProductionItemBuckets', ctgCellProductionItemBuckets);

    function ctgCellProductionItemBuckets() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: '/template/printing/ctgCellProductionItemBuckets.html'
        };

        return directive;
    }
})();
(function() {
    'use strict';

    angular
        .module('printingSlotType')
        .controller('PrintingSlotTypeController', Controller)

    Controller.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout'];

    function Controller($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout) {
        var serverGateway, vm = this;


        vm.submit      = submit;
        vm.rows        = [];
        vm.sorts       = [];
        vm.columns     = config.columns;
        vm.setFilter   = PageViewModel.setFilter;
        vm.clearFilter = PageViewModel.clearFilter;
        vm.sortChange  = PageViewModel.sortChange;
        vm.isLoading   = PageViewModel._isLoading;
        vm.openDetailDialog = openDetailDialog;
        vm.updatePrintingSlotType = updatePrintingSlotType;
        vm.openCreateDialog = openCreateDialog;
        vm.createPrintingSlotType = createPrintingSlotType;
        vm.closeModal = closeModal;
        vm.detail = null;
        vm.duplicate = null;
        vm.printers = [];

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'updatePrintingSlotType': { path: 'slot-type/{id}', method: 'POST' },
                    'createPrintingSlotType': {path: 'slot-type', method: 'POST'},
                }
            });
            vm.gridDataSource = new GridDataSource({
                stationId   : config.stationId,
                resoucesUrl : config.dataUrl,
                gateway     : serverGateway,
                perPage     : 999,
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                return PageViewModel.rows;
            }, function(rows) {
                vm.rows = rows;
                var found = false;
                rows.forEach(function(row) {
                    if (vm.detail && row.id == vm.detail.id) {
                        found = true;
                        // vm.detail = row;
                    }
                });
                if (!found) {
                    vm.detail = null;
                }
            }, true);

            $timeout(() => PageViewModel.update());

            // refresh data periodically
            setInterval(PageViewModel.update, 5000);
            vm.isLoading = false;
        }

        function submit() {

        }

        function openDetailDialog(row) {
            vm.detail = row;
            $('#printingSlotTypeModal').modal('show');
        }

        function updatePrintingSlotType() {
            vm.processing = true;
            serverGateway.ajax('updatePrintingSlotType', {id: vm.detail.id}, $.extend({}, vm.detail)).then(function(response) {
                vm.processing = false;
                PageViewModel.update();
                PageViewModel.hideModal('#printingSlotTypeModal');
            }, function (response) {
                vm.processing = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function openCreateDialog(newSlotType){
            if(vm.detail === null || newSlotType){
                vm.detail = {
                    name: "",
                    description: "",
                    height: "",
                    template_type: "seperated",
                    width: ""
                }
            }
            vm.duplicate = Object.assign({}, vm.detail);
            const match = vm.detail.description.match(/\((.+)\)/);
            if(match !== null){
                vm.duplicate.description = match[1];
            }
            console.log({detail: vm.detail});
            console.log({duplicate: vm.duplicate});
            $('#duplicateModal').modal('show');
        }

        function createPrintingSlotType(){
            serverGateway.ajax('createPrintingSlotType', {id: vm.duplicate.id}, $.extend({}, vm.duplicate)).then(function(response){
                PageViewModel.update();
                vm.detail = null;
                PageViewModel.hideModal('#duplicateModal');
                PageViewModel.hideModal('#printingSlotTypeModal');
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function closeModal(){
            $('.modal').each(function(){
                $(this).modal('hide');
            });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('specialPackaging')
        .controller('SpecialPackagingController', Controller);

    Controller.$inject = ['GridDataSource', 'ServerGateway', '$q', 'config', '$cookies'];

    function Controller(GridDataSource, ServerGateway, $q, config, $cookies) {
        var pendingSort, serverGateway, lastDetail = null;
        var vm = this;

        initialize();

        vm.getBatchLocal = getBatchLocal;
        vm.isLoading  = false;
        vm.rowsLocal  = {};
        vm.rows       = [];
        vm.columns    = config.columns;
        vm.sorts      = [];
        vm.setFilter   = setFilter;
        vm.clearFilter = clearFilter;
        vm.isLoading   = _isLoading;
        vm.sortChange = sortChange;
        vm.done       = done;
        vm.scan       = scan;
        vm.scanned    = scanned;
        vm.moveToPack = moveToPack;
        vm.generateLabel = generateLabel;
        vm.editAddress = editAddress;
        vm.selectAddress = selectAddress;
        vm.saveEditAddress = saveEditAddress;
        vm.confirmAddress = confirmAddress;
        vm.showQAHistory = showQAHistory;
        vm.showMoveDialog = showMoveDialog;
        vm.address = null;
        vm.input      = {};
        vm.printLabelButtonLoading = false;
        vm.reject = reject;
        vm.saveReject = saveReject;
        vm.scrap = scrap;
        vm.loadQueue = loadQueue;
        vm.queue = null;
        vm.toQueueCode = null;
        vm.toLocation = $cookies.get('lastLocation');
        vm.canEditAddress = config.canEditAddress;
        vm.addSerialNumber = addSerialNumber;
        vm.invalidSerialNumber = invalidSerialNumber;
        vm.onChangeSerialNumber = onChangeSerialNumber;
        vm.serialNumber = [];
        vm.serialNumberKeypress = serialNumberKeypress;
        vm.downloadJpYamatoFiles = downloadJpYamatoFiles;
        vm.downloadYamatoSkuCsv = downloadYamatoSkuCsv;

        // detail related
        vm.detailId         = null;
        vm.detail           = null;
        vm.openDetailDialog = openDetailDialog;
        vm.printLabel = printLabel;
        vm.transferQueueItem = transferQueueItem;


        // detai footer
        vm.indexOfBatch   = indexOfBatch;
        vm.detailNext     = detailNext;
        vm.detailPrevious = detailPrevious;
        vm.problem = 'Printing Quality (Reprint)';
        vm.problemList = [
            {'key':'Printing Quality (Reprint)', 'value':'Printing Quality (Reprint)'},
            {'key':'Printing Not Found (Reprint)', 'value':'Printing Not Found (Reprint)'},
            {'key':'Wrong Printable', 'value':'Wrong Printable'},
            {'key':'Printing Mask Problem', 'value':'Printing Mask Problem'},
            {'key':'Other', 'value':'Other'},
        ];
        vm.trashList = [
            {'key':'0', 'value':'No - 不需棄置印刷品'},
            {'key':'1', 'value':'Yes - 需棄置印刷品'},
        ];
        vm.rejectRemarkList = [];
        /*vm.rejectRemarkList = [
            {'key':'1 - 塵', 'value':'1 - 塵'},
            {'key':'2 - 有間', 'value':'2 - 有間'},
            {'key':'3 - 揩到墨頭', 'value':'3 - 揩到墨頭'},
            {'key':'4 - 移位／出界', 'value':'4 - 移位／出界'},
            {'key':'5 - 化開', 'value':'5 - 化開'},
            {'key':'6 - 不對色', 'value':'6 - 不對色'},
            {'key':'7 - 擺錯殼／擺錯殻顏色', 'value':'7 - 擺錯殼／擺錯殻顏色'},
            {'key':'8 - 隻殻有問題(有氣／有雜質／有花／有怪紋路／滲漏）', 'value':'8 - 隻殻有問題(有氣／有雜質／有花／有怪紋路／滲漏）'},
            {'key':'9 - 甩色', 'value':'9 - 甩色'},
            {'key':'10 - Artwork 有問題', 'value':'10 - Artwork 有問題'},
            {'key':'11 - 有黑色烘', 'value':'11 - 有黑色烘'},
            {'key':'12 - 印多咗', 'value':'12 - 印多咗'},
            {'key':'13 - Order Cancelled', 'value':'13 - Order Cancelled'},
            {'key':'14 - White Design (but with white product i.e folio)', 'value':'14 - White Design (but with white product i.e folio)'},
            {'key':'15 - Rrip錯file', 'value':'15 - Rrip錯file'},
            {'key':'16 - Printing Not Found', 'value':'16 - Printing Not Found'},
            {'key':'17 - 印後有bubble', 'value':'17 - 印後有bubble'},
            {'key':'18 - COPYRIGHT Problem', 'value':'18 - COPYRIGHT Problem'},
            {'key':'19. 相框白底漏罅', 'value':'19. 相框白底漏罅'},
            {'key':'other', 'value':'Other (其他)'},
        ];*/
        vm.remark = null;
        vm.stationList = config.stationList;
        vm.station = config.stationList[0].key;

        // TODO: remove it later debug use
        vm.update = update;

        function setDetail(batchItem) {
            vm.detailId = (batchItem === null ? null : batchItem.id);
            vm.detail = batchItem;
            if (batchItem) {
                vm.detail.shouldShowSticker = false;
                if (vm.detail.order.createdAt) {
                    if (Date.parse(vm.detail.order.createdAt) >= Date.parse('2016-10-21 15:00:00') && Date.parse(vm.detail.order.createdAt) <= Date.parse('2016-10-25 15:00:00')) {
                        vm.detail.shouldShowSticker = true;
                    }
                }
            }

            // vm.input = {};
            // if(batchItem && batchItem.orderItems.data) {
            //     for(var idx in batchItem.orderItems.data)
            //         if(typeof batchItem.orderItems.data[idx].inventoryItem !== 'undefined') {
            //             var obj        = {};
            //             obj.serialCode = batchItem.orderItems.data[idx].inventoryItem.data.serial_number;
            //             obj.scanning   = false;
            //             obj.scanned    = false;
            //             if(typeof obj.serialCode == 'string' && obj.serialCode.length > 0)
            //                 obj.scanned = true;

            //             vm.input[batchItem.orderItems.data[idx].id] = obj;
            //         }
            // }
        }

        function initialize() {
            // sever side gateway setup
            serverGateway = new ServerGateway({
                endPoints: {
                    'findDelivery': { path: 'deliveries/{id}', method: 'GET' },
                    'finishShipping': { path: 'deliveries/{id}/done',  method: 'POST' },
                    'linkSerialCode': { path: 'order-items/{id}/linkSerialCode',  method: 'PUT' },
                    'printLabel': { path: 'print-label',  method: 'POST' },
                    'specialHandling': { path: 'deliveries/{id}/special',  method: 'POST' },
                    'printJob': { path: 'print-jobs/{id}',  method: 'GET' },
                    'moveToPack': { path: 'deliveries/{id}/moveToPack', method: 'POST' },
                    'generateLabel': { path: 'deliveries/{id}/generateLabel', method: 'POST' },
                    'saveAddress': { path: 'deliveries/{id}/saveAddress', method: 'POST' },
                    'confirmAddress': { path: 'deliveries/{id}/confirmAddress', method: 'POST' },
                    'moveToPrintSpecial': { path: 'production-items/{id}/moveToPrintSpecial', method: 'POST' },
                    'scrapDelivery': { path: 'deliveries/{id}/scrapDelivery', method: 'POST' },
                    'checkTransitionQueueAvailability': { path: 'transition-queue/checkAvailability', method: 'GET' },
                    'assignToQueueWithCode': { path: 'transition-queue/assignWithCode', method: 'POST' },
                    'qaRejectList': { path: 'qaRejectList', method: 'GET' },
                    'syncSerialNumber': { path: 'order-items/{id}/serial-number',  method: 'POST' },
                    'associateSerialNumber': { path: 'function/associate-serial-number', method: 'POST'}
                }
            });
            vm.gridDataSource = new GridDataSource({
                stationId: config.stationId,
                resoucesUrl: config.dataUrl,
                gateway: serverGateway,
                perPage: 10000
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.lenght; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // load the first batch of data
            load();

            // refresh data periodically
            setInterval(update, 5000);
            console.log(config.filtersConfig);

            // $('#builder-basic').queryBuilder({

            //   plugins: ['bt-tooltip-errors'],

            //   filters: Ctg.makeFiltersConfig(config.filtersConfig),

            // });

            // detail modal setup
            $('#printingBatchModal')
                .modal({
                    show: false
                })
                .on('hide.bs.modal', function() {
                    console.log('#printingBatchModal is hidden');
                    setDetail(null);
                });

            serverGateway.ajax('qaRejectList').then(function(response){
                vm.rejectRemarkList = response.data.data
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function setFilter() {
            var result = $('#builder-basic').queryBuilder('getRules');
            if (!$.isEmptyObject(result)) {
                vm.isLoading = true;
                vm.gridDataSource.setRules(result);
                $timeout(function() { load(); }, 500);
            }
        }

        function clearFilter() {
            $('#builder-basic').queryBuilder('reset');
            vm.gridDataSource.setRules({});
            vm.isLoading = true;
            $timeout(function() { load(); }, 500);
        }

        function sortChange(sorts) {
            console.log('sortChange', arguments);

            vm.gridDataSource.prop('sorts', sorts);
            load();
        }

        function _isLoading() {
            return vm.isLoading;
        }

        function done(delivery, callback) {
            if (!confirm("Confirm?")) {
                return;
            }
            let preSealBoxName = prompt('Please enter the packing box name');
            if(preSealBoxName == '' || preSealBoxName == null){
                $.bootstrapGrowl('Box name is empty', {ele: 'body', type: 'error'});
                return
            }
            vm.detailButtonLoading = true;
            serverGateway.ajax('finishShipping', {id: delivery.id},{boxName: preSealBoxName}).then(function(response) {
                vm.detailButtonLoading = false;
                if(response.data) {
                    $('#printingBatchModal').modal('hide');
                    update();
                    callback && callback();
                }
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function scanned() {
            for(var idx in vm.input) {
                if(vm.input[idx].scanned == false) return false;
            }
            return true;
        }

        function scan(e, id) {
            e.preventDefault();

            var serialCode = vm.input[id].serialCode;
            if(serialCode == '' || vm.input[id].scanned || vm.input[id].scanning) return;
            vm.input[id].scanning = true;

            serverGateway.ajax('linkSerialCode', {id: id}, {code: serialCode}).then(function(response) {
                vm.input[id].scanning = false;
                if(response.data) {
                    vm.input[id].scanned = true;
                    if(vm.scanned()) {
                        // auto done if all scanned
                        var refId = vm.detail.refId;
                        vm.done(vm.detail, function() {
                            $("#doneShippingModal").modal("show");
                            $("#doneShippingModal .box-body").html("The order " + refId + " shipped.");
                        });
                    }
                    update();
                }
            });
        }

        function indexOfBatch(batchItemId) {
            var index = -1;
            vm.rows.forEach(function(row, i) {
                if (batchItemId === row.id) {
                    index = i;
                    return false;
                }
            });

            return index;
        }

        function update(callback) {
            return vm.gridDataSource
                .update()
                .then(function(response) {
                    var detailBatch = null;
                    vm.rows.splice(0, vm.rows.length);
                    response.data.forEach(function(datum) {
                        if (vm.detailId === datum.id) {
                            detailBatch = datum;
                        }
                        vm.rows.push(datum);
                    });

                    // handle detail dialog update
                    if (vm.detailId !== null) {
                        if (detailBatch === null) {
                            // handle may be removed case
                            // TODO: change it with deliveries endpoint
                            serverGateway.ajax('findDelivery', {id: vm.detailId}).then(function(response) {
                                if (response.data === '') {
                                    vm.detail = null;
                                } else {
                                    vm.detail = response.data.data;
                                }
                            });
                        } else {
                            setDetail(detailBatch);
                        }
                    }

                    updateLoadMoreBlock();
                    if (callback) {
                        callback(response);
                    }
                });
        }

        function reloadDetail(batchId) {
            if (batchId !== vm.detailId) {
                return $q(function(resolve) {
                    resolve();
                });
            }

            return serverGateway.ajax('findDelivery', {id: batchId}).then(function(response) {
                if (response.data === '') {
                    vm.detail = null;
                    return;
                }

                var index = indexOfBatch(batchId);
                if (index === -1) return;

                vm.rows[index] = vm.detail;
            });
        }

        function load() {
            console.log('load');

            vm.isLoading = true;

            return vm.gridDataSource
                .load()
                .then(function(response) {
                    console.log('load callback');
                    vm.rows = response.data;
                    vm.isLoading = false;

                    updateLoadMoreBlock();
                });
        }

        function loadNext() {
            console.log('loadNext');

            return vm.gridDataSource
                .loadNext()
                .then(function(response) {
                    console.log('loadNext callback');
                    vm.rows = vm.rows.concat(response.data);
                    updateLoadMoreBlock();
                });
        }

        function updateLoadMoreBlock() {
            if (vm.gridDataSource.hasNext()) {
                setTimeout(enableLoadMore, 10);
            } else {
                disableLoadMore();
            }
        }

        function enableLoadMore() {
            var waypoint = $('#rowLoading')
                .removeClass('hidden')
                .waypoint(function(event, direction) {
                    console.log('enableLoadMore: loadNextRows');
                    waypoint[0].destroy('remove');
                    loadNext();
                }, {
                    offset: '100%'
                });
        }

        function getBatchLocal(batchId) {
            return vm.rowsLocal[batchId] = vm.rowsLocal[batchId] ? vm.rowsLocal[batchId] : {};
        }

        function disableLoadMore() {
            $('#rowLoading').addClass('hidden');
        }

        function openDetailDialog(row, columns) {
            setDetail(row);
            $('#printingBatchModal').modal('show');
        }

        function detailNext() {
            var index = indexOfBatch(vm.detailId);
            if (index === -1) {
                // TODO: show error message
                return;
            }

            if (index === vm.rows.length - 1) {
                loadNext().then(function() {
                    setDetail(vm.rows[index + 1]);
                });
            } else {
                setDetail(vm.rows[index + 1]);
            }
        }

        function detailPrevious() {
            var index = indexOfBatch(vm.detailId);
            if (index === -1) {
                // TODO: show error message
                return;
            }
            setDetail(vm.rows[index - 1]);

        }

        function trackPrintStatus(printJobId, previousStatus, retryCount) {
            if (retryCount <= 0) {
                $.bootstrapGrowl("Print shipping label timeout: " + printJobId, {ele: 'body', type: 'error'});
                return;
            }
            serverGateway.ajax('printJob', {id: printJobId})
            .then(function(response) {
                if (response.data.data.status != previousStatus) {
                    $.bootstrapGrowl(response.data.data.status, {ele: 'body', type: 'info'});
                }
                if (response.data.data.status == 'printing' || response.data.data.status == 'created') {
                    setTimeout(function () {
                        trackPrintStatus(printJobId, response.data.data.status, retryCount -1)
                    }, 1000);
                }
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function printLabel(delivery) {
            vm.printLabelButtonLoading = true;
            serverGateway.ajax('printLabel', null, {stationId: vm.station, deliveryId: delivery.id})
            .then(function(response) {
                vm.printLabelButtonLoading = false;
                if (response.data === '') {
                    console.log(response);
                } else {
                    $.each(response.data.data, function (index, printJob) {
                        trackPrintStatus(printJob.id, printJob.status, 10);
                        // console.log(element);
                    });
                }
            }, function (response) {
                vm.printLabelButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function moveToPack(delivery) {
            vm.moveToPackButtonLoading = true;
            serverGateway.ajax('moveToPack', {id: delivery.id})
            .then(function(response) {
                vm.moveToPackButtonLoading = false;
                if(response.data) {
                    $('#printingBatchModal').modal('hide');
                    update();
                }
            }, function (response) {
                vm.moveToPackButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function generateLabel(delivery) {
            vm.generateButtonLoading = true;
            serverGateway.ajax('generateLabel', {id: delivery.id})
            .then(function(response) {
                vm.generateButtonLoading = false;
                if(response.data) {
                    update();
                }
            }, function (response) {
                vm.generateButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function editAddress(delivery) {
            $("#editAddressModal").modal('show');
            vm.address = JSON.parse(JSON.stringify(delivery.address));
        }

        function selectAddress(address) {
            vm.address = JSON.parse(JSON.stringify(address));
        }

        function saveEditAddress(delivery, address) {
            vm.validateButtonLoading = true;
            vm.addressValidationLoading = true;
            serverGateway.ajax('saveAddress', {id: delivery.id}, {name: address.name, street1: address.street1, street2: address.street2, city: address.city, state: address.state, zip: address.zip, country_code: address.country_code.code, phone: address.phone, email: address.email})
            .then(function(response) {
                vm.validateButtonLoading = false;
                vm.addressValidationLoading = false;
                if(response.data) {
                    selectAddress(response.data.data);
                    if (!response.data.data.warning) {
                        update();
                        generateLabel(delivery);
                        // $("#editAddressModal").modal('hide');
                    } else {
                        $("#confirm-address").modal('show');
                    }
                }
            }, function (response) {
                vm.validateButtonLoading = false;
                vm.addressValidationLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            
        }

        function confirmAddress(delivery, address) {
            vm.confirmAddressButtonLoading = true;
            serverGateway.ajax('confirmAddress', {id: delivery.id}, {addressId: address.id})
            .then(function(response) {
                vm.confirmAddressButtonLoading = false;
                if(response.data) {
                    selectAddress(response.data.data);
                    update();
                    $("#confirm-address").modal('hide');
                    generateLabel(delivery);
                    // $("#editAddressModal").modal('hide');
                }
            }, function (response) {
                vm.confirmAddressButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            
        }

        function scrap(delivery) {
            vm.hideButtonLoading = true;
            for (var i in delivery.orderItems) {
                invalidSerialNumber(delivery.orderItems[i]);
            }
            serverGateway.ajax('scrapDelivery', {id: delivery.id}).then(function(response) {
                vm.hideButtonLoading = false;
                update();
                $('#printingBatchModal').modal('hide');
            }, function (response) {
                vm.hideButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        // function reject(orderItem) {
        //     serverGateway.ajax('moveToPrintSpecial', {id: orderItem.id})
        //     .then(function(response) {
        //         if(response.data) {
        //             update();
        //         }
        //     }, function (response) {
        //         $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
        //     });
        // }

        function reject(orderItem, productionItem) {
            $('#rejectReasonDialog').modal('show')
            vm.rejectItem = productionItem;
            vm.rejectOrderItem = orderItem;
            $('#problem').val($('#problem>option')[0].value);
            vm.remark = '';
            vm.remarkOther = '';
            vm.trashItem = '1';
        }

        function saveReject() {
            var remark = vm.remark;
            // if (vm.remark == 'other') {
            //     remark = vm.remarkOther;
            // }
            if (vm.trashItem == '') {
                alert("Please select trash item.");
                return;
            }
            if (vm.trashItem == '1' && (!remark || remark == '')) {
                alert("Please input remark.");
                return;
            }
            invalidSerialNumber(vm.rejectOrderItem);
            // console.log("save reject");
            $('#rejectReasonDialog').modal('hide');
            serverGateway.ajax('moveToPrintSpecial', { id: vm.rejectItem.id }, { problem: vm.problem, remark: remark, remarkOther: vm.remarkOther, 'trashItem': vm.trashItem }).then(function (response) {
                update();
            });
            return false;
        }

        function showQAHistory(logs) {
            vm.qaHistory = logs;
            $('#qaHistoryDialog').modal('show');
        }

        function showMoveDialog(orderItem, productionItem) {
            vm.productionItem = productionItem;
            vm.orderItem = orderItem;
            $('#moveDialog').modal('show');
            $('#toQueueCode').focus();
        }

        function transferQueueItem(productionItem, queueCode, location) {
            if (!queueCode || queueCode == '') {
                $.bootstrapGrowl('Empty queue code', {ele: 'body', type: 'error'});
                return
            }
            if (!location || location == '') {
                $.bootstrapGrowl('Empty location', {ele: 'body', type: 'error'});
                return
            }
            var date = new Date();
            date.setTime(date.getTime() + (30 * 60 * 1000));
            $cookies.put('lastLocation', location, {expires: date, path: '/'});
            vm.detail.saveCodeButtonLoading = true;
            serverGateway.ajax('assignToQueueWithCode', null, {code: queueCode, productionItemId: productionItem.id, scanLocation: location, reassign: 'Y'}).then(function(response) {
                $.bootstrapGrowl('Queue reassigned', {ele: 'body', type: 'success'});
                update().then(function () {
                    vm.detail.saveCodeButtonLoading = false;
                    $('#moveDialog').modal('hide');
                })
            }, function (response) {
                vm.detail.saveCodeButtonLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            
        }

        function loadQueue(queue) {
            if (!queue) {
                $.bootstrapGrowl("Empty queue", {ele: 'body', type: 'error'});
                return;
            }
            vm.queue = queue;
            vm.gridDataSource.setResourceUrl(config.dataUrl + "?queue=" + queue);
            update();
            serverGateway.ajax('checkTransitionQueueAvailability', null, {queue: vm.queue}).then(function(response) {
                $.bootstrapGrowl("Loaded queue", {ele: 'body', type: 'success'});
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            
            
        }

        function addSerialNumber(orderItem, productionItem = null, locationId = null) {
            if(productionItem){
                submitSerialNumber(orderItem, productionItem, locationId);
                return;
            }
            let serialNumberSelector = document.querySelector('[name="serialNumber-' + orderItem.id + '"]');
            let serialNumber = serialNumberSelector.value;
            const regex = /casetify\.com\/auth\/([\w\-]*)/igm;
            let regexresult = regex.exec(serialNumber)
            if (regexresult && regexresult[1]) {
                serialNumber = regexresult[1]
            }

            if (orderItem.serialNumber == serialNumber) return;
            if (orderItem.serialNumber && !confirm('Confirm Update?')) return;
            
            vm.serialNumberLoading = true;
            serverGateway.ajax('syncSerialNumber', { id: orderItem.id }, { serialNumber: serialNumber })
                .then(function (response) {
                    vm.serialNumberLoading = false;
                    if (response.data.success) {
                        orderItem.serialNumber = serialNumber;
                        vm.detail.orderItems.map((detailOrderItem)=>{
                            if(detailOrderItem.id == orderItem.id){
                                detailOrderItem.serialNumber = serialNumber;
                                return detailOrderItem;
                            }
                        });
                        $.bootstrapGrowl("Serial Number updated successfully", { ele: 'body', type: 'success' });
                    } else {
                        $.bootstrapGrowl(response.data.message, { ele: 'body', type: 'error' });
                    }
                    serialNumberSelector.value = response.data.data && response.data.data.serial_number ? response.data.data.serial_number : orderItem.serialNumber;
                    orderItem.serialNumber = serialNumberSelector.value
                }, function (response) {
                    vm.serialNumberLoading = false;
                    serialNumberSelector.value = response.data.data && response.data.data.serial_number ? response.data.data.serial_number : orderItem.serialNumber;
                    orderItem.serialNumber = serialNumberSelector.value
                    $.bootstrapGrowl(response.data.message, { ele: 'body', type: 'error' });
                });
        }

        function submitSerialNumber(orderItem, productionItem, locationId = null){
            const sn = vm.serialNumber[productionItem.id];
            if(!sn){
                $.bootstrapGrowl('Please fill in Serial Number', {ele: 'body', type: 'error'});
                return;
            }
            if(vm.serialNumberLoading){
                return;
            }
            if(!confirm('Confirm update?')){
                return;
            }
            // const ctf = `CTF-${productionItem.printable.artworkId}-${orderItem.product.name}`;
            const ctf = `CTF-${productionItem.printable.artworkId}-${productionItem.printable.product}`;
            vm.serialNumberLoading = true;
            serverGateway.ajax('associateSerialNumber', null, {sn: sn, ctf: ctf, upc: null, locationId: locationId}).then(function(response){
                if(response.data.status == 'OK'){
                    $.bootstrapGrowl('Associate Success!', {ele: 'body', type: 'success'});
                } else {
                    $.bootstrapGrowl(response.data.msg, {ele: 'body', type: 'error'});
                }
                vm.serialNumberLoading = false;
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.serialNumberLoading = false;
            });
        }

        function onChangeSerialNumber(productionItem){
            const regex = /casetify\.com\/auth\/([\w\-]*)/igm;
            let regexresult = regex.exec(vm.serialNumber[productionItem.id]);
            if (regexresult && regexresult[1]) {
                vm.serialNumber[productionItem.id] = regexresult[1];
            }
        }

        function invalidSerialNumber(orderItem) {
            vm.serialNumberLoading = true;
            serverGateway.ajax('syncSerialNumber', { id: orderItem.id }, { status: false } )
                .then(function (response) {
                    vm.serialNumberLoading = false;
                    if (response.data.success) {
                        let serialNumberSelector = document.querySelector('[name="serialNumber-' + orderItem.id + '"]');
                        serialNumberSelector.value = "";
                        $.bootstrapGrowl("Serial Number invalid successfully", { ele: 'body', type: 'success' });
                    } else {
                        $.bootstrapGrowl(response.data.message, { ele: 'body', type: 'error' });
                    }
                }, function (response) {
                    vm.serialNumberLoading = false;
                    $.bootstrapGrowl(response.data.message, { ele: 'body', type: 'error' });
                });
        }

        function serialNumberKeypress(event, orderItem, productionItem = null, locationId = null){
            if(event.which == 13){//is pressed enter
                addSerialNumber(orderItem, productionItem, locationId);
            }
        }

        function downloadJpYamatoFiles(deliveryId){
            $("<a download/>").attr("href", `/deliveries/${deliveryId}/downloadJpYamatoFiles?fileType=csv`).get(0).click();
            setTimeout(function() {
                $("<a download/>").attr("href", `/deliveries/${deliveryId}/downloadJpYamatoFiles?fileType=trg`).get(0).click();
            }, 2000);//smartcat need to trigger csv first then trg, so need to complete download csv first
        }

        function downloadYamatoSkuCsv(deliveryId){
            $("<a download/>").attr("href", `/deliveries/${deliveryId}/downloadSkuCsv?nonPrint=Yes`).get(0).click();
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('printBot')
        .controller('PrintBotController', Controller);

    Controller.$inject = ['$scope', 'GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel', '$timeout'];

    function Controller($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout) {
        var serverGateway, vm = this;


        vm.submit      = submit;
        vm.rows        = [];
        vm.sorts       = [];
        vm.columns     = config.columns;
        vm.setFilter   = PageViewModel.setFilter;
        vm.clearFilter = PageViewModel.clearFilter;
        vm.sortChange  = PageViewModel.sortChange;
        vm.isLoading   = PageViewModel._isLoading;
        vm.openDetailDialog = openDetailDialog;
        vm.startPrinting = startPrinting;
        vm.startPrintingUndo = startPrintingUndo;
        vm.simulatePrinting = simulatePrinting;
        vm.inputBarcode = inputBarcode;
        vm.qaAccept = qaAccept;
        vm.qaReject = qaReject;
        vm.scrap = scrap;
        vm.authCode = config.defaultAuthCode;
        vm.detail = null;

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'setActiveTask': { path: 'print-bots/{printBotId}/set-active-task', method: 'POST' },
                    'clearActiveTask': { path: 'print-bots/{printBotId}/clear-active-task', method: 'POST' },
                    'startPrinting': { path: 'print-bot-tasks/{taskId}/print-start', method: 'POST' },
                    'simulatePrinting': { path: 'print-bot-tasks/{taskId}/simulate-print', method: 'POST' },
                    'startPrintingUndo': { path: 'print-bot-tasks/{taskId}/print-start-undo', method: 'POST' },
                    'submitQAResult': { path: 'print-bot-tasks/{taskId}/submit-qa-result', method: 'POST' },
                    'inputBarcode': { path: 'print-bot-tasks/{taskId}/register-barcode', method: 'POST' },
                    'scrap': { path: 'print-bot-tasks/{taskId}/scrap', method: 'POST' },
                }
            });
            vm.gridDataSource = new GridDataSource({
                stationId   : null,
                resoucesUrl : config.dataUrl,
                gateway     : serverGateway,
                perPage     : 999,
            });

            var pusher = new Pusher(config.pusherKey, {
              cluster: 'ap1',
              forceTLS: true
            });

            var channel = pusher.subscribe('print_bot.' + config.printBotId);
            channel.bind('app.print-bot-updated', function(data) {
              PageViewModel.debounceUpdate(() => {
                console.log('updated');
                if (vm.detail) {
                    let idx = vm.rows.findIndex((row) => row.id == vm.detail.id);
                    if (idx != -1) {
                        vm.detail = vm.rows[idx];
                    }
                }
              }, 1000);
            });

            // config data source
            var fields = [];
            for (var i, c = config.columns.length; i < c; i++) {
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            // refresh data periodically
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function() {
                return PageViewModel.rows;
            }, function(n) {
                vm.rows = n;
            });
            $timeout(function() { PageViewModel.debounceUpdate(() => {
                if (config.currentTaskId) {
                    let row = vm.rows.find((row) => row.id == config.currentTaskId)
                    if (row) {
                        openDetailDialog(row);
                    }
                }
            }); }, 500);

            $('#printBotTaskModal')
                .on('hide.bs.modal', function() {
                    serverGateway.ajax('clearActiveTask', {printBotId: config.printBotId}).then(function(response) {
                        console.log('cleared current task')
                    }, function (response) {
                        $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                    });
                });


            // PageViewModel.initializeQueryBuilder(config.filtersConfig);
        }

        function submit() {

        }

        function openDetailDialog(row) {
            serverGateway.ajax('setActiveTask', {printBotId: config.printBotId}, {taskId: row.id}).then(function(response) {
                vm.detail = row;
                $('#printBotTaskModal').modal('show');
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });

        }

        function startPrinting(id) {
            vm.startPrintingLoading = true;
            serverGateway.ajax('startPrinting', {taskId: id}, {authCode: vm.authCode}).then(function(response) {
                vm.detail = response.data.data;
                vm.startPrintingLoading = false;
            }, function (response) {
                vm.startPrintingLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function simulatePrinting(id) {
            vm.startPrintingLoading = true;
            serverGateway.ajax('simulatePrinting', {taskId: id}).then(function(response) {
                vm.detail = response.data.data;
                vm.startPrintingLoading = false;
            }, function (response) {
                vm.startPrintingLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function startPrintingUndo(id) {
            vm.startPrintingLoading = true;
            serverGateway.ajax('startPrintingUndo', {taskId: id}).then(function(response) {
                vm.detail = response.data.data;
                vm.startPrintingLoading = false;
            }, function (response) {
                vm.startPrintingLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function qaAccept(id) {
            vm.qaSubmitLoading = true;
            let result = [{'name': 'printing_quality', 'passed': true}];
            serverGateway.ajax('submitQAResult', {taskId: id}, {qaResult: JSON.stringify(result)}).then(function(response) {
                vm.detail = response.data.data;
                vm.qaSubmitLoading = false;
            }, function (response) {
                vm.qaSubmitLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function qaReject(id) {
            vm.qaSubmitLoading = true;
            let result = [{'name': 'printing_quality', 'passed': false}];
            serverGateway.ajax('submitQAResult', {taskId: id}, {qaResult: JSON.stringify(result)}).then(function(response) {
                vm.detail = response.data.data;
                vm.qaSubmitLoading = false;
                $('#printBotTaskModal').modal('hide');
            }, function (response) {
                vm.qaSubmitLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function inputBarcode(id) {
            vm.inputBarcodeLoading = true;
            let barcode = prompt('Please input barcode');
            serverGateway.ajax('inputBarcode', {taskId: id}, {barcode: barcode}).then(function(response) {
                vm.detail = response.data.data;
                vm.inputBarcodeLoading = false;
                $('#printBotTaskModal').modal('hide');
            }, function (response) {
                vm.inputBarcodeLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function scrap(id) {
            vm.qaSubmitLoading = true;
            serverGateway.ajax('scrap', {taskId: id}, {authCode: vm.authCode}).then(function(response) {
                vm.qaSubmitLoading = false;
                $('#printBotTaskModal').modal('hide');
            }, function (response) {
                vm.qaSubmitLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }
    }

})();

(function() {
    'use strict';

    angular
        .module('grid')
        .directive('ctgCellString', ctgCellString);

    function ctgCellString() {
        var directive = {
            restrict: 'E',
            replace: true,
            template: '<span>{{ row[column.field] }}</span>'
        };

        return directive;
    }
})();
(function() {
    'use strict';

    angular
        .module('grid')
        .directive('ctgCellBookingRequestItem', ctgCellBookingRequestItem);

    function ctgCellBookingRequestItem() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: '/template/inventory/cellBookingRequestItem.html',
            controller: ['$scope', function($scope) {
                var vm = this;

                return vm;
            }],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;
    }
})();
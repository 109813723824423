(function() {
    'use strict';

    angular
        .module('grid')
        .directive('ctgCellImg', ctgCellImg);

    function ctgCellImg() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: '/template/inventory/cellImg.html',
            controller: ['$scope', function($scope) {
                var vm = this;

                // console.log($('img', ));

                return vm;
            }],
            controllerAs: 'vm',
            bindToController: true,
            link: link
        };

        link.$inject = ['scope', 'element', 'attrs'];

        function link(scope, element, attrs) {
            $('img', element).load(function() {
                Waypoint.refreshAll();
            });
        }

        return directive;
    }
})();

(function() {
    'use strict';

    // angular
    //     .module('component')
    //     .directive('ctgDatePicker', [ctgDatePicker]);

    // function ctgDatePicker() {
    //     return {
    //         restrict: 'A',
    //         link: function(scope, element, attrs, ngModel) {
    //             $(element).datetimepicker({
    //                 timepicker: false,
    //                 format: 'Y-m-d',
    //                 todayButton: false,
    //                 closeOnDateSelect: true,
    //                 scrollInput: false,
    //                 value: moment().format('YYYY-MM-DD'),
    //                 onChangeDateTime: function(dp, $input) {
    //                     console.log(ngModel);
    //                 },
    //                 onShow: function() {
    //                     this.setOptions({
    //                         value: $(element).val()
    //                     });
    //                 },
    //             });
    //         }
    //     };
    // }
})();

(function() {
    'use strict';

    angular
        .module('grid')
        .directive('ctgCellStringSanitize', ctgCellStringSanitize);

    function ctgCellStringSanitize() {
        var directive = {
            restrict: 'E',
            replace: true,
            template: '<span ng-bind-html="row[column.field] | sanitize"></span>'
        };

        return directive;
    }
})();

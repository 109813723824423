(function(){
    'use strict';

    angular.module('packaging')
    .controller('PreSealController', preSealPackingController);

    preSealPackingController.$inject = ['GridDataSource', 'ServerGateway', '$q', 'config', 'PageViewModel','$scope', '$timeout', '$interval'];

    function preSealPackingController(GridDataSource, ServerGateway, $q, config, PageViewModel, $scope, $timeout, $interval){
        var serverGateway, vm = this;

        vm.rows = [];
        vm.sorts = [];
        vm.columns = config.columns;
        vm.setFilter = PageViewModel.setFilter;
        vm.clearFilter = PageViewModel.clearFilter;
        vm.sortChange = PageViewModel.sortChange;
        vm.isLoading = PageViewModel._isLoading;
        vm.showComplete = false;
        vm.typeFilter = '';
        vm.locationFilter = '';
        vm.textFilter = '';
        vm.showMine = false;
        vm.detail = {};
        vm.addRecordData = '';

        vm.applyFilter = applyFilter;
        vm.createPackingBox = createPackingBox;
        vm.openBoxDetailModal = openBoxDetailModal;
        vm.openBox = openBox;
        vm.closeBox = closeBox;
        vm.processPackingBox = processPackingBox;
        vm.markBoxAsComplete = markBoxAsComplete;
        vm.markItemAsComplete = markItemAsComplete;
        vm.printBoxBarcode = printBoxBarcode;
        vm.updateDetails = updateDetails;
        vm.openAddRecordModal = openAddRecordModal;
        vm.addRecordToBox = addRecordToBox;
        vm.deleteItem = deleteItem;
        vm.deleteBox = deleteBox;

        initialize();

        function initialize() {
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'createPreSealPackingBox' : { path: 'pre-seal-packing-box/create', method: 'POST'},
                    'processPackingBox' : { path: 'pre-seal-packing-box/{id}/process', method: 'POST'},
                    'markBoxAsComplete' : { path: 'pre-seal-packing-box/{id}/markAsComplete', method: 'POST'},
                    'addRecordToBox': {path: 'pre-seal-packing-box/{id}/addRecord', method: 'POST'},
                    'markItemAsComplete': { path: 'pre-seal-packing/{id}/markAsComplete', method: 'POST'},
                    'updateDetails': {path: 'pre-seal-packing/{id}/update', method: 'POST'},
                    'updateBoxStatus': {path: 'pre-seal-packing/{id}/updateStatus', method: 'POST'},
                    'deleteItem': {path: 'pre-seal-packing/{id}/delete', method: 'POST'},
                    'deleteItemBox': {path: 'pre-seal-packing-box/{id}/delete', method: 'POST'},
                    'getPreSealBoxItem': {path: 'pre-seal-packing-box/{id}/items', method: 'GET'}
                }
            });

            let query = new URLSearchParams(
                {
                    showComplete: vm.showComplete,
                    type: vm.typeFilter,
                    location: vm.locationFilter,
                    keyword: vm.textFilter,
                    showMine: vm.showMine
                }
            );

            vm.gridDataSource = new GridDataSource({
                resoucesUrl :   config.dataUrl+"?"+query,
                gateway     :   serverGateway,
                perPage: 5
            });

            var fields = [];
            for(var i, c = config.columns.length; i < c; i++){
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);
            vm.gridDataSource.setRules(
                JSON.stringify({
                    showComplete: vm.showComplete,
                    type: vm.typeFilter,
                    location: vm.locationFilter,
                    keyword: vm.textFilter,
                    showMine: vm.showMine
                })
            );
            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function(){
                return PageViewModel.rows;
            }, function(rows) {
                vm.rows = rows;
            }), true;

            $timeout(function(){PageViewModel.update();}, 500);

            $interval(function () {
                PageViewModel.update();
            }, 5000);
        }

        function applyFilter(){
            let query = new URLSearchParams(
                {
                    showComplete: vm.showComplete,
                    type: vm.typeFilter,
                    location: vm.locationFilter,
                    keyword: vm.textFilter,
                    showMine: vm.showMine
                }
            );
            vm.gridDataSource.setRules(
                JSON.stringify({
                    showComplete: vm.showComplete,
                    type: vm.typeFilter,
                    location: vm.locationFilter,
                    keyword: vm.textFilter,
                    showMine: vm.showMine
                })
            );
            vm.gridDataSource.setResourceUrl(config.dataUrl+"?"+query);
            PageViewModel.setGridDataSource(vm.gridDataSource);
            PageViewModel.load();
            PageViewModel.update();
        }

        function createPackingBox(){
            console.log('createPackingBox START');
            if (vm.typeFilter == ''){ $.bootstrapGrowl('Please select a type', {ele: 'body', type: 'error'}); return;}
            var boxCount = prompt('How many box you want to create?',0);
            if(boxCount == null) return;
            serverGateway.ajax('createPreSealPackingBox',null,{boxCount: boxCount, type: vm.typeFilter}).then(function(repsonse){
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                PageViewModel.update();
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function openBoxDetailModal(row){

            vm.detail = null;
            vm.showOverlay = true;
            $('.gray-overlay').removeClass('d-none');
            serverGateway.ajax('getPreSealBoxItem',{id: row.id}).then(function(response){
                vm.detail = response.data.data;
                $('#boxDetailModal').modal('show');
                vm.showOverlay = false;
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.showOverlay = false;
            });
        }

        function openBox(){
            updateBoxStatus('created');
        }

        function closeBox(){
            updateBoxStatus('closed');
        }

        function updateBoxStatus(status){
            if(!status){
                $.bootstrapGrowl('Status is empty', {ele: 'body', type: 'error'});
            }
            if(!confirm('Are you sure?')) return;

            serverGateway.ajax('updateBoxStatus',{id: vm.detail.id},{status: status}).then(function(response){
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                openDialogAfterUpdateDetail();
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function processPackingBox(){
            console.log('processPackingBox START');
            if(!confirm('Are you sure you want to proceed? You cannot further add records to the box afterwards')) return;
            var assignedName = prompt('Who will be assigned for this packing box?');
            if(assignedName == null) return;
            if(assignedName == ''){
                $.bootstrapGrowl('Please insert a valid name', {ele: 'body', type: 'error'});
            }
            serverGateway.ajax('processPackingBox',{id: vm.detail.id},{assignedName: assignedName}).then(function(response){
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                openDialogAfterUpdateDetail();
            }, function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function markBoxAsComplete(){
            console.log('markBoxAsComplete START');
            if(!confirm('Are you sure you want to complete the box?')) return;

            serverGateway.ajax('markBoxAsComplete',{id: vm.detail.id}).then(function(response){
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                $('#boxDetailModal').modal('hide');
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function markItemAsComplete(id){
            console.log('markItemAsComplete START');
            if(!confirm('Are you sure you want to complete the item?')) return;

            serverGateway.ajax('markItemAsComplete',{id: id}).then(function(response){
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                openDialogAfterUpdateDetail();
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function deleteItem(id){
            console.log('deleteItem START');
            if(!confirm('Are you sure you want to delete?')) return;

            serverGateway.ajax('deleteItem',{id: id}).then(function(response){
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                openDialogAfterUpdateDetail();
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function deleteBox(id){
            console.log('deleteBox START');
            if(vm.detail.items.length > 0){
                $.bootstrapGrowl('Please remove all items in the box before deleting the box', {ele: 'body', type: 'danger'});
                return;
            }
            if(!confirm('Are you sure you want to delete?')) return;

            serverGateway.ajax('deleteItemBox',{id: id}).then(function(response){
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                $('#boxDetailModal').modal('hide');
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function printBoxBarcode(){
            console.log('printBoxBarcode START');
            console.log(vm.detail.id);
            window.open(`${window.location.protocol}//${window.location.hostname}/pre-seal-packing-box/exportBarcode?id=${vm.detail.id}`);
        }

        function updateDetails(){
            console.log('updateDetails START');
            serverGateway.ajax('updateDetails',{id: vm.detail.id},{detail: vm.detail}).then(function(response){
                $.bootstrapGrowl('Updated', {ele: 'body', type: 'success'});
                openDialogAfterUpdateDetail();
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function openAddRecordModal(){
            $('#migrateRecordModal').modal('show');
            vm.addRecordData = null;
        }

        function addRecordToBox(){
            console.log('addRecordToBox START');
            console.log(vm.addRecordData);

            if(!vm.addRecordData){
                $.bootstrapGrowl('Code is empty, please try again', {ele: 'body', type: 'danger'});
                return
            }

            serverGateway.ajax('addRecordToBox',{id: vm.detail.id},{code: vm.addRecordData}).then(function(response){
                $.bootstrapGrowl('Updated', {ele: 'body', type: 'success'});
                $('#migrateRecordModal').modal('hide');
                openDialogAfterUpdateDetail();
            }, function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function openDialogAfterUpdateDetail(){
            PageViewModel.update();
            $timeout(function(){
                let targetId = vm.detail.id;
                vm.rows.forEach((row) => {
                    if(targetId == row.id){
                        vm.detail = row
                    }
                })
            },500);
            
        }
    }
})();
// (function() {
//     'use strict';

//     angular
//         .module('grid')
//         .directive('ctgCellBatchItems', ctgCellBatchItems);

//     function ctgCellBatchItems() {
//         var directive = {
//             restrict: 'E',
//             replace: true,
//             templateUrl: '../template/cellBatchItems.html'
//         };

//         return directive;
//     }
// })();
(function() {
    'use strict';

    angular
        .module('user')
        .controller('UserController', Controller);

    Controller.$inject = ['$scope','GridDataSource','ServerGateway','$q','config','PageViewModel','$timeout','$interval'];

    function Controller($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, $interval) {
        var serverGateway, vm = this;
        
        vm.rows = [];
        vm.sorts = [];
        vm.columns = config.columns;
        vm.setFilter   = PageViewModel.setFilter;
        vm.clearFilter = PageViewModel.clearFilter;
        vm.sortChange  = PageViewModel.sortChange;
        vm.isLoading   = PageViewModel._isLoading;
        vm.roleList = config.role_list;
        vm.inProcess = false;
        vm.manageableRoles = Object.keys(config.manageable_roles).map(function(_) { return config.manageable_roles[_]; });;
        vm.create_user_roles = config.create_user_roles;
        vm.newEmail = '';
        vm.newRoles = [];

        vm.detail = {};
        vm.detailRoles = [];
        vm.openUpdateUserModal = openUpdateUserModal;
        vm.openCreateUser = openCreateUser;
        vm.canRoleBeEdited = canRoleBeEdited;
        vm.updateRoles = updateRoles;
        vm.deleteUser = deleteUser;
        vm.closeModal = closeModal;
        vm.createUser = createUser;

        initialize();

        function initialize() {

            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'updateRole' : { path: 'users/updateRoles/{id}', method: 'POST'},
                    'deleteUser' : { path: 'users/deleteUser/{id}', method: 'DELETE'},
                    'createUser' : { path: 'users/create', method: 'POST'}
                }
            });


            vm.gridDataSource = new GridDataSource({
                resoucesUrl  : config.dataUrl,
                gateway      : serverGateway,
                perPage: 999
            });

            var fields = [];
            for(var i, c = config.columns.length; i < c; i++){
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function(){
                return PageViewModel.rows;
            }, function(rows) {
                vm.rows = rows;
            }), true;

            $timeout(function(){PageViewModel.update();}, 500);

            $interval(function () {
                PageViewModel.update();
            }, 5000);

        }

        function openUpdateUserModal(row){
            vm.detail = row;
            vm.detailRoles = [];
            vm.detail.role_id.split(', ').forEach(function(e){
                vm.detailRoles[e] = true;
            });
            $('#userDetailModal').modal('show');
        }

        function canRoleBeEdited(id){
            return !vm.manageableRoles.includes(id);
        }

        function updateRoles(){
            vm.inProcess = true;
            serverGateway.ajax('updateRole',{id: vm.detail.id},{roles: vm.detailRoles}).then(function(response){
                PageViewModel.update();
                $.bootstrapGrowl('User role updated', {ele: 'body', type: 'success'});
                closeModal();
                vm.inProcess = false;
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.inProcess = false;
            });
        }

        function deleteUser(){
            vm.inProcess = true;
            serverGateway.ajax('deleteUser',{id: vm.detail.id}).then(function(response){
                $.bootstrapGrowl('User deleted', {ele: 'body', type: 'success'});
                closeModal();
                vm.inProcess = false;
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.inProcess = false;
            });
        }

        function closeModal(){
            $('#userDetailModal').modal('hide');
            $('#createUserModal').modal('hide');
        }

        function openCreateUser(){
            $('#createUserModal').modal('show');
        }

        function createUser(){
            if(!vm.newEmail.trim()){
                $.bootstrapGrowl('Email is empty', {ele: 'body', type: 'error'});
                return;
            }
            if(!vm.newRoles || vm.newRoles.length == 0){
                $.bootstrapGrowl('Please select a role', {ele: 'body', type: 'error'});
                return;
            }
            serverGateway.ajax('createUser',{},{email: vm.newEmail, roles: vm.newRoles}).then(function(response){
                $.bootstrapGrowl('User created', {ele: 'body', type: 'success'});
                closeModal();
                vm.inProcess = false;
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.inProcess = false;
            });
        }
    }
})();
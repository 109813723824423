(function() {
    'use strict';

    angular
        .module('packaging')
        .controller('SimplePackagingController', Controller);

    Controller.$inject = ['GridDataSource', 'ServerGateway', '$q', 'config', '$scope'];

    function Controller(GridDataSource, ServerGateway, $q, config, $scope) {
        var pendingSort, serverGateway, lastDetail = null;
        var vm = this;

        vm.barcodeDel = barcodeDel;
        vm.barcodeApply = barcodeApply;
        vm.barcodeClearAll = barcodeClearAll;
        vm.returnToBarcodeScan = returnToBarcodeScan;
        vm.showRejectDialog = showRejectDialog;
        vm.saveReject = saveReject;
        vm.qcAccept = qcAccept;
        vm.bulkQCAccept = bulkQCAccept;
        vm.scrap = scrap;
        vm.wait = wait;
        vm.showWaitDialog = showWaitDialog;
        vm.ship = ship;
        vm.updateWaitingBatches = updateWaitingBatches;
        vm.updateCurrentWaitingBatch = updateCurrentWaitingBatch;
        vm.reloadPreview = reloadPreview;
        vm.saveMergeSlot = saveMergeSlot;
        vm.addSerialNumber = addSerialNumber;
        vm.invalidSerialNumber = invalidSerialNumber;
        vm.applyResultByIndex = applyResultByIndex;
        vm.getPrintLabels = getPrintLabels;
        vm.generateLabels = generateLabels;
        vm.printLabels = printLabels;
        vm.onChangeSerialNumber = onChangeSerialNumber;
        vm.serialNumberKeypress = serialNumberKeypress;

        vm.results = null;
        vm.currentSlotItem = null;
        vm.barcodeInput = '';
        vm.currentTransitionBatchId = null;
        vm.currentQueueId = null;
        vm.barcodeScanType = 'item';
        vm.showBarcodeReadDialog = false;
        vm.showProcessingDialog = false;
        vm.showActionDialog = false;
        vm.stationId = config.stationId;
        vm.currentWaitingBatchId = null;
        vm.currentWaitingBatchItems = [];
        vm.readyWaitingBatches = [];
        vm.mergeSlotCode = null;
        vm.mergeGroup = config.mergeGroup;
        vm.printLabelUrls = null;
        vm.printLabelButtonLoading = false;
        vm.printingBatchIds = [];

        vm.station = null;
        vm.packStationList = config.packStationList;
        vm.station = (typeof config.packStationList != 'undefined') ? config.packStationList[0].key : null;
        if(config.stationId && config.packStationList){
            var tempStations = config.packStationList.filter(function(item){
                return item.key == config.stationId;
            });
            if(typeof tempStations[0] != 'undefined' && tempStations[0]){
                vm.station = tempStations[0]['key'];
            }
        }

        vm.rejectRemarkList = [];
        /*vm.rejectRemarkList = [
            {'key':'1 - 塵', 'value':'塵', 'problem':'Printing Quality (Reprint)'},
            {'key':'2 - 有間', 'value':'有間', 'problem':'Printing Quality (Reprint)'},
            {'key':'4 - 移位／出界', 'value':'移位／出界', 'problem':'Printing Quality (Reprint)'},
            {'key':'5 - 化開', 'value':'化開', 'problem':'Printing Quality (Reprint)'},
            {'key':'6 - 不對色', 'value':'不對色', 'problem':'Printing Quality'},
            {'key':'7 - 擺錯殼／擺錯殻顏色', 'value':'擺錯殼／擺錯殻顏色', 'problem':'Printing Quality (Reprint)'},
            {'key':'8 - 隻殻有問題(有氣／有雜質／有花／有怪紋路／滲漏）', 'value':'隻殻有問題(有氣／有雜質／有花／有怪紋路／滲漏）', 'problem':'Printing Quality (Reprint)'},
            {'key':'9 - 甩色', 'value':'甩色', 'problem':'Printing Quality (Reprint)'},
            {'key':'10 - Artwork 有問題', 'value':'Artwork 有問題', 'problem':'Printing Quality'},
            {'key':'11 - 有黑色烘', 'value':'有黑色烘', 'problem':'Printing Quality (Reprint)'},
            {'key':'14 - White Design (but with white product i.e folio)', 'value':'White Design (but with white product i.e folio)', 'problem':'Printing Quality'},
            {'key':'16 - Printing Not Found', 'value':'找不到手機殻及重印 Printing Not Found and Reprint', 'problem':'Printing Quality (Reprint)'},
            {'key':'17 - 印後有bubble', 'value':'印後有bubble', 'problem':'Printing Quality (Reprint)'},
            {'key':'18 - COPYRIGHT Problem', 'value':'18 - COPYRIGHT Problem'},
            {'key':'19. 相框白底漏罅', 'value':'19. 相框白底漏罅'},
            {'key':'other', 'value':'Other (其他)', 'problem':'Printing Quality'},
        ];*/

        initialize();

        function initialize() {
            // sever side gateway setup
            serverGateway = new ServerGateway({
                endPoints: {
                    'simpleLoadStation': { path: 'pack-stations/{id}/simple-load',  method: 'POST' },
                    'simpleReject': { path: 'pack-stations/{id}/simple-reject', method: 'POST' },
                    'simpleScrap': { path: 'pack-stations/{id}/simple-scrap', method: 'POST' },
                    'simpleWait': { path: 'pack-stations/{id}/simple-wait', method: 'POST' },
                    'simpleMergeSlot': { path: 'pack-stations/{id}/simple-merge-slot', method: 'POST' },
                    'simpleShip': { path: 'pack-stations/{id}/simple-ship', method: 'POST' },
                    'simpleqQCAccept': { path: 'pack-stations/{id}/simple-qc-accept', method: 'POST' },
                    'updateWaitingBatches': { path: 'packing/waiting-batch-status', method: 'GET' },
                    'updateWaitingBatcheItems': { path: 'packing/waiting-batch-items-status', method: 'GET' },
                    'requestPreview': { path: 'order-items/{id}/requestPreview', method: 'POST' },
                    'qaRejectList': {path: 'qaRejectList', method: 'GET'},
                    'syncSerialNumber': { path: 'order-items/{id}/serial-number',  method: 'POST' },
                    'getShippingLabels': {path: 'pack-stations/{id}/getShippingLabels', method: 'POST'},
                    'generateLabels': {path: 'pack-stations/{id}/generateLabels', method: 'POST'},
                    'printLabels': {path: 'pack-stations/{id}/printLabels', method: 'POST'},
                    'associateSerialNumber': { path: 'function/associate-serial-number', method: 'POST'},
                }
            });

            window.addEventListener('keydown', barcodeOnKeydown, false)
            vm.showBarcodeReadDialog = true;
            $("#barcodeInput").focus();

            setInterval(vm.updateWaitingBatches, 10000);
            vm.updateWaitingBatches();
            if (config.code) {
                vm.barcodeInput = config.code;
                vm.barcodeApply();
                window.history.pushState(null, null, window.location.pathname);
            }

            serverGateway.ajax('qaRejectList').then(function(response){
                vm.rejectRemarkList = response.data.data
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });

            $('#barcodeScanType').on({
                'change': function(e){
                    e.preventDefault();

                    localStorage.setItem('packaging-barcode-scan-type', $(this).val());
                }
            });
            var tempPackagingBarcodeScanType = localStorage.getItem('packaging-barcode-scan-type');
            if(typeof tempPackagingBarcodeScanType != 'undefined' && tempPackagingBarcodeScanType){
                $('#barcodeScanType').val(tempPackagingBarcodeScanType);
            }

        }

        function barcodeOnKeydown(event) {
            let char = String.fromCharCode(event.keyCode)
            if (window.document.activeElement && window.document.activeElement.id == 'mergeTransitionQueueCode') {
                if (event.keyCode == 13) {
                    $("#mergeSlotCode").focus();
                    return;
                }
            }
            if (window.document.activeElement && window.document.activeElement.id == 'mergeSlotCode') {
                if (event.keyCode == 13) {
                    vm.saveMergeSlot();
                    return;
                }
            }
            if ($(event.target).hasClass('serial-number-input')) {
                if (event.keyCode == 13) {
                    // enter
                    let inputName = $(event.target).attr('name')
                    let saveId = '#' + inputName + '-save'
                    angular.element(document.querySelector(saveId)).triggerHandler('click');
                    return;
                }
            }
            if (!vm.showBarcodeReadDialog) {
                return;
            }
            
            if (event.keyCode == 13) {
                // enter
                vm.barcodeApply();
            } else if ($("#barcodeInput:focus").length) {
                return;
            } else if (event.keyCode == 8 || event.keyCode == 46) {
                // backspace or delete
                vm.barcodeDel();
            } else if (event.keyCode == 27) {
                // escape
                vm.barcodeClearAll();
            } else if (char.match(/^\d$/)) {
                // digit
                vm.barcodeInput = vm.barcodeInput + char;
            }
            $scope.$apply();
        }

        function barcodeClearAll() {
            vm.barcodeInput = '';
            return false;
        }

        function barcodeDel() {
            if (vm.barcodeInput != null && vm.barcodeInput.length > 0) {
                vm.barcodeInput = vm.barcodeInput.substring(0, vm.barcodeInput.length - 1);
            }
            return false;
        }

        function applyResultByIndex(i) {
            applyResult(vm.results[i])
        }

        function applyResult(result) {
            if (result.remark == 'Special Handle Manual Order') {
                // vm.showBarcodeReadDialog = true;
                // vm.showProcessingDialog = false;
                // vm.barcodeInput = '';
                // $.bootstrapGrowl(result.item.order.refId + " : 特別處理", {ele: 'body', type: 'success'});
                // return;
            }
            vm.item = result.item;
            vm.printingBatchUrl = (typeof result.printingBatchUrl != 'undefined') ? result.printingBatchUrl : null;
            if (result.items) {
                vm.items = result.items;
            } else {
                if (result.item) {
                    vm.items = [result.item];
                } else {
                    vm.items = [];
                }
            }
            vm.items.sort(function (a, b) {
                if (a.id == vm.item.id) {
                    return -1;
                } else {
                    return 0;
                }
            })
            vm.orderId = null;
            vm.orderRemark = null;
            vm.createdAt = null;
            vm.orderExtraData = null;
            vm.orderAlertRemarks = null;
            vm.shippingMethod = null;
            vm.deliveryId = null;
            if (vm.items) {
                vm.items.forEach(function (item, index) {
                    vm.orderId = item.order.refId;
                    vm.orderRemark = item.order.remarks;
                    vm.orderAlertRemarks = item.order.alertRemarks;
                    vm.createdAt = item.order.createdAt;
                    vm.shippingMethod = item.delivery.shippingMethod;
                    vm.deliveryId = item.delivery.id;
                    if (item.order.extraData) {
                        let extraData = item.order.displayExtraData;
                        if (extraData) {
                            vm.orderExtraData = [];
                            for (var key in extraData) {
                                vm.orderExtraData.push({"key": key, "value": extraData[key]})
                            }
                        }
                    }
                })
            }

            vm.alreadyOnMergeSlot = false;
            if (vm.item) {
                vm.item.productionItems.forEach((productionItem) => {
                    if (productionItem.mergeSlots && productionItem.mergeSlots.length) {
                        vm.alreadyOnMergeSlot = true;
                    }
                })
            }

            if(vm.printingBatchUrl && vm.items){
                $.each(vm.printingBatchUrl, function(k, v){
                    vm.items.forEach(function (item, index) {
                        if(k == item.id){
                            item.printingBatchUrl = v;
                            vm.items[index] = item;
                        }
                    });
                });
            }

            vm.mergeSlotName = result.mergeSlotName;
            vm.mergeSlotId = result.mergeSlotId;
            vm.message = result.message;
            vm.remark = result.remark;
            vm.action = result.action;
            vm.packActionLogId = result.packActionLogId;
        }

        function barcodeApply() {
            vm.showBarcodeReadDialog = false;
            vm.showProcessingDialog = true;
            vm.currentTransitionBatchId = null;
            vm.currentQueueId = null;
            
            serverGateway.ajax('simpleLoadStation', {id: vm.stationId}, {queue: vm.barcodeInput, mergeGroup: vm.mergeGroup, barcodeScanType: vm.barcodeScanType}).then(function(response) {
                if(vm.barcodeScanType != 'batch'){
                    vm.currentQueueId = $.trim(vm.barcodeInput);
                } else {
                    vm.currentTransitionBatchId = $.trim(vm.barcodeInput);
                }
                vm.barcodeInput = '';
                vm.showBarcodeReadDialog = false;
                vm.showProcessingDialog = false;
                vm.showActionDialog = true;
                vm.results = response.data.results;
                vm.batchInfo = response.data.batchInfo;
                vm.printingBatchUrl = response.data.printingBatchUrl;
                vm.hasBulkOrder = false;
                let lastResult = null
                for (let i in vm.results) {
                    let reverseI = vm.results.length - i - 1;
                    if (lastResult && vm.results[reverseI] && vm.results[reverseI].item) {
                        vm.results[reverseI].item.serialNumberNextFocus = '[name=serialNumber-'+lastResult.item.id+']';
                    }
                    if (vm.results[i].item && vm.results[i].item.order && vm.results[i].item.order.isBulkOrder) {
                        vm.hasBulkOrder = true;
                    }
                    if (vm.results[reverseI].item) {
                        lastResult = vm.results[reverseI]
                    }
                }

                if(vm.barcodeScanType != 'batch'){
                    applyResultByIndex(0);
                } else {
                    var printingBatchIds = [];
                    if(vm.printingBatchUrl && Object.keys(vm.printingBatchUrl).length > 0){
                        $.each(vm.printingBatchUrl, function(k, v){
                            $.each(v, function(i, j){
                                printingBatchIds.push(j['batch_id']);
                            });
                        });
                        if(printingBatchIds && printingBatchIds.length > 0){
                            vm.getPrintLabels(vm.barcodeScanType, vm.currentTransitionBatchId);
                        }
                        vm.printingBatchIds = printingBatchIds;
                    }
                }
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.showBarcodeReadDialog = true;
                vm.showProcessingDialog = false;
            });
            
        }

        function returnToBarcodeScan() {
            vm.showBarcodeReadDialog = true;
            vm.showActionDialog = false;
        }

        function showRejectDialog(item) {
            if(typeof item != 'undefined' && item){
                vm.currentSlotItem = item;
            }
            $('#rejectReasonDialog').modal('show');
        }

        function saveReject() {
            var remarkRecords = vm.rejectRemarkList.filter(function (rejectRemark) { return rejectRemark.key == vm.remark})
            var problem = 'Printing Quality';
            if (remarkRecords && remarkRecords.length) {
                problem = remarkRecords[0].problem;
            }
            var remark = vm.remark;
            // if (vm.remark == 'other') {
            //     remark = vm.remarkOther;
            // }
            if (!remark || remark == '') {
                alert("Please input remark.");
                return;
            }
            vm.showProcessingDialog = true;
            vm.showActionDialog = false;
            $('#rejectReasonDialog').modal('hide');

            if(typeof vm.currentSlotItem != 'undefined' && vm.currentSlotItem){
                invalidSerialNumber(vm.currentSlotItem);
            } else {
                invalidSerialNumber(vm.item);
            }

            var tempData = { 
                problem: problem, 
                remark: remark, 
                remarkOther: vm.remarkOther, 
                'trashItem': 1, 
                'packActionLogId': vm.packActionLogId,
                'saveQCRecord': vm.currentSlotItem ? vm.currentSlotItem.order.isBulkOrder : vm.item.order.isBulkOrder
            };
            if(typeof vm.currentSlotItem != 'undefined' && vm.currentSlotItem){
                tempData['transitionQueueItemId'] = vm.currentSlotItem.transitionQueueItemId;
            } else {
                tempData['transitionQueueItemId'] = vm.item.transitionQueueItemId;
            }

            serverGateway.ajax('simpleReject', { id: vm.stationId }, tempData).then(function (response) {
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                vm.showBarcodeReadDialog = true;
                vm.showProcessingDialog = false;
                if (vm.currentTransitionBatchId) {
                    vm.barcodeInput = vm.currentTransitionBatchId;
                    vm.barcodeApply()
                }
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.showProcessingDialog = false;
                vm.showActionDialog = true;
            });
            return false;
        }

        function bulkQCAccept() {
            if (vm.QCCompleteLoading) {
                return;
            }
            if (!confirm('Confirm?')) {
                return;
            }

            vm.QCCompleteLoading = true;
            vm.showProcessingDialog = true;
            vm.showActionDialog = false;
            let transitionQueueItemIds = []
            for (let i in vm.results) {
                let result = vm.results[i]
                if (result.item && result.item.order && result.item.order.isBulkOrder && result.item.transitionQueueItemId) {
                    transitionQueueItemIds.push(result.item.transitionQueueItemId)
                }
            }
            Promise.all(transitionQueueItemIds.map((transitionQueueItemId) => {
                return serverGateway.ajax('simpleqQCAccept', { id: vm.stationId }, { transitionQueueItemId: transitionQueueItemId, 'packActionLogId': vm.packActionLogId }).then(function (response) {
                        $.bootstrapGrowl('[' + transitionQueueItemId + '] Success', {ele: 'body', type: 'success'});
                    }, function (response) {
                        $.bootstrapGrowl('[' + transitionQueueItemId + '] ' + response.data.message, {ele: 'body', type: 'error'});
                    });
            })).then((response) => {
                vm.QCCompleteLoading = false;
                $.bootstrapGrowl('All Success', {ele: 'body', type: 'success'});
                vm.showBarcodeReadDialog = true;
                vm.showProcessingDialog = false;
            }, (response) => {
                vm.QCCompleteLoading = false;
                vm.showProcessingDialog = false;
                vm.showActionDialog = true;
                $.bootstrapGrowl('Failed', {ele: 'body', type: 'error'});
            })
        }

        function qcAccept() {
            vm.showProcessingDialog = true;
            vm.showActionDialog = false;

            serverGateway.ajax('simpleqQCAccept', { id: vm.stationId }, { transitionQueueItemId: vm.item.transitionQueueItemId, 'packActionLogId': vm.packActionLogId }).then(function (response) {
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                vm.showBarcodeReadDialog = true;
                vm.showProcessingDialog = false;
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.showProcessingDialog = false;
                vm.showActionDialog = true;
            });
            return false;
        }

        function scrap() {
            vm.showProcessingDialog = true;
            vm.showActionDialog = false;
            invalidSerialNumber(vm.item);

            serverGateway.ajax('simpleScrap', { id: vm.stationId }, { transitionQueueItemId: vm.item.transitionQueueItemId, 'packActionLogId': vm.packActionLogId }).then(function (response) {
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                vm.showBarcodeReadDialog = true;
                vm.showProcessingDialog = false;
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.showProcessingDialog = false;
                vm.showActionDialog = true;
            });
            return false;
        }

        function showWaitDialog() {
            vm.mergeSlotCode = null;
            vm.mergeTransitionQueueCode = null;
            $('#waitDialog').modal('show');
            $("#mergeTransitionQueueCode").focus();
        }

        function wait() {
            vm.showProcessingDialog = true;
            vm.showActionDialog = false;

            var waitTransitionQueueCode = prompt('Queue Code:');
            if (!waitTransitionQueueCode) {
                vm.showProcessingDialog = false;
                vm.showActionDialog = true;
                return false;
            }

            serverGateway.ajax('simpleWait', {id: vm.stationId}, {transitionQueueItemId: vm.item.transitionQueueItemId, 'packActionLogId' : vm.packActionLogId, 'waitTransitionQueueCode' : waitTransitionQueueCode}).then(function(response) {
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                vm.showBarcodeReadDialog = true;
                vm.showProcessingDialog = false;
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.showProcessingDialog = false;
                vm.showActionDialog = true;
            });
            return false;
        }

        function saveMergeSlot() {
            if (vm.savingMergeSlot) return;
            if (vm.alreadyOnMergeSlot && !confirm('Already on merge slot, confirm reassign?')) {
                return;
            }
            vm.showProcessingDialog = true;
            vm.showActionDialog = false;
            vm.savingMergeSlot = true;

            serverGateway.ajax('simpleMergeSlot', {id: vm.stationId}, {transitionQueueItemId: vm.item.transitionQueueItemId, 'packActionLogId' : vm.packActionLogId, 'mergeTransitionQueueCode' : vm.mergeTransitionQueueCode, 'mergeSlotId': vm.mergeSlotId, 'mergeSlotCode': vm.mergeSlotCode}).then(function(response) {
                vm.savingMergeSlot = false;
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                $('#waitDialog').modal('hide');
                vm.showBarcodeReadDialog = true;
                vm.showProcessingDialog = false;
            }, function (response) {
                vm.savingMergeSlot = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.showProcessingDialog = false;
                vm.showActionDialog = true;
            });
            return false;
        }

        function ship() {
            vm.showProcessingDialog = true;
            vm.showActionDialog = false;

            serverGateway.ajax('simpleShip', {id: vm.stationId}, {transitionQueueItemId: vm.item.transitionQueueItemId, 'packActionLogId' : vm.packActionLogId}).then(function(response) {
                $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                vm.showBarcodeReadDialog = true;
                vm.showProcessingDialog = false;
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.showProcessingDialog = false;
                vm.showActionDialog = true;
            });
            return false;
        }

        function updateWaitingBatches() {
            if (vm.waitingBatchLoading) {
                return false;
            }
            vm.waitingBatchLoading = true;
            serverGateway.ajax('updateWaitingBatches', null, null).then(function(response) {
                vm.waitingBatchLoading = false;
                vm.readyWaitingBatches = response.data.data;
                if (vm.currentWaitingBatchId) {
                    vm.updateCurrentWaitingBatch(vm.currentWaitingBatchId);
                }
            }, function (response) {
                vm.waitingBatchLoading = false;
            });
        }

        function updateCurrentWaitingBatch(waitingBatchId) {
            if (vm.waitingBatchItemLoading) {
                return false;
            }
            vm.waitingBatchItemLoading = true;
            vm.currentWaitingBatchId = null;
            vm.currentWaitingBatchItems = [];
            serverGateway.ajax('updateWaitingBatcheItems', null, {waitingBatchId: waitingBatchId}).then(function(response) {
                vm.waitingBatchItemLoading = false;
                vm.currentWaitingBatchItems = response.data.data.cnReadyItems;
                if (response.data.data.cnReadyItems && response.data.data.cnReadyItems.length) {
                    vm.currentWaitingBatchId = waitingBatchId;
                } else {
                    vm.currentWaitingBatchId = null;
                }
            }, function (response) {
                vm.waitingBatchItemLoading = false;
            });
        }

        function reloadPreview() {
            if (vm.item) {
                serverGateway.ajax('requestPreview', {id: vm.item.id}).then(function(response) {
                    $.bootstrapGrowl('Success', {ele: 'body', type: 'success'});
                    var temp = vm.item.preview;
                    vm.item.preview = null;
                    vm.item.preview = temp;
                }, function (response) {
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
                
            }
            return false;
        }
        
        function addSerialNumber(orderItem, productionItem = null, mode = 'single', prefix = null) {
            if(productionItem){
                submitSerialNumber(orderItem, productionItem);
                return;
            }
            let serialNumberSelector = document.querySelector((mode == 'batch' ? '.dialog-mode-batch' : '.dialog-mode-item') + ' [name="serialNumber-' + orderItem.id + '"]');
            let serialNumber = serialNumberSelector.value;
            const regex = /casetify\.com\/auth\/([\w\-]*)/igm;
            let regexresult = regex.exec(serialNumber)
            if (regexresult && regexresult[1]) {
                serialNumber = regexresult[1]
            }

            if (mode == 'single') {
                if (orderItem.serialNumber == serialNumber) return;
                if (orderItem.serialNumber && !confirm('Confirm Update?')) return;
            }
            orderItem.serialNumberLoading = true
            vm.serialNumberLoading = true;
            if (orderItem.serialNumberNextFocus) {
                $(orderItem.serialNumberNextFocus).trigger('focus')
            }
            serverGateway.ajax('syncSerialNumber', { id: orderItem.id }, { serialNumber: serialNumber, packStationId: vm.stationId })
                .then(function (response) {
                    orderItem.serialNumberLoading = false
                    vm.serialNumberLoading = false;
                    if (response.data.success) {
                        orderItem.serialNumber = serialNumber
                        $.bootstrapGrowl((prefix ? ("[" + prefix + "] ") : "") + "Serial Number updated successfully", { ele: 'body', type: 'success' });
                    } else {
                        $.bootstrapGrowl((prefix ? ("[" + prefix + "] ") : "") + response.data.message, { ele: 'body', type: 'error' });
                    }
                    serialNumberSelector.value = response.data.data && response.data.data.serial_number ? response.data.data.serial_number : orderItem.serialNumber;
                    orderItem.serialNumber = serialNumberSelector.value
                }, function (response) {
                    orderItem.serialNumberLoading = false
                    vm.serialNumberLoading = false;
                    serialNumberSelector.value = response.data.data && response.data.data.serial_number ? response.data.data.serial_number : orderItem.serialNumber;
                    orderItem.serialNumber = serialNumberSelector.value
                    if (mode == 'batch') {
                        orderItem.serialNumber = ''
                    }
                    $.bootstrapGrowl((prefix ? ("[" + prefix + "] ") : "") + response.data.message, { ele: 'body', type: 'error' });
                });
        }

        function submitSerialNumber(orderItem, productionItem, locationId = config.stationLocationId){
            const sn = vm.serialNumber[productionItem.id];
            if(!sn){
                $.bootstrapGrowl('Please fill in Serial Number', {ele: 'body', type: 'error'});
                return;
            }
            if(vm.serialNumberLoading){
                return;
            }
            if(!confirm('Confirm update?')){
                return;
            }
            const ctf = `CTF-${productionItem.printable.artworkId}-${productionItem.printable.product}`;
            vm.serialNumberLoading = true;
            serverGateway.ajax('associateSerialNumber', null, {sn: sn, ctf: ctf, upc: null, locationId: locationId}).then(function(response){
                if(response.data.status == 'OK'){
                    $.bootstrapGrowl('Associate Success!', {ele: 'body', type: 'success'});
                } else {
                    $.bootstrapGrowl(response.data.msg, {ele: 'body', type: 'error'});
                }
                vm.serialNumberLoading = false;
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.serialNumberLoading = false;
            });
        }

        function invalidSerialNumber(orderItem) {
            vm.serialNumberLoading = true;
            serverGateway.ajax('syncSerialNumber', { id: orderItem.id }, { status: false } )
                .then(function (response) {
                    vm.serialNumberLoading = false;
                    if (response.data.success) {
                        let serialNumberSelector = document.querySelector('[name="serialNumber-' + orderItem.id + '"]');
                        if(serialNumberSelector && serialNumberSelector.length){
                            serialNumberSelector.value = "";
                            $.bootstrapGrowl("Serial Number invalid successfully", { ele: 'body', type: 'success' });
                        }
                    } else {
                        $.bootstrapGrowl(response.data.message, { ele: 'body', type: 'error' });
                    }
                }, function (response) {
                    vm.serialNumberLoading = false;
                    $.bootstrapGrowl(response.data.message, { ele: 'body', type: 'error' });
                });
        }

        function getPrintLabels(barcodeScanType, transitionBatchId){
            vm.printLabelButtonLoading = true;
            
            serverGateway.ajax('getShippingLabels', { id: transitionBatchId }, { type: barcodeScanType }).then(function(response){
                if(response){
                    vm.printLabelButtonLoading = false;
                    vm.printLabelUrls = response.data;
                }
            }, function(response){
                vm.printLabelButtonLoading = false;

                $.bootstrapGrowl('Cannot get shipping labels', {ele: 'body', type: 'error'});
            });
        }

        function generateLabels(barcodeScanType, transitionBatchId, station, force){
            vm.printLabelButtonLoading = true;

            force = (typeof force != 'undefined') ? force : false;
            
            serverGateway.ajax('generateLabels',{ id: transitionBatchId }, { station: station, force: force, type: barcodeScanType }).then(function(response){
                if(response){
                    vm.printLabelButtonLoading = false;

                    if(typeof response.data.printJobs != 'undefined' && response.data.printJobs.length > 0){
                        $.bootstrapGrowl('Shipping label(s) generated', {ele: 'body', type: 'success'});
                    }

                    if(typeof response.data.errors != 'undefined' && response.data.errors.length === 0){
                        serverGateway.ajax('getShippingLabels',{id: transitionBatchId}, { type: barcodeScanType }).then(function(response){
                            if(response){
                                vm.printLabelButtonLoading = false;
                                vm.printLabelUrls = response.data;
                                if(typeof vm.printLabelUrls == 'undefined' || vm.printLabelUrls.length <= 0){
                                    $.bootstrapGrowl('No Shipping label(s) generated', {ele: 'body', type: 'error'});
                                }
                            }
                        }, function(response){
                            vm.printLabelButtonLoading = false;
                            $.bootstrapGrowl('Cannot get shipping labels', {ele: 'body', type: 'error'});
                        });
                    }

                }
            }, function(response){
                vm.printLabelButtonLoading = false;

                $.bootstrapGrowl('Cannot generate shipping labels', {ele: 'body', type: 'error'});
            });
        }
        function printLabels(barcodeScanType, transitionBatchId, station){
            vm.printLabelButtonLoading = true;
            
            serverGateway.ajax('printLabels',{id: transitionBatchId}, {station: station, type: barcodeScanType}).then(function(response){
                if(response){
                    vm.printLabelButtonLoading = false;

                    if(typeof response.data.printJobs != 'undefined' && response.data.printJobs.length > 0){
                        $.bootstrapGrowl('Shipping label(s) print jobs assigned', {ele: 'body', type: 'success'});
                    }

                    if(typeof response.data.errors != 'undefined' && response.data.errors.length === 0){
                        serverGateway.ajax('getShippingLabels',{id: transitionBatchId}, { type: barcodeScanType }).then(function(response){
                            if(response){
                                vm.printLabelButtonLoading = false;
                                vm.printLabelUrls = response.data;
                            }
                        }, function(response){
                            vm.printLabelButtonLoading = false;
                            $.bootstrapGrowl('Cannot get shipping labels', {ele: 'body', type: 'error'});
                        });
                    }
                }
            }, function(response){
                vm.printLabelButtonLoading = false;
                if(response.data && response.data.message){
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                    return;
                }
                $.bootstrapGrowl('Cannot print shipping labels', {ele: 'body', type: 'error'});
            });
        }

        function serialNumberKeypress(event, orderItem, productionItem = null){
            if(event.which == 13){//is pressed enter
                addSerialNumber(orderItem, productionItem);
            }
        }

        function onChangeSerialNumber(productionItem){
            const regex = /casetify\.com\/auth\/([\w\-]*)/igm;
            let regexresult = regex.exec(vm.serialNumber[productionItem.id]);
            if (regexresult && regexresult[1]) {
                vm.serialNumber[productionItem.id] = regexresult[1];
            }
        }
    }
})();

(function() {
    function indexOfView(id) {
        var result = -1;
        vm.views.forEach(function(view, i) {
            if (view.id == id) {
                result = i;
                return false;
            }
        })
        return result;
    }

    function viewGet(id) {
        var view = vm.views[indexOfView(id)];

        var deferred;

        // check cache
        if (view.view) {
            deferred = $.Deferred().resolve();
            // TODO: apply view

        } else {
            deferred = serverGateway.ajax('showDataGridView', {id: id})
                .done(function(result) {
                    // store the cache
                    view.view = result.view;
                    // apply view
                });
        }
        
        return deferred;
    }

    function viewCreate(name, select2) {
        var deferred = serverGateway.ajax('createDataGridView', {}, {
                name: name,
                view: {},
                gridName: 'order'
            })
            .done(done);

        return deferred;

        function done(result) {
            $scope.$apply(function() {
                vm.views.push({
                    id: result.entity.id,
                    text: name,
                    view: {}
                });

                $timeout(function() {
                    console.log('vm.currentView');
                    vm.currentView = result.entity.id
                });
            });
        }
    }

    function viewRemove(id) {
        var deferred = serverGateway.ajax('deleteDataGridView', { id: id })
            .done(function(result) {
                $scope.$apply(function() {
                    vm.views.splice(indexOfView(id), 1);
                });
            });

        return deferred;
    }
})();
(function(){
    'use strict';

    angular
        .module('setting')
        .controller('printingBatchGroupController', printingBatchGroupController);

        printingBatchGroupController.inject = ['$scope','GridDataSource','ServerGateway','$q','config','PageViewModel','$timeout','$interval'];

        function printingBatchGroupController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, $interval){
            var serverGateway, vm = this;

            vm.rows = [];
            vm.sorts = [];
            vm.columns = config.columns;
            vm.setFilter = PageViewModel.setFilter;
            vm.clearFilter = PageViewModel.clearFilter;
            vm.sortChange = PageViewModel.sortChange;
            vm.isLoading = PageViewModel._isLoading;
            vm.detail = {};
            vm.newList = "";

            vm.openDetailModal = openDetailModal;
            vm.updateList = updateList;
            vm.deleteList = deleteList;
            vm.openCreateModal = openCreateModal;
            vm.createList = createList;
            

            initialize();

            function initialize() {
                serverGateway = new ServerGateway({
                    baseUrl: '/api',
                    endPoints: {
                        'updateList': {path: 'printingBatchGroups/{id}', method:'POST'},
                        'deleteList': {path: 'printingBatchGroups/{id}', method:'DELETE'},
                        'createList': {path: 'printingBatchGroups/new', method: 'POST'},
                    }
                });

                console.log(config.dataUrl);
                vm.gridDataSource = new GridDataSource({
                    resoucesUrl : config.dataUrl,
                    gateway     : serverGateway,
                    perPage: 999
                });

                var fields = [];
                for(var i, c = config.columns.length; i < c; i++){
                    fields.push(config.columns[i].field);
                }
                vm.gridDataSource.setColumns(fields);

                PageViewModel.setGridDataSource(vm.gridDataSource);
                $scope.$watch(function(){
                    return PageViewModel.rows;
                }, function(rows) {
                    vm.rows = rows;
                }), true;

                $timeout(function(){PageViewModel.update();}, 500);

                $interval(function () {
                    PageViewModel.update();
                }, 5000);

            }

            function openDetailModal(row){
                vm.detail = row;
                $('#printingBatchGroupDetailModal').modal('show');
            }

            function openCreateModal(){
                vm.newList = "";
                $('#printingBatchGroupNewModal').modal('show');
            }

            function updateList(){
                console.log(vm.detail);
                serverGateway.ajax('updateList',{id: vm.detail.id},{detail: vm.detail}).then(function(response){
                    PageViewModel.update();
                    $.bootstrapGrowl('List Updated', {ele: 'body', type: 'success'});
                    closeModal();
                },function(response){
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            }

            function deleteList(){
                if(!confirm("Confirm?")) return;
                serverGateway.ajax('deleteList',{id: vm.detail.id}).then(function(){
                    PageViewModel.update();
                    $.bootstrapGrowl('List Deleted', {ele: 'body', type: 'success'});
                    closeModal();
                },function(response){
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                })
            }

            function createList(){
                if(!vm.newList){
                    $.bootstrapGrowl('Please insert the value', {ele: 'body', type: 'error'});
                }
                serverGateway.ajax('createList',{},{description: vm.newList}).then(function(response){
                    PageViewModel.update();
                    $.bootstrapGrowl('List created', {ele: 'body', type: 'success'});
                    closeModal();
                },function(response){
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                })
            }

            function closeModal(){
                $('#printingBatchGroupDetailModal').modal('hide');
                $('#printingBatchGroupNewModal').modal('hide');
            }
        }
})();
